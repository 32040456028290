import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../helpers/actionHelper";

import { brukerListSchema, brukerSchema } from "../constants/schemas";

// GET BRUKERE
export const GET_BRUKERE_REQUEST = "GET_BRUKERE_REQUEST";
export const GET_BRUKERE_SUCCESS = "GET_BRUKERE_SUCCESS";
export const GET_BRUKERE_FAILURE = "GET_BRUKERE_FAILURE";

export function getBrukere() {
  return dispatch =>
    axios
      .get(api.GET_BRUKERE_URL())
      .then(handle.success(dispatch, GET_BRUKERE_SUCCESS, brukerListSchema, {}))
      .catch(handle.error(dispatch, GET_BRUKERE_FAILURE));
}

// EDIT BRUKER
export const EDIT_BRUKERE_REQUEST = "EDIT_BRUKERE_REQUEST";
export const EDIT_BRUKERE_SUCCESS = "EDIT_BRUKERE_SUCCESS";
export const EDIT_BRUKERE_FAILURE = "EDIT_BRUKERE_FAILURE";

export function editBruker(brukerId, data) {
  return dispatch => {
    dispatch({
      type: EDIT_BRUKERE_REQUEST,
      payload: brukerId,
      meta: { brukerId }
    });
    return axios
      .put(api.EDIT_BRUKERE_URL(brukerId), data)
      .then(
        handle.success(dispatch, EDIT_BRUKERE_SUCCESS, brukerSchema, {
          brukerId
        })
      )
      .catch(handle.error(dispatch, EDIT_BRUKERE_FAILURE));
  };
}
