import * as React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import FakturaEditor from "./FakturaEditor";

const FakturaEditorDialog = ({ show, onHide = () => null, title = "Endre faktura", ...rest }) => {
  let fakturaDate = "";
  if (rest.editObject && rest.editObject.ar && rest.editObject.maned) {
    fakturaDate += `${moment()
      .month(rest.editObject.maned - 1) // subtract 1 as moment month is 0 based
      .format("MMMM")} ${rest.editObject.ar}`;
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      bsSize="large"
      dialogClassName="leieobjekt-dialog"
      backdrop="static"
      allowFullScreen={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {fakturaDate ? (
            <>
              {title} for utsending i <strong>{fakturaDate}</strong>
            </>
          ) : (
            title
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FakturaEditor {...rest} onCancel={onHide} slim />
      </Modal.Body>
    </Modal>
  );
};

FakturaEditorDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default FakturaEditorDialog;
