import { Component } from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import KodeAdminContainer from "./KodeAdminContainer";

class AlleKoderAdmin extends Component {
  state = {
    selectedKode: "1",
    koder: [
      {
        id: "1",
        kodeNavn: "koststeder",
        title: "Koststed",
        allowDelete: true,
        allowAdd: true,
        allowEdit: true,
        extraFields: [{ fieldName: "koordinator", title: "Koordinator" }]
      },
      { id: "2", kodeNavn: "kontonummere", title: "Kontonummer", allowAdd: true, allowDelete: true, allowEdit: true },
      { id: "3", kodeNavn: "kontraktTyper", title: "Kontrakttype", allowAdd: true, allowEdit: true },
      { id: "4", kodeNavn: "segmenter", title: "Segment", allowAdd: true, allowEdit: true },
      { id: "5", kodeNavn: "betalingTerminer", title: "Betalingtermin", allowAdd: true, allowEdit: true },
      { id: "6", kodeNavn: "faktureringTyper", title: "Faktureringtype", allowAdd: true, allowEdit: true },
      { id: "7", kodeNavn: "faktureringTidspunkter", title: "Faktureringtidspunkt", allowAdd: true, allowEdit: true },
      {
        id: "8",
        kodeNavn: "kontraktStatuser",
        title: "KontraktStatus",
        allowAdd: true,
        allowDelete: true,
        allowEdit: true
      },
      { id: "9", kodeNavn: "loggHendelseTyper", title: "LoggHendelseType", allowAdd: true, allowEdit: true },
      { id: "10", kodeNavn: "leieObjektTyper", title: "LeieObjektType", allowAdd: true, allowDelete: true },
      { id: "11", kodeNavn: "garantiTyper", title: "GarantiType", allowAdd: true, allowEdit: true },
      { id: "12", kodeNavn: "vernTyper", title: "VernType", allowAdd: true, allowEdit: true },
      { id: "13", kodeNavn: "fakturaStatuser", title: "Fakturastatus", allowAdd: true, allowEdit: true },
      { id: "14", kodeNavn: "filTyper", title: "Filtype", allowAdd: true, allowEdit: true },
      { id: "15", kodeNavn: "paragrafer", title: "Paragraf", allowAdd: true, allowEdit: true },
      { id: "16", kodeNavn: "pristyper", title: "Pristype", allowAdd: true, allowEdit: true },
      {
        id: "19",
        kodeNavn: "kontraktmaler",
        title: "KontraktMal",
        allowAdd: true,
        allowEdit: true,
        allowDelete: true,
        extraFields: [{ fieldName: "wordMal", title: "Word mal" }]
      }
    ]
  };

  render() {
    const { koder, selectedKode } = this.state;
    const selectedKoder = koder.filter(kode => kode.id === selectedKode)[0];

    return (
      <>
        <div className="pull-left">
          <FormGroup controlId="formControlsSelect">
            <ControlLabel>Velg kode</ControlLabel>
            <FormControl
              componentClass="select"
              value={selectedKode}
              placeholder="select"
              onChange={e => this.setState({ selectedKode: e.target.value })}
            >
              {koder.map(kode => (
                <option key={kode.id} value={kode.id}>
                  {kode.title}
                </option>
              ))}
            </FormControl>
          </FormGroup>
        </div>
        <div style={{ clear: "left" }}>
          <KodeAdminContainer
            kodeNavn={selectedKoder.kodeNavn}
            displayName={selectedKoder.title}
            allowDelete={selectedKoder.allowDelete}
            allowAdd={selectedKoder.allowAdd}
            allowEdit={selectedKoder.allowEdit}
            extraFields={selectedKoder.extraFields}
          />
        </div>
      </>
    );
  }
}

export default AlleKoderAdmin;
