import BymFormControl, { BymFormControlProps } from "./BymFormControl";
import { number, length } from "./Rules";

const formatWithSpaces = (text: string, format: RegExp) => {
  return text.replace(/\s+/g, "").replace(format, "$1 ").trimEnd();
};

function formatOrgNumber(orgNumber: string | undefined) {
  if (orgNumber && /^\d/.test(orgNumber?.trim?.() ?? "")) {
    return formatWithSpaces(orgNumber, /(.{3})/g);
  }

  return orgNumber;
}

const BymOrgNumberInput = ({ rules = [], ...props }: BymFormControlProps) => (
  <BymFormControl
    rules={[...rules, number(), length(9)]}
    onChange={(e: any) => {
      if (e?.target?.value) {
        e.target.value = e.target.value.replace(/\s/g, "");
        if (isNaN(e.target.value)) {
          e.target.value = e.target.value.replace(/\D/g, "");
        }
        if (e.target.value.length > 9) {
          e.target.value = e.target.value.substring(0, 9);
        }
      }
      return e;
    }}
    viewFormatter={formatOrgNumber}
    {...props}
    type="text"
  />
);

export default BymOrgNumberInput;
