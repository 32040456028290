import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import KodeAdmin from "../../components/Admin/KodeAdmin";
import { getKoder, postKode, deleteKode, updateKode } from "../../actions/koderAction";
import koderSelector from "../../selectors/koderSelector";

class KodeAdminContainer extends React.Component {
  componentDidMount() {
    const { getKoder: propGetKoder, kodeNavn } = this.props;
    propGetKoder(kodeNavn);
  }

  componentWillReceiveProps(nextProps) {
    nextProps.getKoder(nextProps.kodeNavn);
  }

  render = () => <KodeAdmin {...this.props} />;
}

const mapStateToProps = (state, { kodeNavn, ...ownPropsRest }) => ({
  kodeNavn,
  [kodeNavn]: koderSelector(state, kodeNavn) || [],
  ...ownPropsRest
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getKoder,
      postKode,
      updateKode,
      deleteKode
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(KodeAdminContainer);
