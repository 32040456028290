// Helper function to retrieve the name of the code with the supplied id
function getKodeNavn(koder, id) {
  if (!id) return null;
  if (!koder) return id;

  const kode = koder.find(k => k.id == id); // Compare int to string values
  return kode ? kode.navn : null;
}

function getFormattedLog(
  logg,
  kostSteder,
  kontoNummere,
  kontraktTyper,
  prisTyper,
  kontraktStatuser,
  faktureringTyper,
  faktureringTidspunkter,
  betalingTerminTyper
) {
  // Modify the log to display readable values instead of ID's
  const endringIdTilNavnMap = {
    KontraktTypeId: { name: "Kontrakttype", values: kontraktTyper },
    PrisTypeId: { name: "Pristype", values: prisTyper },
    KontraktStatusId: { name: "Kontraktstatus", values: kontraktStatuser },
    DepositumKonto: {
      name: "Depositumkonto",
      values: kontoNummere ? kontoNummere.map(k => ({ ...k, navn: `${k.id}: ${k.navn}` })) : null
    },
    KoststedId: {
      name: "Koststed",
      values: kostSteder ? kostSteder.map(k => ({ ...k, navn: `${k.id}: ${k.navn}` })) : null
    },
    FaktureringTypeId: { name: "Faktureringstype", values: faktureringTyper },
    FaktureringTidspunktId: { name: "Faktureringstidspunkt", values: faktureringTidspunkter },
    BetalingTerminId: { name: "Betalingstermin", values: betalingTerminTyper }
  };

  const readableKontraksLogg =
    logg && logg.length
      ? logg.map(hendelse => {
          if (hendelse.endringer) {
            const endringer = JSON.parse(hendelse.endringer);

            const readableEndringer = endringer.map(endring => {
              const endringIdTilNavn = endringIdTilNavnMap[endring.PropertyName];
              if (endringIdTilNavn) {
                return {
                  ...endring,
                  PropertyName: endringIdTilNavn.name,
                  OldValue: getKodeNavn(endringIdTilNavn.values, endring.OldValue),
                  NewValue: getKodeNavn(endringIdTilNavn.values, endring.NewValue)
                };
              }

              return endring;
            });

            return {
              ...hendelse,
              endringer: JSON.stringify(readableEndringer)
            };
          } else {
            return hendelse;
          }
        })
      : logg;

  return readableKontraksLogg;
}

export default getFormattedLog;
