import { Component, ComponentType } from "react";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { Route, Redirect, RouteProps } from "react-router-dom";

interface PublicRouteProps extends AuthContextProps, RouteProps {
  component: ComponentType<any>;
}

class PublicRoute extends Component<PublicRouteProps> {
  render() {
    const { component, render, isAuthenticated, ...rest } = this.props;
    if (isAuthenticated) {
      return <Redirect to="/" />;
    }
    if (render) {
      return <Route {...rest} render={render} />;
    }
    return <Route {...rest} render={this.renderComponent} />;
  }

  renderComponent = (props: any) => {
    const { component: Comp } = this.props;
    return <Comp {...props} />;
  };
}

export default withAuth(PublicRoute);
