import { combineReducers } from "redux";

import eiendommer from "./entities/eiendommer";
import kontrakter from "./entities/kontrakter";
import fakturaer from "./entities/fakturaer";
import vedlegg from "./entities/vedlegg";
import bilder from "../store/bilder/bilderEntityReducer";
import kommentarer from "./entities/kommentarer";
import leieObjekter from "./entities/leieObjekter";
import brukere from "./entities/brukere";
import logg from "../store/logg/loggEntityReducer";

export default combineReducers({
  eiendommer,
  kontrakter,
  fakturaer,
  vedlegg,
  bilder,
  kommentarer,
  leieObjekter,
  brukere,
  logg
});
