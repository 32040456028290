import PropTypes from "prop-types";
import PageSection from "../Layout/PageSection";
import EiendomMap from "./EiendomMap";

const EiendomMapSection = ({ eiendom }) => {
  if (!eiendom) return null;
  return (
    <PageSection title="Kart" mountOnEnter>
      {eiendom && <EiendomMap zoom={15} autoBounds eiendommer={eiendom} />}
    </PageSection>
  );
};

EiendomMapSection.propTypes = {
  eiendom: PropTypes.shape({})
};
EiendomMapSection.defaultProps = {
  eiendom: undefined
};

export default EiendomMapSection;
