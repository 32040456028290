import React from "react";
import { JsonObject } from "type-fest";
import BymFormControl from "./BymFormControl";

type BymCustomValidatorProps<T> = {
  validateFunction: (values: T) => string | null;
};

function BymCustomValidator<T = JsonObject>({ validateFunction }: BymCustomValidatorProps<T>) {
  const rule = (value: unknown, id: unknown, values: T) => validateFunction(values);
  return <BymFormControl hidelabel rules={[rule]} component={() => <></>} />;
}

export default BymCustomValidator;
