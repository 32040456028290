import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { take, orderBy } from "lodash-es";
import moment from "moment";

class ExpandableFakturaList extends React.Component {
  state = {
    listLength: undefined
  };

  render() {
    const { initialLength, ...restProps } = this.props;
    const { listLength = initialLength } = this.state;
    return (
      <FakturaList listLength={listLength} showAllClicked={() => this.setState({ listLength: 1000 })} {...restProps} />
    );
  }
}

ExpandableFakturaList.propTypes = {
  initialLength: PropTypes.number.isRequired
};

const getEnhancedFakturaStatusName = faktura => {
  if (faktura.fakturaStatusId === 3 && faktura.purreNivaa === "1") return "1. Purring";
  if (faktura.fakturaStatusId === 3 && faktura.purreNivaa === "2") return "2. Purring";
  if (faktura.fakturaStatusId === 3 && faktura.purreNivaa === "I") return "Inkasso";

  return faktura.fakturaStatus ? faktura.fakturaStatus.navn : "";
};

const FakturaList = ({ fakturaer = [], listLength = 10, showAllClicked = () => null }) => {
  if (fakturaer.length === 0) return "Ingen utestående betalinger funnet";
  const showFakturaer = take(orderBy(fakturaer, "fakturaDato"), listLength);
  const fakturaerView = showFakturaer.map(f => ({
    ...f,
    totalPris: f.ordrelinjer.reduce((acc, currItem) => acc + Number(currItem.enhetspris) * Number(currItem.antall), 0.0)
  }));

  return (
    <>
      <table className="simple-table">
        <thead>
          <tr>
            <th>Fakturadato</th>
            <th>Kontrakt</th>
            <th>Fakturanummer</th>
            <th>Status</th>
            <th>Pris</th>
          </tr>
        </thead>
        <tbody>
          {fakturaerView.map(f => (
            <tr key={f.id}>
              <td>{moment(f.fakturaDato).format("DD.MM.YYYY")}</td>
              <td>
                <Link to={`/kontrakter/${f.kontrakt ? f.kontrakt.id : ""}`}>{f.kontrakt ? f.kontrakt.tittel : ""}</Link>
              </td>
              <td>{f.fakturaNummer}</td>
              <td>
                <div className="fakturastatus-forfalt">{getEnhancedFakturaStatusName(f) || f.fakturaStatus.navn}</div>
              </td>
              <td>{f.totalPris.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {fakturaer.length !== showFakturaer.length && (
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <a
            onClick={e => {
              showAllClicked(e);
              e.preventDefault();
            }}
            href=""
          >
            <strong>Vis alle</strong>
          </a>
        </div>
      )}
    </>
  );
};

FakturaList.propTypes = {
  fakturaer: PropTypes.arrayOf(PropTypes.object).isRequired,
  listLength: PropTypes.number.isRequired,
  showAllClicked: PropTypes.func.isRequired
};

export default ExpandableFakturaList;
