/* eslint-disable react-hooks/exhaustive-deps */
import MapGrayscale from "./mapgrayscale.png";
import MapFlyfoto from "./mapflyfoto.png";
import { ReactComponent as MapLayerIcon } from "./kartlag.svg";
import { useLeaflet } from "react-leaflet";
import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import "./BymLayersControll.scss";

function BymLayersControll() {
  enum LayerType {
    GrayScale,
    AerialPhoto
  }
  const { map } = useLeaflet();
  const controlRef = useRef(null);
  const [layerType, setLayerType] = useState(LayerType.GrayScale);
  const urlGrayscale = "https://cache.kartverket.no/v1/wmts/1.0.0/topograatone/default/webmercator/{z}/{y}/{x}.png";
  const urlAerialPhoto =
    "https://opencache.statkart.no/gatekeeper/gk/gk.open_nib_web_mercator_wmts_v2?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=topo4&STYLE=default&FORMAT=image/jpgpng&TILEMATRIXSET=GoogleMapsCompatible&tilematrix={getMatrix}&TileRow={y}&TileCol={x}";

  const optionGrayscale = {
    opacity: 0.8,
    maxNativeZoom: 18,
    attribution: '&copy; <a href="http://www.kartverket.no/">Kartverket</a>',
    id: "grayscale",
    maxZoom: 21
  };

  const optionAearialPhoto = {
    maxNativeZoom: 18,
    attribution: '&copy; <a href="https://www.norgeibilder.no/">Kartverket, NIBIO og Statens vegvesen</a>',
    getMatrix: (data: any) => `${`0${data.z}`.slice(-2)}`,
    id: "aerialphoto",
    maxZoom: 21
  };

  const layerGrayscale = L.tileLayer(urlGrayscale, optionGrayscale);
  const layerAerialPhoto = L.tileLayer(urlAerialPhoto, optionAearialPhoto);

  const toggleLayer = () => {
    if (layerType === LayerType.AerialPhoto) {
      setLayerType(LayerType.GrayScale);
    } else {
      setLayerType(LayerType.AerialPhoto);
    }
  };

  useEffect(() => {
    if (!map) {
      return;
    }
    if (controlRef.current) {
      L.DomEvent.disableClickPropagation(controlRef.current);
    }
    if (layerType === LayerType.GrayScale) {
      map.removeLayer(layerAerialPhoto);
      map.addLayer(layerGrayscale);
    } else {
      map.removeLayer(layerGrayscale);
      map.addLayer(layerAerialPhoto);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      map.removeLayer(layerAerialPhoto);
      map.removeLayer(layerGrayscale);
    };
  }, [layerType]);

  return (
    <div className="bym-map-layer-controll" ref={controlRef}>
      <button onClick={toggleLayer} type="button">
        {layerType === LayerType.GrayScale && <img src={MapFlyfoto} alt="MapFlyfoto" />}
        {layerType === LayerType.AerialPhoto && <img src={MapGrayscale} alt="MapGrayscale" />}
        <MapLayerIcon />
      </button>
    </div>
  );
}

export default BymLayersControll;
