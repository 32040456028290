import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import "./FakturaTotal.css";

const FakturaTotal = ({ fakturaer }) => {
  // Regner ut totalpris for eksterne fakturaer
  const totalEksternPrice = fakturaer.reduce((acc, currItem) => {
    if (currItem.kontrakt.faktureringTypeId === 31002) {
      return (
        acc +
        currItem.ordrelinjer.reduce(
          (acc2, currOrdrelinje) => acc2 + currOrdrelinje.enhetspris * currOrdrelinje.antall,
          0.0
        )
      );
    }
    return acc + 0.0;
  }, 0.0);

  // Regner ut totalpris for interne fakturaer
  const totalInternPrice = fakturaer.reduce((acc, currItem) => {
    if (currItem.kontrakt.faktureringTypeId === 31001) {
      return (
        acc +
        currItem.ordrelinjer.reduce(
          (acc2, currOrdrelinje) => acc2 + currOrdrelinje.enhetspris * currOrdrelinje.antall,
          0.0
        )
      );
    }
    return acc + 0.0;
  }, 0.0);

  // Regner ut totalpris for ansatte med trekk i lønn
  const totalSalaryDeduct = fakturaer.reduce((acc, currItem) => {
    if (currItem.kontrakt.faktureringTypeId === 31003) {
      return (
        acc +
        currItem.ordrelinjer.reduce(
          (acc2, currOrdrelinje) => acc2 + currOrdrelinje.enhetspris * currOrdrelinje.antall,
          0.0
        )
      );
    }
    return acc + 0.0;
  }, 0.0);

  return (
    <Row className="uge-fakturatotal">
      <Row>
        <Col md={12}>
          <div className="totalsum-info">Totalene er basert på synlige rader i tabellen over.</div>
        </Col>
      </Row>
      <Row>
        <Col md={8}>Total ansatt med trekk i lønn:</Col>
        <Col md={4} className="uge-fakturatotal-sum-align">
          {totalSalaryDeduct.toFixed(2)} kr
        </Col>
      </Row>
      <Row>
        <Col md={8}>Total intern fakturering:</Col>
        <Col md={4} className="uge-fakturatotal-sum-align">
          {totalInternPrice.toFixed(2)} kr
        </Col>
      </Row>
      <Row>
        <Col md={8}>Total ekstern fakturering:</Col>
        <Col md={4} className="uge-fakturatotal-sum-align">
          {totalEksternPrice.toFixed(2)} kr
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={8}>Totalt fakturert:</Col>
        <Col md={4} className="uge-fakturatotal-sum-align">
          {(totalEksternPrice + totalInternPrice + totalSalaryDeduct).toFixed(2)} kr
        </Col>
      </Row>
    </Row>
  );
};

FakturaTotal.propTypes = {
  fakturaer: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default FakturaTotal;
