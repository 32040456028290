import * as React from "react";
import { Button } from "react-bootstrap";
import "./KodeAdminDetails.css";
import { PropTypes } from "prop-types";
import { getFormatedDateTime } from "../../utils/dateUtils";

const KodeAdminDetails = ({ kodeObject, extraFields = [], allowEdit = false, onEditClicked }) => (
  <div className="kode-details">
    <div>
      <strong>id: </strong>
      <span>{kodeObject.id}</span>
    </div>
    <div>
      <strong>Navn: </strong>
      <span>{kodeObject.navn}</span>
    </div>
    {extraFields &&
      extraFields.map(field => (
        <div>
          <strong>{field.title}: </strong>
          <span>{kodeObject[field.fieldName]}</span>
        </div>
      ))}
    <div>
      <strong>Opprettet av: </strong>
      <span>{kodeObject.opprettetAvNavn}</span>
    </div>
    <div>
      <strong>Sist endret av: </strong>
      <span>{kodeObject.sistEndretAvNavn}</span>
    </div>
    <div>
      <strong>Sist endret dato: </strong>
      <span>{getFormatedDateTime(kodeObject.sistEndretDato)}</span>
    </div>
    {allowEdit && (
      <Button onClick={onEditClicked} bsStyle="bym-action">
        Rediger
      </Button>
    )}
  </div>
);

KodeAdminDetails.propTypes = {
  kodeObject: PropTypes.shape({}).isRequired,
  extraFields: PropTypes.arrayOf({}),
  allowEdit: PropTypes.bool,
  onEditClicked: PropTypes.func.isRequired
};
KodeAdminDetails.defaultProps = {
  extraFields: [],
  allowEdit: false
};

export default KodeAdminDetails;
