import { map, orderBy } from "lodash-es";
import { createSelector } from "reselect";
import makeGetAdvancedList from "./advancedListSelector";

const getFakturaIds = state => state.fakturaer.items;
const getFakturaEntities = state => state.entities.fakturaer;
const getSort = state => state.fakturaer.sort;

const getEnhancedFakturaStatusName = faktura => {
  if (faktura.fakturaStatusId === 3 && faktura.purreNivaa === "1") return "1. Purring";

  if (faktura.fakturaStatusId === 3 && faktura.purreNivaa === "2") return "2. Purring";

  if (faktura.fakturaStatusId === 3 && faktura.purreNivaa === "I") return "Inkasso";

  return faktura.fakturaStatus ? faktura.fakturaStatus.navn : "";
};

const getFakturaer = createSelector([getFakturaIds, getFakturaEntities, getSort], (ids, entities, sort) => {
  const cellKey = (sort && sort.cellKey) || "tittel";
  const orders = (sort && sort.orders) || "asc";
  return orderBy(
    map(ids, id => {
      const faktura = entities[id];
      return { ...faktura, utvidetStatus: getEnhancedFakturaStatusName(faktura) };
    }),
    cellKey,
    orders
  );
});

export const getFilteredFakturaer = filter =>
  createSelector([getFakturaIds, getFakturaEntities, getSort], (ids, entities, sort) => {
    const cellKey = (sort && sort.cellKey) || "tittel";
    const orders = (sort && sort.orders) || "asc";
    const fakturaer = orderBy(
      map(ids, id => {
        const faktura = entities[id];
        return { ...faktura, utvidetStatus: getEnhancedFakturaStatusName(faktura) };
      }),
      cellKey,
      orders
    );
    return fakturaer.filter(filter);
  });

export default getFakturaer;

export const getFakturaSelector = (state, id) => state.entities.fakturaer[id];

// Filtered and sorted list of fakturaer
export const getFakturaList = makeGetAdvancedList("fakturaer", getFakturaer);

export const getKontraktFakturaList = makeGetAdvancedList("fakturaer", getFakturaer);
