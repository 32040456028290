/* eslint-disable react/destructuring-assignment */
import { useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, MarkerProps, Tooltip, Polygon, GeoJSON, PolygonProps, GeoJSONProps } from "react-leaflet";
import L from "leaflet";
import { map } from "lodash-es";

import myIconUrl from "./marker-icon.png";
import shadowUrl from "./marker-shadow.png";

const bymMarkerIcon = L.icon({
  iconUrl: myIconUrl,
  shadowUrl,
  shadowAnchor: [12, 41],
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -39]
});

const bymCurrentMarkerIcon = L.icon({
  iconUrl: myIconUrl,
  shadowUrl,
  shadowAnchor: [12, 41],
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -39]
});

export interface BymMarkerTooltipProps {
  showTooltip?: boolean;
  tooltipTitle?: string;
  fields?: any;
}

export interface BymMarkerColorProps {
  color?: string;
  selectedColor?: string;
  fillColor?: string;
  fillOpacity?: number;
}

export interface BymMarkerCommonProps extends BymMarkerTooltipProps, BymMarkerColorProps {
  objectId?: string;
  selected?: boolean;
  isCurrent?: boolean;
}

export interface BymPointMarkerProps extends BymMarkerCommonProps, MarkerProps {
  kind: "point";
}
export interface BymPolygonMarkerProps extends BymMarkerCommonProps, BymMarkerColorProps, PolygonProps {
  kind: "polygon";
}

export interface BymGeoJSONMarkerProps extends BymMarkerCommonProps, BymMarkerColorProps, GeoJSONProps {
  kind: "geoJSON";
}

export type BymMarkerProps = BymPointMarkerProps | BymPolygonMarkerProps | BymGeoJSONMarkerProps;

const BymMarkerTooltip = ({ tooltipTitle, fields }: BymMarkerTooltipProps) => {
  return (
    <div className="bym-marker-tooltip">
      <h5>
        <strong>{tooltipTitle}</strong>
      </h5>
      {fields && (
        <table>
          {map(fields, (value, key) => {
            if (!value) return null;
            return (
              <tr key={key}>
                <td className="bym-marker-tooltip-label">{key}</td>
                <td>{value}</td>
              </tr>
            );
          })}
        </table>
      )}
    </div>
  );
};

export const getGeoJSONFromPoint = (x: number, y: number, properties = {}) => {
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [x, y]
        },
        properties
      }
    ]
  } as GeoJSON.FeatureCollection;
};

export const getGeoJSONFromRings = (rings: any, properties = {}) => {
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: rings
        },
        properties
      }
    ]
  } as GeoJSON.FeatureCollection;
};

const useBymMarkerHook = ({ selected = false, showTooltip, tooltipTitle, fields }: BymMarkerProps) => {
  const [isSelected, setIsSelected] = useState(selected);

  const color = isSelected ? "#F9C66B" : "#2A2859";
  const colors: BymMarkerColorProps = {
    color,
    fillColor: "#2A2859"
  };

  const getBounds = (marker: BymMarkerProps): L.LatLngBounds | undefined => {
    switch (marker.kind) {
      case "point":
        return undefined;
      case "geoJSON":
        return L.geoJSON(marker.data as GeoJSON.GeoJsonObject)
          .getBounds()
          .pad(0.3);
      case "polygon":
        return L.latLngBounds(marker.positions as L.LatLngExpression[]).pad(0.3);
      default:
        return undefined;
    }
  };

  return {
    setIsSelected,
    colors,
    showTooltip,
    fields,
    getBounds,
    tooltipTitle
  };
};

export const BymPointMarker = ({ isCurrent, ...props }: BymPointMarkerProps): JSX.Element => {
  const { showTooltip, tooltipTitle, fields } = useBymMarkerHook(props);

  return (
    <Marker icon={isCurrent ? bymCurrentMarkerIcon : bymMarkerIcon} {...props}>
      {showTooltip && (
        <Tooltip opacity={0.9} direction="top" offset={[-1, -42]}>
          <BymMarkerTooltip tooltipTitle={tooltipTitle} fields={fields} />
        </Tooltip>
      )}
    </Marker>
  );
};

export const BymGeoJSONMarker = ({ ...props }: BymGeoJSONMarkerProps) => {
  const { setIsSelected, colors, showTooltip, tooltipTitle } = useBymMarkerHook(props);

  if (!props.data) return <></>;
  return (
    <GeoJSON
      {...colors}
      onEachFeature={function (feature, layer) {
        if (showTooltip) {
          const tooltip = <BymMarkerTooltip tooltipTitle={tooltipTitle} fields={feature.properties} />;
          const content = ReactDOMServer.renderToString(tooltip);
          layer.bindTooltip(content, { opacity: 0.9, direction: "top", offset: [-1, -42] });
        }
        // layer.bindPopup(content);
      }}
      fillOpacity={0.3}
      onmouseover={() => setIsSelected(true)}
      onmouseout={() => setIsSelected(false)}
      {...props}
      pointToLayer={(feature, latlng) => {
        return new L.Marker(latlng, { icon: bymMarkerIcon });
      }}
    />
  );
};

export const BymPolygonMarker = ({ ...props }: BymPolygonMarkerProps) => {
  const { setIsSelected, colors, showTooltip, tooltipTitle, fields } = useBymMarkerHook(props);

  return (
    <>
      <Polygon {...colors} onmouseover={() => setIsSelected(true)} onmouseout={() => setIsSelected(false)} {...props}>
        {showTooltip && (
          <Tooltip opacity={0.9} direction="top" offset={[-1, -42]}>
            <BymMarkerTooltip tooltipTitle={tooltipTitle} fields={fields} />
          </Tooltip>
        )}
      </Polygon>
    </>
  );
};

export default BymPointMarker;
