import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../helpers/actionHelper";
import { leieObjektSchema, leieObjektListSchema } from "../constants/schemas";
import etag from "../utils/etagUtils";

// GET ALLE LEIEOBJEKTER
export const GET_LEIEOBJEKTER_REQUEST = "GET_LEIEOBJEKTER_REQUEST";
export const GET_LEIEOBJEKTER_SUCCESS = "GET_LEIEOBJEKTER_SUCCESS";
export const GET_LEIEOBJEKTER_FAILURE = "GET_LEIEOBJEKTER_FAILURE";

export function getLeieObjekter() {
  return (dispatch, getState) => {
    dispatch({ type: GET_LEIEOBJEKTER_REQUEST });
    return axios
      .get(api.GET_LEIEOBJEKTER_URL, etag(getState, "leieobjekter"))
      .then(handle.success(dispatch, GET_LEIEOBJEKTER_SUCCESS, leieObjektListSchema))
      .catch(handle.error(dispatch, GET_LEIEOBJEKTER_FAILURE));
  };
}

// GET ALLE LEIEOBJEKTER
export const GET_LEIEOBJEKT_REQUEST = "GET_LEIEOBJEKT_REQUEST";
export const GET_LEIEOBJEKT_SUCCESS = "GET_LEIEOBJEKT_SUCCESS";
export const GET_LEIEOBJEKT_FAILURE = "GET_LEIEOBJEKT_FAILURE";

export function getLeieObjekt(leieObjektId) {
  return dispatch => {
    dispatch({ type: GET_LEIEOBJEKT_REQUEST });
    return axios
      .get(api.GET_LEIEOBJEKT_URL(leieObjektId))
      .then(handle.success(dispatch, GET_LEIEOBJEKT_SUCCESS, leieObjektSchema))
      .catch(handle.error(dispatch, GET_LEIEOBJEKT_FAILURE));
  };
}

// POST LEIEOBJEKT
export const POST_LEIEOBJEKT_REQUEST = "POST_LEIEOBJEKT_REQUEST";
export const POST_LEIEOBJEKT_SUCCESS = "POST_LEIEOBJEKT_SUCCESS";
export const POST_LEIEOBJEKT_FAILURE = "POST_LEIEOBJEKT_FAILURE";

export function postLeieObjekt(eiendomId, leieObjektData) {
  return dispatch =>
    axios
      .post(api.POST_LEIEOBJEKT_URL(eiendomId), leieObjektData)
      .then(
        handle.success(dispatch, POST_LEIEOBJEKT_SUCCESS, leieObjektSchema, {
          eiendomId
        })
      )
      .catch(handle.error(dispatch, POST_LEIEOBJEKT_FAILURE));
}

// EDIT LEIEOBJEKT
export const EDIT_LEIEOBJEKT_REQUEST = "EDIT_LEIEOBJEKT_REQUEST";
export const EDIT_LEIEOBJEKT_SUCCESS = "EDIT_LEIEOBJEKT_SUCCESS";
export const EDIT_LEIEOBJEKT_FAILURE = "EDIT_LEIEOBJEKT_FAILURE";

export function editLeieObjekt(eiendomId, leieObjektId, leieObjektData) {
  return dispatch =>
    axios
      .put(api.EDIT_LEIEOBJEKT_URL(eiendomId, leieObjektId), leieObjektData)
      .then(
        handle.success(dispatch, EDIT_LEIEOBJEKT_SUCCESS, leieObjektSchema, {
          eiendomId
        })
      )
      .catch(handle.error(dispatch, EDIT_LEIEOBJEKT_FAILURE));
}
