import React, { useState, useRef } from "react";
import axios from "services/axios";
import dokumentIcon from "images/dokument.svg";
import ImageWithPromiseSpinner from "components/Common/Spinner/ImageWithPromiseSpinner";
import { AxiosResponse } from "axios";

type BlobLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  showIcon?: boolean;
  downloadFunction?: () => Promise<AxiosResponse<any>>;
};

const BlobLink = ({ href, downloadFunction, children, showIcon, ...rest }: BlobLinkProps) => {
  const [documentLoadPromise, setDocumentLoadPromise] = useState<any>();
  const anchorRef = useRef<any>();
  const isSimulatedClick = useRef(false);

  const anchorClicked = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isSimulatedClick.current) return;
    isSimulatedClick.current = false;
    downloadAndClick(e);
  };

  const downloadAndClick = async (e: any) => {
    const promise = href === undefined ? downloadFunction?.() : axios.get(href, { responseType: "arraybuffer" });
    if (promise === undefined) return;
    e.preventDefault();
    setDocumentLoadPromise(promise);
    const result = await promise;
    const type = result.request.getResponseHeader("Content-Type");
    const blob = new Blob([result.data], { type });
    console.log("Type from Content-Type is: ", type);

    let fileName: string = result.request.getResponseHeader("Content-Disposition")?.split("filename*=UTF-8''")?.[1];
    if (!fileName) {
      fileName = result.request.getResponseHeader("Content-Disposition")?.split("filename=")[1]?.split(";")?.[0];
      console.log("Setting fileName from Content-Disposition: ", fileName);
    } else {
      console.log("Setting fileName UTF-8 from Content-Disposition: ", fileName);
    }

    // fiks for pdf-nedlasting
    if (type == "application/pdf" && !fileName.toLowerCase().endsWith(".pdf")) {
      console.log("Forcing .pdf extension", fileName);
      fileName = fileName + ".pdf";
    }

    anchorRef.current.href = window.URL.createObjectURL(blob);
    anchorRef.current.download = decodeURIComponent(fileName);

    console.log("Setting a.download: ", anchorRef.current.download);

    isSimulatedClick.current = true;
    anchorRef.current.click();
    // window.URL.revokeObjectURL(anchorRef.current.href);
    isSimulatedClick.current = false;
  };

  const iconStyles = { width: "16px", margin: "-2px 8px 0 0" };
  return (
    <a ref={anchorRef} href="" onClick={anchorClicked} {...rest}>
      {showIcon && (
        <ImageWithPromiseSpinner promise={documentLoadPromise} style={iconStyles} src={dokumentIcon} alt="" />
      )}
      {children}
    </a>
  );
};

export default BlobLink;
