import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import BlobImage from "./BlobImage";
import { getToken } from "auth/authTools";

import "./BildeDialog.css";

const BildeDialog = ({ show, onClose = () => null, src, title }) => (
  <Modal onHide={onClose} show={show} backdrop="static" dialogClassName="bilde-dialog">
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <BlobImage src={src} alt="" responsive />
    </Modal.Body>

    <Modal.Footer>
      <div style={{ display: "inline-block", marginRight: "10px" }}>
        <form method="post" action={src} target="_blank">
          <input type="hidden" name="sps_access_token" value={getToken()} />
          <Button type="submit">Last ned</Button>
        </form>
      </div>
      <Button id="BildeDialogCloseButton" onClick={onClose}>
        Lukk
      </Button>
    </Modal.Footer>
  </Modal>
);

BildeDialog.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  src: PropTypes.string,
  title: PropTypes.string
};

export default BildeDialog;
