import { Component, ComponentType } from "react";
import { connect } from "react-redux";
import { Route, Redirect, RouteProps, RouteComponentProps } from "react-router-dom";
import { withAuth, AuthContextProps } from "react-oidc-context";
import Spinner from "components/Common/Spinner/Spinner";

interface AdminpropTypes extends RouteProps, AuthContextProps {
  component: ComponentType<any>;
  isAdmin?: boolean;
}

class AdminRoute extends Component<AdminpropTypes> {
  render() {
    const { component, ...rest } = this.props;
    return <Route {...rest} render={this.renderRoute} />;
  }

  renderRoute = (props: RouteComponentProps) => {
    const { component: Comp, isAuthenticated, isAdmin, isLoading } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    if (isAuthenticated && isAdmin) {
      return <Comp {...props} />;
    } else if (isAuthenticated && !isAdmin) {
      return <div>Access denied</div>; // should be replaced with proper access denied component
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      );
    }
  };
}

function mapStateToProps(state: any) {
  return {
    isAdmin: state.login?.isAdmin
  };
}

export default withAuth(connect(mapStateToProps)(AdminRoute));
