import { ReactNode } from "react";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { errorSelector, resetToast, successSelector, toastTypeSelector } from "../../../actions/toastAction";

import "./toast.css";

function getErrorMessage(error: any) {
  let messageToUser: string | ReactNode = "";
  if (error) {
    messageToUser = error.message ?? error.data;

    if (error.response) {
      const { status, data, statusText } = error.response;
      if (data && data.errorMessage) {
        messageToUser = data.errorMessage;
      }
      switch (status) {
        case 401:
          messageToUser = "Du er ikke autorisert, logg inn på nytt.";
          break;
        case 404:
          if (data === "token er ugyldig") {
            messageToUser = "Ugyldig token, logg inn på nytt.";
          }
          break;
        case 400:
          messageToUser = `Kall på ${error.config.url} gikk galt: ${error.response.data.errorMessage}`;
          break;
        case 403:
          messageToUser = `Kall på ${error.config.url} gikk galt: Din bruker har ikke tilgang`;
          break;
        case 409:
          messageToUser = (
            <div>
              <h4>
                {error.response.status} - {error.response.statusText}!
              </h4>
              <p>{`${error.response.data.errorMessage || error.response.data}`}</p>
            </div>
          );
          break;
        case 500:
          messageToUser = `Kall på ${error.config.url} gikk galt på serveren: ${error.response.data.errorMessage}`;
          break;
        default:
          messageToUser = `Kall på ${error.config.url} gikk galt: ${status}: ${statusText}`;
      }
    }
  }
  return messageToUser;
}

interface ToastProps {
  error: any;
  success: string;
  toastType: string;
  toastDismiss: () => void;
}

const Toast = ({ error, success, toastType, toastDismiss }: ToastProps) => {
  const className = success || error ? "toast toast-visible" : "toast toast-hidden";
  const content = success || getErrorMessage(error);
  if (success) {
    setTimeout(toastDismiss, 3000);
  }
  return (
    <div className="toast-top-fixed">
      <Alert className={className} bsStyle={toastType} onDismiss={toastDismiss}>
        <div className="content">{content}</div>
      </Alert>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  error: errorSelector(state),
  success: successSelector(state),
  toastType: toastTypeSelector(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  toastDismiss: () => dispatch(resetToast())
});

export default connect(mapStateToProps, mapDispatchToProps)((props: any) => <Toast {...props} />);
