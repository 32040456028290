import { uniqBy, get, map } from "lodash-es";
import { createSelector } from "reselect";

const getFilterKey = (state, filterKey) => filterKey;

const makeGetFilterValue = getEntities =>
  createSelector([getEntities, getFilterKey], (entities, filterKey) => {
    const uniqueItems = uniqBy(entities, entity => get(entity, filterKey));
    const uniqueValues = map(uniqueItems, filterKey);
    return uniqueValues.sort();
  });

export default makeGetFilterValue;
