import PropTypes from "prop-types";
import Table from "../../containers/Common/SortableTableContainer";
import { HeaderCell } from "../Common/Tables";
import DataExportButton from "../Common/Tables/DataExportButton";
import FakturaListRow from "./FakturaListRow";
import FakturaAdminListRow from "./FakturaAdminListRow";
import FakturaWarnings from "./FakturaWarnings";
import { faktureringTidspunkt } from "constants/kontrakt";

const FakturaList = ({
  fakturaer,
  fakturaType,
  isAdmin = false,
  onDelete,
  showWarnings = false,
  allFakturaerInList = []
}) => {
  const renderKontraktRow = isAdmin
    ? faktura => <FakturaAdminListRow {...faktura} faktura={faktura} key={faktura.id} onDelete={onDelete} />
    : faktura => <FakturaListRow {...faktura} faktura={faktura} key={faktura.id} />;

  const header = isAdmin ? (
    <HeaderCell cellKey="kontrakt.opprettetAvNavn">Eier</HeaderCell>
  ) : (
    <HeaderCell cellKey="fakturaNummer">Fakturanummer</HeaderCell>
  );

  const getFakturaWithTotalPris = f => ({
    ...f,
    totalPris: f.ordrelinjer.reduce((acc, currItem) => acc + Number(currItem.enhetspris) * Number(currItem.antall), 0.0)
  });

  const fakturaerView = fakturaer.map(getFakturaWithTotalPris);

  const allFakturaerInTable = allFakturaerInList.map(getFakturaWithTotalPris);

  const deleteHeaderCell = onDelete ? <th /> : <></>;

  return (
    <>
      {showWarnings && <FakturaWarnings fakturaer={allFakturaerInTable} />}
      <Table id="faktura-liste" storeKey="fakturaer" items={fakturaerView} renderRow={renderKontraktRow} omitBody>
        <HeaderCell cellKey="kontrakt.tittel">Kontrakt</HeaderCell>
        <HeaderCell cellKey="utvidetStatus">Status</HeaderCell>
        <HeaderCell cellKey="ordreNummer">Ordrenummer</HeaderCell>
        {header}
        <HeaderCell cellKey="kontonummerId">Konto</HeaderCell>
        <HeaderCell cellKey="koststedId">Koststed</HeaderCell>
        <HeaderCell cellKey="kontrakt.faktureringTidspunktId">Tidspunkt</HeaderCell>
        <HeaderCell disableSort style={{ textAlign: "right" }}>
          Totalpris
        </HeaderCell>
        {deleteHeaderCell}
        <HeaderCell disableSort>Detaljer</HeaderCell>
        <HeaderCell disableSort>{fakturaType === "faktura" ? "Kreditnotaer" : "Faktura"}</HeaderCell>
      </Table>
      <DataExportButton
        filenamePrefix="uge-faktura"
        rows={fakturaerView}
        columns={[
          { cellKey: "kontrakt.tittel", displayName: "Kontrakt" },
          { cellKey: "utvidetStatus", displayName: "Status" },
          { cellKey: "ordreNummer", displayName: "Ordrenummer" },
          isAdmin
            ? { cellKey: "kontrakt.opprettetAvNavn", displayName: "Eier" }
            : { cellKey: "fakturanummer", displayName: "Fakturanummer" },
          { cellKey: "kontonummerId", displayName: "Kontonummer" },
          { cellKey: "koststedId", displayName: "Koststed" },
          {
            cellKey: "kontrakt.faktureringTidspunktId",
            displayName: "Tidspunkt",
            transformFunc: faktureringTidspunktId =>
              faktureringTidspunktId === faktureringTidspunkt.forskuddsvis ? "Forskudd" : "Etterskudd"
          },
          { cellKey: "totalPris", displayName: "Totalpris" }
        ]}
      />
    </>
  );
};

FakturaList.propTypes = {
  fakturaer: PropTypes.arrayOf(PropTypes.object).isRequired,
  allFakturaerInList: PropTypes.arrayOf(PropTypes.object),
  showWarnings: PropTypes.bool,
  fakturaType: PropTypes.object,
  isAdmin: PropTypes.bool,
  onDelete: PropTypes.func
};

FakturaList.defaultProps = {
  isAdmin: false,
  onDelete: undefined
};

export default FakturaList;
