import React, { useState } from "react";
import PropTypes from "prop-types";
import BlobImage from "./BlobImage";
import deleteIcon from "../../images/delete.svg";
import ConfirmDialog from "../Common/ConfirmDialog";

import "./BildeItem.css";

const BildeItem = ({ deleteBilde, label, ...rest }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  return (
    <div className="bilde-container">
      {showDeleteDialog && (
        <ConfirmDialog
          show={showDeleteDialog}
          message="Er du sikker på at du vil slette bildet?"
          title="Slett bilde"
          confirmButtonText="Slett bilde"
          confirmButtonStyle="bym-negative"
          onConfirm={() => {
            deleteBilde();
            setShowDeleteDialog(false);
          }}
          onClose={() => setShowDeleteDialog(false)}
        />
      )}
      <div className="bilde">
        <BlobImage {...rest} />
      </div>
      <div className="bilde-label">
        {label && <strong>{label}</strong>}
        {deleteBilde && (
          <img
            id="deleteImage"
            role="presentation"
            src={deleteIcon}
            onClick={() => setShowDeleteDialog(true)}
            alt="slett bilde"
          />
        )}
      </div>
    </div>
  );
};

BildeItem.propTypes = {
  deleteBilde: PropTypes.func,
  label: PropTypes.string
};

export default BildeItem;
