import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { take } from "lodash-es";
import { getFormatedDate } from "../../utils/dateUtils";

class ExpandableKontraktList extends React.Component {
  state = {
    listLength: undefined
  };

  render() {
    const { initialLength, ...restProps } = this.props;
    const { listLength = initialLength } = this.state;
    return (
      <KontraktList listLength={listLength} showAllClicked={() => this.setState({ listLength: 1000 })} {...restProps} />
    );
  }
}

ExpandableKontraktList.propTypes = {
  initialLength: PropTypes.number.isRequired
};

const KontraktList = ({ kontrakter = [], listLength = 10, showAllClicked = () => null }) => {
  if (kontrakter.length === 0) return "Ingen utgående kontrakter neste 6 måneder funnet";
  const showKontrakter = take(kontrakter, listLength);
  return (
    <>
      <table className="simple-table">
        <thead>
          <tr>
            <th>Kontrakt</th>
            <th>Eiendom</th>
            <th>Status</th>
            <th>Utløper</th>
          </tr>
        </thead>
        <tbody>
          {showKontrakter.map(k => (
            <tr key={k.id}>
              <td>
                <Link to={`/kontrakter/${k.id}`}>{k.tittel}</Link>
              </td>
              <td>
                <Link to={k.eiendom ? `/eiendommer/${k.eiendom.id}` : ""}>{k.eiendom && k.eiendom.Navn}</Link>
              </td>
              <td>{k.kontraktStatus.navn}</td>
              <td>{getFormatedDate(k.utlopDato)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {kontrakter.length !== showKontrakter.length && (
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <a
            onClick={e => {
              showAllClicked(e);
              e.preventDefault();
            }}
            href=""
          >
            <strong>Vis alle</strong>
          </a>
        </div>
      )}
    </>
  );
};

KontraktList.propTypes = {
  kontrakter: PropTypes.arrayOf(PropTypes.object).isRequired,
  listLength: PropTypes.number.isRequired,
  showAllClicked: PropTypes.func.isRequired
};

export default ExpandableKontraktList;
