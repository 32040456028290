import React from "react";
import { orderBy, includes } from "lodash-es";
import PropTypes from "prop-types";
import { Alert, Col, Row, Button, Modal } from "react-bootstrap";
import {
  BymForm,
  BymTextInput,
  BymNumberInput,
  BymSelectInput,
  BymRadioButtonsInput,
  BymCheckboxInput,
  BymSubmitButton,
  BymEmailInput,
  length,
  required,
  optionalNumber
} from "components/Common/Forms";
import moment from "moment";
import UgeDateInput from "components/Common/Forms/UgeDateInput";
import BymButton from "components/Common/Forms/BymButton";
import { isProduction } from "constants/environment";
import { getMonths, getFormatedDate } from "utils/dateUtils";
import { debouncePromise } from "utils/debouncePromise";

import FakturaGenerationViewer from "./FakturaGenerationViewer";

import "./KontraktEditor.css";
import EditKundenummerDialog from "./EditKundenummerDialog";
import { getCssBakgrunnForVerifisertSaksnummer, validerArkivsaknummerWithFunc } from "utils/arkivUtils";
import BymCurrencyInput from "components/Common/Forms/BymCurrencyInput";
import { beregnFakturaBelop as beregnFakturaBelopAction } from "actions/fakturaAction";
import {
  pristype,
  betalingTermin,
  faktureringTidspunkt,
  kontraktStatus,
  faktureringType,
  kontraktType
} from "constants/kontrakt";
import ShowOnlyForUsersWith from "auth/ShowOnlyFor";
import FakturaBeregning from "./FakturaBeregning/FakturaBeregning";

export default class KontraktEditor extends React.Component {
  emptyKontraktObjekt = {
    tittel: null,
    eiendomId: "",
    betalingTermin: null,
    betalingTerminId: "",
    depositumKonto: null,
    deresRef: null,
    faktureringTidspunkt: null,
    faktureringTidspunktId: faktureringTidspunkt.forskuddsvis,
    faktureringType: null,
    faktureringTypeId: null,
    fakturaAdresse: null,
    fodselsnummer: "",
    garantiType: null,
    garantiTypeId: 2,

    gyldigFraDato: null,
    kontraktStatusId: kontraktStatus.underBehandling,
    kontraktTypeId: "",
    kundenummer: null,
    notat: null,
    organisasjonsnummer: "",
    arkivSakNummer: null,
    segment: null,
    segmentId: 2,
    startLeieDato: null,
    utlopDato: null,
    tilpassetBetalingManed1: null,
    tilpassetBetalingManed2: null,
    leieObjekter: [],
    prisTypeId: pristype.aar,
    sesongStartDato: null,
    sesongSluttDato: null,
    hasPris2: "0",
    fornyes: true,
    kontraktMalId: null
  };

  state = {
    kontrakt: { ...this.emptyKontraktObjekt },
    isEditMode: false,
    disableSubmitButton: false,
    disableCreatearkivSaknummer: true, // needs a title so don't allow before kontrakt title is set
    isProductionEnv: false,
    showKundenummerDialog: false,
    showKontraktStatusAlert: undefined,
    beregnetFakturaBelop: undefined
  };

  kundenummerDialogResolve = null;

  async componentDidMount() {
    const { kontrakt = this.emptyKontraktObjekt, editObject, leieObjekter, saerAvtaleGrunn, eiendomId } = this.props;

    const isEditMode = editObject !== undefined;
    const kontraktLeieObjekter = this.getLeieObjekter(kontrakt, leieObjekter);
    let hasPris2 = kontraktLeieObjekter.filter(k => k.pris2).length > 0 ? "1" : "0";
    if (!kontrakt.eiendomId) hasPris2 = kontrakt.pris2 > 0 ? "1" : "0";

    const ikkeFornyes = !kontrakt.fornyes;

    const kontraktWithLeieobjekter = {
      ...kontrakt,
      leieObjekter: kontraktLeieObjekter,
      hasPris2,
      ikkeFornyes,
      saerAvtaleGrunn,
      eiendomId: kontrakt?.eiendomId ? kontrakt.eiendomId : eiendomId
    };

    this.setState({
      kontrakt: kontraktWithLeieobjekter,
      isEditMode,
      isProductionEnv: isProduction()
    });

    this.beregnFakturaBelop(kontraktWithLeieobjekter);
  }

  saveKundenummer = ({ kundenummer, organisasjonsnummer, personnummer }) => {
    const { kontrakt } = this.state;
    const newKontrakt = { ...kontrakt, kundenummer, organisasjonsnummer, fodselsnummer: personnummer };
    this.setState({ kontrakt: newKontrakt, showKundenummerDialog: false });

    // Resolve the promise that was returned in onKontraktChange
    if (this.kundenummerDialogResolve) {
      this.kundenummerDialogResolve(newKontrakt);
    }
  };

  canBeregneFakturaBelop = kontrakt => {
    const basicTest =
      !!kontrakt && !!kontrakt.gyldigFraDato && !!kontrakt.kontraktTypeId && kontrakt.kontraktTypeId !== "";

    if (!basicTest) return false;

    // hvis tilpasset, krev at enten startmåned har verdi
    if (kontrakt.betalingTerminId == betalingTermin.tilpasset && !kontrakt.tilpassetBetalingManed1) return false;

    // hvis sesong, krev start- og sluttdato
    if (kontrakt.prisTypeId == pristype.sesong && (!kontrakt.sesongStartDato || !kontrakt.sesongSluttDato))
      return false;

    return true;
  };

  beregnFakturaBelop = kontrakt => {
    if (this.canBeregneFakturaBelop(kontrakt)) {
      const callwrapper = debouncePromise(beregnFakturaBelopAction, 400, { leading: false, accumulate: false });

      callwrapper(kontrakt)
        .then(result => {
          if (result.status === 200) {
            this.setState({ beregnetFakturaBelop: result.data.result });
          } else this.setState({ beregnetFakturaBelop: { fakturaBelop: [] } });
        })
        .catch(() => this.setState({ beregnetFakturaBelop: { fakturaBelop: [] } }));
    } else {
      this.setState({ beregnetFakturaBelop: { fakturaBelop: [] } });
    }
  };

  onKontraktChange = (propertyName, kontrakt) => {
    const { isProductionEnv, disableCreateArkivSaknummer, isEditMode, showKontraktStatusAlert } = this.state;

    this.beregnFakturaBelop(kontrakt);

    if (propertyName === "editKundenummer") {
      this.setState({ showKundenummerDialog: true });

      return new Promise(resolve => {
        this.kundenummerDialogResolve = resolve;
      });
    }

    if (!isProductionEnv && propertyName === "tittel") {
      const newDisableCreateArkivSaknummer = !(kontrakt.tittel && kontrakt.tittel.length > 3);
      if (disableCreateArkivSaknummer !== newDisableCreateArkivSaknummer) {
        this.setState({ disableCreateArkivSaknummer: newDisableCreateArkivSaknummer });
        return undefined;
      }
    }

    if (propertyName === "tilpassetBetalingManed1" && !kontrakt.tilpassetBetalingManed1) {
      const newKontrakt = { ...kontrakt, tilpassetBetalingManed2: undefined };
      this.setState({ kontrakt: newKontrakt });
      return newKontrakt;
    }

    if (propertyName === "betalingTerminId") {
      let newKontrakt = { ...kontrakt, betalingTerminId: Number(kontrakt.betalingTerminId) };
      if (!this.isSeasonPriceAvailable(Number(kontrakt.betalingTerminId))) {
        // reset prisType when choosing something where season price is available
        const leieObjekter = kontrakt.leieObjekter.map(lo => ({ ...lo, pris2: undefined }));
        newKontrakt = {
          ...kontrakt,
          leieObjekter,
          prisTypeId: pristype.aar,
          sesongStartDato: null,
          sesongSluttDato: null,
          betalingTerminId: Number(kontrakt.betalingTerminId)
        };
      }
      if (Number(kontrakt.betalingTerminId) === betalingTermin.sesong && kontrakt.prisTypeId !== pristype.sesong) {
        newKontrakt = { ...kontrakt, prisTypeId: pristype.sesong, betalingTerminId: Number(kontrakt.betalingTerminId) };
      }
      this.setState({ kontrakt: newKontrakt });
      this.beregnFakturaBelop(newKontrakt);

      return newKontrakt;
    }

    if (propertyName === "prisTypeId") {
      let { leieObjekter } = kontrakt;
      if (Number(kontrakt.prisTypeId) === pristype.aar) {
        leieObjekter = leieObjekter.map(lo => ({ ...lo, pris2: undefined }));
      }
      const newKontrakt = {
        ...kontrakt,
        leieObjekter,
        prisTypeId: Number(kontrakt.prisTypeId),
        betalingTerminId: betalingTermin.maanedlig,
        hasPris2: "0"
      };
      this.setState({ kontrakt: newKontrakt });
      this.beregnFakturaBelop(newKontrakt);
      return newKontrakt;
    }

    if (propertyName === "kontraktStatusId" && isEditMode && showKontraktStatusAlert === undefined) {
      if (this.isFakturerbarKontrakt(kontrakt)) {
        this.setState({ showKontraktStatusAlert: true });
      }
    }

    // update state with current kontrakt when leieobjekter changes (to support disabling fields when leieobjekt is checked/uncheked)
    if (propertyName.indexOf("leieObjekter") === 0) {
      this.setState({ kontrakt });
      return undefined;
    }

    // update state with current kontrakt when kontraktTypeId changes (to support showing antall on leieobjekter for "belysning")
    if (propertyName.indexOf("kontraktTypeId") === 0) {
      if (Number(kontrakt.kontraktTypeId) !== kontraktType.veibelysning) {
        // clear antall on all leieobjekter
        const leieObjekter = kontrakt.leieObjekter.map(lo => ({ ...lo, antall: undefined }));
        const newKontrakt = { ...kontrakt, leieObjekter };
        this.setState({ newKontrakt });
        this.beregnFakturaBelop(newKontrakt);
        return newKontrakt;
      }
      this.setState({ kontrakt });
      return undefined;
    }
    // update state with current kontrakt when hasPris2 changes (to support showing pris2 column)
    if (propertyName.indexOf("hasPris2") === 0) {
      if (kontrakt.hasPris2 === "0") {
        // clear pris2 on all leieobjekter
        const leieObjekter = kontrakt.leieObjekter.map(lo => ({ ...lo, pris2: undefined }));
        const newKontrakt = { ...kontrakt, leieObjekter };
        if (!kontrakt.eiendomId) newKontrakt.pris2 = undefined;
        this.setState({ kontrakt: newKontrakt });

        this.beregnFakturaBelop(newKontrakt);
        return newKontrakt;
      }
      this.setState({ kontrakt });
    }
    if (propertyName === "faktureringTidspunktId") {
      this.setState({ kontrakt });
      return { ...kontrakt, faktureringTidspunktId: Number(kontrakt.faktureringTidspunktId) };
    }
    if (propertyName.indexOf("tilpasset") === 0 || propertyName.indexOf("sesongS") === 0) {
      this.setState({ kontrakt });
      return undefined;
    }

    // Set the fornyes property to be the opposite of the ikkeFornyes property
    if (propertyName === "ikkeFornyes") {
      const newKontrakt = { ...kontrakt, fornyes: !kontrakt.ikkeFornyes };
      this.setState({ kontrakt: newKontrakt });
      this.beregnFakturaBelop(newKontrakt);
      return newKontrakt;
    }

    return undefined;
  };

  /**
   * Returns a list of all available leieobjekter for the kontrakt merged with the
   * values from existing leieobjekter in the kontrakt
   */
  getLeieObjekter = (kontrakt, availableLeieObjekter = []) =>
    availableLeieObjekter.map(lo => {
      const kontraktLeieObjekt = kontrakt.leieObjekter.filter(klo => klo.leieObjektId === lo.id)[0];
      return {
        leieObjektId: lo.id,
        navn: lo.navn,
        pris: kontraktLeieObjekt ? kontraktLeieObjekt.pris : null,
        pris2: kontraktLeieObjekt ? kontraktLeieObjekt.pris2 : null,
        fakturaTekst: kontraktLeieObjekt ? kontraktLeieObjekt.fakturaTekst : null,
        antall: kontraktLeieObjekt ? kontraktLeieObjekt.antall : null,
        checked: kontraktLeieObjekt !== undefined
      };
    });

  /**
   * Validation logic for the whole form.
   */
  globalFormValidator = kontrakt => {
    if (kontrakt.eiendomId) {
      const checkedLeieObjekter = kontrakt.leieObjekter.filter(lo => lo.checked && lo.pris);
      if (checkedLeieObjekter.length < 1) {
        return "Kontrakt må inneholde minst ett leieobjekt med pris";
      }

      if (kontrakt.hasPris2 === "1") {
        return kontrakt.leieObjekter.filter(lo => lo.checked && lo.pris2).length > 0
          ? true
          : "Må ha minst ett leieobjekt med pris2";
      }
    } else {
      // kontrakt uten eiendom
      if (!kontrakt.pris) return "Kontrakten må ha en pris";
      if (!kontrakt.fakturaTekst) return "Kontrakten må ha fakturatekst";
      if (kontrakt.hasPris2 === "1" && !kontrakt.pris2) return "Kontrakten må ha pris2";
    }

    if (
      (Number(kontrakt.kontraktStatusId) === kontraktStatus.tidsbestemtKontrakt ||
        Number(kontrakt.kontraktStatusId) == kontraktStatus.forlengetKontrakt) &&
      !kontrakt.utlopDato
    ) {
      return "Tidsbestemt kontrakt trenger gyldig til dato";
    }

    if (kontrakt.utlopDato && moment(kontrakt.utlopDato).isBefore(moment(kontrakt.gyldigFraDato)))
      return "Gyldig til dato må være etter gyldig fra dato";

    return true; // form won't submit until this function returns true
  };

  isInternalKontrakt = kontrakt => Number(kontrakt.faktureringTypeId) === faktureringType.intern;

  isExternalKontrakt = kontrakt => Number(kontrakt.faktureringTypeId) === faktureringType.ekstern;

  isFakturerbarKontrakt = kontrakt =>
    includes(
      [kontraktStatus.tidsbestemtKontrakt, kontraktStatus.lopendeKontrakt, kontraktStatus.forlengetKontrakt],
      Number(kontrakt.kontraktStatusId)
    );

  isNumeric = n => !Number.isNaN(parseFloat(n)) && Number.isFinite(n);

  isTilpassetBetalingTermin = betalingTerminId => Number(betalingTerminId) === betalingTermin.tilpasset;
  isEngangBetalingTermin = betalingTerminId => Number(betalingTerminId) === betalingTermin.engang;

  isSeasonPriceAvailable = betalingTerminId =>
    betalingTerminId === betalingTermin.maanedlig ||
    betalingTerminId === betalingTermin.kvartalsvis ||
    betalingTerminId === betalingTermin.sesong;

  getValidBetalingTermin = kontrakt => {
    const { betalingTerminTyper } = this.props;
    const validTyper = betalingTerminTyper.filter(btt => {
      if (kontrakt.prisTypeId === pristype.sesong) {
        return (
          btt.id === betalingTermin.maanedlig ||
          (btt.id === betalingTermin.kvartalsvis && kontrakt.betalingTerminId === betalingTermin.kvartalsvis) || // kvartalsvis er ikke lenger tilgjengelig for sesong (hvis man ikke allerede har valgt det)
          btt.id === betalingTermin.sesong
        );
      }
      return btt.id !== betalingTermin.engang && btt.id !== betalingTermin.sesong;
    });
    return validTyper.sort((a, b) => a.id > b.id);
  };

  saveKontrakt = kontrakt => {
    this.setState({ disableSubmitButton: true });

    // Only save leieobjekter that has been checked
    const leieObjekter = kontrakt.leieObjekter.filter(lo => lo.checked);
    const { eiendomId, segmentId, onEdit, onSave } = this.props;
    const { isEditMode } = this.state;
    const newKontrakt = { ...kontrakt, leieObjekter };

    if (!kontrakt.eiendomId) {
      newKontrakt.eiendomId = eiendomId;
      newKontrakt.segmentId = segmentId ?? newKontrakt.segmentId;
    }
    if (isEditMode && onEdit) {
      onEdit(newKontrakt);
    } else {
      // Korrigere tidsonefeil med API - legger på 4 timer for å hindre at dato blir dagen før
      if (newKontrakt.utlopDato) newKontrakt.utlopDato.setHours(newKontrakt.utlopDato.getHours() + 4);
      if (newKontrakt.gyldigFraDato) newKontrakt.gyldigFraDato.setHours(newKontrakt.gyldigFraDato.getHours() + 4);

      if (onSave) onSave(newKontrakt);
    }
  };

  showPris2OrAntallColumn = kontrakt =>
    kontrakt.hasPris2 === "1" || Number(kontrakt.kontraktTypeId) === kontraktType.veibelysning;

  render() {
    const {
      kontraktTyper = [],
      faktureringTyper = [],
      faktureringTidspunkter = [],
      betalingTerminTyper = [],
      kontraktStatuser = [],
      kostSteder = [],
      kontoNummere = [],
      onCancel,
      showUnsentFakturaWarning,
      ...restProps
    } = this.props;
    const {
      kontrakt,
      isEditMode,
      disableSubmitButton,
      showKundenummerDialog,
      showKontraktStatusAlert,
      beregnetFakturaBelop
    } = this.state;
    const validBetalingTerminTyper = this.getValidBetalingTermin(kontrakt);
    const kostStederWithNumber = kostSteder.map(ks => {
      return { ...ks, navn: `${ks.id}: ${ks.navn}` };
    });
    const kontoNummereWithNumber = kontoNummere.map(kn => {
      return { ...kn, navn: `${kn.id}: ${kn.navn}` };
    });

    const setSaksnummer = e => {
      if (e.currentTarget) {
        this.setState({ arkivSaknummer: e.currentTarget.value });
      }
    };

    const validerSaksnummerInput = () => {
      this.setState({ verifisertSaksnummer: undefined });

      const saksnummer = this.state.arkivSaknummer;
      if (saksnummer) {
        validerArkivsaknummerWithFunc(
          saksnummer,
          () => this.setState({ verifisertSaksnummer: true }),
          () => this.setState({ verifisertSaksnummer: false })
        );
      }
    };

    return (
      <div className="leieobjekt-details leieobjekt-edit" style={restProps.style}>
        {showKundenummerDialog && (
          <EditKundenummerDialog
            show={showKundenummerDialog}
            onHide={() => this.setState({ showKundenummerDialog: false })}
            onSave={this.saveKundenummer}
            kundenummer={kontrakt.kundenummer}
          />
        )}
        {showKontraktStatusAlert && (
          <Modal onHide={() => this.setState({ showKontraktStatusAlert: false })} show>
            <Modal.Header closeButton>
              <Modal.Title>Fakturerbar kontrakt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert bsStyle="warning">
                Du har valgt en kontraktstaus som gjør at fakturaer nå sendes ut automatisk. Husk å se over
                fakturainformasjonen under for å verifisere at den er riktig før du lagrer endringen{" "}
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setState({ showKontraktStatusAlert: false })}>Ok</Button>
            </Modal.Footer>
          </Modal>
        )}
        <BymForm
          id="addKontrakt"
          onChange={this.onKontraktChange}
          onSubmit={this.saveKontrakt}
          values={kontrakt}
          globalValidator={this.globalFormValidator}
        >
          <Row className="kontrakt-information">
            <Col md={12}>
              <Row>
                <Col md={4}>
                  <BymNumberInput controlId="organisasjonsnummer" label="Organisasjonsnummer" disabled />
                </Col>
                <Col md={4}>
                  <BymNumberInput controlId="fodselsnummer" label="Personnummer" disabled />
                </Col>
                <Col md={3}>
                  <BymNumberInput
                    controlId="kundenummer"
                    label="*Kundenummer"
                    rules={[required(), length(5, 8, false)]}
                    disabled
                  />
                </Col>
                <ShowOnlyForUsersWith roles={["UGE-admin", "UGE-bruker"]}>
                  <Col md={1}>
                    <BymButton
                      style={{ marginTop: "24px", marginLeft: "-24px" }}
                      bsStyle="bym-action"
                      controlId="editKundenummer"
                      text="Rediger"
                    />
                  </Col>
                </ShowOnlyForUsersWith>
              </Row>
              <Row>
                <Col md={4}>
                  <BymTextInput
                    controlId="tittel"
                    placeholder="Navn på kontrakt"
                    label="*Kontraktnavn"
                    rules={[required(), length(4, 100, false)]}
                  />
                </Col>
                <Col md={3}>
                  {kontraktTyper.length > 0 && (
                    <BymSelectInput
                      controlId="kontraktTypeId"
                      rules={[required()]}
                      label="*Kontrakt type"
                      items={kontraktTyper}
                    />
                  )}
                </Col>
                <Col md={3}>
                  <BymSelectInput
                    controlId="kontraktStatusId"
                    rules={[required()]}
                    label="*Kontrakt status"
                    items={kontraktStatuser}
                  />
                </Col>
                <Col md={2}>
                  <UgeDateInput controlId="signertDato" label="Signert" placeholder="dd.mm.åååå" alignRight />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <BymTextInput
                    className={`form-control ${getCssBakgrunnForVerifisertSaksnummer(this.state.verifisertSaksnummer)}`}
                    onChange={setSaksnummer}
                    controlId="arkivSakNummer"
                    label="Arkiv saksnummer"
                    placeholder="Arkiv saksnummer"
                    autocomplete="off"
                  />

                  {this.state.arkivSaknummer && (
                    <>
                      <label htmlFor="validerSaksnummer" className="control-label"></label>
                      <Button className="valider-button" id="validerSaksnummer" onClick={validerSaksnummerInput}>
                        Valider saksnummer
                      </Button>
                    </>
                  )}
                </Col>
                <Col md={3}>
                  <BymSelectInput
                    controlId="depositumKonto"
                    rules={[required()]}
                    label="*Konto"
                    items={kontoNummereWithNumber}
                  />
                </Col>
                <Col md={3}>
                  <BymSelectInput
                    controlId="koststedId"
                    rules={[required()]}
                    label="*Koststed"
                    items={kostStederWithNumber}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  {faktureringTyper.length > 0 && (
                    <BymSelectInput
                      controlId="faktureringTypeId"
                      rules={[required()]}
                      label="*Fakturering type"
                      items={faktureringTyper}
                    />
                  )}
                </Col>
                <Col md={2}>
                  <BymTextInput controlId="deresRef" rules={[length(0, 15, false)]} label="Deres ref" />
                </Col>
                <Col md={2}>
                  <UgeDateInput
                    controlId="gyldigFraDato"
                    label="*Gyldig fra"
                    placeholder="dd.mm.åååå"
                    rules={[required()]}
                  />
                </Col>
                <Col md={2}>
                  {Number(kontrakt.kontraktStatusId) === kontraktStatus.lopendeKontrakt && (
                    <BymTextInput label="Gyldig til" disabled value="Løpende kontrakt" />
                  )}
                  {Number(kontrakt.kontraktStatusId) !== kontraktStatus.lopendeKontrakt && (
                    <UgeDateInput controlId="utlopDato" label="Gyldig til" placeholder="dd.mm.åååå" />
                  )}
                </Col>
                <Col md={2}>
                  <BymCheckboxInput controlId="ikkeFornyes" label="Skal ikke fornyes" />
                </Col>
                <Col md={2}>
                  <BymTextInput
                    controlId="ansvarlig"
                    placeholder="bym id"
                    rules={[length(4, 6, false)]}
                    label="Saksbehandler"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <BymTextInput controlId="kontaktpersonNavn" label="Kontaktperson" rules={[length(3, 100, false)]} />
                </Col>
                <Col md={4}>
                  <BymTextInput
                    controlId="kontaktpersonTelefon"
                    label="Kontaktperson telefon"
                    rules={[length(4, 14, false), optionalNumber()]}
                  />
                </Col>
                <Col md={4}>
                  <BymEmailInput controlId="kontaktpersonEpost" label="Kontaktperson epost" />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="faktura-information">
            <Col md={12}>
              {kontraktStatuser.find(k => k.id === Number(kontrakt.kontraktStatusId))?.faktureres && (
                <Row>
                  <Col md={12}>
                    <h2>
                      Fakturaer genereres i perioden {getFormatedDate(kontrakt.gyldigFraDato)}{" "}
                      {Number(kontrakt.kontraktStatusId) === kontraktStatus.lopendeKontrakt
                        ? " og fremover"
                        : ` til ${getFormatedDate(kontrakt.utlopDato)}`}{" "}
                      ({kontraktStatuser.find(k => k.id === Number(kontrakt.kontraktStatusId)).navn ?? ""})
                    </h2>
                  </Col>
                </Row>
              )}
              {!kontraktStatuser.find(k => k.id === Number(kontrakt.kontraktStatusId))?.faktureres && (
                <Row>
                  <Col md={12}>
                    <h2>
                      Faktura vil ikke faktureres (
                      {kontraktStatuser.find(k => k.id === Number(kontrakt.kontraktStatusId)).navn}){" "}
                    </h2>
                  </Col>
                </Row>
              )}

              <Row>
                <Col md={12}>
                  <h3>Fakturainformasjon</h3>
                  <p>
                    Hvor ofte det skal faktureres, hvilke leieobjekter skal inkluderes og hva er prisen som skal betales
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <h4>Velg når det skal faktureres</h4>
                </Col>
              </Row>
              <Row>
                <Col md={5}>
                  <BymRadioButtonsInput
                    inline
                    hidelabel
                    controlId="faktureringTidspunktId"
                    items={orderBy(faktureringTidspunkter, "id")}
                    rules={[required()]}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <h4>Er utsendelse av faktura sesongbasert?</h4>
                </Col>
              </Row>
              <Row className="sesong-row">
                <Col md={2}>
                  <BymRadioButtonsInput
                    inline
                    controlId="prisTypeId"
                    hidelabel
                    items={[
                      { id: pristype.aar, name: "Nei" },
                      { id: pristype.sesong, name: "Ja" }
                    ]}
                    rules={[required()]}
                  />
                </Col>
                {kontrakt.prisTypeId === pristype.sesong && (
                  <>
                    <Col md={isEditMode ? 2 : 3} className="month-input">
                      <UgeDateInput
                        utc
                        label="Fra:"
                        dateFormat="MMMM"
                        controlId="sesongStartDato"
                        placeholder="Sesong start"
                        rules={[required()]}
                      />
                    </Col>
                    <Col
                      md={isEditMode ? 2 : 3}
                      className={isEditMode ? "month-input" : "sesong-slutt-picker month-input"}
                    >
                      <UgeDateInput
                        utc
                        label="Til:"
                        dateFormat="MMMM"
                        controlId="sesongSluttDato"
                        placeholder="Sesong slutt"
                        rules={[required()]}
                      />
                    </Col>
                  </>
                )}
              </Row>
              {kontrakt.prisTypeId === pristype.sesong && (
                <>
                  <Row>
                    <Col md={12}>
                      <h4>
                        Er det egen leiepris utenfor sesongen <small>(pris2)</small>?
                      </h4>
                    </Col>
                  </Row>
                  <Row className="sesong-row">
                    <Col md={2}>
                      <BymRadioButtonsInput
                        inline
                        controlId="hasPris2"
                        hidelabel
                        items={[
                          { id: "0", name: "Nei" },
                          { id: "1", name: "Ja" }
                        ]}
                        rules={[required()]}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col md={12}>
                  <h4>Oppgi hvor ofte det skal betales</h4>
                </Col>
              </Row>
              {this.isEngangBetalingTermin(kontrakt.betalingTerminId) && (
                <Alert bsStyle="warning">
                  Fakturaintervall er satt til <span style={{ fontWeight: "bold" }}>En gang</span> og vil ikke generere
                  fakturaer automatisk. For å opprette en faktura så kan du gjøre dette via tillegsfaktura på
                  kontrakten, eller endre intervall her.
                </Alert>
              )}
              <Row className="betalingtermin-row">
                <Col md={isEditMode ? 6 : 12}>
                  {betalingTerminTyper.length > 0 && (
                    <BymRadioButtonsInput
                      inline
                      hidelabel
                      controlId="betalingTerminId"
                      rules={[required()]}
                      items={validBetalingTerminTyper}
                    />
                  )}
                </Col>
                {this.isTilpassetBetalingTermin(kontrakt.betalingTerminId) && (
                  <>
                    <Col md={3}>
                      <BymSelectInput controlId="tilpassetBetalingManed1" label="Måned 1" items={getMonths()} />
                    </Col>
                    <Col md={3}>
                      <BymSelectInput
                        disabled={!kontrakt.tilpassetBetalingManed1}
                        controlId="tilpassetBetalingManed2"
                        label="Måned 2"
                        items={getMonths()}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col md={12}>
                  <FakturaGenerationViewer kontrakt={kontrakt} />
                </Col>
              </Row>
            </Col>
          </Row>
          {kontrakt?.eiendomId && (
            <Row className="leieobjekt-information">
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <h4>Velg leieobjekter som skal faktureres</h4>
                    {kontrakt.prisTypeId === pristype.sesong && (
                      <p>
                        Du har valgt sesongbasert utsendelse av faktura. Dette betyr at priser er pr. periode. Dvs. at
                        prisen man legger inn er den som vil stå på fakturaen. Velger man f.eks månedlig under hvor ofte
                        det skal faktureres så vil prisen være månedspris, velger man sesong så er prisen for hele
                        sesongen.
                      </p>
                    )}
                    {Number(kontrakt.betalingTerminId) === betalingTermin.tilpasset && (
                      <p>
                        Du har valgt tilpasset utsendelse av faktura. Dette betyr ar prisen er pr periode delt på antall
                        valgte måneder (1 eller 2 valgte måneder). Dvs. at prisen man legger inn er den som vil stå på
                        fakturaen delt på 1 eller 2.
                      </p>
                    )}
                    {kontrakt.prisTypeId !== pristype.sesong &&
                      Number(kontrakt.betalingTerminId) !== betalingTermin.tilpasset && (
                        <p>
                          Her setter du årlig pris pr. leieobjekt. Pris pr. faktura vil utledes av årlig pris,
                          kontraktens gyldighet (Gyldig fra - til), fakturaintervall, kontraktstatus, sesong/ikke sesong
                          og om det er forskuddsvis/etterskuddsvis.
                          <br />
                          Bruk den blå boksen nedenfor til å se hva som blir beløp pr faktura fremover.
                        </p>
                      )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <h5>Leieobjekt</h5>
                  </Col>
                  <Col xs={4}>
                    <h5>Manuell ordrelinje tekst</h5>
                  </Col>
                  <Col xs={this.showPris2OrAntallColumn(kontrakt) ? 5 : 3}>
                    <Row>
                      <Col xs={this.showPris2OrAntallColumn(kontrakt) ? 6 : 12}>
                        <h5>Pris (eks mva)</h5>
                      </Col>
                      {kontrakt.hasPris2 === "1" && (
                        <Col xs={4}>
                          <h5>Pris2 (eks mva)</h5>
                        </Col>
                      )}
                      {Number(kontrakt.kontraktTypeId) === kontraktType.veibelysning &&
                        kontrakt.prisTypeId === pristype.aar && (
                          <Col xs={6}>
                            <h5>Antall</h5>
                          </Col>
                        )}
                    </Row>
                  </Col>
                </Row>
                {kontrakt &&
                  kontrakt.leieObjekter.map((leieObjekt, index) => (
                    <Row key={leieObjekt.leieObjektId}>
                      <Col xs={3}>
                        <BymCheckboxInput controlId={`leieObjekter[${index}].checked`} label={leieObjekt.navn} inline />
                      </Col>
                      <Col xs={4}>
                        <BymTextInput
                          className="leieobjekt-pris"
                          placeholder="Manuell faktura tekst"
                          disabled={!leieObjekt.checked}
                          controlId={`leieObjekter[${index}].fakturaTekst`}
                          hidelabel
                        />
                      </Col>
                      <Col xs={this.showPris2OrAntallColumn(kontrakt) ? 5 : 3}>
                        <Row>
                          <Col xs={this.showPris2OrAntallColumn(kontrakt) ? 6 : 12}>
                            <BymCurrencyInput
                              className="leieobjekt-pris"
                              placeholder={
                                kontrakt.prisTypeId === pristype.sesong ||
                                Number(kontrakt.betalingTerminId) === betalingTermin.tilpasset
                                  ? "Pris pr periode"
                                  : "Årspris"
                              }
                              disabled={!leieObjekt.checked}
                              controlId={`leieObjekter[${index}].pris`}
                              hidelabel
                            />
                          </Col>
                          {kontrakt.hasPris2 === "1" && (
                            <Col xs={6}>
                              <BymCurrencyInput
                                className="leieobjekt-pris"
                                placeholder="Utenfor sesong"
                                disabled={!leieObjekt.checked}
                                controlId={`leieObjekter[${index}].pris2`}
                                hidelabel
                              />
                            </Col>
                          )}
                          {Number(kontrakt.kontraktTypeId) === kontraktType.veibelysning &&
                            kontrakt.prisTypeId === pristype.aar && (
                              <Col xs={6}>
                                <BymNumberInput
                                  className="leieobjekt-pris"
                                  placeholder="Antall"
                                  disabled={!leieObjekt.checked}
                                  controlId={`leieObjekter[${index}].antall`}
                                  hidelabel
                                />
                              </Col>
                            )}
                        </Row>
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          )}
          {kontrakt && !kontrakt.eiendomId && (
            <Row className="leieobjekt-information">
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    {kontrakt.prisTypeId === pristype.sesong && (
                      <>
                        <h4>Pris pr. periode</h4>
                        <p>
                          Du har valgt sesongbasert utsendelse av faktura. Dette betyr at priser er pr. periode. Dvs. at
                          prisen man legger inn er den som vil stå på fakturaen. Velger man f.eks månedlig under hvor
                          ofte det skal faktureres så vil prisen være månedspris, velger man sesong så er prisen for
                          hele sesongen.
                        </p>
                      </>
                    )}
                    {Number(kontrakt.betalingTerminId) === betalingTermin.tilpasset && (
                      <>
                        <h4>Pris pr. periode</h4>
                        <p>
                          Du har valgt tilpasset utsendelse av faktura. Dette betyr ar prisen er pr periode delt på
                          antall valgte måneder (1 eller 2 valgte måneder). Dvs. at prisen man legger inn er den som vil
                          stå på fakturaen delt på 1 eller 2.
                        </p>
                      </>
                    )}
                    {kontrakt.prisTypeId !== pristype.sesong &&
                      Number(kontrakt.betalingTerminId) !== betalingTermin.tilpasset && (
                        <>
                          <h4>Årlig pris</h4>
                          <p>
                            Her setter du årlig pris for kontrakten. Pris pr faktura vil utledes av årlig pris,
                            kontraktens gyldighet (Gyldig fra - til), fakturaintervall, kontraktstatus, sesong/ikke
                            sesong og om det er forskuddsvis/etterskuddsvis.
                            <br />
                            Bruk den blå boksen nedenfor til å se hva som blir beløp pr faktura fremover.
                          </p>
                        </>
                      )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <h5>Faktura tekst (teksten som skal ligge på ordrelinjen)</h5>
                  </Col>
                  <Col xs={this.showPris2OrAntallColumn(kontrakt) ? 5 : 3}>
                    <Row>
                      <Col xs={this.showPris2OrAntallColumn(kontrakt) ? 6 : 12}>
                        <h5>Pris (eks mva)</h5>
                      </Col>
                      {kontrakt.hasPris2 === "1" && (
                        <Col xs={4}>
                          <h5>Pris2 (eks mva)</h5>
                        </Col>
                      )}
                      {Number(kontrakt.kontraktTypeId) === kontraktType.veibelysning &&
                        kontrakt.prisTypeId === pristype.aar && (
                          <Col xs={6}>
                            <h5>Antall</h5>
                          </Col>
                        )}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <BymTextInput
                      className="leieobjekt-pris"
                      placeholder="Manuell faktura tekst"
                      controlId={`fakturaTekst`}
                      hidelabel
                    />
                  </Col>
                  <Col xs={this.showPris2OrAntallColumn(kontrakt) ? 5 : 3}>
                    <Row>
                      <Col xs={this.showPris2OrAntallColumn(kontrakt) ? 6 : 12}>
                        <BymCurrencyInput
                          className="leieobjekt-pris"
                          placeholder={
                            kontrakt.prisTypeId === pristype.sesong ||
                            Number(kontrakt.betalingTerminId) === betalingTermin.tilpasset
                              ? "Pris pr periode"
                              : "Årspris"
                          }
                          controlId={`pris`}
                          hidelabel
                        />
                      </Col>
                      {kontrakt.hasPris2 === "1" && (
                        <Col xs={6}>
                          <BymCurrencyInput
                            className="leieobjekt-pris"
                            placeholder="Utenfor sesong"
                            controlId={`pris2`}
                            hidelabel
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={5}>
              <BymTextInput
                controlId="fakturaAdresse"
                rules={[length(0, 30, false)]}
                placeholder="Eksempel: Adresse"
                label="Topptekst"
              />
            </Col>
            <Col md={2}>
              <BymTextInput controlId="fakturaPostnummer" rules={[length(4, 4, false)]} label="Postnummer" />
            </Col>
            <Col md={3}>
              <BymTextInput controlId="fakturaPoststed" rules={[length(0, 30, false)]} label="Poststed" />
            </Col>
          </Row>
          <Row />
          <Row>
            <Col md={3} />
          </Row>
          <br />
          {showUnsentFakturaWarning && (
            <Alert bsStyle="warning">
              <strong>Advarsel!</strong> Det finnes fakturaer på denne kontrakten som ikke er sendt til Agresso. Husk å
              behandle denne/disse hvis du har gjort endringer på kontrakten som påvirker faktureringen.
            </Alert>
          )}
          <FakturaBeregning kontrakt={kontrakt} beregnetFakturaBelop={beregnetFakturaBelop} />
          <BymButton bsStyle="link" text="Avbryt" onClick={onCancel} />
          <BymSubmitButton text={isEditMode ? "Oppdater" : "Opprett kontrakt"} disabled={disableSubmitButton} />
        </BymForm>
        <br />
        <br />
      </div>
    );
  }
}

KontraktEditor.propTypes = {
  kontrakt: PropTypes.shape({}),
  eiendomId: PropTypes.string,
  segmentId: PropTypes.number,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  editObject: PropTypes.shape({}),
  leieObjekter: PropTypes.arrayOf(PropTypes.object).isRequired,
  kontraktTyper: PropTypes.arrayOf(PropTypes.object).isRequired,
  faktureringTyper: PropTypes.arrayOf(PropTypes.object).isRequired,
  betalingTerminTyper: PropTypes.arrayOf(PropTypes.object).isRequired,
  kostSteder: PropTypes.arrayOf(PropTypes.object).isRequired,
  kontoNummere: PropTypes.arrayOf(PropTypes.object).isRequired,
  kontraktStatuser: PropTypes.arrayOf(PropTypes.object).isRequired,
  faktureringTidspunkter: PropTypes.arrayOf(PropTypes.object),
  showUnsentFakturaWarning: PropTypes.bool,
  saerAvtaleGrunn: PropTypes.string
};

KontraktEditor.defaultProps = {
  kontrakt: undefined,
  segmentId: undefined,
  eiendomId: undefined,
  editObject: undefined,
  onEdit: undefined,
  onSave: undefined,
  onCancel: undefined
};
