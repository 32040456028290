import React, { useState } from "react";
import { BymSelectInput, BymTextInput, required } from "components/Common/Forms";
import { Row, Col, Button, FormControl } from "react-bootstrap";
import { getCssBakgrunnForVerifisertSaksnummer, validerArkivsaknummerWithFunc } from "utils/arkivUtils";

const KontraktName = ({
  kontoNummere,
  kostSteder,
  kontraktTyper,
  faktureringTyper,
  showTittelFormaal,
  showTittelNavn
}: any) => {
  const [verifisertSaksnummer, setVerifisertSaksnummer] = useState<boolean | undefined>(undefined);
  const [saksnummer, setSaksnummer] = useState<string | undefined>();

  const updateSaksnummer = (e: React.FormEvent<FormControl>) => {
    if (e.currentTarget) {
      setSaksnummer((e.currentTarget as any).value);
    }
  };

  const validerSaksnummerInput = () => {
    setVerifisertSaksnummer(undefined);

    if (saksnummer) {
      validerArkivsaknummerWithFunc(
        saksnummer,
        () => setVerifisertSaksnummer(true),
        () => setVerifisertSaksnummer(false)
      );
    }
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <BymTextInput
            controlId="kontrakt.tittel"
            label="*Kontraktsnavn"
            placeholder="Navn på kontrakt i UGE"
            rules={[required()]}
          />
        </Col>
      </Row>
      <Row>
        {showTittelNavn && (
          <Col md={6}>
            <BymTextInput
              controlId="avtaleData.tittelNavn"
              label="*Dokumenttittel navn"
              placeholder="Vises i tittel på kontraktsdokumentet"
              rules={[required()]}
            />
          </Col>
        )}
        {showTittelFormaal && (
          <Col md={6}>
            <BymTextInput
              controlId="avtaleData.tittelFormaal"
              label="*Dokumenttittel formål"
              placeholder="Vises i tittel på kontraktsdokumentet"
              rules={[required()]}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md={3}>
          <BymSelectInput
            controlId="kontrakt.depositumKonto"
            rules={[required()]}
            label="*Konto"
            items={kontoNummere}
          />
        </Col>
        <Col md={3}>
          <BymSelectInput controlId="kontrakt.koststedId" rules={[required()]} label="*Koststed" items={kostSteder} />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <BymSelectInput
            controlId="kontrakt.kontraktTypeId"
            rules={[required()]}
            label="*Kontrakt type"
            items={kontraktTyper}
          />
        </Col>
        <Col md={3}>
          <BymSelectInput
            controlId="kontrakt.faktureringTypeId"
            rules={[required()]}
            label="*Fakturering type"
            items={faktureringTyper}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <br />
          <h4>Registrer saksnummer</h4>
          <BymTextInput
            onChange={updateSaksnummer}
            className={getCssBakgrunnForVerifisertSaksnummer(verifisertSaksnummer)}
            controlId="kontrakt.arkivSakNummer"
            label="Arkiv Saknummer"
            placeholder="Arkiv saknummer"
            autoComplete="off"
          />

          {saksnummer && (
            <>
              <label htmlFor="validerSaksnummer" className="control-label"></label>
              <Button
                className="valider-button"
                id="validerSaksnummer"
                onClick={e => {
                  e.preventDefault();
                  validerSaksnummerInput();
                }}
              >
                Valider saksnummer
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default KontraktName;
