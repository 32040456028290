import { FormContextTypes } from "components/Common/Forms";
import { get } from "lodash-es";
import { FormGroup, FormControl, ControlLabel, HelpBlock, FormControlProps } from "react-bootstrap";

function validate(controlId: string, label: string, values = [], touched: boolean, rules: any, onValidate: any) {
  if (rules) {
    const value = get(values, controlId);
    for (const rule of rules) {
      const error = rule(value, label, values);
      if (error != null) {
        return onValidate(controlId, {
          hasError: true,
          errorState: touched ? "error" : null,
          errorText: touched ? error : null
        });
      }
    }
  }

  return onValidate(controlId, { hasError: false, errorState: null, errorText: null });
}

export interface UgeFormControlProps extends FormControlProps {
  controlId: string;
  value: any;
  label: string;
  component: any;
  rules: any;
  hidelabel: boolean;
  alwaysShowError: boolean;
  onValidate: (_id: string, v: any) => any;
}

const UgeFormControl = (
  {
    controlId,
    value,
    label,
    component: Component = FormControl,
    rules,
    hidelabel = false,
    alwaysShowError = false,
    onValidate = (_id, v) => v,
    ...props
  }: UgeFormControlProps,
  context: any
) => {
  label = hidelabel !== true ? label || controlId : label;
  context = context || {};
  context.form = context.form || {};
  const { touched, values = [], validatedWithErrors } = context.form;
  const { hasError, errorState, errorText } = validate(
    controlId,
    label,
    values,
    alwaysShowError || touched,
    rules,
    onValidate
  );
  value = value || get(values, controlId);
  if (hasError) validatedWithErrors();
  if (props.children && !Component) {
    Component = () => props.children;
  }
  const readOnly = !!(context.form.readOnly || props.readOnly);
  const disabled = !!(context.form.disabled || props.disabled);
  return (
    <FormGroup controlId={controlId} validationState={errorState}>
      {label && <ControlLabel>{label}</ControlLabel>}
      <Component value={value} readOnly={readOnly} disabled={disabled} {...props} />
      {errorText && <HelpBlock>{errorText}</HelpBlock>}
    </FormGroup>
  );
};

UgeFormControl.contextTypes = FormContextTypes;

export default UgeFormControl;
