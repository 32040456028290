import { map } from "lodash-es";
import createSelector from "re-reselect";

const eiendomSelector = (state: any, eiendomId: string) => state.entities.eiendommer[eiendomId];
const leieObjekterSelector = (state: any) => state.entities.leieObjekter;
const leieObjektSelector = (state: any, id: any) => state.entities.leieObjekter[id];

const getLeieObjektSelector = createSelector(leieObjektSelector, leieObjekt => leieObjekt)((state, id) => id);

export default createSelector(
  eiendomSelector,
  leieObjekterSelector,
  (eiendom, leieObjekter) => (eiendom && map(eiendom.leieObjekter, id => leieObjekter[id])) || []
)((state, eiendomId) => eiendomId) as any;

export { getLeieObjektSelector };
