import React from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

import "./UserIcon.css";
import defaultIcon from "../../images/profilbilde_large.svg";

const UserIcon = ({ brukerId, className = "user-icon-large", ...restProps }) => (
  <div className="user-icon-container">
    <div className={`user-icon ${className}`}>
      <Image circle src={defaultIcon} {...restProps} />
      <span>{brukerId}</span>
    </div>
  </div>
);

UserIcon.propTypes = {
  brukerId: PropTypes.string.isRequired,
  className: PropTypes.string
};

UserIcon.defaultProps = {
  className: "user-icon-large"
};

export default UserIcon;
