import { User } from "oidc-client-ts";
import { oidcConfig } from "./oidcConfig";
import { OneloginAccessToken } from "./interface";
import jwtDecode from "jwt-decode";
import { Ugeroller, UgerollerUnion } from "constants/auth";

export const getUser = () => {
  const oidcStorage = sessionStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);
  return !oidcStorage ? null : User.fromStorageString(oidcStorage);
};

export function getToken() {
  const token = getUser()?.access_token ?? null;
  return token;
}

export function getAccessTokenDecoded(accessToken?: string) {
  const at = accessToken ?? getUser()?.access_token;
  return at ? jwtDecode<OneloginAccessToken>(at) : null;
}

export function isOneOfUserRolesIncludedInRolesList(userRoles?: string[], roles?: UgerollerUnion[]) {
  const userRolesFromToken = userRoles ?? getAccessTokenDecoded()?.roles;
  if (!userRolesFromToken || !roles) return false;
  if (roles?.find(r => userRolesFromToken?.includes(r))) return true;
  return false;
}

export function isRoleInAccessToken(role: Ugeroller, accessToken?: OneloginAccessToken) {
  return isOneOfUserRolesIncludedInRolesList(accessToken?.roles, [role]);
}

export function isAdmin(accessToken?: OneloginAccessToken) {
  return isRoleInAccessToken(Ugeroller.UgeAdmin, accessToken);
}

export function isUgeBruker(accessToken?: OneloginAccessToken) {
  return isRoleInAccessToken(Ugeroller.UgeBruker, accessToken);
}

export function isLesebruker(accessToken?: OneloginAccessToken) {
  return isRoleInAccessToken(Ugeroller.UgeLeseBruker, accessToken);
}

export function isAdminOrUgeBruker(accessToken?: OneloginAccessToken) {
  return isAdmin(accessToken) || isUgeBruker(accessToken);
}
