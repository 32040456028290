import React from "react";
import DateTime from "react-datetime";
import { FormContextTypes } from "components/Common/Forms";
import { get } from "lodash-es";
import { getFormatedDate } from "../../../utils/dateUtils";
import UgeFormControl from "./UgeFormControl";
import "./UgeDateInput.css";

const getdateControl = (
  value,
  controlId,
  dateFormat,
  placeholder = "",
  onChange,
  readOnly,
  alignRight = false,
  alignTop = false,
  viewDate = new Date()
) => {
  const onKeyDown = e => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      e.preventDefault();
      onChange("");
    }
  };
  return (
    <DateTime
      className={`${alignRight ? "right-align-input" : "left-align-input"} ${alignTop ? "top-align-input" : ""}`}
      dateFormat={dateFormat}
      utc
      input
      show={!readOnly}
      open={readOnly ? false : undefined}
      viewDate={viewDate}
      timeFormat={false}
      inputProps={{ onKeyDown, placeholder, readOnly }}
      value={getFormatedDate(value, dateFormat)}
      onChange={e => onChange(get(e, "_d"))}
    />
  );
};

const UgeDateInput = (
  { controlId, placeholder, viewDate, alignRight, dateFormat = "DD.MM.YYYY", ...restProps },
  context
) => {
  const { form } = context;
  const value = get(form.values, controlId);
  const readOnly = !!(form.readOnly || restProps.readOnly);
  const onChange = e => {
    context.form.handleChange({ target: { id: controlId, value: e } });
  };
  return (
    <UgeFormControl
      controlId={controlId}
      {...restProps}
      component={() =>
        getdateControl(
          value,
          controlId,
          dateFormat,
          placeholder,
          onChange,
          readOnly,
          alignRight,
          restProps.alignTop,
          viewDate
        )
      }
    />
  );
};

UgeDateInput.contextTypes = FormContextTypes;

export default UgeDateInput;
