import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Glyphicon } from "react-bootstrap";
import PageSection from "../Layout/PageSection";
import AddKontraktAvtaleDialog from "./AddKontraktAvtaleDialog";
import AddKontraktSpecialDialog from "./AddKontraktSpecialDialog";

import dokumentIcon from "images/dokument.svg";
import "./KontraktSection.css";
import { Eiendom, Kontrakt, LeieObjekt } from "interfaces";
import { getKontrakterForEiendom } from "selectors/kontrakterSelector";
import getLeieObjekterForEiendom from "selectors/leieObjektSelector";
import { useSelector } from "react-redux";
import * as kontraktActions from "../../actions/kontraktAction";
import * as toastActions from "../../actions/toastAction";
import useActions from "store/useActions";
import { BymForm, BymCheckboxInput } from "components/Common/Forms";
import { blirFakturert } from "utils/kontraktUtils";
import ShowOnlyForUsersWith from "auth/ShowOnlyFor";

const SectionKontraktElement = ({ kontrakt }: { kontrakt: Kontrakt }) => {
  return (
    <li>
      <img src={dokumentIcon} alt="" />
      <Link to={`/kontrakter/${kontrakt.id}`}>
        {kontrakt.tittel}{" "}
        {!blirFakturert(kontrakt) ? (
          <span style={{ color: "#a71225" }}>({kontrakt.kontraktStatus?.navn})</span>
        ) : undefined}
      </Link>
    </li>
  );
};

const KontraktSectionButtons = ({ onAdd, onAddSpecial, allowAddKontrakt }: any) => {
  if (!allowAddKontrakt) return <></>;

  return (
    <>
      <Button
        bsStyle="bym-standard"
        onClick={e => {
          e.stopPropagation();
          onAdd();
        }}
      >
        <Glyphicon glyph="plus" />
        Ny kontraktsavtale
      </Button>{" "}
      <Button
        bsStyle="bym-standard"
        onClick={e => {
          e.stopPropagation();
          onAddSpecial();
        }}
      >
        <Glyphicon glyph="plus" />
        Ny særavtale
      </Button>
    </>
  );
};

interface KontraktSectionProps {
  eiendom?: Eiendom;
  eiendomId?: string;
  sectionUpdated?: () => void;
}

const KontraktSection = ({ eiendom, eiendomId, sectionUpdated }: KontraktSectionProps) => {
  const [showAddKontraktDialog, setShowAddKontraktDialog] = useState(false);
  const [showAddSpecialKontraktDialog, setShowAddSpecialKontraktDialog] = useState(false);
  const kontrakter = useSelector<any, Kontrakt[]>(state => getKontrakterForEiendom(state, eiendomId)) ?? [];
  const leieObjekter = useSelector<any, LeieObjekt[]>(state => getLeieObjekterForEiendom(state, eiendomId)) ?? [];
  const { postKontrakt } = useActions(kontraktActions);
  const { setToastSuccess } = useActions(toastActions);
  const [excludeArchived, setExcludeArchived] = useState(true);

  const saveKontrakt = (kontrakt: Kontrakt, closeDialog = true) => {
    setShowAddKontraktDialog(!closeDialog);
    return postKontrakt(kontrakt.eiendomId, kontrakt).then(() => {
      if (sectionUpdated) sectionUpdated();
      if (sectionUpdated) sectionUpdated();
      setToastSuccess("Ny kontrakt opprettet");
      if (closeDialog) {
        setShowAddSpecialKontraktDialog(false);
      }
    });
  };

  const showKontrakt = (kontrakt: Kontrakt) =>
    !excludeArchived || (kontrakt.kontraktStatusId !== 7 && kontrakt.kontraktStatusId !== 10); // oppsagt eller utgått kontrakt

  const visibleKontrakter = kontrakter.filter(k => showKontrakt(k));

  const allowAddKontrakt = leieObjekter && leieObjekter.length > 0 && eiendom;

  const title = allowAddKontrakt ? (
    `Kontrakter (${visibleKontrakter.length})`
  ) : (
    <>
      Kontrakter (
      <small style={{ color: "#d32316", padding: "0 4px" }}>Opprettelse av kontrakt krever minst 1 leieobjekt</small>)
    </>
  );

  return (
    <>
      {showAddKontraktDialog && (
        <AddKontraktAvtaleDialog
          eiendom={eiendom}
          show={showAddKontraktDialog}
          leieObjekter={leieObjekter}
          onSave={saveKontrakt}
          onHide={() => setShowAddKontraktDialog(false)}
        />
      )}
      {showAddSpecialKontraktDialog && (
        <AddKontraktSpecialDialog
          eiendom={eiendom}
          eiendomId={eiendom?.id}
          segmentId={eiendom?.segmentId}
          leieObjekter={leieObjekter}
          onSave={saveKontrakt}
          onHide={() => setShowAddSpecialKontraktDialog(false)}
          show
        />
      )}

      <PageSection
        title={title}
        buttonsComponent={
          <ShowOnlyForUsersWith roles={["UGE-admin", "UGE-bruker"]}>
            <KontraktSectionButtons
              allowAddKontrakt={allowAddKontrakt}
              onAdd={() => setShowAddKontraktDialog(true)}
              onAddSpecial={() => setShowAddSpecialKontraktDialog(true)}
            />
          </ShowOnlyForUsersWith>
        }
      >
        <div style={{ marginLeft: "-20px" }}>
          <BymForm values={{ excludeArchived: !excludeArchived }} onChange={() => setExcludeArchived(!excludeArchived)}>
            <BymCheckboxInput controlId="excludeArchived" label="Inkluder utgåtte/oppsagte kontrakter" inline />
          </BymForm>
        </div>
        <ul className="list-unstyled ps-kontrakt-list">
          {visibleKontrakter.map(kontrakt => (
            <SectionKontraktElement key={kontrakt.id} kontrakt={kontrakt} />
          ))}
        </ul>
      </PageSection>
    </>
  );
};

export default KontraktSection;
