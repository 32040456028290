import classnames from "classnames";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

import "./HeaderCell.css";
import normalIcon from "../../../images/sortering.svg";
import ascIcon from "../../../images/sortering_ned.svg";
import descIcon from "../../../images/sortering_opp.svg";

function getSortClass(cellKey, sortCellKey, sortOrders) {
  if (cellKey !== sortCellKey) {
    return "hc-sort-normal";
  }
  return sortOrders === "desc" ? "hc-sort-desc" : "hc-sort-asc";
}

class HeaderCell extends PureComponent {
  handleClick = () => {
    const { cellKey, sort, onSort, disableSort } = this.props;
    const { cellKey: sortCellKey, orders: sortOrders } = sort || {};
    if (onSort && !disableSort) {
      onSort({
        cellKey,
        orders: sortCellKey === cellKey && sortOrders === "asc" ? "desc" : "asc"
      });
    }
  };

  render() {
    const { cellKey, sort, onSort, disableSort, children, ...rest } = this.props;
    const { cellKey: sortCellKey, orders: sortOrders } = sort || {};
    const sortClass = getSortClass(cellKey, sortCellKey, sortOrders);
    return (
      <th className="header-cell" onClick={this.handleClick} {...rest}>
        <div>
          {children}
          {onSort && !disableSort && (
            <div className={classnames("hc-sort", sortClass)}>
              <Image className="hc-img-normal" src={normalIcon} />
              <Image className="hc-img-asc" src={ascIcon} />
              <Image className="hc-img-desc" src={descIcon} />
            </div>
          )}
        </div>
      </th>
    );
  }
}

HeaderCell.propTypes = {
  cellKey: PropTypes.string,
  sort: PropTypes.shape({
    cellKey: PropTypes.string,
    orders: PropTypes.string
  }),
  children: PropTypes.node,
  disableSort: PropTypes.bool,
  onSort: PropTypes.func
};

HeaderCell.defaultProps = {
  children: [],
  cellKey: "",
  sort: { cellKey: null, orders: null },
  disableSort: false,
  onSort: () => {}
};

export default HeaderCell;
