import React from "react";
import PropTypes from "prop-types";
import DeleteFakturaDialog from "components/Faktura/DeleteFakturaDialog";
import FakturaSectionList from "./FakturaSectionList";
import PageSection from "../Layout/PageSection";

class KreditnotaSection extends React.Component {
  state = {
    showConfirmDeleteDialog: false,
    deleteKreditnotaId: undefined
  };

  onDeleteKreditnotaClicked = kreditnotaId => {
    this.setState({ showConfirmDeleteDialog: true, deleteKreditnotaId: kreditnotaId });
  };

  deleteFaktura = kommentar => {
    const { onDelete } = this.props;
    const { deleteKreditnotaId } = this.state;
    if (onDelete) onDelete(deleteKreditnotaId, kommentar);
    this.setState({ showConfirmDeleteDialog: false, deleteKreditnotaId: undefined });
  };

  render() {
    const { kontrakt, kreditnotaer: sorterteKreditnotaer, onSendToAgresso, ...rest } = this.props;
    const { showConfirmDeleteDialog } = this.state;
    sorterteKreditnotaer.sort((a, b) => a.maned < b.maned).sort((c, d) => c.ar < d.ar);

    return (
      <>
        <DeleteFakturaDialog
          show={showConfirmDeleteDialog}
          onConfirm={kommentar => this.deleteFaktura(kommentar)}
          onClose={() => this.setState({ showConfirmDeleteDialog: false, deleteKreditnotaId: undefined })}
        />
        <PageSection title={`Kreditnotaer (${sorterteKreditnotaer.length})`}>
          <FakturaSectionList
            {...rest}
            fakturaer={sorterteKreditnotaer}
            showDeleteColumn
            showAgressoColumn
            onDelete={this.onDeleteKreditnotaClicked}
            onSendToAgresso={onSendToAgresso}
            showKreditnotaInfo
          />
        </PageSection>
      </>
    );
  }
}

KreditnotaSection.propTypes = {
  kontrakt: PropTypes.shape({}).isRequired,
  kreditnotaer: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSendToAgresso: PropTypes.func.isRequired
};

export default KreditnotaSection;
