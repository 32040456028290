import { merge } from "lodash-es";

export default function bilder(state = {}, action) {
  switch (action.type) {
    default:
      if (action.payload && action.payload.entities && action.payload.entities.bilder) {
        return merge({}, state, action.payload.entities.bilder);
      }
      return state;
  }
}
