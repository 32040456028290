import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import axios from "services/axios";
import * as api from "constants/api";
import * as fakturaAction from "actions/fakturaAction";
import * as toastAction from "actions/toastAction";
import FakturaSection from "components/Faktura/FakturaSection";
import { getKontraktFakturaList } from "selectors/fakturaerSelector";
import { fakturaShape } from "constants/proptypes";

class FakturaSectionContainer extends Component {
  updateKontraktFaktura = kontraktFaktura => {
    const { updateKontraktFaktura, kontraktId, sectionUpdated, getKontraktFakturaer, setToastSuccess } = this.props;
    updateKontraktFaktura(kontraktId, kontraktFaktura.id, kontraktFaktura).then(() => {
      setToastSuccess("Faktura oppdatert");
      sectionUpdated();
      getKontraktFakturaer(kontraktId);
    });
  };

  synkroniserKontraktFaktura = async faktura => {
    await axios.post(`${api.GET_FAKTURA_SYNKRONISER_URL(faktura.id)}`);
    const { kontraktId, sectionUpdated, getKontraktFakturaer, setToastSuccess } = this.props;
    setToastSuccess("Fakturastatus oppdatert");
    sectionUpdated();
    getKontraktFakturaer(kontraktId);
  };

  createKontraktFaktura = () => {
    const date = new Date();
    const body = {
      ar: date.getFullYear(),
      maned: date.getMonth() + 1
    };
    const { createKontraktFaktura, kontraktId, sectionUpdated, getKontraktFakturaer, setToastSuccess } = this.props;
    return createKontraktFaktura(kontraktId, body).then(result => {
      sectionUpdated();
      getKontraktFakturaer(kontraktId);
      if (result.entities && result.entities.fakturaer) {
        setToastSuccess("Faktura opprettet");
        return result.entities.fakturaer;
      }
      return null;
    });
  };

  addKreditnota = kreditnota => {
    const { postKontraktKreditnota, kontraktId, getKontraktFakturaer, setToastSuccess } = this.props;
    postKontraktKreditnota(kontraktId, kreditnota).then(() => {
      setToastSuccess("Kreditnota opprettet");
      getKontraktFakturaer(kontraktId);
    });
  };

  render() {
    const { nesteFakturering, faktureringFerdigDenneManed } = this.props;
    return (
      <FakturaSection
        {...this.props}
        nesteKontraktFakturering={nesteFakturering}
        faktureringFerdigDenneManed={faktureringFerdigDenneManed}
        showEditColumn
        onAddKreditnota={this.addKreditnota}
        onSynkroniserAgresso={this.synkroniserKontraktFaktura}
        createKontraktFaktura={this.createKontraktFaktura}
        updateKontraktFaktura={this.updateKontraktFaktura}
        addKreditnota={this.addKreditnota}
      />
    );
  }
}

FakturaSectionContainer.propTypes = {
  fakturaer: PropTypes.arrayOf(fakturaShape).isRequired,
  kontrakt: PropTypes.shape({}).isRequired,
  kontraktId: PropTypes.string.isRequired,
  getKontraktFakturaer: PropTypes.func.isRequired,
  createKontraktFaktura: PropTypes.func.isRequired,
  updateKontraktFaktura: PropTypes.func.isRequired,
  postKontraktKreditnota: PropTypes.func.isRequired,
  sectionUpdated: PropTypes.func,
  setToastSuccess: PropTypes.func.isRequired,
  nesteFakturering: PropTypes.string,
  faktureringFerdigDenneManed: PropTypes.bool
};

FakturaSectionContainer.defaultProps = {
  sectionUpdated: () => {}
};

const mapStateToProps = state => {
  const allFakturaer = getKontraktFakturaList(state);
  const fakturaer = allFakturaer.filter(f => f.fakturaType === "faktura");
  return {
    fakturaer
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...fakturaAction, setToastSuccess: toastAction.setToastSuccess }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FakturaSectionContainer);
