import React from "react";
import { Row, Col } from "react-bootstrap";
import { BymRadioButtonsInput, required } from "components/Common/Forms";
import { KontraktTemplates } from "./KontraktAvtaleUtils";

const TemplateChooser = ({ kontraktTemplates }: { kontraktTemplates: KontraktTemplates }) => {
  return (
    <Row>
      <Col md={6}>
        <BymRadioButtonsInput
          hidelabel
          controlId="avtaleData.kontraktsMal"
          items={kontraktTemplates}
          rules={[required()]}
        />
      </Col>
      <Col md={6}>
        <h4 style={{ marginTop: "-30px" }}>Informasjon</h4>

        <strong>Leie av areal:</strong>
        <p>
          Denne kontrakten kan brukes på søknader om:
          <ul>
            <li>uteservering</li>
            <li>vareutstilling</li>
            <li>salgsplass</li>
            <li>standsplass</li>
            <li>arrangementer</li>
            <li>konserter/festivaler</li>
            <li>demonstrasjon/markering</li>
            <li>filminnspilling/fotografering</li>
            <li>arrangere løp eller ritt</li>
          </ul>
        </p>
        <strong>Leie av riggplass:</strong>
        <p>Brukes ved leie av riggplass</p>
        <strong>Leie av lokale på eiendom med flere brukere:</strong>
        <p>
          Denne malen skal kun brukes der det er flere leietakere inn i samme eiendom, og det dreier seg om
          næringseiendom. Eks. Flere leietakere som driver næringsdrift, eksempelvis Bazaarbygget
        </p>
        <strong>Timesleie av idrettsanlegg:</strong>
        <p>
          Denne malen tar sikte på «alminnelig timesutleie av idrettsanlegg, slik som haller og baner. Denne malen skal
          brukes om de typiske avtalene hvor idrettsorganisasjoner avtaler bruk av idrettsanlegg for bruk, men til
          bestemte tider/dager. Eks: Lillevik fotballklubb leier deler av Hypotetiskstadium for trening to timer hver
          tirsdag og torsdag. Denne malen skal ikke benyttes hvis en idrettsorganisasjon skal overta dag til dag driften
          av et idrettsanlegg.
        </p>
        <strong>Timesleie av lokaler:</strong>
        <p>
          (vurderer denne endret til «festlokalekontakt», tenker videre på det og tar gjerne innspill). Denne malen
          brukes ved utleie av festlokaler og andre dagsbaserte utleier a lokaler. Eks: Peder Ås leier Bjørnehuset på en
          fredag for å feire en dåp.
        </p>
        <strong>Leiekontrakt – bruk av bad:</strong>
        <p>Denne er vurdert endret til timesleie av bad:</p>
        <p>
          Denne malen tar sikte på «alminnelig» timesutleie av bad Eks: Lillevik svømmeklubb leier Hypotetiskhallen for
          svømmetrening hver torsdag og lørdag.
        </p>
        <p>Denne malen skal ikke benyttes hvis en idrettsorganisasjon skal overta dag til dag driften av et bad.</p>
        <strong>Leie av hytte:</strong>
        <p>
          Skal kun brukes ved utleie av hytter i marka. Husk at disse kun kan leies ut til samfunnsnyttige formål. Eks:
          Utleie av hytte til speiderforeningen.
        </p>
        <strong>Leie av bolig:</strong>
        <p>Denne malen skal benyttes ved utleie av husrom til boligformål (bolig).</p>
        <p>
          Eks: Peder Ås leier hus av BYM for å bo i huset. Husk at denne malen gjelder et område hvor det foreligger
          streng, ufravikelig lovgivning.
        </p>
        <strong>Leie av lokale på eiendom med en bruker:</strong>
        <p>
          Denne malen skal brukes der det kun er en leietaker som benytter seg av en næringseiendom eller næringsdelen
          av en eiendom. Eks: Peder Ås leier de eneste kontorlokalene på Hypotetiskstadium for å drive sin
          næringsvirksomhet.
        </p>
        <strong>Leie av tjenestebolig med boplikt:</strong>
        <p>Denne malen skal brukes ved utleie av tjenestebolig hvor det foreligger boplikt for leieforholdets husrom</p>
        <strong>Leie av tjenestebolig:</strong>
        <p>
          Denne malen skal brukes ved utleie av tjenestebolig hvor det ikke foreligger boplikt for leieforholdets husrom
        </p>
      </Col>
    </Row>
  );
};

export default TemplateChooser;
