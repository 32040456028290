import { get } from "lodash-es";
import BymCheckboxInternal from "./BymCheckboxInternal";
import { FormContextTypes } from "./BymForm";
import BymFormControl, { BymFormControlProps } from "./BymFormControl";

const getCheckBoxControl = (
  checked: any,
  onChange: any,
  controlId: any,
  readOnly: any,
  inline: any,
  label: any,
  checkedValue: any = null,
  disabled?: boolean
) => {
  const newChecked = !!checked;
  return (
    <BymCheckboxInternal
      data-checkedvalue={checkedValue}
      key={controlId}
      name={controlId}
      checked={newChecked}
      onChange={onChange}
      className=""
      inline={inline}
      readOnly={readOnly}
      disabled={disabled}
    >
      {inline && label}
    </BymCheckboxInternal>
  );
};

type BymCheckboxInputProps = BymFormControlProps & {
  checkedValue?: any;
  inline?: boolean;
};

const BymCheckboxInput = (
  {
    controlId = "",
    onChange,
    value,
    inline = false,
    label,
    hidelabel,
    checkedValue,
    disabled,
    ...restProps
  }: BymCheckboxInputProps,
  { form }: any
) => {
  const checked = value || get(form.values, controlId);
  const readOnly = !!(form.readOnly || restProps.readOnly);

  // if we specify inline we send label to internal control and hide label from BymFormControl
  const inlineLabel = inline ? label : null;
  const newHidelabel = inline ? true : hidelabel;
  return (
    <BymFormControl
      controlId={controlId}
      label={inline ? undefined : label}
      hidelabel={newHidelabel}
      disabled={disabled}
      {...restProps}
      readOnly={readOnly}
      component={() =>
        getCheckBoxControl(checked, onChange, controlId, readOnly, inline, inlineLabel, checkedValue, disabled)
      }
    />
  );
};

BymCheckboxInput.contextTypes = FormContextTypes;

export default BymCheckboxInput;
