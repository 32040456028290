import { map, filter } from "lodash-es";
import createSelector from "re-reselect";

const eiendomSelector = (state, eiendomId) => state.entities.eiendommer[eiendomId];
const vedleggSelector = state => state.entities.vedlegg;

export default createSelector(eiendomSelector, vedleggSelector, (eiendom, vedlegg) => {
  const allVedlegg = (eiendom && map(eiendom.vedlegg, id => vedlegg[id])) || [];
  return filter(allVedlegg, vedlegg => vedlegg && !vedlegg.fil.slettet);
})((state, eiendomId) => eiendomId);
