import React from "react";
import { Modal } from "react-bootstrap";
import { map } from "lodash-es";
import PropTypes from "prop-types";
import "./LoggDetailsDialog.css";

const getValue = value => (typeof value === "undefined" || value === null ? "null" : value);

const LoggDetailsDialog = ({ onHide, loggDetails = [] }) => {
  return (
    <Modal
      onHide={onHide}
      show
      bsSize="large"
      dialogClassName="leieobjekt-dialog"
      backdrop="static"
      allowFullScreen={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Endringer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="logg-details-table">
          {loggDetails &&
            map(loggDetails, change => {
              return (
                <tr className={`change-type-${change.ChangeType}`}>
                  <td>{change.PropertyName}</td>
                  <td>
                    <span className="new-value">{getValue(change.NewValue)}</span>
                    <span className="old-value">{getValue(change.OldValue)}</span>
                  </td>
                  <br />
                </tr>
              );
            })}
        </table>
        <br />
      </Modal.Body>
    </Modal>
  );
};

LoggDetailsDialog.propTypes = {
  onHide: PropTypes.func,
  loggDetails: PropTypes.arrayOf(PropTypes.object)
};

export default LoggDetailsDialog;
