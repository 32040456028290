import * as toastAction from "../actions/toastAction";

export default function reducer(state = { error: undefined, success: undefined, toastType: undefined }, action = {}) {
  switch (action.type) {
    case toastAction.ERROR:
      return {
        ...state,
        toastType: "danger",
        error: action.payload
      };

    case toastAction.SUCCESS:
      return {
        ...state,
        toastType: "success",
        success: action.payload
      };

    case toastAction.CLEAR:
      return {
        ...state,
        error: null,
        success: null
      };
    default:
      return { ...state };
  }
}
