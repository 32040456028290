import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setFilter as setFilterAction } from "../../actions/advancedListAction";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../Common/Tables/FreetextFilter.css";
import searchIcon from "../../images/search.svg";
import "./Forside.css";

const redirectToEiendommer = (searchString, setFilterFn, history) => {
  setFilterFn("eiendommer", "freetextSearchProperty", searchString);
  history.push(`/eiendommer`);
};

const EiendomAutocomplete = ({ eiendommer = [], setFilter = () => null, ...rest }) => {
  let thisTypeahead;
  return (
    <div className="autocomplete freetext-filter">
      <Typeahead
        ref={typeahead => {
          thisTypeahead = typeahead;
        }}
        onChange={selected => {
          rest.history.push(`/eiendommer/${selected[0].id}`);
        }}
        onKeyDown={key => {
          if (key.keyCode === 13) {
            // use setTimeout to let the onChange event "Win" when pressing enter on a selected element
            setTimeout(() => {
              if (thisTypeahead)
                redirectToEiendommer(thisTypeahead.getInstance().getInput().value, setFilter, rest.history);
            }, 200);
          }
        }}
        options={eiendommer}
        labelKey="Navn"
        filterBy={["Kategori", "Adresse", "Distrikt", "Område", "Anleggsnummer"]}
        defaultOpen={false}
        minLength={2}
        autoFocus
        bsSize="large"
        placeholder="Søk i eiendomsregisteret"
      />
      <a
        href=""
        onClick={e => {
          redirectToEiendommer(thisTypeahead.getInstance().getInput().value, setFilter, rest.history);
          e.preventDefault();
        }}
        className="freetext-searchicon"
      >
        <img src={searchIcon} alt="" />
      </a>
    </div>
  );
};

EiendomAutocomplete.propTypes = {
  eiendommer: PropTypes.arrayOf(PropTypes.object),
  setFilter: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setFilter: setFilterAction }, dispatch);
}

export default withRouter(connect(undefined, mapDispatchToProps)(EiendomAutocomplete));
