import React, { Component } from "react";
import PropTypes from "prop-types";
import PageSection from "../Layout/PageSection";
import BildeList from "./BildeList";
import { isAdminOrUgeBruker } from "auth/authTools";

class BilderSection extends Component {
  showFileSelector = () => {
    this.fileInput.click();
  };

  handleFileSelected = e => {
    const { postBilder, eiendomId, sectionUpdated } = this.props;
    postBilder(eiendomId, e.target.files).then(() => {
      if (sectionUpdated) sectionUpdated();
    });
    e.target.value = null;
  };

  deleteBilde = bildeId => {
    const { deleteBilde, eiendomId, sectionUpdated } = this.props;
    deleteBilde(eiendomId, bildeId).then(() => {
      if (sectionUpdated) sectionUpdated();
    });
  };

  render() {
    const { bilder = [] } = this.props;
    const title = `Bilder (${bilder.length})`;
    return (
      <PageSection title={title} addText="" onAdd={isAdminOrUgeBruker() && this.showFileSelector}>
        <BildeList
          bilder={bilder}
          onAdd={isAdminOrUgeBruker() && this.showFileSelector}
          onDelete={bilde => this.deleteBilde(bilde.fil.id)}
          showDialog
        />
        <input
          ref={r => {
            this.fileInput = r;
          }}
          type="file"
          multiple
          autoFocus
          style={{ display: "none" }}
          onChange={this.handleFileSelected}
        />
      </PageSection>
    );
  }
}

BilderSection.propTypes = {
  eiendomId: PropTypes.string.isRequired,
  bilder: PropTypes.arrayOf(PropTypes.object).isRequired,
  postBilder: PropTypes.func.isRequired,
  deleteBilde: PropTypes.func.isRequired,
  sectionUpdated: PropTypes.func.isRequired
};

export default BilderSection;
