import { useEffect, useRef } from "react";
import { FeatureGroup, useLeaflet } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { BymPolygonMarker, BymMarkerProps, BymGeoJSONMarker, BymPointMarker } from "./BymMarker";
import "react-leaflet-markercluster/dist/styles.min.css";

export interface BymMarkersLayerProps {
  markers?: BymMarkerProps[];
  autoBounds?: boolean;
  markerClusterLimit?: number;
  useMarkerCluster?: boolean;
}

const BymMarkersLayer = ({ markers = [], autoBounds, markerClusterLimit = 60 }: BymMarkersLayerProps) => {
  const { map } = useLeaflet();
  const markerLayerRef = useRef<FeatureGroup>(null);

  const useMarkerCluster = markers && markers.length > markerClusterLimit;

  const getDisableClusteringAtZoom = (m: BymMarkerProps[]): number => {
    if (m.length > 200) return 15;
    return m.length < 100 ? 13 : 14;
  };

  useEffect(() => {
    if (autoBounds && map && markerLayerRef.current) {
      const bounds = markerLayerRef.current.leafletElement.getBounds();
      if (map && bounds && bounds.isValid()) {
        map.fitBounds(markerLayerRef.current.leafletElement.getBounds().pad(0.2));
      }
    }
  }, [autoBounds, map, markers]);

  return (
    <FeatureGroup ref={markerLayerRef}>
      {useMarkerCluster && (
        <MarkerClusterGroup
          disableClusteringAtZoom={getDisableClusteringAtZoom(markers)}
          zoomToBoundsOnClick
          spiderfyOnMaxZoom={false}
        >
          {markers.map(marker => {
            switch (marker.kind) {
              case "point":
                return <BymPointMarker {...marker} key={marker.objectId} />;
              case "polygon":
                return <BymPolygonMarker {...marker} key={marker.objectId} />;
              case "geoJSON":
                return <BymGeoJSONMarker {...marker} key={marker.objectId} />;
              default:
                return undefined;
            }
          })}
        </MarkerClusterGroup>
      )}
      {!useMarkerCluster &&
        markers.map(marker => {
          switch (marker.kind) {
            case "point":
              return <BymPointMarker {...marker} key={marker.objectId} />;
            case "polygon":
              return <BymPolygonMarker {...marker} key={marker.objectId} />;
            case "geoJSON":
              return <BymGeoJSONMarker {...marker} key={marker.objectId} />;
            default:
              return undefined;
          }
        })}
    </FeatureGroup>
  );
};

export default BymMarkersLayer;
