import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./Breadcrumb.css";

const Breadcrumb = ({ pathElements = [], location }) => (
  <div className="breadcrumb">
    <Link to="/">Forsiden</Link>
    {location.pathname !== "/" &&
      pathElements.map(e => (
        <React.Fragment key={e.title}>
          <span className="path-separator">{">"}</span>
          <Link to={e.url}>{e.title}</Link>
        </React.Fragment>
      ))}
  </div>
);

Breadcrumb.propTypes = {
  pathElements: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.shape({}).isRequired
};

Breadcrumb.defaultProps = {
  pathElements: undefined
};

export default Breadcrumb;
