import { merge } from "lodash-es";
import * as kommentarAction from "../../actions/kommentarAction";
import * as kontraktAction from "../../actions/kontraktAction";
import * as stateUtils from "../../utils/stateUtils";

export default function kontrakter(state = {}, action) {
  switch (action.type) {
    case kommentarAction.POST_KOMMENTARER_SUCCESS:
      return action.meta.kontraktId
        ? stateUtils.setWithArrayPush(state, `[${action.meta.kontraktId}].kommentarer`, action.payload.result)
        : state;
    case kommentarAction.DELETE_KOMMENTARER_SUCCESS:
      return action.meta.kontraktId
        ? stateUtils.setWithArrayPull(state, `[${action.meta.kontraktId}].kommentarer`, action.payload.result)
        : state;
    case kontraktAction.EDIT_KONTRAKT_SUCCESS:
    case kontraktAction.GET_KONTRAKT_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.kontrakter) {
        // Replace the entire kontrakt because GET and EDIT return complete kontrakt objects.
        return { ...state, ...action.payload.entities.kontrakter };
      }
      return state;
    default:
      if (action.payload && action.payload.entities && action.payload.entities.kontrakter) {
        return merge({}, state, action.payload.entities.kontrakter);
      }
      return state;
  }
}
