// Check if zipcode is four digits or not, add 0 when only three digits
// Also adds city name to the zip-code
function getFormatedZipCode(zipcode, city) {
  let zip = "";
  if (zipcode) {
    if (zipcode.toString().length === 3) {
      zip = "0";
    }
    zip += zipcode;
  }

  return `${zip} ${city || ""}`;
}

// eslint-disable-next-line import/prefer-default-export
export { getFormatedZipCode };
