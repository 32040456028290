import React from "react";

import KommentarEditor from "./KommentarEditor";
import KommentarItem from "./KommentarItem";

import "./KommentarSection.css";
import ShowOnlyForUsersWith from "auth/ShowOnlyFor";

class KommentarSection extends React.Component {
  state = {
    commentText: ""
  };

  addComment = commentText => {
    this.props
      .postKommentar(this.props.eierType, this.props.eierId, {
        eiendomId: this.props.eierId,
        tekst: commentText || this.state.commentText
      })
      .then(() => {
        this.props.sectionUpdated && this.props.sectionUpdated();
      });
    this.setState({ commentText: "" });
  };

  editComment = (commentText, commentId) => {
    this.props
      .editKommentar(this.props.eierType, this.props.eierId, commentId, {
        eiendomId: this.props.eierId,
        tekst: commentText
      })
      .then(() => {
        this.props.sectionUpdated && this.props.sectionUpdated();
      });
    this.setState({ commentText: "" });
  };

  deleteComment = commentId => {
    this.props.deleteKommentar(this.props.eierType, this.props.eierId, commentId).then(() => {
      this.props.sectionUpdated && this.props.sectionUpdated();
    });
  };

  commentChanged(commentText) {
    this.setState({ commentText: commentText });
  }

  render() {
    return (
      <div className={"kommentar-component " + this.props.className || ""}>
        <ShowOnlyForUsersWith roles={["UGE-admin", "UGE-bruker"]}>
          <KommentarEditor defaultValue="" onSave={text => this.addComment(text)} />
        </ShowOnlyForUsersWith>

        <div className="kommentar-list">
          {(!this.props.kommentarer || this.props.kommentarer.length < 1) && (
            <i className="kommentar-empty">Det er ingen kommentarer knyttet til denne saken</i>
          )}
          {this.props.kommentarer &&
            this.props.kommentarer.map(kommentar => {
              return (
                <KommentarItem
                  key={kommentar.id}
                  login={this.props.login}
                  allowEdit={this.props.allowEdit}
                  deleteKommentar={this.deleteComment}
                  editKommentar={newText => this.editComment(newText, kommentar.id)}
                  {...kommentar}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default KommentarSection;
