import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../helpers/actionHelper";

import { kommentarSchema } from "../constants/schemas";

const metaIds = { eiendommer: "eiendomId", kontrakter: "kontraktId" };

// POST KOMMENTAR
export const POST_KOMMENTARER_REQUEST = "POST_KOMMENTARER_REQUEST";
export const POST_KOMMENTARER_SUCCESS = "POST_KOMMENTARER_SUCCESS";
export const POST_KOMMENTARER_FAILURE = "POST_KOMMENTARER_FAILURE";

export function postKommentar(eierType, eierId, data) {
  const meta = { [metaIds[eierType]]: eierId };
  return dispatch =>
    axios
      .post(api.POST_KOMMENTARER_URL(eierType, eierId), data)
      .then(handle.success(dispatch, POST_KOMMENTARER_SUCCESS, kommentarSchema, meta))
      .catch(handle.error(dispatch, POST_KOMMENTARER_FAILURE));
}

// EDIT KOMMENTAR
export const EDIT_KOMMENTARER_REQUEST = "EDIT_KOMMENTARER_REQUEST";
export const EDIT_KOMMENTARER_SUCCESS = "EDIT_KOMMENTARER_SUCCESS";
export const EDIT_KOMMENTARER_FAILURE = "EDIT_KOMMENTARER_FAILURE";

export function editKommentar(eierType, eierId, id, data) {
  const meta = { [metaIds[eierType]]: eierId };
  return dispatch => {
    dispatch({
      type: EDIT_KOMMENTARER_REQUEST,
      payload: id,
      meta
    });
    return axios
      .put(api.EDIT_KOMMENTARER_URL(eierType, eierId, id), data)
      .then(handle.success(dispatch, EDIT_KOMMENTARER_SUCCESS, kommentarSchema, meta))
      .catch(handle.error(dispatch, EDIT_KOMMENTARER_FAILURE));
  };
}

// DELETE KOMMENTAR
export const DELETE_KOMMENTARER_REQUEST = "DELETE_KOMMENTARER_REQUEST";
export const DELETE_KOMMENTARER_SUCCESS = "DELETE_KOMMENTARER_SUCCESS";
export const DELETE_KOMMENTARER_FAILURE = "DELETE_KOMMENTARER_FAILURE";

export function deleteKommentar(eierType, eierId, id) {
  const meta = { [metaIds[eierType]]: eierId };
  return dispatch => {
    dispatch({
      type: DELETE_KOMMENTARER_REQUEST,
      payload: id,
      meta
    });
    return axios
      .delete(api.DELETE_KOMMENTARER_URL(eierType, eierId, id))
      .then(handle.success(dispatch, DELETE_KOMMENTARER_SUCCESS, kommentarSchema, meta))
      .catch(handle.error(dispatch, DELETE_KOMMENTARER_FAILURE));
  };
}
