import { Component } from "react";
import { Button } from "react-bootstrap";
import { DOWNLOAD_VEDLEGG_URL } from "../../constants/api";
import { getToken } from "auth/authTools";
import ConfirmDialog from "../Common/ConfirmDialog";

import dokumentIcon from "../../images/dokument.svg";
import deleteIcon from "../../images/delete.svg";

import "./VedleggSection.css";

class VedleggItem extends Component {
  state = {
    showDeleteDialog: false
  };

  render() {
    const { dok } = this.props;
    return (
      <li key={dok.fil.id}>
        <ConfirmDialog
          show={this.state.showDeleteDialog}
          message="Er du sikker på at du vil slette dokumentet?"
          title="Slett dokument"
          confirmButtonText="Slett dokument"
          confirmButtonStyle="bym-negative"
          onConfirm={() => {
            this.props.onDelete(dok.fil.id);
            this.setState({ showDeleteDialog: false });
          }}
          onClose={() => this.setState({ showDeleteDialog: false })}
        />
        <img
          className="delete-icon"
          src={deleteIcon}
          onClick={() => this.setState({ showDeleteDialog: true })}
          alt={"Slett " + dok.fil.navn}
          title={"Slett " + dok.fil.navn}
        />
        <form method="post" action={DOWNLOAD_VEDLEGG_URL(dok.fil.id, dok.eiendomId)} target="_blank">
          <input type="hidden" name="sps_access_token" value={getToken()} />
          <img src={dokumentIcon} alt="" />
          <Button type="submit" bsStyle="link">
            {dok.fil.navn}
          </Button>
        </form>
      </li>
    );
  }
}

export default VedleggItem;
