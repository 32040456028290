import PropTypes from "prop-types";
import { Row, Alert } from "react-bootstrap";

const PublikumSettings = ({ leieObjekt, saveLeieObjekt }) => {
  if (!leieObjekt) return <>loading</>;
  return (
    <Row>
      <Alert>Byleie informasjon om leieobjektet er flyttet til byleie saksbehandling</Alert>
    </Row>
  );
};

PublikumSettings.propTypes = {
  leieObjekt: PropTypes.shape(PropTypes.object),
  saveLeieObjekt: PropTypes.func
};

export default PublikumSettings;
