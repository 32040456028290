import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Switch, useHistory } from "react-router-dom";

import LeieObjektPage from "components/LeieObjekt/LeieObjektPage";
import PublicRoute from "./components/Routes/PublicRoute";
import PrivateRoute from "./components/Routes/PrivateRoute";
import AdminRoute from "./components/Routes/AdminRoute";
import AdminRedirect from "./components/Routes/AdminRedirect";
import NavigationBar from "./containers/Layout/NavigationBarContainer";
import LoginPage, { getRedirectRoute, removeRedirectRoute, setRedirectRoute } from "./containers/Login/LoginPage";
import EiendomPage from "./containers/Eiendom/EiendomPage";
import AdminPage from "./containers/Admin/AdminPage";
import EiendomListPage from "./containers/Eiendom/EiendomListPage";
import LeieObjektListPage from "./containers/Eiendom/LeieObjektListPage";
import KontraktListPage from "./containers/Kontrakt/KontraktListPage";
import KontraktPage from "./containers/Kontrakt/KontraktPage";
import FakturaListPage from "./containers/Faktura/FakturaListPage";
import AdminFakturaListPage from "./containers/Faktura/AdminFakturaListPage";
import { ADMIN_VIEW } from "actions/loginAction";
import Toast from "./components/Common/Toast/Toast";
import Forside from "./containers/Forside/ForsideContainer";
import ForcefullyLoggedOutModal, { setWasForcefullyLoggedOut } from "auth/ForcefullyLoggedOutModal";
import { useDispatch } from "react-redux";
import { loginUserOneLogin as loginUserOneLoginAction, logoutUser as logoutUserAction } from "actions/loginAction";
import NewReleaseModal from "releasenotes/NewReleaseModal";

function App() {
  const auth = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(loginUserOneLoginAction(auth?.user?.access_token, auth.user?.profile.preferred_username));
      const redirectRoute = getRedirectRoute();
      if (redirectRoute) {
        history.push(redirectRoute);
        removeRedirectRoute();
      }
    }
  }, [auth.isAuthenticated, dispatch, history]);

  useEffect(() => {
    function logoutUser() {
      dispatch(logoutUserAction());
    }
    auth.events.addUserSignedOut(logoutUser);
    return auth.events.removeUserSignedOut(logoutUser);
  }, []);

  useEffect(() => {
    function expired() {
      setRedirectRoute(`${history.location.pathname}/${history.location.search}`);
      setWasForcefullyLoggedOut(true);
      auth.signoutRedirect();
    }

    auth.events.addAccessTokenExpired(expired);

    return () => {
      auth.events.removeAccessTokenExpired(expired);
    };
  }, [auth.signoutRedirect, auth.events]);

  return (
    <div>
      <Toast />
      <NavigationBar />
      <ForcefullyLoggedOutModal />
      <NewReleaseModal />
      <Switch>
        <AdminRedirect exact path="/" whenAppView={ADMIN_VIEW} to={"/admin"}>
          <PrivateRoute exact path="/" component={Forside} />
        </AdminRedirect>

        <PublicRoute exact path="/login" component={LoginPage} />

        <PrivateRoute exact path="/eiendommer" component={EiendomListPage} />
        <PrivateRoute exact path="/eiendommer/:id" component={EiendomPage} />

        <PrivateRoute exact path="/leieobjekter" component={LeieObjektListPage} />
        <PrivateRoute exact path="/leieobjekter/:id/:year?/:week?" component={LeieObjektPage} />

        <PrivateRoute exact path="/kontrakter" component={KontraktListPage} />
        <PrivateRoute exact path="/kontrakter/:id" component={KontraktPage} />

        <PrivateRoute exact path="/okonomi/:year?/:month?" component={FakturaListPage} />

        <AdminRoute exact path="/admin" component={AdminPage} />
        <AdminRoute exact path="/fakturaadministrasjon/:year?/:month?" component={AdminFakturaListPage} />
      </Switch>
    </div>
  );
}

export default App;
