import { useState, useEffect, useRef } from "react";
import { Map, MapProps, ContextProps, ZoomControl } from "react-leaflet";
import L, { ControlPosition } from "leaflet";
import { EditDetails } from "./BymMapEditControl";
import BymMapGeastureControll from "./BymGeastureControll";
import { BymMarkerProps } from "./BymMarker";
import "./BymMap.scss";
import "leaflet/dist/leaflet.css";
import BymLayersControll from "./BymLayersControll";
import BymMarkersLayer from "./BymMarkersLayer";
import BymMapEditLayer from "./BymMapEditLayer";

export * from "./BymMarker"; // reexport for easier import in user of this component

export const geoJSONToPolygon = (geoJSON: GeoJSON.FeatureCollection) => {
  return geoJSON.features.map((feature: any) => {
    return feature.geometry.coordinates[0].map((p: any) => [p[1], p[0]]);
  });
};

export interface BymMapProps extends Partial<MapProps>, ContextProps {
  markers?: BymMarkerProps[];
  boundsMarkers?: BymMarkerProps[];
  editGeoJSON?: GeoJSON.FeatureCollection;
  autoBounds?: boolean;
  showTooltip?: boolean;
  markerClusterLimit?: number;
  editMode?: boolean;
  useClickToScroll?: boolean;
  zoomControlPosition?: ControlPosition;
  onMarkerMouseOver?: (marker: BymMarkerProps) => void;
  onMarkerClick?: (marker: BymMarkerProps) => void;
  onEditChange?: (editDetails: EditDetails) => void;
  onCreated?: (geometry: any) => void;
  onReady?: (map: Map) => void;
  onEditorReady?: (editControl: any) => void;
  onLocationSelected?: (position: any) => void;
}

const BymMap = ({
  markers = [],
  boundsMarkers,
  editGeoJSON,
  autoBounds,
  bounds,
  showTooltip,
  editMode = false,
  markerClusterLimit = 60,
  useClickToScroll = false,
  zoomControlPosition = "bottomright",
  onLocationSelected,
  onMarkerMouseOver,
  onMarkerClick,
  onEditChange,
  onCreated,
  onReady,
  onEditorReady,
  ...rest
}: BymMapProps): JSX.Element => {
  const [isInitialized, setInitialized] = useState(false);
  const mapRef = useRef<Map>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleClick = (e: any) => {
    if (!onLocationSelected) return;

    onLocationSelected({
      lat: e.latlng.lat,
      lng: e.latlng.lng
    });
  };

  useEffect(() => {
    setInitialized(true);
  }, [isInitialized]);

  const defaultCenter = L.latLng(59.911111, 10.733333);
  const defaultZoom = 12;

  useEffect(() => {
    if (!useClickToScroll) {
      return () => {};
    }

    const handleContainerClick = (event: MouseEvent) => {
      if (containerRef.current) {
        const containerClicked = containerRef.current.contains(event.target as HTMLElement);
        setIsFocused(containerClicked);
      }
    };
    document.addEventListener("click", handleContainerClick);
    return () => document.removeEventListener("click", handleContainerClick);
  }, [containerRef, useClickToScroll]);

  if (!isInitialized) return <></>;
  return (
    <div ref={containerRef} className="bym-map-container">
      <Map
        whenReady={() => {
          if (onReady) {
            onReady(mapRef.current as Map);
          }
        }}
        zoom={defaultZoom}
        maxZoom={19}
        tap={!L.Browser.mobile}
        dragging={!L.Browser.mobile}
        onclick={handleClick}
        {...rest}
        center={rest.center ?? defaultCenter}
        ref={mapRef}
        zoomControl={false}
        scrollWheelZoom={!useClickToScroll || isFocused}
      >
        <ZoomControl position={zoomControlPosition} />
        {editMode && (
          <BymMapEditLayer
            editGeoJSON={editGeoJSON}
            autoBounds={editMode && editGeoJSON ? autoBounds : false}
            onEditChange={onEditChange}
            onCreated={onCreated}
            onEditorReady={onEditorReady}
          />
        )}

        <BymMapGeastureControll />
        <BymLayersControll />
        <BymMarkersLayer
          markers={markers}
          markerClusterLimit={markerClusterLimit}
          autoBounds={editMode && editGeoJSON ? false : autoBounds}
        />
      </Map>
    </div>
  );
};

export default BymMap;
