import * as eiendomAction from "../actions/eiendomAction";

const INITIAL_STATE = [];

export default function eiendomReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case eiendomAction.GET_EIENDOMMER_SUCCESS:
      return action.payload.result;
    case eiendomAction.CREATE_EIENDOM_SUCCESS:
      return [...state, action.payload.result];
    default:
      return state;
  }
}
