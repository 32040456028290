import React from "react";
import PropTypes from "prop-types";
import { ButtonGroup } from "react-bootstrap";

import "./Filter.css";

const Filters = ({ children, ...rest }) => (
  <ButtonGroup {...rest} className={`filters ${rest.className || ""}`}>
    {children}
  </ButtonGroup>
);

Filters.propTypes = {
  children: PropTypes.node.isRequired
};

export default Filters;
