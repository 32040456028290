import { Grid, GridProps, Row, Col, PageHeader } from "react-bootstrap";

import "./Page.css";

interface PageProps extends GridProps {
  title?: string;
  subtitle?: string;
  children?: any;
  className?: string;
}

const Page = ({ title, subtitle, children, className, ...rest }: PageProps) => (
  <Grid fluid className={`uge-page ${className}`} {...rest}>
    <Row>
      <Col>
        {title && (
          <PageHeader>
            {title}
            {subtitle && <small>{subtitle}</small>}
          </PageHeader>
        )}
        <div className="page-body">{children}</div>
      </Col>
    </Row>
  </Grid>
);

export default Page;
