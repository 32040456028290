import { merge, mergeWith, isArray } from "lodash-es";
import { EDIT_LEIEOBJEKT_SUCCESS } from "../../actions/leieObjektAction";

export default function leieObjekter(state = {}, action) {
  switch (action.type) {
    case EDIT_LEIEOBJEKT_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.leieObjekter) {
        // eslint-disable-next-line consistent-return
        return mergeWith({}, state, action.payload.entities.leieObjekter, (objValue, srcValue) => {
          if (isArray(objValue)) {
            return srcValue;
          }
        });
      }
      return state;
    default:
      if (action.payload && action.payload.entities && action.payload.entities.leieObjekter) {
        return merge({}, state, action.payload.entities.leieObjekter);
      }
      return state;
  }
}
