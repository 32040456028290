import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withCookies } from "react-cookie";
import SegmentChooser from "../../components/Layout/SegmentChooser";
import { getKoder } from "../../actions/koderAction";
import getKoderSelector from "../../selectors/koderSelector";

const SegmentChooserContainer = ({ getKoderAction, ...restProps }) => {
  if (restProps.segmenter.length === 0) getKoderAction("segmenter");
  return <SegmentChooser {...restProps} />;
};

SegmentChooserContainer.propTypes = {
  getKoderAction: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  segmenter: getKoderSelector(state, "segmenter") || []
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getKoderAction: getKoder }, dispatch);
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(SegmentChooserContainer));
