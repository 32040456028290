function createAdvancedListReducer(name, reducer, defaultSort = null, count = 100) {
  return (
    state = {
      page: 1,
      count,
      total: 0,
      sort: defaultSort,
      filters: {},
      items: []
    },
    action
  ) => {
    let newState = state;
    switch (action.type) {
      case `GET_${name}_REQUEST`:
        newState = {
          ...state,
          isFetching: true
        };
        break;
      case `GET_${name}_SUCCESS`:
        newState = {
          ...state,
          isFetching: false,
          updated: Date.now(),
          etag: action.meta && action.meta.etag
        };
        break;
      case `GET_${name}_FAILURE`:
        newState = {
          ...state,
          isFetching: false
        };
        break;
      case `SET_PAGE_COUNT_${name}`:
        newState = {
          ...state,
          count: action.payload,
          page: 1
        };
        break;
      case `SET_PAGE_${name}`:
        newState = {
          ...state,
          page: action.payload
        };
        break;
      case `SET_FILTER_${name}`:
        newState = {
          ...state,
          filters: {
            ...state.filters,
            [action.meta.filterKey]: action.payload
          },
          page: 1
        };
        break;
      case `SET_SORT_${name}`:
        newState = {
          ...state,
          sort: action.payload
        };
        break;
      default:
        break;
    }

    if (action.not_modified !== true) {
      const items = reducer(state.items, action);
      if (items !== state.items) {
        newState = {
          ...newState,
          items
        };
      }
    }

    return newState;
  };
}

export default createAdvancedListReducer;
