import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { HeaderCell } from "../Common/Tables";
import Table from "../../containers/Common/SortableTableContainer";

import "./EiendomList.css";

const renderRow = ({ id, Navn, Adresse, Anleggsnummer, Distrikt, Område, Kategori, leieObjektCount }) => (
  <tr className="eiendom-list-row" key={id}>
    <td>
      <NavLink to={`/eiendommer/${id}`}>{Navn}</NavLink>
    </td>
    <td>{Adresse}</td>
    <td>{Distrikt}</td>
    <td>{Område}</td>
    <td>{Kategori}</td>
    <td>{Anleggsnummer}</td>
    <td>{leieObjektCount}</td>
  </tr>
);

const EiendomList = ({ eiendommer }) => (
  <Table
    className="eiendom-list-table"
    id="kontrakt-liste"
    storeKey="eiendommer"
    items={eiendommer}
    renderRow={renderRow}
  >
    <HeaderCell cellKey="Navn">Eiendom navn</HeaderCell>
    <HeaderCell cellKey="Adresse">Adresse</HeaderCell>
    <HeaderCell cellKey="Distrikt">Distrikt</HeaderCell>
    <HeaderCell cellKey="Område">Område</HeaderCell>
    <HeaderCell cellKey="Kategori">Kategori</HeaderCell>
    <HeaderCell cellKey="Anleggsnummer">Anleggsnr</HeaderCell>
    <HeaderCell cellKey="leieObjektCount">Leieobjekter</HeaderCell>
  </Table>
);

renderRow.propTypes = {
  id: PropTypes.string.isRequired,
  Navn: PropTypes.string.isRequired,
  Adresse: PropTypes.string.isRequired,
  Anleggsnummer: PropTypes.string.isRequired,
  Område: PropTypes.string.isRequired,
  Distrikt: PropTypes.string.isRequired,
  Kategori: PropTypes.string.isRequired,
  leieObjektCount: PropTypes.number.isRequired
};

EiendomList.propTypes = {
  eiendommer: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default EiendomList;
