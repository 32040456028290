import get from "lodash-es/get";
import { FormContextTypes } from "./BymForm";
import BymFormControl, { BymFormControlProps } from "./BymFormControl";
import { currency, number } from "./Rules";

function BymCurrencyInput(this: any, context: any, { rules = [], ...props }: BymFormControlProps) {
  function handleChange(e: any) {
    if (e?.target?.value) {
      e.target.value = e.target.value.replace(/[\s]/g, "");
      if (isNaN(e.target.value) && e.target.value !== "+" && e.target.value !== "-") {
        e.target.value = e.target.value.replace(/(?![+-])[^0-9.]/g, "");
      }
    }
    return e;
  }

  const currentValue = get((props as any).form.values, context.controlId);
  const defaultValue = context.controlId && currentValue && Number(currentValue).toFixed(2).toString();

  return (
    <BymFormControl
      rules={[...rules, number(), currency()]}
      setDefaultValue={defaultValue}
      onChange={handleChange}
      {...context}
      {...props}
      type="text"
    />
  );
}

BymCurrencyInput.contextTypes = FormContextTypes;

export default BymCurrencyInput;
