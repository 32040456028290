import React, { Component } from "react";
import PropTypes from "prop-types";
import { union, without, set } from "lodash-es";

import "./BymForm.css";

export const FormContextTypes = {
  form: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func,
    readOnly: PropTypes.bool,
    validatedWithErrors: PropTypes.func.isRequired
  })
};

interface BymFormProps {
  id?: string;
  children?: any;
  values?: any;
  onSubmit?: any;
  onChange?: any;
  onReset?: any;
  globalValidator?: any;
  readOnly?: boolean;
  disabled?: boolean;
}

class BymForm extends Component<BymFormProps> {
  // eslint-disable-next-line react/static-property-placement
  static childContextTypes = FormContextTypes;

  state = {
    touched: false,
    edited: false,
    formValues: {},
    globalErrorMessage: null
  };

  hasErrors = false;

  getChildContext() {
    const { touched, formValues } = this.state;
    const { readOnly, disabled } = this.props;
    return {
      form: {
        touched,
        values: formValues,
        handleChange: this.handleChange,
        readOnly,
        disabled,
        validatedWithErrors: this.handleValidatedWithError
      }
    };
  }

  componentDidMount() {
    const { values } = this.props;
    this.setState({ formValues: values });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: any, prevProps: any) {
    const { edited } = this.state;
    // only set new values if the form has not been edited
    if (edited) {
      return;
    }
    if (nextProps.values && !prevProps.values) {
      this.setState({ formValues: nextProps.values });
    }
  }

  handleChange = (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement;
    const { onChange } = this.props;
    const propName = target.id || target.name;
    let propValue: string | boolean | null = target.value;
    // for checkboxgroup we add all checked values to an array
    if (target.dataset && target.dataset?.bymtype === "checkboxgroup") {
      propValue = this.getCheckboxGroupValue(propName, e.target);
    } else if (target.type === "checkbox") {
      propValue = target.checked;
      if (target.dataset && target.dataset?.checkedvalue) {
        propValue = target.checked ? target.dataset?.checkedvalue : null;
      }
    }
    const { formValues = {} } = this.state;
    set(formValues, propName, propValue);
    this.setState(
      {
        edited: true,
        formValues
      },
      () => {
        if (onChange && target.dataset?.disableonformchange !== "true") {
          const newFormValues = onChange(propName, formValues, target);
          // if the onchange handler returns a value we set it as the new form values
          // this allows users of this component to update other fields when one field changes
          // Also wrap result in Promise.resolve to support onChange handlers that return promises.
          if (newFormValues) {
            Promise.resolve(newFormValues).then(result => {
              this.setState({ formValues: result });
            });
          }
        }
      }
    );
  };

  // add/remove checkbox value to/from array of checked values
  getCheckboxGroupValue = (propName: string, targetNode: any) => {
    const { formValues }: any = this.state;
    let checkedValues = formValues[propName] || [];
    if (targetNode?.checked) {
      return union(checkedValues, [targetNode.value]);
    }
    checkedValues = without(checkedValues, targetNode.value);
    return checkedValues.length > 0 ? checkedValues : null; // return null to trigger required validation
  };

  handleReset = (e: React.FormEvent) => {
    const { onReset, values } = this.props;
    this.setState({
      touched: false,
      formValues: { ...values }
    });
    if (onReset) {
      onReset(e);
    }
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { globalValidator, onSubmit } = this.props;
    const { formValues } = this.state;
    this.setState({ touched: true });
    if (!this.hasErrors) {
      const globalValidatorMessage = globalValidator ? globalValidator(formValues) : true;
      if (globalValidatorMessage === true) {
        this.setState({ globalErrorMessage: null });
        const result = onSubmit({ ...formValues });
        if (result instanceof Promise) {
          result.catch(() => {
            // TODO extract error
            return result;
          });
        }
      } else {
        this.setState({ globalErrorMessage: globalValidatorMessage });
      }
    }
  };

  handleValidatedWithError = () => {
    this.hasErrors = true;
  };

  render() {
    this.hasErrors = false;
    const { id, children } = this.props;
    const { globalErrorMessage } = this.state;
    return (
      <form
        className="bym-form"
        id={id}
        onChange={this.handleChange}
        onReset={this.handleReset}
        onSubmit={this.handleSubmit}
      >
        {children}
        {globalErrorMessage && (
          <div className="has-error">
            <span className="help-block">{globalErrorMessage}</span>
          </div>
        )}
      </form>
    );
  }
}

export default BymForm;
