import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";

import "./Tag.css";

const Tag = ({ tagStyle = "default", className, ...rest }) => (
  <div {...rest} className={classnames(tagStyle, className, "bym-tag")}>
    {rest.children}
  </div>
);

Tag.propTypes = {
  className: PropTypes.string,
  tagStyle: PropTypes.string
};

Tag.defaultProps = {
  className: undefined,
  tagStyle: "default"
};

export default Tag;
