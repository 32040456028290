import React from "react";
import { Button, ButtonProps } from "react-bootstrap";

type BymSubmitButtonProps = ButtonProps & {
  text?: string;
};

const BymSubmitButton = ({ text = "Lagre", bsStyle = "bym-action", ...props }: BymSubmitButtonProps) => (
  <Button type="submit" bsStyle={bsStyle} {...props}>
    {text}
  </Button>
);

export default BymSubmitButton;
