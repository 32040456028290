import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withCookies, Cookies } from "react-cookie";
import * as eiendomAction from "../../actions/eiendomAction";
import * as kontraktAction from "../../actions/kontraktAction";
import Page from "../../components/Layout/Page";
import KontraktList from "../../components/Kontrakt/KontraktList";
import Filters from "../../components/Common/Tables/Filters";
import getKontrakterSelector, { getKontraktList } from "../../selectors/kontrakterSelector";
import PromiseSpinner from "../../components/Common/Spinner/PromiseSpinner";
import { BymForm, BymCheckboxInput } from "components/Common/Forms";

import createColumnFilter from "../Common/createColumnFilter";
import createFreetextFilter from "../Common/createFreetextFilter";
import createPaginationControl from "../Common/createPaginationControl";
import { Kontrakt } from "interfaces";

const KontraktColumnFilter = createColumnFilter("kontrakter", getKontrakterSelector);
const KontraktFreetextFilter = createFreetextFilter("kontrakter", getKontrakterSelector);
const PaginationControl = createPaginationControl("kontrakter", getKontrakterSelector);

interface KontraktListPageProps {
  kontrakter: Kontrakt[];
  getKontrakter: (filter: any) => Promise<Kontrakt[]>;
  getKontrakterBySegment: (segmentId: string, filter: any) => Promise<Kontrakt[]>;
  getEiendommer: any;
  cookies: Cookies;
}

class KontraktListPage extends Component<KontraktListPageProps, any> {
  state = { spinnerPromise: undefined, excludeArchived: true };

  componentDidMount() {
    const { getEiendommer } = this.props;
    const { excludeArchived } = this.state;
    getEiendommer();
    this.loadKontrakter(excludeArchived);
  }

  loadKontrakter = (excludeArchived = false) => {
    const { getKontrakter, getKontrakterBySegment, cookies } = this.props;

    const segment = cookies.get("uge_segment");
    let promise;
    if (segment) promise = getKontrakterBySegment(segment, { excludeArchived: excludeArchived });
    else promise = getKontrakter({ excludeArchived: excludeArchived });
    this.setState({ spinnerPromise: promise });
  };

  showArchivedClicked = () => {
    const { excludeArchived } = this.state;
    const newExcludeArchived = !excludeArchived;
    this.setState({ excludeArchived: newExcludeArchived });
    this.loadKontrakter(newExcludeArchived);
  };

  render() {
    const { kontrakter = [] } = this.props as any;
    const { spinnerPromise } = this.state;
    return (
      <Page title="Kontrakter">
        <Filters>
          <KontraktColumnFilter
            id="eiendomKategoriFilter"
            title="Eiendomskategori"
            filterKey="eiendom.Kategori"
            onChange={() => undefined}
          />
          <KontraktColumnFilter
            id="kontraktTypeFilter"
            title="Kontrakttype"
            filterKey="kontraktType.navn"
            onChange={() => undefined}
          />
          <KontraktColumnFilter
            id="kontraktstatusfilter"
            title="Kontraktstatus"
            filterKey="kontraktStatus.navn"
            onChange={() => undefined}
          />
          <KontraktColumnFilter
            id="omraadeFilter"
            title="Område"
            filterKey="eiendom.Område"
            onChange={() => undefined}
          />
          <KontraktFreetextFilter id="kontrakttextfilter" onChange={() => undefined} filterKey={undefined} />
          <div style={{ display: "inline-flex", alignSelf: "center", paddingTop: "8px" }}>
            <BymForm values={{ excludeArchived: false }} onChange={this.showArchivedClicked}>
              <BymCheckboxInput controlId="excludeArchived" label="Inkluder utgåtte/oppsagte" inline />
            </BymForm>
            {spinnerPromise ? (
              <PromiseSpinner spinnerStyle="spinner inline-spinner" promise={spinnerPromise}>
                <span />
              </PromiseSpinner>
            ) : (
              ""
            )}
          </div>
        </Filters>
        <PromiseSpinner spinnerStyle="page-spinner" text="Laster kontrakter" promise={spinnerPromise}>
          <PaginationControl storeKey="kontrakter" />
          <KontraktList kontrakter={kontrakter} />
          <PaginationControl storeKey="kontrakter" />
        </PromiseSpinner>
      </Page>
    );
  }
}

const mapStateToProps = (state: any) => ({
  kontrakter: getKontraktList(state)
});

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({ ...kontraktAction, getEiendommer: eiendomAction.getEiendommer } as any, dispatch);
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(KontraktListPage) as any);
