import React from "react";
import { FormContextTypes } from "components/Common/Forms";

import { Button, ButtonProps } from "react-bootstrap";
import { Primitive, JsonObject } from "type-fest";

export type BymButtonProps<T = JsonObject> = ButtonProps & {
  text?: string;
  controlId?: string;
  formValue?: Primitive;
  updateFunction?: (formValues: T) => T | Primitive;
};

/**
 * Button that triggers form onChange so that button click can be handeled there.
 */
const BymButton = (
  { text = "", bsStyle = "bym-standard", controlId, formValue, children, updateFunction, ...props }: BymButtonProps,
  { form }: any
) => {
  const content = text || children;
  return (
    <Button
      type="button"
      bsStyle={bsStyle}
      {...props}
      onClick={(e: any) => {
        if (props.onClick) props.onClick(e);
        if (updateFunction) {
          form.handleChange({
            target: { id: controlId, value: updateFunction(form.values), dataset: e.target.dataset }
          });
        } else if (controlId)
          form.handleChange({ target: { id: controlId, value: formValue, dataset: e.target.dataset } });
      }}
    >
      {content}
    </Button>
  );
};

BymButton.contextTypes = FormContextTypes;

export default BymButton;
