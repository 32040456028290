import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as bilderAction from "../../store/bilder/bilderAction";
import getBilder from "../../store/bilder/bilderSelector";
import BilderSection from "../../components/Bilder/BilderSection";

const mapStateToProps = (state, ownProps) => ({
  bilder: getBilder(state, ownProps.eiendomId)
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(bilderAction, dispatch);
}

const BilderSectionContainer = connect(mapStateToProps, mapDispatchToProps)(BilderSection);

BilderSectionContainer.propTypes = {
  eiendomId: PropTypes.string
};

export default BilderSectionContainer;
