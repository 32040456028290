import { Component } from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

import "./PaginationControl.css";
import iconLeft from "../../images/pil-venstre.svg";
import iconRight from "../../images/pil-høyre.svg";

class PaginationControl extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
    onChange: PropTypes.func
  };

  get firstItem() {
    return this.props.count * (this.props.page - 1) + 1;
  }

  get lastItem() {
    return Math.min(this.props.count * this.props.page, this.props.total);
  }

  get canGoBack() {
    return this.props.page > 1;
  }

  get canGoForward() {
    return this.props.page * this.props.count < this.props.total;
  }

  handleChange = e => {
    if (this.props.onChange) {
      this.props.onChange(Number(e.target.value));
    }
  };

  render() {
    const { count, total, onNext, onPrevious } = this.props;
    return (
      <div className="clearfix">
        <div className="pagination-control pull-right">
          <span>Rader per side: </span>
          <select value={count} onChange={this.handleChange}>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={500}>500</option>
            <option value={9999}>9999</option>
          </select>
          <div className="pc-space-small pc-page-number">
            {this.firstItem}-{this.lastItem} av {total}
          </div>
          <button className="pc-space-large" onClick={onPrevious} disabled={!this.canGoBack}>
            <Image src={iconLeft} />
          </button>
          <button className="pc-space-small" onClick={onNext} disabled={!this.canGoForward}>
            <Image src={iconRight} />
          </button>
        </div>
      </div>
    );
  }
}

export default PaginationControl;
