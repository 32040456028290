import { createSelector } from "reselect";

const makeGetKontrakter = key => {
  const getTab = state => state.tab[key];
  return createSelector([getTab], tab => {
    return tab;
  });
};

export default makeGetKontrakter;
