import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import Button from "react-bootstrap/lib/Button";

const FORCEFULLY_LOGGED_OUT = "wasForcefullyLoggedOut";
export function getWasForcefullyLoggedOut() {
  return window.sessionStorage.getItem(FORCEFULLY_LOGGED_OUT);
}
export function setWasForcefullyLoggedOut(loggedOut: boolean) {
  return window.sessionStorage.setItem(FORCEFULLY_LOGGED_OUT, String(loggedOut));
}

export default function ForcefullyLoggedOutModal() {
  const [isForcefullyLoggedOut, setIsForcefullyLoggedOut] = useState(false);

  useEffect(() => {
    if (Boolean(getWasForcefullyLoggedOut())) setIsForcefullyLoggedOut(true);
  }, []);

  const onToggle = () => {
    setIsForcefullyLoggedOut(false);
    window.sessionStorage.removeItem(FORCEFULLY_LOGGED_OUT);
  };

  return (
    <Modal style={{ width: "100%", margin: "auto", padding: "10em" }} show={isForcefullyLoggedOut} onHide={() => false}>
      <ModalHeader>
        <h4>Sesjonen er utløpt</h4>
      </ModalHeader>
      <ModalBody>
        <div style={{ paddingBottom: "2em" }}>Du ble logget ut.</div>
        <Button bsStyle="bym-standard" onClick={onToggle}>
          Ok
        </Button>
      </ModalBody>
    </Modal>
  );
}
