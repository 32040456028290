import React from "react";
import PropTypes from "prop-types";
import { Tab } from "react-bootstrap";

const EiendomTab = ({ mode, children, ...restProps }) => (
  <Tab eventKey={mode} {...restProps} mountOnEnter>
    {children}
  </Tab>
);

EiendomTab.propTypes = {
  mode: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default EiendomTab;
