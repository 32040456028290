import * as kontraktAction from "../actions/kontraktAction";

const INITIAL_STATE = [];

export default function kontraktReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case kontraktAction.GET_KONTRAKTER_SUCCESS:
      return action.payload.result;
    default:
      return state;
  }
}
