import React from "react";
import { Col, Row } from "react-bootstrap";
import "./DetailsView.css";

const DetailsView = ({ children }) => <Row className="details-view">{children}</Row>;

const DetailsColumn = ({ children, ...props }) => (
  <Col {...props} className="details-column">
    <table className="table table-hover table-condensed details-table center">
      <tbody>{children}</tbody>
    </table>
  </Col>
);

const DetailsItem = ({ label, value }) => (
  <tr>
    <td>{label}</td>
    <td>{value}</td>
  </tr>
);

const CustomDetailsItem = ({ label, children }) => (
  <tr>
    <td>{label}</td>
    <td>{children}</td>
  </tr>
);

export { DetailsView, DetailsColumn, DetailsItem, CustomDetailsItem };
