import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import utvidIcon from "images/utvid.svg";
import { Button, Image } from "react-bootstrap";
import { ReactComponent as SpinnerImage } from "images/spinner.svg";
import Table from "../../containers/Common/SortableTableContainer";
import { HeaderCell } from "../Common/Tables";
import { getFormatedDate } from "../../utils/dateUtils";
import BymUpdateSpinnerButton from "../Common/Forms/BymUpdateSpinnerButton";
import editImage from "../../images/rediger.svg";
import deleteImage from "../../images/delete.svg";
import kreditnotaImage from "../../images/kreditnota.svg";
import FakturaDetails from "./FakturaDetails";
import "./FakturaList.css";
import ShowOnlyForUsersWith from "auth/ShowOnlyFor";

const ImageActionColumn = ({ imageSrc, action, ...restProps }) => (
  <a
    href=""
    onClick={e => {
      e.preventDefault();
      if (action) action();
    }}
  >
    <img src={imageSrc} alt="" {...restProps} />
  </a>
);

ImageActionColumn.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired
};

const FakturaSectionListRow = ({
  faktura,
  onEdit,
  onDelete,
  onSendToAgresso,
  onSynkroniserAgresso,
  onAddKreditnota = () => null,
  showEditColumn = false,
  showDeleteColumn = false,
  showAgressoColumn = false,
  showKreditnotaInfo = false
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [isSendingToAgresso, setIsSendingToAgresso] = React.useState(false);
  const totalPrice = faktura.ordrelinjer.reduce(
    (acc, currItem) => acc + Number(currItem.enhetspris) * Number(currItem.antall),
    0.0
  );
  const getFakturaStatusClassName = () => {
    if (!faktura.fakturaDato || !faktura.fakturaStatus) return "";
    const forfallDato = moment(faktura.fakturaDato).add("days", 15);
    if (faktura.fakturaStatus.id === 4) return "fakturastatus-betalt";
    if (faktura.fakturaStatus.id === 3 && moment().isAfter(forfallDato)) return "fakturastatus-forfalt";
    return "";
  };

  const sendToAgresso = async fakturaId => {
    setIsSendingToAgresso(true);
    await onSendToAgresso(fakturaId);
  };

  return (
    <tbody aria-expanded={showDetails}>
      <tr>
        <td>
          {faktura.ar}-{faktura.maned}
        </td>
        <td>
          <div className={getFakturaStatusClassName()}>
            {faktura.utvidetStatus || (faktura.fakturaStatus && faktura.fakturaStatus.navn)}
          </div>
        </td>
        {showKreditnotaInfo && <td className="opprinneligFakturaColumn">{faktura.kreditertFakturaId}</td>}
        <td>{faktura.fakturaNummer}</td>
        <td>{faktura.ordreNummer}</td>
        <td className="fakturaDatoColumn">{getFormatedDate(faktura.fakturaDato)}</td>
        <td>{faktura.fakturertBelop}</td>
        <td className="restbelopColumn">{faktura.restBelop}</td>
        <td>{totalPrice.toFixed(2)}</td>
        {showAgressoColumn && (
          <td style={{ textAlign: "right" }}>
            {faktura.fakturaStatusId === 1 && (
              <Button
                bsStyle="bym-action"
                style={{ marginTop: "-6px", position: "relative", paddingRight: "20px" }}
                onClick={() => {
                  sendToAgresso(faktura.id);
                }}
                onDoubleClick={() => undefined}
                disabled={isSendingToAgresso}
              >
                Send tilAgresso
                {isSendingToAgresso && (
                  <SpinnerImage style={{ position: "absolute", top: "10px", right: "1px" }} height="18" width="18" />
                )}
              </Button>
            )}
          </td>
        )}
        <td className="faktura-actions">
          <ShowOnlyForUsersWith roles={["UGE-admin", "UGE-bruker"]}>
            {showEditColumn && faktura.fakturaStatusId === 1 && faktura.fakturaStatusId === 1 && (
              <ImageActionColumn title="Rediger faktura" imageSrc={editImage} action={() => onEdit(faktura)} />
            )}

            {showDeleteColumn && faktura.fakturaStatusId === 1 && (
              <ImageActionColumn imageSrc={deleteImage} title="Slett faktura" action={() => onDelete(faktura.id)} />
            )}

            {faktura.fakturaStatusId !== 1 && faktura.fakturaStatusId !== 4 && (
              <BymUpdateSpinnerButton
                title="Oppdater status fra Agresso"
                onClick={() => onSynkroniserAgresso(faktura)}
              />
            )}
            {faktura.fakturaNummer && (
              <ImageActionColumn
                style={{ height: "24px", color: "red" }}
                title="Opprett kreditnota"
                imageSrc={kreditnotaImage}
                action={() => onAddKreditnota(faktura.fakturaNummer)}
              />
            )}
          </ShowOnlyForUsersWith>
        </td>
        <td>
          <Image
            style={{ transform: showDetails ? "rotate(90deg)" : "" }}
            onClick={() => setShowDetails(!showDetails)}
            title="Detaljer"
            className="collapse-icon"
            src={utvidIcon}
          />
        </td>
      </tr>
      {showDetails && (
        <tr className="details">
          <td colSpan="10">
            <FakturaDetails faktura={faktura} />
          </td>
        </tr>
      )}
    </tbody>
  );
};

class FakturaSectionList extends React.Component {
  renderKontraktRow = faktura => {
    return <FakturaSectionListRow faktura={faktura} {...faktura} {...this.props} key={faktura.id} />;
  };

  render() {
    const { fakturaer, showKreditnotaInfo, ...rest } = this.props;
    const sortedFakturaer = fakturaer.sort((a, b) => {
      if (a.ar > b.ar) return -1;
      if (a.ar === b.ar && a.maned > b.maned) return -1;
      return 1;
    });
    return (
      <Table
        id="faktura-liste"
        storeKey="fakturaer"
        items={sortedFakturaer}
        renderRow={this.renderKontraktRow}
        omitBody
      >
        <HeaderCell disableSort>Periode</HeaderCell>
        <HeaderCell disableSort>Status</HeaderCell>
        {showKreditnotaInfo ? (
          <HeaderCell disableSort className="opprinneligFakturaColumn">
            Opprinnelig faktura
          </HeaderCell>
        ) : (
          <></>
        )}
        <HeaderCell disableSort>Fakturanummer</HeaderCell>
        <HeaderCell disableSort>Ordrenummer</HeaderCell>
        <HeaderCell disableSort className="fakturaDatoColumn">
          Fakturadato
        </HeaderCell>
        <HeaderCell disableSort>Fakturert beløp</HeaderCell>
        <HeaderCell disableSort className="restbelopColumn">
          Restbeløp
        </HeaderCell>
        <HeaderCell disableSort>Totalpris</HeaderCell>
        {rest.showAgressoColumn ? <HeaderCell disableSort style={{ maxWidth: "20px" }} /> : <></>}
        <HeaderCell disableSort />
        <HeaderCell disableSort />
      </Table>
    );
  }
}

FakturaSectionList.propTypes = {
  fakturaer: PropTypes.arrayOf(PropTypes.object).isRequired,
  showKreditnotaInfo: PropTypes.bool
};

FakturaSectionList.defaultProps = {
  showKreditnotaInfo: false
};

FakturaSectionListRow.propTypes = {
  faktura: PropTypes.object,
  showEditColumn: PropTypes.bool,
  showDeleteColumn: PropTypes.bool,
  showAgressoColumn: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSendToAgresso: PropTypes.func,
  onSynkroniserAgresso: PropTypes.func,
  onAddKreditnota: PropTypes.func,
  showKreditnotaInfo: PropTypes.bool
};

FakturaSectionListRow.defaultProps = {
  showEditColumn: false,
  showDeleteColumn: false,
  showAgressoColumn: false,
  onAddKreditnota: () => null,
  showKreditnotaInfo: false
};

export default FakturaSectionList;
