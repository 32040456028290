import * as React from "react";
import { Button, Col, Row, Panel } from "react-bootstrap";
import { BymForm, BymTextInput, BymNumberInput, length, required } from "components/Common/Forms";
import { PropTypes, object } from "prop-types";
import { merge } from "lodash-es";
import BymButton from "../Common/Forms/BymButton";
import BymImageButton from "../Common/Forms/BymImageButton";
import deleteIcon from "../../images/delete.svg";

import "./FakturaEditor.css";

function replaceKomma(e) {
  const input = e.target;
  if (input && input.value) {
    input.value = input.value.replace(/,/g, ".");
  }
}

export default class FakturaEditor extends React.Component {
  state = {
    isEditMode: false,
    disableSubmitButton: false
  };

  componentDidMount() {
    const { editObject, kontrakt, kreditnotaFakturaReferanse } = this.props;
    const fakturaDefaults = {
      tittel: "",
      topptekst: "",
      ansvarlig: "",
      ar: undefined,
      maned: undefined,
      ordrelinjer: [],
      kontonummerId: kontrakt.depositumKonto,
      koststedId: kontrakt.koststedId,
      kundenummer: kontrakt.kundenummer,
      organisasjonsnummer: kontrakt.organisasjonsnummer,
      fodselsnummer: kontrakt.fodselsnummer,
      kreditnotaFakturaReferanse,
      newOrdrelinje: this.getNewOrdreLinjeDefaults()
    };
    if (editObject) {
      const mergedFaktura = merge({}, fakturaDefaults, editObject);
      this.setState({
        faktura: { ...mergedFaktura },
        isEditMode: editObject.id ? true : false
      });
    } else {
      this.setState({
        faktura: { ...fakturaDefaults }
      });
    }
  }

  isKreditnota = () => {
    const { kreditnotaFakturaReferanse } = this.props;
    if (kreditnotaFakturaReferanse) return true;
    return false;
  };

  isNew = () => {
    const { ordreNummer } = this.props;
    if (ordreNummer) return false;
    return true;
  };

  saveKontraktFaktura = kontraktFaktura => {
    const { isEditMode } = this.state;
    const { onEdit, onSave } = this.props;
    this.setState({ disableSubmitButton: true });
    if (isEditMode && onEdit) {
      onEdit(kontraktFaktura);
    } else if (onSave) {
      onSave(kontraktFaktura);
    }
  };

  globalValidator = faktura => {
    if (!faktura.ordrelinjer || faktura.ordrelinjer.length < 1) {
      return "Faktura må ha minst 1 ordrelinje";
    }
    return true;
  };

  // Autofylle fakturaskjema med verdier fra kontrakten
  getNewOrdreLinjeDefaults = () => {
    const { kontrakt } = this.props;
    return {
      kontonummerId: kontrakt.depositumKonto,
      koststedId: kontrakt.koststedId,
      enhetspris: "",
      antall: 1,
      tittel: ""
    };
  };

  onKontraktFakturaChange = (controlId, faktura) => {
    if (controlId.indexOf("deleteOrdreLinje_") === 0) {
      const arrayIndex = controlId.substring(controlId.indexOf("_") + 1);
      faktura.ordrelinjer.splice(arrayIndex, 1);
      this.setState({ faktura }); // update state so that ordrelinje table gets updated
      return faktura;
    }
    if (controlId === "addOrdreLinje") {
      let enhetsPris = Number(faktura.newOrdrelinje.enhetspris);
      if (this.isKreditnota()) {
        enhetsPris = -Math.abs(enhetsPris);
      }
      if (enhetsPris && faktura.newOrdrelinje.tittel) {
        const newOrdreLinje = {
          tittel: faktura.newOrdrelinje.tittel,
          antall: faktura.newOrdrelinje.antall,
          enhetspris: enhetsPris,
          kontonummerId: faktura.newOrdrelinje.kontonummerId,
          koststedId: faktura.newOrdrelinje.koststedId
        };
        faktura.ordrelinjer.push(newOrdreLinje);
        const newFaktura = {
          ...faktura,
          newOrdrelinje: this.getNewOrdreLinjeDefaults()
        };
        this.setState({ faktura: newFaktura }); // update state so that ordrelinje table gets updated
        return newFaktura;
      }
    }
    if (controlId === "newOrdrelinje.tittel" || controlId === "newOrdrelinje.enhetspris") {
      this.setState({ faktura });
    }
    return undefined;
  };

  disableNewOrdreLinjeButton = faktura => {
    if (faktura.newOrdrelinje.tittel && faktura.newOrdrelinje.enhetspris) {
      return false;
    }
    return true;
  };

  render() {
    const { onCancel } = this.props;
    const { isEditMode, faktura, disableSubmitButton } = this.state;
    if (!faktura) return <></>;
    return (
      <div className="leieobjekt-details leieobjekt-edit">
        <BymForm
          values={faktura}
          globalValidator={this.globalValidator}
          id="addKontraktFaktura"
          onChange={this.onKontraktFakturaChange}
          onSubmit={this.saveKontraktFaktura}
        >
          <Row>
            <Col md={8}>
              <BymTextInput
                controlId="tittel"
                readOnly={!this.isNew()}
                placeholder="Tittel"
                label="Tittel på faktura ( vises ikke på faktura til kunde )"
              />
            </Col>
            {this.isKreditnota() && (
              <Col md={4}>
                <BymNumberInput
                  controlId="kreditnotaFakturaReferanse"
                  rules={[required(), length(8, 8)]}
                  label="Faktura referanse"
                  readOnly
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col md={4}>
              <BymTextInput controlId="kontonummerId" readOnly label="Kontonummer" />
            </Col>
            <Col md={4}>
              <BymTextInput controlId="koststedId" readOnly label="Koststed" />
            </Col>
            {!this.isKreditnota() && (
              <Col md={4}>
                <BymTextInput controlId="ordreNummer" readOnly label="Ordrenummer" />
              </Col>
            )}
            {this.isKreditnota() && (
              <Col md={4}>
                <BymTextInput controlId="prosjektnummer" />
              </Col>
            )}
          </Row>
          <Row>
            <Col md={8} sm={8}>
              <BymTextInput controlId="topptekst" placeholder="Topptekst" label="Topptekst" />
            </Col>
            <Col md={4} sm={4}>
              <BymTextInput
                controlId="ansvarlig"
                placeholder="Ansvarlig ( bym ident, uten 'bym' )"
                label="Ansvarlig"
                rules={[length(4, 6, false)]}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <BymTextInput controlId="kundenummer" label="Kundenummer" />
            </Col>
            <Col md={4}>
              <BymTextInput controlId="organisasjonsnummer" label="Organisasjonsnummer" />
            </Col>
            <Col md={4}>
              <BymTextInput controlId="fodselsnummer" label="Fødselsnummer" />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h4>Ordrelinjer</h4>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <table className="bym-table table-striped ordrelinje-table">
                <thead>
                  <tr>
                    <th>Tekst</th>
                    <th>Kontonummer</th>
                    <th>Koststed</th>
                    <th>Pris (kr)</th>
                    <th>Antall</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {faktura &&
                    faktura.ordrelinjer.map((ol, index) => (
                      <tr key={index.toString()}>
                        <td>{ol.tittel}</td>
                        <td>{ol.kontonummerId}</td>
                        <td>{ol.koststedId}</td>
                        <td>{ol.enhetspris && ol.enhetspris.toFixed(2)}</td>
                        <td>{ol.antall}</td>
                        <td>
                          <BymImageButton src={deleteIcon} alt="" controlId={`deleteOrdreLinje_${index}`} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Panel>
                <Row>
                  <Col md={6} sm={6}>
                    <BymTextInput
                      controlId="newOrdrelinje.tittel"
                      label="Tekst"
                      placeholder="Tekst som vises på faktura"
                      rules={[length(2, 35, false)]}
                    />
                  </Col>
                  <Col md={4} sm={4}>
                    <BymNumberInput
                      controlId="newOrdrelinje.enhetspris"
                      placeholder="Pris i kroner"
                      label="Pris (kr)"
                      onChange={replaceKomma}
                    />
                  </Col>
                  <Col md={2} sm={2}>
                    <BymNumberInput controlId="newOrdrelinje.antall" label="Antall" />
                  </Col>
                </Row>
                <Row>
                  <Col md={3} sm={3}>
                    <BymTextInput controlId="newOrdrelinje.kontonummerId" label="Kontonummer" />
                  </Col>
                  <Col md={3} sm={3}>
                    <BymTextInput controlId="newOrdrelinje.koststedId" label="Koststed" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <BymButton
                      controlId="addOrdreLinje"
                      bsStyle="link"
                      disabled={this.disableNewOrdreLinjeButton(faktura)}
                    >
                      Legg til ny ordrelinje
                    </BymButton>
                  </Col>
                </Row>
              </Panel>
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12} style={{ marginTop: "20px" }}>
              <Button bsStyle="link" onClick={onCancel}>
                Avbryt
              </Button>
              <Button bsStyle="bym-action" type="submit" disabled={disableSubmitButton}>
                {isEditMode ? "Oppdater" : "Opprett"}
              </Button>
            </Col>
          </Row>
        </BymForm>
        <br />
      </div>
    );
  }
}

FakturaEditor.propTypes = {
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  editObject: PropTypes.shape({}),
  onCancel: PropTypes.func,
  kontraktFaktura: PropTypes.shape({}),
  kontrakt: PropTypes.shape(object).isRequired,
  kreditnotaFakturaReferanse: PropTypes.string,
  ordreNummer: PropTypes.string
};
