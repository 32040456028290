import { get } from "lodash-es";
import { FormGroup, FormControl, FormControlProps, ControlLabel, HelpBlock } from "react-bootstrap";
import { FormContextTypes } from "./BymForm";

function validate(controlId = "", label = "", values: any[] = [], touched: boolean, rules: any[] = []) {
  if (rules) {
    const value = get(values, controlId);
    for (const rule of rules) {
      const error = rule(value, label, values);
      if (error != null) {
        return {
          hasError: true,
          errorState: touched ? "error" : null,
          errorText: touched ? error : null
        };
      }
    }
  }
  return { hasError: false, errorState: null, errorText: null };
}

export type BymFormControlProps = FormControlProps & {
  controlId?: string;
  label?: string;
  component?: any;
  rules?: any;
  hidelabel?: boolean;
  readOnly?: any;
  className?: string;
  setDefaultValue?: any;
  /**
   * When set to true the BymForm onChange method wont be called for changes in this control.
   * This can help performance in large and complex forms
   */
  disableOnFormChange?: boolean;
  viewFormatter?: (value: any) => any;
};

const BymFormControl = (
  {
    controlId = "",
    value,
    setDefaultValue,
    label,
    component: Component = FormControl,
    rules,
    disableOnFormChange,
    viewFormatter,
    ...props
  }: BymFormControlProps,
  context: any
): any => {
  label = props.hidelabel !== true ? label || controlId : label;
  context = context || {};
  context.form = context.form || {};
  const { touched, values = [], validatedWithErrors } = context.form;
  const { hasError, errorState, errorText } = validate(controlId, label, values, touched, rules);
  value = value || get(values, controlId);
  if (viewFormatter) {
    value = viewFormatter(value);
  }
  if (hasError) validatedWithErrors();
  if (props.children && !Component) {
    Component = () => props.children;
  }
  const readOnly = context.form.readOnly || props.readOnly ? true : false;
  const disabled = context.form.disabled || props.disabled ? true : false;
  return (
    <FormGroup controlId={controlId} validationState={errorState as "error" | null}>
      {label && props.hidelabel !== true && <ControlLabel>{label}</ControlLabel>}
      {setDefaultValue ? (
        <Component
          defaultValue={setDefaultValue}
          readOnly={readOnly}
          disabled={disabled}
          data-disableonformchange={disableOnFormChange ? "true" : undefined}
          {...props}
        />
      ) : (
        <Component
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          data-disableonformchange={disableOnFormChange ? "true" : undefined}
          {...props}
        />
      )}
      {errorText && <HelpBlock>{errorText}</HelpBlock>}
    </FormGroup>
  );
};

BymFormControl.contextTypes = FormContextTypes;

export default BymFormControl;
