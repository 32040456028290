import PropTypes from "prop-types";
import { NavDropdown, MenuItem } from "react-bootstrap";

import UserIcon from "../Common/UserIcon";

import "./UserNavItem.css";

const UserNavItem = ({ eventKey, brukerId, onLogout }) => {
  const userIcon = <UserIcon brukerId={brukerId} />;
  return (
    <NavDropdown id="meny-bruker-profil" eventKey={eventKey} title={userIcon}>
      <MenuItem eventKey={`${eventKey}.4`} onClick={onLogout}>
        Logg ut
      </MenuItem>
    </NavDropdown>
  );
};

UserNavItem.propTypes = {
  brukerId: PropTypes.string.isRequired,
  eventKey: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired
};

export default UserNavItem;
