const mimeTypes = {
  txt: "text/plain",
  pdf: "application/pdf",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  doc: "application/msword",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ppt: "application/vnd.ms-powerpoint",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xls: "application/vnd.ms-excel",
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpeg"
};

/**
 * return mimetype by given extension
 * @param  {string} extension
 */
export default function getMimeTypeByExtension(extension) {
  const ext = extension || "txt"; // text/plain is default
  ext.trim(".");
  return mimeTypes[ext.toLowerCase()] || mimeTypes.txt;
}

export { getMimeTypeByExtension };
