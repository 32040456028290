import { Component, ComponentType } from "react";
import { connect } from "react-redux";
import { withAuth, AuthContextProps } from "react-oidc-context";
import { Redirect, Route, RouteProps } from "react-router-dom";
import Spinner from "components/Common/Spinner/Spinner";
import { setRedirectRoute } from "containers/Login/LoginPage";

interface PrivateRouteProps extends AuthContextProps, RouteProps {
  component: ComponentType<any>;
  isUserAdmin?: boolean;
  hideForAdmin?: boolean;
}

class PrivateRoute extends Component<PrivateRouteProps> {
  static defaultProps = {
    hideForAdmin: false,
    isUserAdmin: false
  };

  renderRoute = (props: any) => {
    const { component: Comp, isAuthenticated, isLoading, signinRedirect } = this.props;
    if (isLoading) {
      return <Spinner />;
    }

    if (isAuthenticated) {
      return <Comp {...props} />;
    }

    if (window.location.pathname === "/") {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      );
    }

    const currentUri = `${window.location.pathname}${window.location.search}`;
    setRedirectRoute(currentUri);
    signinRedirect();
    return <></>;
  };

  render() {
    const { component, isAuthenticated, hideForAdmin = false, isUserAdmin, ...rest } = this.props;
    const shouldShow = !(hideForAdmin && isUserAdmin);
    if (shouldShow) {
      return <Route {...rest} render={this.renderRoute} />;
    }
    return null;
  }
}

function mapStateToProps(state: any) {
  return {
    isUserAdmin: state.login?.isAdmin
  };
}

export default withAuth(connect(mapStateToProps)(PrivateRoute));
