import { get } from "lodash-es";

/**
 * Function that returns a concatenated string from all properties
 * of obj specified in keys
 * @param {*} obj
 * Object to get properties from
 * @param {*} keys
 * Array of property keys
 * @param {*} sep
 * separator to be used between strings
 */
const obsKeysToString = (obj, keys, sep = "") => {
  if (!keys) return undefined;

  return keys
    .map(key => get(obj, key))
    .filter(v => v)
    .join(sep)
    .toLowerCase();
};

// eslint-disable-next-line import/prefer-default-export
export { obsKeysToString };
