import { useEffect, useState } from "react";
import useActions from "store/useActions";
import { useSelector } from "react-redux";
import * as koderActions from "actions/koderAction";
import getKoderSelector from "selectors/koderSelector";
import { KontraktMal, KostSted } from "interfaces";

export default function useKoder(forceReload = false) {
  const [initialized, setInitialized] = useState(false);
  const { getKoder } = useActions(koderActions);
  const kontraktTyper = useSelector(state => getKoderSelector(state, "kontrakttyper"));
  const betalingTerminTyper = useSelector(state => getKoderSelector(state, "betalingterminer"));
  const faktureringTyper = useSelector(state => getKoderSelector(state, "faktureringTyper"));
  const faktureringTidspunkter = useSelector(state => getKoderSelector(state, "faktureringTidspunkter"));
  const kontraktStatuser = useSelector(state => getKoderSelector(state, "kontraktStatuser"));
  const kostSteder = useSelector(state => getKoderSelector<KostSted>(state, "kostSteder"));
  const kontoNummere = useSelector(state => getKoderSelector(state, "kontoNummere"));
  const kontraktMaler = useSelector(state => getKoderSelector<KontraktMal>(state, "kontraktMaler"));

  useEffect(() => {
    if (!initialized) {
      getKoder("kontrakttyper", forceReload);
      getKoder("betalingterminer", forceReload);
      getKoder("faktureringTyper", forceReload);
      getKoder("faktureringTidspunkter", forceReload);
      getKoder("kontraktStatuser", forceReload);
      getKoder("kostSteder", forceReload);
      getKoder("kontoNummere", forceReload);
      getKoder("kontraktMaler", forceReload);
    }
    setInitialized(true);
  }, [getKoder, forceReload, initialized]);

  return {
    kontraktTyper,
    betalingTerminTyper,
    faktureringTyper,
    faktureringTidspunkter,
    kontraktStatuser,
    kostSteder,
    kontoNummere,
    kontraktMaler
  };
}
