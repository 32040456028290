import React from "react";
import { Row, Col } from "react-bootstrap";
import { BymTextInput } from "components/Common/Forms";
import BymButton from "components/Common/Forms/BymButton";
import BymFormValueWrapper from "components/Common/Forms/BymFormValueWrapper";
import { set, without, map } from "lodash-es";
import { ReactComponent as DokumentIcon } from "images/dokument.svg";
import { ReactComponent as DeleteIcon } from "images/delete.svg";

const AttachmentList = ({ items = [] }: { items?: string[] }) => {
  return (
    <>
      <ul className="vedlegg-liste" style={{ listStyleType: "none" }}>
        {map(items, (i: any) => (
          <li>
            <div>
              <DokumentIcon /> {i.name}{" "}
            </div>{" "}
            <BymButton
              updateFunction={(formValues: any) => {
                const newValues: any = { ...formValues };
                set(newValues, "avtaleData.vedlegg", without(formValues?.avtaleData.vedlegg, i));
                return newValues;
              }}
              bsStyle="link"
              title="Slett vedlegg"
            >
              {i.allowDelete && <DeleteIcon />}
            </BymButton>
          </li>
        ))}
      </ul>
      <Row>
        <Col md={4}>
          <BymTextInput
            controlId="avtaleData.nyttVedlegg"
            hidelabel
            style={{ marginTop: "-13px" }}
            placeholder="Navn på vedlegg (minst 4 tegn)"
          />
        </Col>
        <Col>
          <BymFormValueWrapper
            mapFunction={(formValues: any) => ({ disabled: !(formValues?.avtaleData?.nyttVedlegg?.length > 3) })}
          >
            <BymButton
              updateFunction={(formValues: any) => {
                const newValues: any = { ...formValues };
                set(newValues, "avtaleData.vedlegg", [
                  ...formValues?.avtaleData.vedlegg,
                  { name: formValues?.avtaleData?.nyttVedlegg, allowDelete: true }
                ]);
                set(newValues, "avtaleData.nyttVedlegg", "");
                return newValues;
              }}
              bsStyle="bym-standard"
            >
              + Legg til
            </BymButton>
          </BymFormValueWrapper>
        </Col>
      </Row>
    </>
  );
};

export default AttachmentList;
