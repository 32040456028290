import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as kommentarAction from "../../actions/kommentarAction";
import getKommentarer from "../../selectors/kommentarerSelector";
import KommentarSection from "../../components/Kommentar/KommentarSection";

const mapStateToProps = (state, ownProps) => {
  return {
    kommentarer: getKommentarer(state, ownProps.eierType, ownProps.eierId),
    eierType: ownProps.eierType,
    eierId: ownProps.eierId,
    login: state.login
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(kommentarAction, dispatch);
}

const KommentarSectionContainer = connect(mapStateToProps, mapDispatchToProps)(KommentarSection);

KommentarSectionContainer.propTypes = {
  eierType: PropTypes.string,
  eierId: PropTypes.string
};

export default KommentarSectionContainer;
