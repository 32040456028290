import { isNumber } from "lodash-es";
import get from "lodash-es/get";
import { useRef } from "react";
import Button from "react-bootstrap/lib/Button";
import { getFormatedDate } from "utils/dateUtils";

import "./DataExportButton.css";

interface DataExportColumnDef {
  cellKey: string;
  displayName: string;
  transformFunc?: (value: any) => string;
}

interface DataExportProps {
  filenamePrefix: string;
  rows: any[][];
  columns?: DataExportColumnDef[];
}

// Tilpasse til Excel med norske regioninnstillinger: bruk , som desimalskilletegn og ; som kolonneskilletegn
const DataExportButton = ({ filenamePrefix, rows, columns }: DataExportProps) => {
  const colSep = ";";
  const decimalSep = ",";

  const generateData = (rows: any[][], columns?: DataExportColumnDef[]): string[] => {
    if (!rows?.length) return [];
    if (!columns?.length) return [];

    const returnArray: string[] = [];
    returnArray.push(columns.map(column => column.displayName).join(colSep));

    // data
    rows.map(row => {
      const rowValues: string[] = [];

      for (const column of columns) {
        const value = get(row, column.cellKey);

        // Hvis det ikke er en transformasjonsfunksjon og ser ut som tall, så formaterer vi det som et tall
        if (!column.transformFunc) {
          isNumber(value) ? rowValues.push(value.toFixed(2).replace(".", decimalSep)) : rowValues.push(value);
        } else {
          rowValues.push(column.transformFunc(value));
        }
      }

      returnArray.push(rowValues.join(colSep));
    });

    return returnArray;
  };

  const filename = filenamePrefix + "-" + getFormatedDate(new Date(), "yyyy-MM-DD_hh.mm") + ".csv";
  const linkref = useRef<HTMLAnchorElement>(null);

  // Bruk <a>-tag for å sette filnavn på klienten ved nedlasting
  return (
    <>
      <a download={filename} ref={linkref} style={{ display: "none" }}>
        Last ned
      </a>
      <Button
        disabled={rows.length === 0}
        className="data-export-button"
        onClick={() => {
          if (linkref.current) {
            const data = generateData(rows, columns).map(row => row + "\n");
            const url = URL.createObjectURL(
              // Første tegn er BOM (byte order mark) for å få Excel til å forstå at det er UTF-8
              new Blob(["\ufeff", ...data], { type: "application/vnd.ms-excel;charset=utf-8" })
            );

            linkref.current.href = url;
            linkref.current.click();
          }
        }}
      >
        Last ned synlige datarader
      </Button>
    </>
  );
};

export default DataExportButton;
