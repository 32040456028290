import { without, includes } from "lodash-es";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import FilterCount from "./FilterCount";
import FilterMenu from "./FilterMenu";

import "./Filters.css";

class Filter extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    filter: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired
  };

  handleChange = (value, checked) => {
    let filter = this.props.filter;
    if (checked === true) {
      filter = [...filter, value];
    } else {
      filter = without(filter, value);
    }
    this.props.onChange(filter);
  };

  handleClear = () => {
    this.props.onChange([]);
  };

  render() {
    const { id, title, items = [], filter = [] } = this.props;

    const filterItems = items.map((item, index) => ({
      id: index,
      value: item,
      checked: includes(filter, item)
    }));

    return (
      <Dropdown id={id} className="filter">
        <Dropdown.Toggle className="filter-button">
          {title}
          <FilterCount value={filter.length} />
        </Dropdown.Toggle>
        <FilterMenu bsRole="menu" items={filterItems} onChange={this.handleChange} onClear={this.handleClear} />
      </Dropdown>
    );
  }
}

export default Filter;
