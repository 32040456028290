import getEnvironmentVariable from "./environment";

const ugeService = `${getEnvironmentVariable("REACT_APP_URL_UGE_SERVICE")}/api`;

// - Koder
export const GET_KODER_URL = `${ugeService}/`;

// - Eiendommer
export const GET_EIENDOMMER_URL = `${ugeService}/eiendommer?include=leieObjekter`;
export const GET_EIENDOM_URL = id =>
  `${ugeService}/eiendommer/${id}?include=kontrakter,kontrakter.kontraktStatus,kontrakter.kontraktType,kontrakter.faktureringTidspunkt,kontrakter.prisType,kontrakter.faktureringType,kontrakter.betalingTermin,kontrakter.kontraktMal,vedlegg,kommentarer,bilder,leieobjekter,logg`;
export const POST_EIENDOM_URL = `${ugeService}/eiendommer`;

// - Leieobjekter
export const GET_LEIEOBJEKT_URL = id => `${ugeService}/leieobjekt/${id}`;
export const GET_LEIEOBJEKTER_URL = `${ugeService}/leieobjekter`;

// - Kontrakter
export const GET_KONTRAKTER_URL = () =>
  `${ugeService}/kontrakter?include=kontraktStatus,kontraktType,faktureringType,betalingTermin`;

export const GET_KONTRAKT_URL = id =>
  `${ugeService}/kontrakter/${id}?include=kontraktStatus,eiendom,kontraktType,betalingTermin,leieObjekter,faktureringType,faktureringTidspunkt,prisType,kommentarer,kontraktMal`;

export const GET_KONTRAKT_SAK_URL = sakNummer => `${ugeService}/arkiv/saker/${sakNummer}`;
export const GET_KONTRAKT_DOKUMENTER_URL = sakNummer => `${ugeService}/arkiv/saker/${sakNummer}/dokumenter`;
export const GET_KONTRAKT_DOKUMENT_URL = dokumentNummer => `${ugeService}/arkiv/dokumenter/${dokumentNummer}`;
export const GET_KONTRAKT_FILE_URL = fileId => `${ugeService}/arkiv/dokumenterfil/${fileId}`;

export const GET_KONTRAKT_NESTE_FAKTURERING_URL = id => `${ugeService}/kontrakter/${id}/fakturaer/nestefakturering`;

export const POST_KONTRAKT_URL = `${ugeService}/kontrakter`;
export const POST_KONTRAKT_VEDLEGG_URL = (saknummer, tittel, kategori, status) =>
  `${ugeService}/arkiv/dokumenter?saknummer=${saknummer}&tittel=${tittel}&kategori=${kategori}&Status=${status}`;

export const EDIT_KONTRAKT_URL = id => `${ugeService}/kontrakter/${id}`;
export const POST_KONTRAKT_AVTALE = `${ugeService}/kontrakter/avtaledokument`;
export const POST_KONTRAKT_AVTALE_PREVIEW = `${ugeService}/kontrakter/avtaledokument?preview=true`;

// - FRONT Kunder
export const GET_KUNDE_URL = kundeNummer => `${ugeService}/kunder?kundenummer=${kundeNummer}`;
export const GET_KUNDE_PRIVAT_URL = personNummer => `${ugeService}/kunder?personnummer=${personNummer}`;
export const GET_KUNDE_ORGANISASJON_URL = organisasjonsNummer =>
  `${ugeService}/kunder?organisasjonsnummer=${organisasjonsNummer}`;
export const POST_KUNDE_URL = `${ugeService}/kunder`;

// - Fakturaer
export const GET_FAKTURA_SYNKRONISER_URL = fakturaid =>
  `${ugeService}/kontrakter/fakturaer/${fakturaid}/synkroniserubw`;

export const GET_FAKTURAER_URL = (ar, maned) => `${ugeService}/kontrakter/fakturaer/${ar}/${maned}`;
export const GET_UTESTAAENDE_FAKTURAER_URL = () => `${ugeService}/kontrakter/fakturaer/utestaaende`;

export const GET_FAKTURAER_AGRESSOFIL_URL = `${ugeService}/kontrakter/fakturaer/agressofil`;
export const GET_FAKTURAER_AGRESSO_URL = `${ugeService}/kontrakter/fakturaer/agresso`;
export const POST_FAKTURAER_URL = `${ugeService}/kontrakter/fakturaer`;
export const GET_KONTRAKT_FAKTURAER_URL = kontraktid => `${ugeService}/kontrakter/${kontraktid}/fakturaer`;
export const POST_KONTRAKT_FAKTURAER_URL = kontraktid => `${ugeService}/kontrakter/${kontraktid}/fakturaer`;
export const POST_KONTRAKT_MANUAL_FAKTURA_URL = kontraktid =>
  `${ugeService}/kontrakter/${kontraktid}/fakturaer/manuell`;
export const KONTRAKT_FAKTURA_URL = (kontraktid, fakturaid) =>
  `${ugeService}/kontrakter/${kontraktid}/fakturaer/${fakturaid}`;

export const FAKTURA_AGRESSO_URL = fakturaid => `${ugeService}/kontrakter/fakturaer/${fakturaid}/agresso`;
export const FAKTURA_BEREGN_BELOP_URL = kontraktid => `${ugeService}/kontrakter/${kontraktid}/fakturaer/beregnbelop`;

export const KONTRAKT_KREDITNOTA_URL = kontraktid => `${ugeService}/kontrakter/${kontraktid}/fakturaer/kreditnotaer`;

// - Vedlegg
export const DOWNLOAD_VEDLEGG_URL = (id, eiendomId) => `${ugeService}/eiendommer/${eiendomId}/vedlegg/${id}/data`;
export const POST_VEDLEGG_URL = eiendomId => `${ugeService}/eiendommer/${eiendomId}/vedlegg`;
export const DELETE_VEDLEGG_URL = (eiendomId, id) => `${ugeService}/eiendommer/${eiendomId}/vedlegg/${id}`;

// - Bilder
export const DOWNLOAD_BILDER_URL = (eiendomId, id) => `${ugeService}/eiendommer/${eiendomId}/bilder/${id}/data`;
export const POST_BILDER_URL = eiendomId => `${ugeService}/eiendommer/${eiendomId}/bilder`;
export const DELETE_BILDER_URL = (eiendomId, id) => `${ugeService}/eiendommer/${eiendomId}/bilder/${id}`;

// - Kommentarer
export const POST_KOMMENTARER_URL = (eierType, eierId) => `${ugeService}/${eierType}/${eierId}/kommentarer`;
export const DELETE_KOMMENTARER_URL = (eierType, eierId, id) => `${ugeService}/${eierType}/${eierId}/kommentarer/${id}`;
export const EDIT_KOMMENTARER_URL = (eierType, eierId, id) => `${ugeService}/${eierType}/${eierId}/kommentarer/${id}`;

// - Leieobjekter
export const POST_LEIEOBJEKT_URL = eiendomId => `${ugeService}/eiendommer/${eiendomId}/leieobjekter`;
export const EDIT_LEIEOBJEKT_URL = (eiendomId, id) => `${ugeService}/eiendommer/${eiendomId}/leieobjekter/${id}`;

// - Brukere (OneLogin)
export const GET_BRUKERE_URL = () => `${ugeService}/brukere`;
export const EDIT_BRUKERE_URL = id => `${ugeService}/brukere/${id}`;
export const GET_BRUKER_URL = EDIT_BRUKERE_URL;

// Indeksregulering
export const GET_INDEKSREGULERINGER_URL = () => `${ugeService}/indeksreguleringer`;
export const POST_INDEKSREGULERINGER_URL = GET_INDEKSREGULERINGER_URL;

// Geo
export const GET_ADRESSE_FOR_LOKASJON = (lat, lng, radius) =>
  `${ugeService}/geo/adresse?radius=${radius}&lat=${lat}&lng=${lng}`;
