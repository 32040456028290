import React from "react";
import { FormContextTypes } from "components/Common/Forms";
import { get } from "lodash-es";
import { JsonObject } from "type-fest";

type BymFormValueWrapperProps<T = JsonObject> = {
  children?: React.ReactElement;
  controlId?: string;
  mappedPropName?: string;
  mapFunction?: (formValues: T) => any;
};

const BymFormValueWrapper = <T extends unknown = JsonObject>(
  { children, controlId, mappedPropName, mapFunction }: BymFormValueWrapperProps<T>,
  { form }: any
) => {
  if (children === undefined) {
    return controlId ? <>{get(form.values, controlId)}</> : <></>;
  }
  let childProps = { ...form.values };
  if (mapFunction) {
    childProps = mapFunction(form.values);
  } else if (controlId) {
    childProps = { [mappedPropName || controlId]: get(form.values, controlId), controlId };
  }
  return <>{React.cloneElement(children, childProps)}</>;
};

BymFormValueWrapper.contextTypes = FormContextTypes;

export default BymFormValueWrapper;
