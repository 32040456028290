import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { BymForm, BymSubmitButton, BymTextInput, length, required } from "components/Common/Forms";

const DeleteFakturaDialog = ({ show, onClose = () => null, onConfirm = () => null }) => {
  return (
    <Modal onHide={() => onClose()} show={show} backdrop="static" style={{ display: "flex", alignItems: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title>Er du sikker på at du vil slette fakturaen?</Modal.Title>
      </Modal.Header>
      <BymForm values={{ kommentar: "" }} onSubmit={values => onConfirm(values.kommentar)}>
        <Modal.Body>
          <p>
            Er du sikker på at du vil slette fakturaen? Hvis du vil dette må du skrive inn en kommentar for hvorfor.
          </p>
          <BymTextInput
            componentClass="textarea"
            controlId="kommentar"
            label="kommentar"
            hidelabel
            rows={4}
            rules={[required(), length(6, 255)]}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} bsStyle="link">
            Avbryt
          </Button>
          <BymSubmitButton text="Slett faktura" bsStyle="bym-negative" />
        </Modal.Footer>
      </BymForm>
    </Modal>
  );
};

DeleteFakturaDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default DeleteFakturaDialog;
