export default function getEtagConfig(getState, key) {
  const state = getState();
  if (state[key] && state[key].etag) {
    return {
      headers: { "If-None-Match": state[key].etag },
      validateStatus: status => (status >= 200 && status < 300) || status === 304
    };
  }
  return null;
}
