import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../helpers/actionHelper";
import { fakturaListSchema, fakturaSchema, createFakturaListSchema } from "../constants/schemas";

// GET FAKTURAER
export const GET_FAKTURAER_REQUEST = "GET_FAKTURAER_REQUEST";
export const GET_FAKTURAER_SUCCESS = "GET_FAKTURAER_SUCCESS";
export const GET_FAKTURAER_FAILURE = "GET_FAKTURAER_FAILURE";

export function getFakturaer(ar, maned, segment) {
  const url = segment ? `${api.GET_FAKTURAER_URL(ar, maned)}?segment=${segment}` : api.GET_FAKTURAER_URL(ar, maned);
  return dispatch => {
    dispatch({ type: GET_FAKTURAER_REQUEST });
    return axios
      .get(url)
      .then(handle.success(dispatch, GET_FAKTURAER_SUCCESS, fakturaListSchema))
      .catch(handle.error(dispatch, GET_FAKTURAER_FAILURE));
  };
}

// GET FAKTURAER
export const GET_UTESTAAENDE_FAKTURAER_REQUEST = "GET_UTESTAAENDE_FAKTURAER_REQUEST";
export const GET_UTESTAAENDE_FAKTURAER_SUCCESS = "GET_UTESTAAENDE_FAKTURAER_SUCCESS";
export const GET_UTESTAAENDE_FAKTURAER_FAILURE = "GET_UTESTAAENDE_FAKTURAER_FAILURE";

export function getUtestaaendeFakturaer(segment) {
  const url = segment
    ? `${api.GET_UTESTAAENDE_FAKTURAER_URL()}?segment=${segment}`
    : api.GET_UTESTAAENDE_FAKTURAER_URL();
  return dispatch => {
    dispatch({ type: GET_UTESTAAENDE_FAKTURAER_REQUEST });
    return axios
      .get(url)
      .then(handle.success(dispatch, GET_UTESTAAENDE_FAKTURAER_SUCCESS, fakturaListSchema))
      .catch(handle.error(dispatch, GET_UTESTAAENDE_FAKTURAER_FAILURE));
  };
}

// GET KONTRAKT FAKTURAER
export const GET_KONTRAKT_FAKTURAER_REQUEST = "GET_KONTRAKT_FAKTURAER_REQUEST";
export const GET_KONTRAKT_FAKTURAER_SUCCESS = "GET_KONTRAKT_FAKTURAER_SUCCESS";
export const GET_KONTRAKT_FAKTURAER_FAILURE = "GET_KONTRAKT_FAKTURAER_FAILURE";

export function getKontraktFakturaer(kontraktid) {
  return dispatch => {
    dispatch({ type: GET_KONTRAKT_FAKTURAER_REQUEST });
    return axios
      .get(api.GET_KONTRAKT_FAKTURAER_URL(kontraktid))
      .then(handle.success(dispatch, GET_KONTRAKT_FAKTURAER_SUCCESS, fakturaListSchema))
      .catch(handle.error(dispatch, GET_KONTRAKT_FAKTURAER_FAILURE));
  };
}

// POST KONTRAKT FAKTURAER
export const POST_KONTRAKT_FAKTURAER_REQUEST = "POST_KONTRAKT_FAKTURAER_REQUEST";
export const POST_KONTRAKT_FAKTURAER_SUCCESS = "POST_KONTRAKT_FAKTURAER_SUCCESS";
export const POST_KONTRAKT_FAKTURAER_FAILURE = "POST_KONTRAKT_FAKTURAER_FAILURE";

export function createKontraktFaktura(kontraktid, fakturaData) {
  return dispatch =>
    axios
      .post(api.POST_KONTRAKT_FAKTURAER_URL(kontraktid), fakturaData)
      .then(
        handle.success(dispatch, POST_KONTRAKT_FAKTURAER_SUCCESS, createFakturaListSchema, {
          kontraktid
        })
      )
      .catch(handle.error(dispatch, POST_KONTRAKT_FAKTURAER_FAILURE));
}

// POST KONTRAKT MANUAL FAKTURA
export const POST_KONTRAKT_MANUAL_FAKTURA_REQUEST = "POST_KONTRAKT_MANUAL_FAKTURA_REQUEST";
export const POST_KONTRAKT_MANUAL_FAKTURA_SUCCESS = "POST_KONTRAKT_MANUAL_FAKTURA_SUCCESS";
export const POST_KONTRAKT_MANUAL_FAKTURA_FAILURE = "POST_KONTRAKT_MANUAL_FAKTURA_FAILURE";

export function postKontraktManualFaktura(kontraktid, body) {
  return dispatch =>
    axios
      .post(api.POST_KONTRAKT_MANUAL_FAKTURA_URL(kontraktid), body, {
        validateStatus(status) {
          return status >= 200 && status < 300; // default
        }
      })
      .then(
        handle.success(dispatch, POST_KONTRAKT_MANUAL_FAKTURA_SUCCESS, null, {
          kontraktid
        })
      )
      .catch(handle.error(dispatch, POST_KONTRAKT_MANUAL_FAKTURA_FAILURE));
}

// UPDATE KONTRAKT FAKTURAER
export const UPDATE_KONTRAKT_FAKTURAER_REQUEST = "UPDATE_KONTRAKT_FAKTURAER_REQUEST";
export const UPDATE_KONTRAKT_FAKTURAER_SUCCESS = "UPDATE_KONTRAKT_FAKTURAER_SUCCESS";
export const UPDATE_KONTRAKT_FAKTURAER_FAILURE = "UPDATE_KONTRAKT_FAKTURAER_FAILURE";

export function updateKontraktFaktura(kontraktId, fakturaId, fakturaData) {
  return dispatch =>
    axios
      .put(api.KONTRAKT_FAKTURA_URL(kontraktId, fakturaId), fakturaData)
      .then(
        handle.success(dispatch, UPDATE_KONTRAKT_FAKTURAER_SUCCESS, fakturaSchema, {
          kontraktId
        })
      )
      .catch(handle.error(dispatch, UPDATE_KONTRAKT_FAKTURAER_FAILURE));
}

// DELETE KONTRAKT FAKTURA
export const DELETE_KONTRAKT_FAKTURA_REQUEST = "DELETE_KONTRAKT_FAKTURA_REQUEST";
export const DELETE_KONTRAKT_FAKTURA_SUCCESS = "DELETE_KONTRAKT_FAKTURA_SUCCESS";
export const DELETE_KONTRAKT_FAKTURA_FAILURE = "DELETE_KONTRAKT_FAKTURA_FAILURE";

export function deleteKontraktFaktura(kontraktid, fakturaid, kommentar) {
  return dispatch =>
    axios
      .delete(api.KONTRAKT_FAKTURA_URL(kontraktid, fakturaid), { data: { kommentar } })
      .then(
        handle.success(dispatch, DELETE_KONTRAKT_FAKTURA_SUCCESS, null, {
          kontraktid
        })
      )
      .catch(handle.error(dispatch, DELETE_KONTRAKT_FAKTURA_FAILURE));
}

// POST KONTRAKT KREDITNOTA
export const POST_KONTRAKT_KREDITNOTA_REQUEST = "POST_KONTRAKT_KREDITNOTA_REQUEST";
export const POST_KONTRAKT_KREDITNOTA_SUCCESS = "POST_KONTRAKT_KREDITNOTA_SUCCESS";
export const POST_KONTRAKT_KREDITNOTA_FAILURE = "POST_KONTRAKT_KREDITNOTA_FAILURE";

export function postKontraktKreditnota(kontraktid, body) {
  return dispatch =>
    axios
      .post(api.KONTRAKT_KREDITNOTA_URL(kontraktid), body)
      .then(
        handle.success(dispatch, POST_KONTRAKT_KREDITNOTA_SUCCESS, null, {
          kontraktid
        })
      )
      .catch(handle.error(dispatch, POST_KONTRAKT_KREDITNOTA_FAILURE));
}

// POST FAKTURA AGRESSO
export const POST_FAKTURA_AGRESSO_REQUEST = "POST_FAKTURA_AGRESSO_REQUEST";
export const POST_FAKTURA_AGRESSO_SUCCESS = "POST_FAKTURA_AGRESSO_SUCCESS";
export const POST_FAKTURA_AGRESSO_FAILURE = "POST_FAKTURA_AGRESSO_FAILURE";

export function postFakturaToAgresso(fakturaId) {
  return dispatch =>
    axios
      .post(api.FAKTURA_AGRESSO_URL(fakturaId))
      .then(
        handle.success(dispatch, POST_FAKTURA_AGRESSO_SUCCESS, null, {
          fakturaId
        })
      )
      .catch(handle.error(dispatch, POST_FAKTURA_AGRESSO_FAILURE));
}

export const POST_BEREGN_FAKTURA_BELOP_SUCCESS = "POST_BEREGN_FAKTURA_BELOP_SUCCESS";
export const POST_BEREGN_FAKTURA_BELOP_FAILURE = "POST_BEREGN_FAKTURA_BELOP_FAILURE";

export function beregnFakturaBelop(kontrakt) {
  const leieObjekter = kontrakt.leieObjekter.filter(lo => lo.checked);
  const newKontrakt = { ...kontrakt, leieObjekter, tittel: kontrakt.tittel ?? "..." };
  return axios.post(api.FAKTURA_BEREGN_BELOP_URL(kontrakt.id ?? "00000000-0000-0000-0000-000000000000"), newKontrakt);
}
