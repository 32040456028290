import { map } from "lodash-es";
import classNames from "classnames";
import React from "react";
import { Table as BSTable } from "react-bootstrap";
import PropTypes from "prop-types";

import "./Table.css";

const Table = ({ className, items, renderRow, children, sort, onSort, omitBody = false, ...rest }) => {
  let headers = children;

  if (sort || onSort) {
    headers = React.Children.map(children, child => React.cloneElement(child, { sort, onSort }));
  }

  return (
    <BSTable className={classNames("bym-table", className)} hover striped {...rest}>
      <thead>
        <tr>{headers}</tr>
      </thead>
      {omitBody && <>{map(items, renderRow)}</>}
      {!omitBody && <tbody>{map(items, renderRow)}</tbody>}
    </BSTable>
  );
};

Table.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRow: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    cellKey: PropTypes.string,
    orders: PropTypes.string
  }),
  onSort: PropTypes.func,
  omitBody: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

Table.defaultProps = {
  className: null,
  sort: {},
  onSort: undefined
};

export default Table;
