import { useEffect } from "react";
import { useLeaflet } from "react-leaflet";
import "./BymGeastureControll.scss";
/** This file is in js-format beacuse of missing types in leaflet
 *  It is cumbersome to make types
 */

export default function BYMMapGeastureControll() {
  const { map } = useLeaflet();

  /**
   * This handler maipulates the dom directly
   */
  const handleTouch = e => {
    if (!map) return;
    const container = map.getContainer();

    /* If user touches with one finger
    if (e.type === "touchstart" && e.touches && e.touches.length === 1) {
    } */

    // If user moves with one fingers over map
    if (e.type === "touchmove" && e.touches && e.touches.length === 1) container.classList.add("double-tap-message");

    // If user moves with two fingers
    if (e.type === "touchmove" && e.touches && e.touches.length === 2) container.classList.remove("double-tap-message");

    // When user releases finger
    if (e.type === "touchend") container.classList.remove("double-tap-message");
  };

  useEffect(() => {
    // Uses native event listeners instead of L.DomEvent due to issues with Android touch events
    if (!map) return;
    const container = map.getContainer();
    container.addEventListener("touchstart", handleTouch);
    container.addEventListener("touchmove", handleTouch);
    container.addEventListener("touchend", handleTouch);
    container.addEventListener("touchcancel", handleTouch);
    container.addEventListener("click", handleTouch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
}
