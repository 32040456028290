import * as React from "react";
import { PropTypes } from "prop-types";
import {
  BymForm,
  BymTextInput,
  BymSubmitButton,
  BymResetButton,
  BymSelectInput,
  required
} from "components/Common/Forms";

class BrukerDetails extends React.Component {
  state = { bruker: undefined };

  componentDidMount() {
    const { onUpdate, segmenter, ...bruker } = this.props;
    this.setState({ bruker });
  }

  render() {
    const { onUpdate, segmenter } = this.props;
    const { bruker } = this.state;
    if (!bruker) return null;

    const alleSegmenter = [{ id: 0, navn: "Alle" }, ...segmenter];

    return (
      <div className="col-md-4">
        <BymForm id="UpdateUserForm" onSubmit={onUpdate} values={bruker}>
          {bruker.erUgeAdmin && (
            <div style={{ marginBottom: "1em" }}>
              <strong>Brukeren er UGE-admin</strong>
            </div>
          )}
          <BymTextInput readOnly controlId="epost" />
          <BymSelectInput items={alleSegmenter} controlId="segmentId" label="Seksjon" rules={[required()]} />
          <div className="pull-right" style={{ marginTop: "15px" }}>
            <BymResetButton text="Avbryt" />
            <BymSubmitButton text="Lagre" />
          </div>
        </BymForm>
      </div>
    );
  }
}

BrukerDetails.propTypes = {
  onUpdate: PropTypes.func,
  segmenter: PropTypes.arrayOf(PropTypes.object).isRequired
};

BrukerDetails.defaultProps = {
  onUpdate: () => null
};

export default BrukerDetails;
