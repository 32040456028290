import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";
import axios from "../../services/axios";

export const getImageBlobUrl = image => {
  const blob = new Blob([image]);
  const urlCreator = window.URL || window.webkitURL;
  return urlCreator.createObjectURL(blob);
};

const BlobImage = ({ src, ...rest }) => {
  const [blobUrl, setBlobUrl] = useState("");
  useEffect(() => {
    axios
      .post(src, null, {
        responseType: "arraybuffer"
      })
      .then(response => {
        const newBlobUrl = getImageBlobUrl(response.data);
        setBlobUrl(newBlobUrl);
      });
  }, []);
  return <Image alt="" {...rest} src={blobUrl} />;
};

BlobImage.propTypes = {
  src: PropTypes.string
};

export default BlobImage;
