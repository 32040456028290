import React, { Component } from "react";
import PropTypes from "prop-types";
import PageSection from "../Layout/PageSection";
import { vedleggShape } from "../../constants/proptypes";
import VedleggItem from "./VedleggItem";

import "./VedleggSection.css";
import { isAdminOrUgeBruker } from "auth/authTools";

class VedleggSection extends Component {
  static propTypes = {
    eiendomId: PropTypes.string.isRequired,
    vedlegg: PropTypes.arrayOf(vedleggShape).isRequired,
    postVedlegg: PropTypes.func.isRequired,
    deleteVedlegg: PropTypes.func.isRequired,
    sectionUpdated: PropTypes.func.isRequired
  };

  showFileSelector = () => {
    this.fileInput.click();
  };

  handleFileSelected = e => {
    const { eiendomId, postVedlegg, sectionUpdated } = this.props;
    postVedlegg(eiendomId, e.target.files).then(() => {
      if (sectionUpdated) sectionUpdated();
    });
    e.target.value = null;
  };

  deleteVedlegg = vedleggId => {
    const { eiendomId, deleteVedlegg, sectionUpdated } = this.props;
    deleteVedlegg(eiendomId, vedleggId).then(() => {
      if (sectionUpdated) sectionUpdated();
    });
  };

  render() {
    const { vedlegg = [] } = this.props;
    const title = `Dokumenter (${vedlegg.length})`;
    return (
      <PageSection
        title={title}
        addText="Legg til dokument"
        onAdd={this.showFileSelector}
        showButton={isAdminOrUgeBruker()}
      >
        <ul className="list-unstyled vedlegg-list">
          {vedlegg.map(v => (
            <VedleggItem dok={v} key={v.filId} onDelete={() => this.deleteVedlegg(v.fil.id)} />
          ))}
        </ul>
        <input
          ref={r => {
            this.fileInput = r;
          }}
          type="file"
          multiple
          autoFocus
          style={{ display: "none" }}
          onChange={this.handleFileSelected}
        />
      </PageSection>
    );
  }
}

export default VedleggSection;
