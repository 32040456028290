import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withCookies } from "react-cookie";
import { getLeieObjekter } from "../../actions/leieObjektAction";
import Page from "../../components/Layout/Page";
import LeieObjektList from "../../components/LeieObjekt/LeieObjektList";
import Filters from "../../components/Common/Tables/Filters";
import createColumnFilter from "../Common/createColumnFilter";
import makeGetLeieObjekter, { makeGetLeieObjektList } from "../../selectors/leieObjekterSelector";
import createPaginationControl from "../Common/createPaginationControl";
import createFreetextFilter from "../Common/createFreetextFilter";
import { getEiendommer, getEiendommerBySegment } from "../../actions/eiendomAction";

const LeieObjektColumnFilter = createColumnFilter("leieobjekter", makeGetLeieObjekter());
const LeieObjektFreetextFilter = createFreetextFilter("leieobjekter", makeGetLeieObjekter());
const PaginationControl = createPaginationControl("leieobjekter", makeGetLeieObjekter());

class LeieObjektListPage extends Component {
  static propTypes = {
    eiendommer: PropTypes.arrayOf(
      PropTypes.shape({
        navn: PropTypes.string,
        adresse: PropTypes.string,
        id: PropTypes.string,
        Område: PropTypes.string,
        Distrikt: PropTypes.string,
        type: PropTypes.string
      })
    ),
    leieObjekter: PropTypes.arrayOf(PropTypes.object),
    getLeieObjekter: PropTypes.func.isRequired,
    getEiendommer: PropTypes.func.isRequired,
    getEiendommerBySegment: PropTypes.func.isRequired
  };

  static defaultProps = {
    eiendommer: [],
    leieObjekter: []
  };

  componentDidMount() {
    const { ...allProps } = this.props;
    allProps.getLeieObjekter();
    const segment = allProps.cookies.get("uge_segment");
    if (segment) allProps.getEiendommerBySegment(segment);
    else allProps.getEiendommer();
  }

  render() {
    const { leieObjekter } = this.props;
    return (
      <Page title="Leieobjekter">
        <Filters>
          <LeieObjektColumnFilter id="stedsnavnfilter" title="Distrikt" filterKey="Distrikt" />
          <LeieObjektColumnFilter id="omraadefilter" title="Område" filterKey="Område" />
          <LeieObjektColumnFilter id="kategorifilter" title="Kategori" filterKey="Kategori" />
          <LeieObjektColumnFilter id="eiendomstypefilter" title="Type" filterKey="typeNavn" />
          <LeieObjektFreetextFilter id="kontrakttextfilter" />
        </Filters>
        <PaginationControl storeKey="leieobjekter" />
        <LeieObjektList leieObjekter={leieObjekter} />
        <PaginationControl storeKey="leieobjekter" />
      </Page>
    );
  }
}

const makeMapStateToProps = () => {
  const getLeieObjekterSelector = makeGetLeieObjektList();
  const mapStateToProps = state => {
    const leieObjekter = getLeieObjekterSelector(state);
    return {
      leieObjekter
    };
  };
  return mapStateToProps;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeieObjekter,
      getEiendommer,
      getEiendommerBySegment
    },
    dispatch
  );
}

export default withCookies(connect(makeMapStateToProps, mapDispatchToProps)(LeieObjektListPage));
