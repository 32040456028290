import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Cookies, withCookies } from "react-cookie";
import { Button, Glyphicon } from "react-bootstrap";
import AddEiendomDialog from "components/Eiendom/AddEiendomDialog";
import * as eiendomAction from "../../actions/eiendomAction";
import { setActiveTab } from "../../actions/tabAction";
import Page from "../../components/Layout/Page";
import EiendomTabs from "../../components/Eiendom/EiendomTabs";
import EiendomTab from "../../components/Eiendom/EiendomTab";
import EiendomList from "../../components/Eiendom/EiendomList";
import EiendomMap from "../../components/Map/EiendomMap";
import makeGetTab from "../../selectors/tabSelector";
import Filters from "../../components/Common/Tables/Filters";
import createColumnFilter from "../Common/createColumnFilter";
import createFreetextFilter from "../Common/createFreetextFilter";
import makeGetEiendommer, { makeGetEiendomList } from "../../selectors/eiendommerSelector";
import createPaginationControl from "../Common/createPaginationControl";
import { setPageCount } from "../../actions/advancedListAction";
import PromiseSpinner from "../../components/Common/Spinner/PromiseSpinner";
import { setToastSuccess } from "../../actions/toastAction";
import { Eiendom } from "interfaces";
import ShowOnlyForUsersWith from "auth/ShowOnlyFor";

const searchProps = ["Navn", "Adresse", "Distrikt", "Område", "Kategori", "Anleggsnummer"];
const EiendomColumnFilter = createColumnFilter("eiendommer", makeGetEiendommer(searchProps));
const EiendomFreetextFilter = createFreetextFilter("eiendommer", makeGetEiendommer(searchProps));
const PaginationControl = createPaginationControl("eiendommer", makeGetEiendommer(searchProps));

type EiendomListMode = "liste" | "kart";

interface EiendomListPageProps {
  eiendommer: Eiendom[];
  getEiendommer: () => Eiendom[];
  getEiendommerBySegment: (segmentId: string | number) => Eiendom[];
  createEiendom: (eiendomData: Eiendom) => any;
  setToastSuccess: (message: string) => any;
  cookies: Cookies;
  mode: EiendomListMode;
  onSelect: (mode: EiendomListMode) => any;
  setPageCountAction: (storeKey: string, value: number) => any;
}

class EiendomListPage extends Component<EiendomListPageProps, any> {
  state = {
    spinnerPromise: undefined,
    searchFilteredEiendommer: undefined,
    isAddEiendomDialogVisible: false
  };

  searchTimeout = null;

  componentDidMount() {
    const { cookies, getEiendommer, getEiendommerBySegment } = this.props;
    const segment = cookies.get("uge_segment");
    let promise;
    if (segment) {
      promise = getEiendommerBySegment(segment);
    } else {
      promise = getEiendommer();
    }
    this.setState({ spinnerPromise: promise });
  }

  toggleAddEiendomClick = (isOpen: boolean) => {
    this.setState({ isAddEiendomDialogVisible: isOpen });
  };

  onAddEiendom = async (eiendomFormValues: any) => {
    const { isAddEiendomDialogVisible } = this.state;
    if (!isAddEiendomDialogVisible) return;

    const { createEiendom, setToastSuccess: setSuccessMessage } = this.props;

    await createEiendom(eiendomFormValues);
    setSuccessMessage("Eiendommen ble opprettet");

    this.toggleAddEiendomClick(false);
  };

  onTabSelect = (mode: EiendomListMode) => {
    // the map uses the same data as the list so if we click the map we
    // set the page count to a high number so that all eiendommer shows on the map
    const { onSelect, setPageCountAction } = this.props;
    const pageCount = mode === "kart" ? 10000 : 100;
    setPageCountAction("eiendommer", pageCount);
    if (onSelect) onSelect(mode);
  };

  obsKeysToString = (obj: any, keys: any, sep = "") =>
    keys
      .map((key: any) => obj[key])
      .filter((v: any) => v)
      .join(sep);

  render() {
    const { mode, eiendommer = [], cookies } = this.props;
    const { spinnerPromise, searchFilteredEiendommer, isAddEiendomDialogVisible } = this.state;
    return (
      <Page title="Eiendommer" subtitle={""} className={""}>
        <ShowOnlyForUsersWith roles={["UGE-admin", "UGE-bruker"]}>
          <Button
            className="pull-right add-eiendom-button"
            bsStyle="bym-positive"
            onClick={() => this.toggleAddEiendomClick(true)}
          >
            <Glyphicon glyph="plus" /> Ny eiendom
          </Button>
        </ShowOnlyForUsersWith>

        {isAddEiendomDialogVisible && (
          <AddEiendomDialog
            show
            segment={cookies.get("uge_segment")}
            onClose={() => this.toggleAddEiendomClick(false)}
            onSubmit={this.onAddEiendom as any}
          />
        )}
        <Filters style={{ marginBottom: "15px", marginRight: "300px" }}>
          <EiendomColumnFilter id="stedsnavnfilter" title="Distrikt" filterKey="Distrikt" onChange={() => undefined} />
          <EiendomColumnFilter id="omraadefilter" title="Område" filterKey="Område" onChange={() => undefined} />
          <EiendomColumnFilter
            id="eiendomstypefilter"
            title="Kategori"
            filterKey="Kategori"
            onChange={() => undefined}
          />
          <EiendomFreetextFilter id="eiendomstextfilter" onChange={() => undefined} filterKey={""} />
        </Filters>
        <PromiseSpinner spinnerStyle="page-spinner" text="Laster eiendommer" promise={spinnerPromise}>
          <EiendomTabs mode={mode} onSelect={this.onTabSelect}>
            <EiendomTab mode="liste" className="eiendomliste-tab">
              <PaginationControl storeKey="eiendommer" />
              <EiendomList eiendommer={searchFilteredEiendommer || eiendommer} />
              <PaginationControl storeKey="eiendommer" />
            </EiendomTab>
            <EiendomTab mode="kart" style={{ marginTop: "22px" }}>
              <EiendomMap
                zoom={12}
                eiendommer={searchFilteredEiendommer || eiendommer}
                showTooltip
                autoBounds
                className="bym-map-big"
              />
            </EiendomTab>
          </EiendomTabs>
        </PromiseSpinner>
      </Page>
    );
  }
}

const makeMapStateToProps = () => {
  const getEiendommerList = makeGetEiendomList(searchProps);
  const getTab = makeGetTab("eiendommer");
  const mapStateToProps = (state: any) => ({
    eiendommer: getEiendommerList(state),
    mode: getTab(state)
  });
  return mapStateToProps;
};

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      setPageCountAction: setPageCount,
      ...eiendomAction,
      setToastSuccess,
      onSelect: (value: any) => setActiveTab("eiendommer", value)
    } as any,
    dispatch
  );
}

export default withCookies(connect(makeMapStateToProps, mapDispatchToProps)(EiendomListPage) as any);
