import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  BymForm,
  BymSubmitButton,
  BymResetButton,
  BymTextInput,
  BymNumberInput,
  BymSelectInput,
  length,
  required
} from "components/Common/Forms";
import moment from "moment";
import UgeDateInput from "../Common/Forms/UgeDateInput";
import BymEditMap from "components/Common/Map/BymEditMap";
import { getGeoJSONFromPoint } from "components/Common/Map/BymMarker";

import "./LeieObjektEditor.css";

export default class LeieObjektEditor extends React.Component {
  emptyLeieObjekt = {
    navn: null,
    leieObjektTypeId: "",
    bygningsnummer: null,
    gyldigFra: null,
    gyldigTil: null,
    adresse2: "",
    gnr: null,
    bnr: null,
    byggeAr: null,
    bebygdAreal: null,
    bruksAreal: null,
    primarRom: null,
    sekundarRom: null,
    vernTypeId: 1501, // Standard verdi "IR"
    stromMalernummer: "",
    stromMalepunkt: "",
    vannMalernummer: "",
    vannMalepunkt: "",
    bruksenhetNummer: "",
    geometri: null
  };

  state = {
    leieObjekt: { ...this.emptyLeieObjekt },
    isEditMode: false,
    geometry: undefined
  };

  componentDidMount() {
    this.setLeieObjekt(this.props);
  }

  componentWillReceiveProps(nextProps: any) {
    // only set leieobjekt if it has not been set before, in case we have started to edit the form
    this.setLeieObjekt(nextProps);
  }

  shouldComponentUpdate() {
    return true;
  }

  setLeieObjekt(props: any) {
    if (props.editObject) {
      this.setState({ leieObjekt: props.editObject, isEditMode: true });
    } else {
      this.setState({ leieObjekt: this.emptyLeieObjekt, isEditMode: false });
    }
  }

  saveLeieObjekt = (leieObjekt: any) => {
    const { onEdit, onSave } = this.props as any;
    const { isEditMode } = this.state;
    if (isEditMode && onEdit) onEdit({ ...leieObjekt, geometri: this.state.geometry });
    if (!isEditMode && onSave) onSave({ ...leieObjekt, geometri: this.state.geometry });
  };

  /**
   * Validation logic for the whole form.
   */
  globalFormValidator = (leieobjekt: any) => {
    if (
      leieobjekt.gyldigFra &&
      leieobjekt.gyldigTil &&
      moment(leieobjekt.gyldigTil).isBefore(moment(leieobjekt.gyldigFra))
    )
      return "Gyldig til dato må være etter gyldig fra dato";

    return true; // form won't submit until this function returns true
  };

  onMapEdited = (geometry: any) => {
    this.setState({ geometry: geometry });
  };

  renderSlimForm = () => {
    const { leieObjektTyper, vernTyper, eiendom } = this.props as any;
    const { leieObjekt } = this.state;
    return (
      <>
        <Row>
          <Col md={8} sm={12}>
            {leieObjektTyper.length > 0 && (
              <BymSelectInput controlId="leieObjektTypeId" rules={[required()]} label="*Type" items={leieObjektTyper} />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4}>
            <UgeDateInput
              controlId="gyldigFra"
              label="Gyldig fra"
              placeholder="dd.mm.åååå"
              utc
              viewDate={undefined}
              alignRight={undefined}
            />
          </Col>
          <Col md={4} sm={4}>
            <UgeDateInput
              controlId="gyldigTil"
              label="Gyldig til"
              placeholder="dd.mm.åååå"
              utc
              viewDate={undefined}
              alignRight={undefined}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <BymTextInput
              controlId="navn"
              placeholder="Navn på leieobjekt"
              label="*Navn på leieobjekt"
              rules={[required(), length(6, 56, false)]}
            />
          </Col>
          <Col md={12} sm={12}>
            <BymTextInput controlId="adresse2" label="Adresse" />
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={2}>
            <BymNumberInput controlId="byggeAr" label="Byggeår" rules={[length(4, 4, false)]} />
          </Col>
          <Col md={4} sm={5}>
            <BymTextInput controlId="bruksenhetNummer" label="Bruksenhetnummer" />
          </Col>
          <Col md={4} sm={5}>
            <BymNumberInput controlId="bygningsnummer" label="Bygningsnummer" />
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <BymTextInput controlId="gnr" label="Gnr" />
          </Col>
          <Col md={4} sm={6}>
            <BymTextInput controlId="bnr" label="Bnr" />
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <BymNumberInput controlId="bebygdAreal" label="Bebygd areal" />
          </Col>
          <Col md={4} sm={6}>
            <BymNumberInput controlId="bruksAreal" label="Bruksareal" />
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <BymNumberInput controlId="primarRom" label="Primærrom areal" />
          </Col>
          <Col md={4} sm={6}>
            <BymNumberInput controlId="sekundarRom" label="Sekundærrom areal" />
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <BymNumberInput controlId="stromMalernummer" label="Strømmåler ID" />
          </Col>
          <Col md={4} sm={6}>
            <BymNumberInput controlId="stromMalepunkt" label="Strømmåler målepunkt ID" />
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <BymNumberInput controlId="vannMalernummer" label="Vannmåler ID" />
          </Col>
          <Col md={4} sm={6}>
            <BymTextInput controlId="vannMalepunkt" label="Vannmåler målepunkt ID" />
          </Col>
          <Col md={8} sm={6}>
            {vernTyper.length > 0 && (
              <BymSelectInput controlId="vernTypeId" rules={[required()]} label="*Vernetype" items={vernTyper} />
            )}
          </Col>
        </Row>
        {eiendom && eiendom.visIPublikumPortal && (
          <Row>
            <Col>
              <br />
              <BymEditMap
                isOpen
                markers={
                  eiendom?.koordinater
                    ? [
                        {
                          kind: "geoJSON",
                          data: getGeoJSONFromPoint(eiendom.koordinater.x, eiendom.koordinater.y),
                          tooltipTitle: eiendom?.Navn,
                          showTooltip: true
                        }
                      ]
                    : []
                }
                onCreated={this.onMapEdited}
                editGeoJSON={leieObjekt.geometri as any}
                autoBounds
              />
            </Col>
          </Row>
        )}
      </>
    );
  };

  renderWideForm = () => {
    const { leieObjektTyper, vernTyper, eiendom } = this.props as any;
    const { leieObjekt } = this.state;
    return (
      <>
        <Row>
          <Col sm={12} md={6}>
            {leieObjektTyper.length > 0 && (
              <BymSelectInput controlId="leieObjektTypeId" rules={[required()]} label="*Type" items={leieObjektTyper} />
            )}
          </Col>
          <Col sm={12} md={6}>
            <Row>
              <Col sm={6}>
                <UgeDateInput
                  controlId="gyldigFra"
                  label="Gyldig fra"
                  placeholder="dd.mm.åååå"
                  utc
                  viewDate={undefined}
                  alignRight={undefined}
                />
              </Col>
              <Col sm={6}>
                <UgeDateInput
                  controlId="gyldigTil"
                  label="Gyldig til"
                  placeholder="dd.mm.åååå"
                  utc
                  viewDate={undefined}
                  alignRight={undefined}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <BymTextInput
              controlId="navn"
              placeholder="Navn på leieobjekt"
              label="*Navn på leieobjekt"
              rules={[required(), length(6, 56, false)]}
            />
          </Col>
          <Col sm={12} md={6}>
            <Row>
              <Col sm={6}>
                <BymNumberInput controlId="byggeAr" label="Byggeår" rules={[length(4, 4, false)]} />
              </Col>
              <Col sm={6}>
                <BymTextInput controlId="bruksenhetNummer" label="Bruksenhetnummer" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <BymTextInput controlId="adresse2" label="Adresse" />
          </Col>
          <Col sm={12} md={6}>
            <Row>
              <Col sm={6}>
                <BymNumberInput controlId="bygningsnummer" label="Bygningsnummer" />
              </Col>
              <Col sm={3}>
                <BymTextInput controlId="gnr" label="Gnr" rules={[]} />
              </Col>
              <Col sm={3}>
                <BymTextInput controlId="bnr" label="Bnr" rules={[]} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={3}>
            <BymNumberInput controlId="bebygdAreal" label="Bebygd areal" />
          </Col>
          <Col sm={6} md={3}>
            <BymNumberInput controlId="bruksAreal" label="Bruksareal" />
          </Col>
          <Col sm={6} md={3}>
            <BymNumberInput controlId="primarRom" label="Primærrom areal" />
          </Col>
          <Col sm={6} md={3}>
            <BymNumberInput controlId="sekundarRom" label="Sekundærrom areal" />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={3}>
            <BymNumberInput controlId="stromMalernummer" label="Strømmåler ID" />
          </Col>
          <Col sm={6} md={3}>
            <BymNumberInput controlId="stromMalepunkt" label="Strømmåler målepunkt ID" />
          </Col>
          <Col sm={6} md={3}>
            <BymNumberInput controlId="vannMalernummer" label="Vannmåler ID" />
          </Col>
          <Col sm={6} md={3}>
            <BymTextInput controlId="vannMalepunkt" label="Vannmåler målepunkt ID" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            {vernTyper.length > 0 && (
              <BymSelectInput controlId="vernTypeId" rules={[required()]} label="*Vernetype" items={vernTyper} />
            )}
          </Col>
        </Row>
        {eiendom && eiendom.visIPublikumPortal && (
          <Row>
            <Col>
              <BymEditMap
                isOpen
                markers={
                  eiendom?.koordinater
                    ? [
                        {
                          kind: "geoJSON",
                          data: getGeoJSONFromPoint(eiendom.koordinater.x, eiendom.koordinater.y),
                          tooltipTitle: eiendom?.Navn,
                          showTooltip: true
                        }
                      ]
                    : []
                }
                onCreated={this.onMapEdited}
                editGeoJSON={leieObjekt.geometri as any}
                autoBounds
              />
            </Col>
          </Row>
        )}
      </>
    );
  };

  render() {
    const { slim, onCancel } = this.props as any;
    const { isEditMode, leieObjekt } = this.state;
    return (
      <div className="leieobjekt-details leieobjekt-edit">
        <BymForm
          id="addLeieObjekt"
          onSubmit={this.saveLeieObjekt}
          globalValidator={this.globalFormValidator}
          values={leieObjekt}
        >
          {slim ? this.renderSlimForm() : this.renderWideForm()}
          <Row>
            <Col md={12} sm={12} style={{ marginTop: "20px" }}>
              {isEditMode && <BymResetButton text="Tilbakestill" />}
              {!isEditMode && (
                <Button bsStyle="link" onClick={onCancel}>
                  Avbryt
                </Button>
              )}
              <BymSubmitButton text={isEditMode ? "Oppdater" : "Legg til"} type="submit" />
            </Col>
          </Row>
        </BymForm>
      </div>
    );
  }
}
