import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { HeaderCell } from "../Common/Tables";
import Table from "../../containers/Common/SortableTableContainer";

const renderRow = ({ id, eiendomId, navn, typeNavn, Område, Kategori, Distrikt, eiendomNavn }) => (
  <tr className="eiendom-list-row" key={id}>
    <td>
      <Link to={`/leieobjekter/${id}`}>{navn}</Link>
    </td>
    <td>
      <Link to={`/eiendommer/${eiendomId}`}>{eiendomNavn}</Link>
    </td>
    <td>{Distrikt}</td>
    <td>{Område}</td>
    <td>{Kategori}</td>
    <td>{typeNavn}</td>
  </tr>
);

const LeieObjektList = ({ leieObjekter }) => (
  <Table id="kontrakt-liste" storeKey="leieobjekter" items={leieObjekter} renderRow={renderRow}>
    <HeaderCell cellKey="navn">Leieobjekt navn</HeaderCell>
    <HeaderCell cellKey="eiendomNavn">Eiendom</HeaderCell>
    <HeaderCell cellKey="Distrikt">Distrikt</HeaderCell>
    <HeaderCell cellKey="Område">Område</HeaderCell>
    <HeaderCell cellKey="Kategori">Kategori</HeaderCell>
    <HeaderCell cellKey="typeNavn">Type</HeaderCell>
  </Table>
);

LeieObjektList.propTypes = {
  leieObjekter: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      eiendomId: PropTypes.string,
      navn: PropTypes.string,
      eiendomNavn: PropTypes.string,
      typeNavn: PropTypes.string,
      Område: PropTypes.string,
      Distrikt: PropTypes.string
    })
  )
};

export default LeieObjektList;
