import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Spinner from "./Spinner";
import { setToastError } from "../../../actions/toastAction";

/**
 * Component that takes a promise as prop and renders a spinner while the promise is loading.
 * When the promise is finished loading, it renders its children instead
 */
class PromiseSpinner extends React.Component {
  state = {
    showSpinner: false,
    failed: false
  };

  componentWillReceiveProps(nextProps) {
    const { promise, onError = () => null } = nextProps;
    const { showSpinner, failed } = this.state;
    if (promise && !showSpinner && !failed) {
      this.setState({ showSpinner: true });
      promise
        .then(() => {
          this.setState({ showSpinner: false });
        })
        .catch(error => {
          onError(error);
          this.setState({ showSpinner: false, failed: true });
        });
    }
  }

  render() {
    const { showSpinner } = this.state;
    const { children, ...restProps } = this.props;
    if (!showSpinner) return children;

    return <Spinner {...restProps} />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onError: setToastError }, dispatch);
}

export default connect(null, mapDispatchToProps)(PromiseSpinner);
