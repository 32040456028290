import { UgerollerUnion } from "constants/auth";
import { isOneOfUserRolesIncludedInRolesList } from "./authTools";

interface ShowOnlyForUsersWith {
  roles: UgerollerUnion[];
}

export default function ShowOnlyForUsersWith({ roles, children }: React.PropsWithChildren<ShowOnlyForUsersWith>) {
  if (isOneOfUserRolesIncludedInRolesList(undefined, roles)) {
    return <>{children}</>;
  }
  return null;
}
