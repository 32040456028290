import { useState, useRef, useCallback, useEffect } from "react";
import { FeatureGroup, GeoJSON, useLeaflet } from "react-leaflet";
import L from "leaflet";
import BymMapEditControl, { EditDetails } from "./BymMapEditControl";
import "leaflet-draw/dist/leaflet.draw.css";

export interface BymMapEditLayerProps {
  editGeoJSON?: GeoJSON.FeatureCollection;
  autoBounds?: boolean;
  onEditChange?: (editDetails: EditDetails) => void;
  onCreated?: (geometry: any) => void;
  onEditorReady?: (editControl: any) => void;
}

const BymMapEditLayer = ({ editGeoJSON, autoBounds, onEditChange, onCreated, onEditorReady }: BymMapEditLayerProps) => {
  const { map } = useLeaflet();
  const editLayerRef = useRef<FeatureGroup>(null);
  const [showArea, setShowArea] = useState(false);
  const lineLengthRef = useRef<HTMLSpanElement>(null);
  const arealRef = useRef<HTMLSpanElement>(null);

  const addArealPopup = (layer: L.Layer) => {
    if (layer instanceof L.Polygon) {
      const latlng = (layer as L.Polygon).getLatLngs()[0] as L.LatLng[];
      const area = L.GeometryUtil.geodesicArea(latlng);
      layer.bindPopup(`<span><b>Total areal:</b> ${area.toFixed(1)} m</span>`);
    }
  };

  const calculateAreas = useCallback(
    (editDetails: EditDetails) => {
      if (lineLengthRef.current !== null) {
        lineLengthRef.current.innerHTML = `${editDetails.currentLineLength.toFixed(1)}`;
      }
      if (arealRef.current !== null) {
        arealRef.current.innerHTML = `${editDetails.currentArea.toFixed(0)}`;
      }
      if (onEditChange) {
        onEditChange(editDetails);
      }
    },
    [onEditChange]
  );

  useEffect(() => {
    if (editGeoJSON && autoBounds && map && editLayerRef.current) {
      const bounds = editLayerRef.current.leafletElement.getBounds();
      if (map && bounds && bounds.isValid()) {
        map.fitBounds(editLayerRef.current.leafletElement.getBounds().pad(0.3));
      }
    }
  }, [autoBounds, map, editGeoJSON]);

  return (
    <FeatureGroup ref={editLayerRef}>
      {showArea && (
        <div className="bym-map-area">
          <div>
            <strong>Nåværende linje lengde:</strong> <span ref={lineLengthRef}>0</span>m
          </div>
          <div>
            <strong>Nåværende areal:</strong> <span ref={arealRef}>0</span>
            &#13217;
          </div>
        </div>
      )}
      <BymMapEditControl
        onMounted={onEditorReady}
        onEditChange={calculateAreas}
        onEditStart={() => setShowArea(true)}
        onEditStop={() => setShowArea(false)}
        onDrawStart={() => setShowArea(true)}
        onDrawStop={() => setShowArea(false)}
        onCreated={(e: any) => {
          // addArealPopup(e.layer as L.Layer);
          if (onCreated) {
            onCreated(e.layer.toGeoJSON());
          }
        }}
        position="bottomleft"
      />
      {editGeoJSON && <GeoJSON data={editGeoJSON as any} onEachFeature={(_feature, layer) => addArealPopup(layer)} />}
    </FeatureGroup>
  );
};

export default BymMapEditLayer;
