import { map } from "lodash-es";
import { createSelector } from "reselect";
import makeGetAdvancedList from "./advancedListSelector";
import { obsKeysToString } from "../utils/utils";

const getLeieObjektIds = state => state.leieobjekter.items;
const getLeieObjektEntities = state => state.entities.leieObjekter;
const getEiendomEntities = state => state.entities.eiendommer;

const makeGetLeieObjekter = () =>
  createSelector([getLeieObjektIds, getLeieObjektEntities, getEiendomEntities], (ids, entities, eiendomEntities) => {
    const leieObjekter = map(ids, id => {
      let leieObjekt = entities[id];
      const eiendom = leieObjekt ? eiendomEntities[leieObjekt.eiendomId] : undefined;
      if (eiendom && leieObjekt) {
        leieObjekt = {
          ...leieObjekt,
          typeNavn: leieObjekt.leieObjektType ? leieObjekt.leieObjektType.navn : "",
          Område: eiendom.Område,
          Distrikt: eiendom.Distrikt,
          eiendomNavn: eiendom.Navn,
          Kategori: eiendom.Kategori,
          segmentId: eiendom.segmentId
        };
      }
      // eslint-disable-next-line no-underscore-dangle
      const _freetextSearchProperty = obsKeysToString(
        leieObjekt,
        ["navn", "eiendomNavn", "Distrikt", "Område", "Kategori", "typeNavn"],
        " "
      );
      return {
        ...leieObjekt,
        _freetextSearchProperty,
        typeNavn: leieObjekt && leieObjekt.leieObjektType ? leieObjekt.leieObjektType.navn : undefined
      };
    });
    return leieObjekter.filter(
      leieObjekt =>
        // only return leieObjekter that is connected to a eiendom
        leieObjekt.eiendomNavn
    );
  });

export default makeGetLeieObjekter;

// Filtered and sorted list of eiendommer
export const makeGetLeieObjektList = () => makeGetAdvancedList("leieobjekter", makeGetLeieObjekter());
