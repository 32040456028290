import React from "react";
import { get } from "lodash-es";
import { leieObjektShape } from "../../constants/proptypes";
import { getFormatedDate } from "../../utils/dateUtils";
import { DetailsView, DetailsItem, DetailsColumn } from "../Common/DetailsView";

const LeieObjektDetails = ({ leieObjekt }) => (
  <DetailsView>
    <DetailsColumn md={4} sm={6}>
      <DetailsItem label="Type" value={get(leieObjekt, "leieObjektType.navn", leieObjekt.leieObjektTypeId)} />
      <DetailsItem label="Adresse2" value={leieObjekt.adresse2} />
      <DetailsItem label="Bruksenhetnummer" value={leieObjekt.bruksenhetNummer} />
      <DetailsItem label="Bygningsnummer" value={leieObjekt.bygningsnummer} />
      <DetailsItem label="Gnr" value={leieObjekt.gnr} />
      <DetailsItem label="Bnr" value={leieObjekt.bnr} />
    </DetailsColumn>
    <DetailsColumn md={4} sm={6}>
      <DetailsItem label="Byggeår" value={leieObjekt.byggeAr} />
      <DetailsItem label="Bebygd areal" value={leieObjekt.bebygdAreal} />
      <DetailsItem label="Bruksareal" value={leieObjekt.bruksAreal} />
      <DetailsItem label="Verntype" value={get(leieObjekt, "vernType.navn", leieObjekt.vernTypeId)} />
      <DetailsItem label="Gyldig fra" value={getFormatedDate(leieObjekt.gyldigFra)} />
      <DetailsItem label="Gyldig til" value={getFormatedDate(leieObjekt.gyldigTil)} />
    </DetailsColumn>
    <DetailsColumn md={4} sm={6}>
      <DetailsItem label="Primærrom" value={leieObjekt.primarRom} />
      <DetailsItem label="Sekundarrom" value={leieObjekt.sekundarRom} />
      <DetailsItem label="Strømmålernummer" value={leieObjekt.stromMalernummer} />
      <DetailsItem label="Strømmålepunkt" value={leieObjekt.stromMalepunkt} />
      <DetailsItem label="Vannmålernummer" value={leieObjekt.vannMalernummer} />
      <DetailsItem label="Vannmålepunkt" value={leieObjekt.vannMalepunkt} />
    </DetailsColumn>
  </DetailsView>
);

LeieObjektDetails.propTypes = {
  leieObjekt: leieObjektShape
};

LeieObjektDetails.defaultProps = {
  leieObjekt: {}
};

export default LeieObjektDetails;
