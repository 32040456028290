import * as React from "react";
import PropTypes from "prop-types";
import { BymForm, BymSubmitButton, BymTextInput, required, length } from "components/Common/Forms";
import { Row, Col, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "../../services/axios";
import { GET_INDEKSREGULERINGER_URL, POST_INDEKSREGULERINGER_URL } from "../../constants/api";
import { getFormatedDate } from "../../utils/dateUtils";
import ConfirmDialog from "../Common/ConfirmDialog";
import { setToastSuccess } from "../../actions/toastAction";

function minMax(min, max) {
  return (value, id) => {
    if (value <= min) {
      return `${id} må være større enn ${min}`;
    }
    if (value > max) {
      return `${id}  kan være maximum  ${max}`;
    }
    return null;
  };
}

function decimal() {
  return (value, id) => (Number.isNaN(value - parseFloat(value)) ? `${id} må være et desimal tall (med '.')` : null);
}

class IndexReguleringAdmin extends React.Component {
  state = {
    indeksReguleringer: [],
    indeksReguleringToAdd: null,
    errorMessage: null
  };

  componentDidMount() {
    axios.get(GET_INDEKSREGULERINGER_URL()).then(result => {
      this.setState({ indeksReguleringer: result.data.result || [] });
    });
  }

  onConfirm = () => {
    const { indeksReguleringToAdd } = this.state;
    const { setToastSuccess: setSuccessMessage } = this.props;
    const ir = indeksReguleringToAdd;
    axios
      .post(POST_INDEKSREGULERINGER_URL(), ir)
      .then(() => {
        axios.get(GET_INDEKSREGULERINGER_URL()).then(result => {
          this.setState({
            indeksReguleringer: result.data.result || [],
            indeksReguleringToAdd: null
          });
          setSuccessMessage("Indeksregulering av pris på relevante kontrakter ble kjørt");
          document.getElementById("IndeksreguleringForm").reset();
        });
      })
      .catch(error => {
        this.setState({
          errorMessage: error.response.data.errorMessage,
          indeksReguleringToAdd: null
        });
      });
    return {};
  };

  addIndeksRegulering = ir => {
    this.setState({ indeksReguleringToAdd: ir });
  };

  cancel = () => {
    this.setState({ indeksReguleringToAdd: null });
  };

  render() {
    const { indeksReguleringToAdd, indeksReguleringer, errorMessage } = this.state;
    return (
      <React.Fragment>
        {indeksReguleringToAdd !== null && (
          <ConfirmDialog
            title="Kjør indeksregulering"
            message={
              <div>{`Er du sikker på at du vil kjøre indeksregulering på ${indeksReguleringToAdd.prosent} prosent?
            Dette vil endre priser på alle kontrakter. Bør testes godt før første indeksregulering i prod`}</div>
            }
            show
            onConfirm={this.onConfirm}
            onClose={this.cancel}
          />
        )}
        <Row>
          <Col sm={6}>
            <h4>Tidligere indeksreguleringer</h4>
            <table cellSpacing="10px">
              <thead>
                <th style={{ width: "150px" }}>Kjørt</th>
                <th>Prosent</th>
              </thead>
              <tbody>
                {indeksReguleringer.map(ir => (
                  <tr>
                    <td>{getFormatedDate(ir.opprettetDato)} </td>
                    <td>{ir.prosent} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
          <Col sm={6}>
            <h4>Kjør ny indeksregulering</h4>
            <BymForm onSubmit={this.addIndeksRegulering} id="IndeksreguleringForm">
              <BymTextInput
                style={{ maxWidth: "140px" }}
                hidelabel
                label="*Prosent"
                controlId="prosent"
                rules={[required(), decimal(), length(1, 5), minMax(0, 10)]}
              />
              <BymSubmitButton text="Kjør" />
            </BymForm>
            <br />
            {errorMessage && (
              <Alert bsStyle="danger">
                <h4>Det har oppstått en feil!</h4>
                <p>{errorMessage}</p>
              </Alert>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

IndexReguleringAdmin.propTypes = {
  setToastSuccess: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setToastSuccess }, dispatch);
}

export default connect(null, mapDispatchToProps)(IndexReguleringAdmin);
