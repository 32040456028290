import * as tabAction from "../actions/tabAction";

export default function tabReducer(state = {}, action) {
  switch (action.type) {
    case tabAction.SET_ACTIVE_TAB:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    default:
      return state;
  }
}
