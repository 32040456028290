import React from "react";
import { BymNumberInput, BymRadioButtonsInput, BymTextInput, required } from "components/Common/Forms";
import { Row, Col } from "react-bootstrap";
import UgeDateInput from "components/Common/Forms/UgeDateInput";
import BymCurrencyInput from "components/Common/Forms/BymCurrencyInput";

const FellesKostnader = () => {
  return (
    <>
      <Row>
        <Col md={8}>
          <br />
          <h4>*Felleskostnader</h4>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <BymRadioButtonsInput
            inline
            label="*Oppgi hvor ofte felleskostnader skal betales"
            items={[
              { id: "1", name: "Månedlig" },
              { id: "2", name: "Kvartalsvis" },
              { id: "3", name: "Halvårlig" },
              { id: "4", name: "Årlig" }
            ]}
            controlId="avtaleData.betalingTerminIdFelleskostnader"
            rules={[required()]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <BymRadioButtonsInput
            inline
            label="*Når skal felleskostnader faktureres"
            items={[
              { id: "501", name: "Forskudd" },
              { id: "502", name: "Etterskudd" }
            ]}
            controlId="avtaleData.faktureringTidspunktIdFelleskostnader"
            rules={[required()]}
          />
        </Col>
        <Col md={4}>
          <BymTextInput
            controlId="avtaleData.forfallFelleskostnader"
            label="*Forfall dag/dato felleskostnader"
            placeholder=""
            rules={[required()]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <BymNumberInput controlId="avtaleData.forskuddFelleskostnader" label="Forskudd" placeholder="sum  /kvm./år" />
        </Col>
        <Col md={3}>
          <BymTextInput
            controlId="avtaleData.felleskostnaderForskuddPeriode"
            label="*Forskudd fakturaperiode"
            placeholder="eks jan-mars"
            rules={[required()]}
          />
        </Col>
        <Col md={3}>
          <BymNumberInput controlId="avtaleData.arealFelleskostnader" label="Felleskostnader areal" />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <BymCurrencyInput controlId="avtaleData.akontoSum" label="À-kontobeløp" />
        </Col>
        <Col md={3}>
          <UgeDateInput
            controlId="avtaleData.akontoStartDato"
            label="*À-kontobeløp start dato"
            viewDate={new Date()}
            alignRight={false}
            alignTop
            placeholder=""
            rules={[required()]}
          />
        </Col>
        <Col md={3}>
          <BymTextInput
            controlId="avtaleData.akontoMottaker"
            label="*À-kontobeløp mottaker"
            placeholder="Legg inn kontonummer"
            rules={[required()]}
          />
        </Col>
      </Row>
    </>
  );
};

export default FellesKostnader;
