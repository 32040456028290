import { merge } from "lodash-es";
import * as vedleggAction from "../../actions/vedleggAction";
import * as bilderAction from "../../store/bilder/bilderAction";
import * as kommentarAction from "../../actions/kommentarAction";
import * as leieObjektAction from "../../actions/leieObjektAction";
import * as kontraktAction from "../../actions/kontraktAction";
import * as stateUtils from "../../utils/stateUtils";

export default function eiendommer(state = {}, action) {
  switch (action.type) {
    case kommentarAction.POST_KOMMENTARER_SUCCESS:
      return action.meta.eiendomId
        ? stateUtils.setWithArrayPush(state, `[${action.meta.eiendomId}].kommentarer`, action.payload.result)
        : state;
    case kommentarAction.DELETE_KOMMENTARER_SUCCESS:
      return action.meta.eiendomId
        ? stateUtils.setWithArrayPull(state, `[${action.meta.eiendomId}].kommentarer`, action.payload.result)
        : state;
    case vedleggAction.POST_VEDLEGG_SUCCESS:
      return stateUtils.setWithArrayPush(state, `[${action.meta.eiendomId}].vedlegg`, action.payload.result);

    case vedleggAction.DELETE_VEDLEGG_SUCCESS:
      return stateUtils.setWithArrayPull(state, `[${action.meta.eiendomId}].vedlegg`, action.payload.result);

    case bilderAction.POST_BILDER_SUCCESS:
      return stateUtils.setWithArrayPush(state, `[${action.meta.eiendomId}].bilder`, action.payload.result[0]);

    case bilderAction.DELETE_BILDER_SUCCESS:
      return stateUtils.setWithArrayPull(state, `[${action.meta.eiendomId}].bilder`, action.payload.result[0]);
    case leieObjektAction.POST_LEIEOBJEKT_SUCCESS:
      return stateUtils.setWithArrayPush(state, `[${action.meta.eiendomId}].leieObjekter`, action.payload.result);
    case kontraktAction.POST_KONTRAKT_SUCCESS:
      return stateUtils.setWithArrayPush(state, `[${action.meta.eiendomId}].kontrakter`, action.payload.result);
    default:
      if (action.payload && action.payload.entities && action.payload.entities.eiendommer) {
        return merge({}, state, action.payload.entities.eiendommer);
      }
      return state;
  }
}
