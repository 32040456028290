import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { BymForm, BymTextInput } from "components/Common/Forms";
import { getFormatedDate } from "../../../utils/dateUtils";

const ArkivInfoDialog = ({ arkivSak, onHide }) => {
  if (!arkivSak) return null;
  const values = {
    ...arkivSak,
    opprettetDato: getFormatedDate(arkivSak.opprettetDato)
  };
  return (
    <Modal onHide={onHide} show backdrop="static">
      <BymForm id="arkivInfoDialog" readOnly values={values}>
        <Modal.Header closeButton>
          <Modal.Title>Sak: {arkivSak ? arkivSak.sakNummer : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={10}>
              <BymTextInput controlId="offisiellTittel" label="Tittel" />
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <BymTextInput controlId="ansvarligPersonNavn" label="Ansvarlig" />
            </Col>
            <Col md={5}>
              <BymTextInput controlId="ansvarligEnhetNavn" label="Ansvarlig enhet" />
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <BymTextInput controlId="status" label="Status" />
            </Col>
            <Col md={5}>
              <BymTextInput controlId="opprettetDato" label="Opprettet" />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={e => {
              e.preventDefault();
              if (onHide) onHide();
            }}
            bsStyle="link"
          >
            Lukk
          </Button>
        </Modal.Footer>
      </BymForm>
    </Modal>
  );
};

ArkivInfoDialog.propTypes = {
  arkivSak: PropTypes.shape({}).isRequired,
  onHide: PropTypes.func.isRequired
};

export default ArkivInfoDialog;
