import { useState, useEffect } from "react";
import BymButton from "components/Common/Forms/BymButton";
import L from "leaflet";
import { ReactComponent as TegnAreal } from "./tegnareal.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as Question } from "./spørsmål.svg";
import BymMap, { BymMapProps } from "./BymMap";
import { BymMarkerProps } from "./BymMarker";
import { Glyphicon } from "react-bootstrap";
import "./BymEditMap.scss";

export interface BymEditMapProps extends BymMapProps {
  isOpen: boolean;
  readOnly?: boolean;
  onClosed?: () => void;
  onSave?: (geoJSON: any, adressestring?: string, adresse?: any) => void;
  onReset?: () => void;
  markers?: BymMarkerProps[];
}

const BymEditMap = ({
  isOpen,
  onClosed,
  onSave,
  onReset,
  markers,
  readOnly,
  editGeoJSON,
  ...mapProps
}: BymEditMapProps) => {
  const [geoJSON, setGeoJSON] = useState<any>();
  const [mapMarkers, setMapMarkers] = useState<BymMarkerProps[]>();
  const [mapEditControl, setMapEditControl] = useState<any>();
  const [isDrawing, setIsDrawing] = useState(false);
  const [showDrawingExplanation, setShowDrawingExplanation] = useState(true);

  const hasUnsavedData = () => !!geoJSON;

  const startDrawing = () => {
    if (mapEditControl) {
      mapEditControl._toolbars?.draw?._modes?.polygon?.handler?.enable();
      setIsDrawing(true);
      setTimeout(() => {}, 80);
    }
  };

  const stopDrawing = () => {
    if (mapEditControl) {
      mapEditControl._toolbars?.draw?._modes?.polygon?.handler?.disable();
      setIsDrawing(false);
    }
  };

  const resetDrawing = () => {
    if (mapEditControl) {
      mapEditControl._toolbars?.edit?._modes?.remove?.handler?.enable();
      mapEditControl._toolbars?.edit?._modes?.remove?.handler?.removeAllLayers();
      mapEditControl._toolbars?.edit?._modes?.remove?.handler?.disable();
      setGeoJSON(undefined);
      stopDrawing();
    }
  };

  useEffect(() => {
    if (editGeoJSON) {
      setGeoJSON(editGeoJSON);
    }
    if (markers && !mapMarkers) {
      setMapMarkers(markers);
    }
  }, [markers, markers?.length, editGeoJSON, mapMarkers]);

  return (
    <div className="bymmap-editable">
      <BymMap
        maxZoom={21}
        showTooltip
        {...mapProps}
        onEditorReady={editControl => setMapEditControl(editControl)}
        bounds={geoJSON ? L.geoJSON(geoJSON).getBounds().pad(0.3) : undefined}
        editMode
        touchExtend={false}
        markers={mapMarkers}
        editGeoJSON={geoJSON}
        zoomControlPosition="bottomright"
        onCreated={(createdGeoJSON: any) => {
          const featureCollection: any = geoJSON ?? {
            type: "FeatureCollection",
            features: []
          };
          featureCollection.features.push(createdGeoJSON);
          setGeoJSON(featureCollection);
          setIsDrawing(false);
          mapProps.onCreated?.(featureCollection);
        }}
      />
      {showDrawingExplanation && (
        <div className="bymmap-explanation">
          <div className="bymmap-explanation-dialog">
            <div className="bymmap-explanation-dialog-header">
              Slik tegner du
              <Glyphicon glyph="remove" onClick={() => setShowDrawingExplanation(false)} />
            </div>
            <div className="bymmap-explanation-dialog-body">
              <ul>
                <li>
                  Klikk på &quot;tegn areal&quot;
                  <TegnAreal style={{ width: "25px", height: "25px", margin: "0px 7px" }} /> for å begynne å tegne
                  punkter..
                </li>
                <li>
                  Dobbelklikk når du plasserer siste punkt for å fullføre arealet (Eller trykk første punkt på nytt)
                </li>
                <li>Du kan tegne opp flere områder</li>
                <li>Hvis du tegner feil kan du start på nytt ved å trykke søppelkassen</li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <div className="bymmap-edit-buttons">
        <BymButton
          disabled={isDrawing}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            startDrawing();
          }}
        >
          <TegnAreal />
        </BymButton>
        <BymButton
          disabled={!hasUnsavedData()}
          onClick={() => {
            resetDrawing();
          }}
        >
          <Trash />
        </BymButton>
        <BymButton
          disabled={showDrawingExplanation}
          onClick={() => setShowDrawingExplanation(true)}
          className="bym-button-help"
        >
          <Question />
        </BymButton>
      </div>
    </div>
  );
};

export default BymEditMap;
