import { schema } from "normalizr";

// Vedlegg
export const vedleggSchema = new schema.Entity("vedlegg", {}, { idAttribute: "filId" });
export const vedleggListSchema = new schema.Array(vedleggSchema);

// Bilder
export const bilderSchema = new schema.Entity("bilder", {}, { idAttribute: "filId" });
export const bilderListSchema = new schema.Array(bilderSchema);

// LeieObjekt
export const leieObjektSchema = new schema.Entity("leieObjekter");
export const leieObjektListSchema = new schema.Array(leieObjektSchema);

// Faktura
export const fakturaSchema = new schema.Entity("fakturaer");
export const fakturaListSchema = new schema.Array(fakturaSchema);
export const createFakturaListSchema = new schema.Entity("createFakturaer", { fakturaer: fakturaListSchema });

// Kommentar
export const kommentarSchema = new schema.Entity("kommentarer");
export const kommentarListSchema = new schema.Array(kommentarSchema);

// Kontrakt
export const kontraktSchema = new schema.Entity("kontrakter", {
  fakturaer: fakturaListSchema,
  kommentarer: kommentarListSchema
});
export const kontraktListSchema = new schema.Array(kontraktSchema);

// Logg
export const loggSchema = new schema.Entity("logg");
export const loggListSchema = new schema.Array(loggSchema);

// Brukere
export const brukerSchema = new schema.Entity("brukere", {}, { idAttribute: "brukerId" });
export const brukerListSchema = new schema.Array(brukerSchema);

// Eiendom
export const eiendomSchema = new schema.Entity("eiendommer", {
  kontrakter: kontraktListSchema,
  vedlegg: vedleggListSchema,
  bilder: bilderListSchema,
  kommentarer: kommentarListSchema,
  leieObjekter: leieObjektListSchema,
  logg: loggListSchema
});
export const eiendomListSchema = new schema.Array(eiendomSchema);

// FRONT Kunde
export const kundeSchema = new schema.Entity("kunde");
