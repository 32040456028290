import React, { useContext, useReducer, useRef, HTMLAttributes, PropsWithChildren } from "react";
import { union, includes, filter, map } from "lodash-es";
import { produce } from "immer";
import "./VerticalTabs.css";
import BymButton from "../Forms/BymButton";

const verticalTabsContextInitialState = {
  activeTabIndex: 0,
  errorTabs: [] as number[]
};

type VerticalTabsState = typeof verticalTabsContextInitialState;
type VerticalTabsAction =
  | { type: "setactivetab"; payload: { index: number } }
  | { type: "shownexttab"; payload: {} }
  | { type: "sethaserror"; payload: { index?: number } }
  | { type: "removehaserror"; payload: { index?: number } };

const verticalTabsContextReducer = produce((draft: VerticalTabsState, action: VerticalTabsAction): void => {
  switch (action.type) {
    case "setactivetab":
      draft.activeTabIndex = action.payload.index;
      break;
    case "sethaserror":
      if (action.payload.index !== undefined) {
        draft.errorTabs = union(draft.errorTabs, [action.payload.index]);
      }
      break;
    case "removehaserror":
      if (action.payload.index !== undefined) {
        draft.errorTabs = filter(draft.errorTabs, f => f !== action.payload.index);
      }
      break;
    case "shownexttab":
      draft.activeTabIndex += 1;
      break;
    default:
  }
}, verticalTabsContextInitialState);

type VerticalTabsContextType = {
  state: ReturnType<typeof verticalTabsContextReducer>;
  dispatch: React.Dispatch<VerticalTabsAction>;
};

const VerticalTabsContext = React.createContext<VerticalTabsContextType>({
  state: verticalTabsContextInitialState,
  dispatch: () => null
});

const VerticalTabs = ({ children = [], ...rest }: HTMLAttributes<HTMLDivElement>) => {
  const [state, dispatch] = useReducer(verticalTabsContextReducer, verticalTabsContextInitialState);
  return (
    <VerticalTabsContext.Provider value={{ state, dispatch }}>
      <div className="vertical-tabs" {...rest}>
        <div className="tabs-column">
          {map(React.Children.toArray(children), (child, index) => (
            <VerticalTab
              title={(child as JSX.Element)?.props?.title}
              index={index}
              disabled={(child as JSX.Element)?.props?.disabled}
            />
          ))}
        </div>
        <div className="content-column">
          {map(React.Children.toArray(children), (child, index) => (
            <VerticalTabContent
              {...(child as JSX.Element)?.props}
              index={index}
              visible={index === state.activeTabIndex}
            />
          ))}
        </div>
      </div>
    </VerticalTabsContext.Provider>
  );
};

const VerticalTab = ({ title, index, disabled = false }: { title: string; index: number; disabled?: boolean }) => {
  const { state, dispatch } = useContext(VerticalTabsContext);
  const tabClassName = state.activeTabIndex === index ? "vertical-tabs-tab selected" : "vertical-tabs-tab";
  const hasError = state.errorTabs.includes(index);
  return (
    <a
      title={title}
      className={`${tabClassName} ${hasError ? "has-error" : ""} ${disabled ? "disabled" : ""}`}
      href=""
      onClick={e => {
        if (!disabled) {
          dispatch({ type: "setactivetab", payload: { index } });
        }
        e.preventDefault();
      }}
    >
      <span className="section-number">{index + 1}</span>
      {title}
    </a>
  );
};

type VerticalTabContentProps = {
  title: string;
  index?: number;
  visible?: boolean;
  disabled?: boolean;
  showNextButton?: boolean;
};

const VerticalTabContent = ({
  children,
  title,
  index,
  visible,
  showNextButton = true
}: PropsWithChildren<VerticalTabContentProps>) => {
  const { state, dispatch } = useContext(VerticalTabsContext);
  const contentRef = useRef<HTMLDivElement>(null);

  if (index !== undefined) {
    const hasContextError = includes(state.errorTabs, index);
    const errorFields = contentRef.current?.querySelectorAll(".has-error");
    const hasFormError = errorFields && errorFields.length > 0;
    if (!hasContextError && hasFormError) {
      dispatch({ type: "sethaserror", payload: { index } });
    } else if (hasContextError && !hasFormError) {
      dispatch({ type: "removehaserror", payload: { index } });
    }
  }
  return (
    <div ref={contentRef} className={`vertical-tabs-content ${visible ? "visible" : ""}`}>
      <div>
        <h3>{title}</h3>
        {children}
      </div>
      {showNextButton && (
        <div className="vertical-tabs-next-button">
          <BymButton
            bsStyle="bym-positive"
            onClick={() => dispatch({ type: "shownexttab", payload: {} })}
            text="Neste"
          />
        </div>
      )}
    </div>
  );
};

export { VerticalTabs, VerticalTabContent, VerticalTabsContext };
