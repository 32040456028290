import { Component } from "react";
import { split } from "lodash-es";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tabs, Tab, TabPane, TabContent, TabContainer, Button } from "react-bootstrap";
import LeieObjektSection from "components/LeieObjekt/LeieObjektSection";
import { getKoder as getKoderAction } from "actions/koderAction";
import AddEiendomDialog from "components/Eiendom/AddEiendomDialog";
import Page from "../../components/Layout/Page";
import EiendomDetails from "../../components/Eiendom/EiendomDetails";
import * as toastAction from "../../actions/toastAction";
import { getEiendom as getEiendomAction, updateEiendom as updateEiendomAction } from "../../actions/eiendomAction";
import getEiendomSelector from "../../selectors/eiendomSelector";
import VedleggSection from "../Vedlegg/VedleggSectionContainer";
import KontraktSection from "components/Kontrakt/KontraktSection";
import EiendomMapSection from "../../components/Map/EiendomMapSection";
import BilderSection from "../Bilder/BilderSectionContainer";
import KommentarSectionContainer from "../Kommentar/KommentarSectionContainer";
import LoggSection from "../Logg/LoggSectionContainer";
import { Eiendom } from "interfaces";
import ShowOnlyForUsersWith from "auth/ShowOnlyFor";

interface EiendomPageProps {
  eiendomId: string;
  eiendom: Eiendom;
  getEiendom: any;
  updateEiendom: any;
  setToastSuccess: any;
  getKoder: any;
}

class EiendomPage extends Component<EiendomPageProps, any> {
  state = {
    selectedTab: 1,
    historyNeedsReload: false,
    isEditEiendomDialogVisible: false
  };

  componentDidMount() {
    const { eiendomId, getEiendom, getKoder } = this.props;
    getEiendom(eiendomId);

    // Pre-fetch koder
    getKoder("kontrakttyper");
    getKoder("betalingterminer");
    getKoder("faktureringTyper");
    getKoder("faktureringTidspunkter");
    getKoder("kontraktStatuser");
    getKoder("kostSteder");
    getKoder("kontoNummere");
    getKoder("prisTyper");
    getKoder("kontraktMaler");
  }

  toggleEditEiendomClick = (isOpen: boolean) => {
    this.setState({ isEditEiendomDialogVisible: isOpen });
  };

  onLogUndo = () => {
    const { eiendomId, getEiendom } = this.props;
    // reload eiendom after undo to show undeleted items
    getEiendom(eiendomId);
  };

  onSectionUpdated = () => {
    const { eiendomId, getEiendom } = this.props;
    const { selectedTab } = this.state;

    // if history tab is open reload eiendom after change to get updated history/log
    if (selectedTab === 2) {
      getEiendom(eiendomId);
      this.setState({ historyNeedsReload: false });
    } else {
      this.setState({ historyNeedsReload: true });
    }
  };

  onTabSelected = (eventKey: any) => {
    const { eiendomId, getEiendom } = this.props;
    const { historyNeedsReload } = this.state;

    // if we clicked history/log and there has been changes, reload eiendom to get updated history
    if (eventKey === 2 && historyNeedsReload) {
      getEiendom(eiendomId);
      this.setState({ historyNeedsReload: false });
    }
    this.setState({ selectedTab: eventKey });
  };

  onUpdateEiendom = async (updatedEiendom: any) => {
    const { eiendomId, updateEiendom, setToastSuccess } = this.props;
    await updateEiendom(eiendomId, updatedEiendom);

    this.toggleEditEiendomClick(false);
    setToastSuccess("Eiendommen ble oppdatert");
  };

  render() {
    const { eiendomId, eiendom } = this.props;
    const { isEditEiendomDialogVisible } = this.state;

    const subtitle = eiendom != null ? eiendom.Navn : "laster...";
    return (
      <Page title={subtitle} subtitle={""} className={""}>
        <>
          <ShowOnlyForUsersWith roles={["UGE-admin", "UGE-bruker"]}>
            <Button
              id="editButton"
              className="pull-right"
              bsStyle="bym-standard"
              type="button"
              onClick={() => this.toggleEditEiendomClick(true)}
              style={{ marginTop: "-44px" }}
            >
              Rediger
            </Button>

            {eiendom && isEditEiendomDialogVisible && (
              <AddEiendomDialog
                eiendomsdata={
                  {
                    navn: eiendom.Navn,
                    segmentId: eiendom.segment?.id,
                    adresse: eiendom.Adresse,
                    kategori: eiendom.Kategori,
                    postnummer: eiendom.Postnummer,
                    poststed: eiendom.Poststed,
                    kommune: eiendom.Kommune,
                    distrikt: eiendom.Distrikt,
                    omrade: eiendom.Område,
                    gnr: split(eiendom.Gnr_Bnr, "/")[0],
                    bnr: split(eiendom.Gnr_Bnr, "/")[1],
                    anleggsnummer: eiendom.Anleggsnummer,
                    prosjektnummer: eiendom.Prosjektnummer,
                    vann: eiendom.Vann,
                    koordinater:
                      eiendom.koordinater && eiendom.koordinater.x !== 0 && eiendom.koordinater.y !== 0
                        ? eiendom.koordinater
                        : undefined,
                    visIPublikumPortal: eiendom.visIPublikumPortal,
                    arkivSakNummer: eiendom.arkivSakNummer
                  } as any
                }
                show={isEditEiendomDialogVisible}
                onClose={() => this.toggleEditEiendomClick(false)}
                onSubmit={this.onUpdateEiendom as any}
              />
            )}
          </ShowOnlyForUsersWith>

          <EiendomDetails eiendom={eiendom} />
          <LeieObjektSection eiendomId={eiendomId} eiendom={eiendom} sectionUpdated={this.onSectionUpdated} />
          <VedleggSection eiendomId={eiendomId} sectionUpdated={this.onSectionUpdated} />
          <KontraktSection eiendomId={eiendomId} eiendom={eiendom} sectionUpdated={this.onSectionUpdated} />
          <BilderSection eiendomId={eiendomId} sectionUpdated={this.onSectionUpdated} />
          <EiendomMapSection eiendom={eiendom} />
          <TabContainer id="eiendom-tabs-container">
            <Tabs id="eiendom-tabs" onSelect={this.onTabSelected}>
              <Tab eventKey={1} title="Kommentarer" />
              <Tab eventKey={2} title="Historikk" />
              <TabContent>
                <TabPane eventKey={1}>
                  <KommentarSectionContainer
                    eierType="eiendommer"
                    eierId={eiendomId}
                    sectionUpdated={this.onSectionUpdated}
                    allowEdit
                  />
                </TabPane>
                <TabPane eventKey={2}>
                  <LoggSection eiendomId={eiendomId} onUndo={this.onLogUndo} />
                </TabPane>
              </TabContent>
            </Tabs>
          </TabContainer>
        </>
      </Page>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const eiendomId = ownProps.match.params.id;
  return {
    eiendomId,
    eiendom: getEiendomSelector(state, eiendomId)
  };
};

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      getEiendom: getEiendomAction,
      updateEiendom: updateEiendomAction,
      getKoder: getKoderAction,
      ...toastAction
    } as any,
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EiendomPage);
