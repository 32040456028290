import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Row, Col, Tabs, Tab, TabContent, TabContainer } from "react-bootstrap";
import { BymForm, BymSelectInput } from "components/Common/Forms";
import { withCookies } from "react-cookie";

import { getFakturaer } from "actions/fakturaAction";
import { setFilter } from "actions/advancedListAction";
import { getFakturaList } from "selectors/fakturaerSelector";

import { routerMatchShape, routerHistoryShape, fakturaShape } from "constants/proptypes";
import { isProduction } from "constants/environment";

import createPaginationControl from "containers/Common/createPaginationControl";
import createColumnFilter from "containers/Common/createColumnFilter";

import Page from "components/Layout/Page";
import FakturaList from "components/Faktura/FakturaList";
import Filters from "components/Common/Tables/Filters";
import PromiseSpinner from "components/Common/Spinner/PromiseSpinner";
import BymImageButton from "components/Common/Forms/BymImageButton";

import { getMonths } from "utils/dateUtils";

import iconLeft from "../../images/pil-venstre.svg";
import iconRight from "../../images/pil-høyre.svg";

const PaginationControl = createPaginationControl("fakturaer", getFakturaList);
const FakturaColumnFilter = createColumnFilter("fakturaer", getFakturaList);

class FakturaListPage extends Component {
  state = {
    spinnerPromise: undefined,
    fakturaType: "faktura"
  };

  componentDidMount() {
    const { match, history, setFilter } = this.props;
    setFilter("fakturaer", "fakturaType", ["faktura"]);

    let date;
    if (match.params.year && match.params.month) {
      date = {
        ar: match.params.year + "",
        maned: match.params.month + ""
      };
    } else {
      const currentDate = moment();
      date = {
        ar: currentDate.year() + "",
        maned: currentDate.month() + 1 + ""
      };

      history.push(`/okonomi/${date.ar}/${date.maned}`);
    }

    const promise = this.getFakturaer(this.props);
    this.setState({ spinnerPromise: promise });
  }

  componentWillUnmount() {
    const { setFilter } = this.props;

    // Reset the filters that were set on the page
    ["fakturaType", "kontrakt.faktureringType.navn", "fakturaStatus.navn"].forEach(filterKey =>
      setFilter("fakturaer", filterKey, [])
    );
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props;
    if (!nextProps.match.params.year || !nextProps.match.params.month) {
      const currentDate = moment();
      nextProps.history.push(`/okonomi/${currentDate.year()}/${currentDate.month() + 1}`);
      return;
    }

    // if we have changed year or month reload fakturaer for the new year/month
    if (
      !match ||
      nextProps.match.params.year !== match.params.year ||
      nextProps.match.params.month !== match.params.month
    ) {
      const promise = this.getFakturaer(nextProps);
      this.setState({ spinnerPromise: promise });
    }
  }

  onFormChange = (propertyName, values) => {
    const { history } = this.props;

    if ((propertyName === "ar" || propertyName === "maned") && values.ar && values.maned) {
      history.push(`/okonomi/${values.ar}/${values.maned}`);
    }

    if (
      (propertyName === "previous" && this.canGoBack(values)) ||
      (propertyName === "next" && this.canGoForward(values))
    ) {
      const newMoment = moment({ year: values.ar, month: values.maned - 1 }).add(propertyName === "next" ? 1 : -1, "M");

      const newDate = {
        ar: newMoment.year() + "",
        maned: newMoment.month() + 1 + ""
      };

      history.push(`/okonomi/${newDate.ar}/${newDate.maned}`);

      return newDate;
    }

    return undefined;
  };

  canGoBack = values => {
    return values.maned !== "1" || values.ar !== this.getSelectableYears().slice(-1)[0].value;
  };

  canGoForward = values => {
    return values.maned !== "12" || values.ar !== this.getSelectableYears()[0].value;
  };

  getSelectableYears = () => {
    const yearsToAdd = isProduction() ? 0 : 1; // allow to generate faktura in the future in dev/test environments
    let currentYear = moment().add(1, "M").add(yearsToAdd, "y").year();
    const years = [];
    while (currentYear >= 2018) {
      years.push({ name: currentYear.toString(), value: currentYear.toString() });
      currentYear -= 1;
    }
    return years;
  };

  getFakturaer = props => {
    const segment = props.cookies.get("uge_segment");
    const currentDate = moment();

    const year = props.match.params.year || currentDate.year();
    const month = props.match.params.month || currentDate.month() + 1;
    return props.getFakturaer(year, month, segment);
  };

  selectTab = filterValue => {
    this.props.setFilter("fakturaer", "fakturaType", [filterValue]);
    this.setState({ fakturaType: filterValue });
  };

  render() {
    const { fakturaer, match } = this.props;
    const { spinnerPromise, fakturaType } = this.state;
    const values = {
      maned: match.params.month,
      ar: match.params.year
    };

    return (
      <Page title="Økonomi">
        <TabContainer id="kontrakt-tabs-container" style={{ paddingTop: "15px" }}>
          <Tabs id="kontrakt-tabs" onSelect={this.selectTab}>
            <Tab eventKey="faktura" title="Fakturaer" />
            <Tab eventKey="kreditnota" title="Kreditnotaer" />
            <TabContent>
              <Row>
                <Col md={3}>
                  <Filters style={{ borderBottom: "0px" }}>
                    <FakturaColumnFilter id="typeFilter" title="Type" filterKey="kontrakt.faktureringType.navn" />
                    <FakturaColumnFilter id="statusFilter" title="Status" filterKey="fakturaStatus.navn" />
                  </Filters>
                </Col>
                <Col md={9}>
                  <BymForm id="fakturaListe" onChange={this.onFormChange} values={values}>
                    <Row>
                      <Col md={2}>
                        <div style={{ top: "16px", position: "absolute", width: "90%" }}>
                          <BymSelectInput hidelabel controlId="ar" items={this.getSelectableYears()} />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div style={{ top: "16px", position: "absolute", width: "70%" }}>
                          <BymSelectInput hidelabel controlId="maned" items={getMonths()} />
                        </div>
                        <div className="pagination-control" style={{ position: "absolute", top: "22px", right: 0 }}>
                          <BymImageButton
                            controlId="previous"
                            src={iconLeft}
                            alt="Forrige"
                            buttonStyle={{ padding: "0 8px", cursor: this.canGoBack(values) ? "pointer" : "default" }}
                            buttonClass="pc-space-large"
                          />
                          <BymImageButton
                            controlId="next"
                            src={iconRight}
                            alt="Neste"
                            buttonStyle={{
                              padding: "0 8px",
                              cursor: this.canGoForward(values) ? "pointer" : "default"
                            }}
                            buttonClass="pc-space-small"
                          />
                          {/* <button
                            className="pc-space-large"
                            onClick={this.onArrow(-1)}
                            disabled={!this.canGoBack}
                            >
                            <Image src={iconLeft} />
                          </button>
                          <button
                            className="pc-space-small"
                            onClick={this.onArrow(1)}
                            disabled={!this.canGoForward}
                            >
                            <Image src={iconRight} />
                          </button> */}
                        </div>
                      </Col>
                    </Row>
                  </BymForm>
                </Col>
              </Row>
              <Row style={{ position: "relative" }}>
                <Col md={12}>
                  <PromiseSpinner spinnerStyle="page-spinner" text="Laster fakturaer" promise={spinnerPromise}>
                    <PaginationControl storeKey="fakturaer" />
                    <FakturaList fakturaer={fakturaer} fakturaType={fakturaType} />
                  </PromiseSpinner>
                </Col>
              </Row>
            </TabContent>
          </Tabs>
        </TabContainer>
      </Page>
    );
  }
}

const mapStateToProps = state => ({ fakturaer: getFakturaList(state) });

const mapDispatchToProps = dispatch => bindActionCreators({ getFakturaer, setFilter }, dispatch);

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(FakturaListPage));

FakturaListPage.propTypes = {
  fakturaer: PropTypes.arrayOf(fakturaShape).isRequired,
  match: routerMatchShape.isRequired,
  history: routerHistoryShape.isRequired
};
