import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setFilter } from "../../actions/advancedListAction";
import Filter from "../../components/Common/Tables/Filter";
import makeGetFilterItems from "../../selectors/columnFilterSelector";

export default function createColumnFilter(storeKey, getEntities) {
  const makeMapStateToProps = () => {
    const getFilterItems = makeGetFilterItems(getEntities);

    const mapStateToProps = (state, { filterKey }) => {
      const advList = state[storeKey];
      return {
        items: getFilterItems(state, filterKey),
        filter: advList.filters[filterKey] || []
      };
    };
    return mapStateToProps;
  };

  const mapDispatchToProps = dispatch => bindActionCreators({ setFilter }, dispatch);

  const mergeProps = (stateProps, dispatchProps, { filterKey, ...ownProps }) => ({
    ...ownProps,
    ...stateProps,
    onChange: filter => dispatchProps.setFilter(storeKey, filterKey, filter)
  });

  return connect(makeMapStateToProps, mapDispatchToProps, mergeProps)(Filter);
}
