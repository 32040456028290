import * as leieObjektAction from "../actions/leieObjektAction";

const INITIAL_STATE = [];

export default function leieObjektReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case leieObjektAction.GET_LEIEOBJEKTER_SUCCESS:
      return action.payload.result;
    default:
      return state;
  }
}
