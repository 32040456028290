import React from "react";
import { FormContextTypes } from "components/Common/Forms";
import { JsonObject } from "type-fest";

type BymFormConditionalProps<T = JsonObject> = {
  children: React.ReactNode;
  comparator: (formValues: T) => boolean;
};

const BymFormConditional = <T extends unknown = JsonObject>(
  { children, comparator }: BymFormConditionalProps<T>,
  { form }: any
): JSX.Element => (comparator(form.values) ? <>{children}</> : <></>);

BymFormConditional.contextTypes = FormContextTypes;

export default BymFormConditional;
