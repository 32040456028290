import { useState } from "react";
import { Panel, Image, Button, Glyphicon } from "react-bootstrap";
import "./PageSection.css";
import utvidIcon from "../../images/utvid.svg";

interface PageSectionProps {
  title: string | JSX.Element;
  addText?: string;
  onAdd?: any;
  mountOnEnter?: boolean;
  children?: any;
  buttonsComponent?: any;
  showButton?: boolean;
  [key: string]: any;
}

const PageSection = ({
  title,
  addText,
  onAdd,
  mountOnEnter = false,
  showButton = true,
  children,
  buttonsComponent,
  ...rest
}: PageSectionProps) => {
  const [expanded, setExpanded] = useState(false);

  const renderHeader = ({ title, addText, onAdd }: PageSectionProps) => {
    return (
      <div className="page-section-header clearfix" {...rest}>
        <div className="pull-right">
          {buttonsComponent && buttonsComponent}
          {addText && showButton && (
            <Button
              bsStyle="bym-standard"
              onClick={e => {
                e.stopPropagation();
                onAdd();
              }}
            >
              <Glyphicon glyph="plus" />
              {addText}
            </Button>
          )}
        </div>
        <Image className="collapse-icon" src={utvidIcon} />
        <h1>{title}</h1>
      </div>
    );
  };

  return (
    <Panel
      className="page-section"
      header={renderHeader({ title, addText, onAdd })}
      collapsible
      expanded={expanded}
      onSelect={() => setExpanded(!expanded)}
    >
      {(mountOnEnter !== true || expanded) && <div className="page-section-body">{children}</div>}
    </Panel>
  );
};

export default PageSection;
