import { map, orderBy } from "lodash-es";
import createSelector from "re-reselect";

const eiendomSelector = (state, eiendomId) => state.entities.eiendommer[eiendomId];
const loggSelector = state => state.entities.logg;

export default createSelector(
  eiendomSelector,
  loggSelector,
  (eiendom, logg) =>
    eiendom &&
    orderBy(
      map(eiendom.logg, id => logg[id]),
      "opprettetDato",
      "desc"
    )
)((state, eiendomId) => eiendomId);
