import classnames from "classnames";
import React, { Component } from "react";
import { Checkbox as BSCheckbox, Image } from "react-bootstrap";
import PropTypes from "prop-types";

import "./Checkbox.css";
import checkIcon from "../../images/avkrysning_ja.svg";
import uncheckIcon from "../../images/avkrysning_nei.svg";

class Checkbox extends Component {
  static propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
  };

  render() {
    const { className, checked, onChange, ...rest } = this.props;
    return (
      <BSCheckbox className={classnames(className, "common-checkbox")} checked={checked} onChange={onChange} {...rest}>
        <Image src={this.props.checked === true ? checkIcon : uncheckIcon} />
        {this.props.children}
      </BSCheckbox>
    );
  }
}

export default Checkbox;
