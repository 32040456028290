import { Kontrakt, KontraktStatusId } from "interfaces";
import moment from "moment";

export const blirFakturert = (kontrakt: Kontrakt) =>
  kontrakt.kontraktStatusId === KontraktStatusId.UnderBehandling ||
  kontrakt.kontraktStatusId === KontraktStatusId.NullKontrakt ||
  kontrakt.kontraktStatusId === KontraktStatusId.OppsagtKontrakt ||
  kontrakt.kontraktStatusId === KontraktStatusId.UtgattKontrakt
    ? false
    : true;

export const expiresSoon = (kontrakt: Kontrakt) => {
  const soon = moment().add(3, "M");
  const newUtlopDato = moment(kontrakt.utlopDato);
  return newUtlopDato ? newUtlopDato.isBefore(soon) : false;
};
