import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { BymForm, BymTextInput } from "components/Common/Forms";

import searchIcon from "../../../images/search.svg";
import "./FreetextFilter.css";

class FreetextFilter extends PureComponent {
  state = {
    initialValue: {}
  };

  searchTimeout = null;

  componentDidMount() {
    const { onChange, filter } = this.props;
    if (filter && filter.length > 0) {
      onChange(filter);
      this.setState({ initialValue: { searchText: filter } });
    } else {
      onChange(undefined);
    }
    if (this.nameInput) {
      this.nameInput.querySelector("input").focus();
    }
  }

  onSearchChange = (_controlId, { searchText }) => {
    clearTimeout(this.searchTimeout);
    const { onChange } = this.props;
    if (searchText && searchText.length > 1) {
      this.searchTimeout = setTimeout(() => {
        onChange(searchText);
      }, 400);
    } else {
      onChange(undefined);
    }
  };

  render() {
    const { initialValue } = this.state;
    return (
      <div
        className="freetext-filter"
        ref={input => {
          this.nameInput = input;
        }}
      >
        <BymForm
          onSubmit={this.addIndeksRegulering}
          values={initialValue}
          {...this.props}
          onChange={this.onSearchChange}
        >
          <BymTextInput placeholder="Filtrer på fritekst" hidelabel controlId="searchText" />
          <div className="freetext-searchicon">
            <img src={searchIcon} alt="" />
          </div>
        </BymForm>
      </div>
    );
  }
}

FreetextFilter.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  filter: PropTypes.oneOf([PropTypes.string, PropTypes.array, ""])
};

export default FreetextFilter;
