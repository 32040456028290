import * as React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import LeieObjektEditor from "./LeieObjektEditor";
import "./AddLeieObjektDialog.css";

const AddLeieObjektDialog = ({ show, onHide = () => {}, ...rest }) => (
  <Modal
    onHide={onHide}
    show={show}
    bsSize="large"
    dialogClassName="leieobjekt-dialog"
    backdrop="static"
    allowFullScreen={false}
  >
    <Modal.Header closeButton>
      <Modal.Title>Legg til leieobjekt</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <LeieObjektEditor {...rest} onCancel={onHide} slim />
      <br />
    </Modal.Body>
  </Modal>
);

AddLeieObjektDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default AddLeieObjektDialog;
