import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tabs, Tab, TabPane, TabContent, TabContainer } from "react-bootstrap";
import Page from "../../components/Layout/Page";
import BrukerAdmin from "../../components/Admin/BrukerAdmin";
import brukereSelector from "../../selectors/brukereSelector";
import * as brukerAction from "../../actions/brukerAction";
import AlleKoderAdmin from "./AlleKoderAdmin";
import IndeksReguleringAdmin from "./IndeksReguleringAdminContainer";
import * as toastAction from "../../actions/toastAction";

import "./AdminPage.css";

class AdminPage extends Component {
  componentDidMount() {
    const { getBrukere } = this.props;
    getBrukere();
  }

  render() {
    return (
      <Page title="Admin">
        <TabContainer id="AdminPage">
          <Tabs id="controlled-tab-example">
            <Tab eventKey={1} title="Brukere" style={{ color: "red" }} />
            <Tab eventKey={2} title="Koder" />
            <Tab eventKey={3} title="Indeksregulering" />
            <TabContent>
              <TabPane eventKey={1}>
                <BrukerAdmin {...this.props} />
              </TabPane>
              <TabPane eventKey={2}>
                <AlleKoderAdmin />
              </TabPane>
              <TabPane eventKey={3}>
                <IndeksReguleringAdmin />
              </TabPane>
            </TabContent>
          </Tabs>
        </TabContainer>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  brukere: brukereSelector(state)
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...brukerAction,
      ...toastAction
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
