import axios from "services/axios";
import * as api from "constants/api";

export const getCssBakgrunnForVerifisertSaksnummer = (verifisertSaksnummerState: boolean | undefined) => {
    if (verifisertSaksnummerState === true)
      return "saksnummer-verifisert";

    if (verifisertSaksnummerState === false)
      return "saksnummer-feil";

    return "";
  }

export const validerArkivsaknummerWithFunc = (saksnummer: string, onSuccess: Function, onError?: Function) => {
  axios.get(api.GET_KONTRAKT_SAK_URL(saksnummer)).then(result => {
    if (result.status == 200)
      onSuccess();
    else 
      onError && onError();
  });
}
