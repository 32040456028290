import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withAuth } from "react-oidc-context";
import { logoutUser, setView, USER_VIEW } from "../../actions/loginAction";
import NavigationBar from "../../components/Layout/NavigationBar";

const mapStateToProps = (state, ownProps) => {
  const isAdmin = state.login ? state.login.isAdmin : false;
  const brukerId = state.login ? state.login?.sub : null;
  const brukernavn = ownProps.isAuthenticated ? ownProps?.user?.profile.name : "";
  const appView = state.login?.appView ?? USER_VIEW;
  return {
    brukerId,
    isAdmin,
    isBruker: brukerId && !isAdmin,
    brukernavn,
    appView
  };
};

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      onLogout: () => {
        ownProps.history.push("/login");
        if (ownProps.isAuthenticated) ownProps.signoutRedirect();
        return logoutUser();
      },
      onAppViewSelected: appView => setView(appView)
    },
    dispatch
  );

export default withAuth(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBar)));
