import moment from "moment";
import { orderBy } from "lodash-es";

export const expiresSoon = (kontrakt, soonInMonths = 6) => {
  const soon = moment().add(soonInMonths, "M");
  const newUtlopDato = moment(kontrakt.utlopDato);
  return kontrakt.utlopDato ? newUtlopDato.isBefore(soon) : false;
};

const isValidKontraktStatus = kontrakt =>
  kontrakt.kontraktStatusId === 3 ||
  kontrakt.kontraktStatusId === 5 ||
  kontrakt.kontraktStatusId === 6 ||
  kontrakt.kontraktStatusId === 2;

export const getExpiresKontrakter = kontrakter =>
  orderBy(
    kontrakter.filter(k => k.fornyes && k.utlopDato && expiresSoon(k) && isValidKontraktStatus(k)),
    "utlopDato"
  );
