import { getUser } from "auth/authTools";
import axios from "axios";

const defaultTransformers = () => {
  const { transformRequest } = axios.defaults;
  if (!transformRequest) return [];
  if (transformRequest instanceof Array) return transformRequest;
  return [transformRequest];
};

const instance = axios.create({
  validateStatus: false,
  transformRequest: [
    ...defaultTransformers(),
    (data, headers) => {
      delete headers.Authorization;
      const user = getUser();
      if (user) headers.Authorization = `Bearer ${user?.access_token}`;
      return data;
    }
  ]
});

export default instance;
