export default function Release5() {
  return (
    <>
      <h5 style={{ fontSize: "1.5em" }}>Booking</h5>
      <div>
        <p>All saksbehandling av bookinger tilknyttet byleie.no, er flyttet til Byleie Saksbehandling.</p>
      </div>
    </>
  );
}
