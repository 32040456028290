import * as React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { BymForm, BymTextInput, BymSubmitButton, required, length } from "components/Common/Forms";

const KodeEditor = ({
  kodeNavn,
  submitButtonTitle,
  onSubmit,
  onCancel,
  extraFields,
  kodeObject,
  buttonPlacement = "left"
}) => (
  <BymForm id={`addKode${kodeNavn}`} onSubmit={onSubmit} values={kodeObject}>
    <BymTextInput readOnly={kodeObject !== undefined} controlId="id" />
    <BymTextInput controlId="navn" label="*Navn" rules={[required(), length(3, 56, false)]} />
    {extraFields && extraFields.map(field => <BymTextInput controlId={field.fieldName} label={field.title} />)}
    <div className={buttonPlacement === "left" ? "pull-left" : "pull-right"}>
      <Button onClick={onCancel} bsStyle="link">
        Avbryt
      </Button>
      <BymSubmitButton text={submitButtonTitle} />
    </div>
    <br clear="all" />
  </BymForm>
);

export default KodeEditor;

KodeEditor.propTypes = {
  kodeNavn: PropTypes.string.isRequired,
  submitButtonTitle: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  extraFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  kodeObject: PropTypes.shape({}).isRequired,
  buttonPlacement: PropTypes.string.isRequired
};
