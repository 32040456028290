import jwtDecode from "jwt-decode";
import { isAdmin } from "auth/authTools";

export const ADMIN_VIEW = "ADMIN_VIEW";

export const USER_VIEW = "USER_VIEW";

// Sign In User
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_APP_VIEW = "SET_APP_VIEW";

const loginUserRequest = epost => ({
  type: LOGIN_USER_REQUEST,
  payload: epost
});

const loginUserSuccess = userData => ({
  type: LOGIN_USER_SUCCESS,
  payload: userData
});

export const setAppView = view => ({
  type: SET_APP_VIEW,
  payload: view
});

export function loginUserOneLogin(access_token, preferred_username) {
  return dispatch => {
    dispatch(loginUserRequest(preferred_username));
    const decodedToken = jwtDecode(access_token);
    if (decodedToken) {
      dispatch(loginUserSuccess(decodedToken));
    }
    const appView = sessionStorage.getItem(SET_APP_VIEW);
    if (appView && isAdmin(decodedToken)) {
      setView(appView)(dispatch);
    } else {
      setView(isAdmin(decodedToken) ? ADMIN_VIEW : USER_VIEW)(dispatch);
    }
  };
}

export function setView(appView) {
  return dispatch => {
    window.sessionStorage.setItem(SET_APP_VIEW, appView);
    dispatch(setAppView(appView));
  };
}

export function logoutUser() {
  return { type: LOGOUT_USER };
}
