const SET_PAGE = storeKey => `SET_PAGE_${storeKey.toUpperCase()}`;
const SET_PAGE_COUNT = storeKey => `SET_PAGE_COUNT_${storeKey.toUpperCase()}`;
const SET_SORT = storeKey => `SET_SORT_${storeKey.toUpperCase()}`;
const SET_FILTER = storeKey => `SET_FILTER_${storeKey.toUpperCase()}`;

export function setPage(storeKey, value) {
  return { type: SET_PAGE(storeKey), payload: value };
}

export function setPageCount(storeKey, value) {
  return { type: SET_PAGE_COUNT(storeKey), payload: value };
}

export function setSort(storeKey, value) {
  return { type: SET_SORT(storeKey), payload: value };
}

export function setFilter(storeKey, filterKey, value) {
  return { type: SET_FILTER(storeKey), payload: value, meta: { filterKey } };
}

export function setFreetextFilter(storeKey, filterKey, value) {
  return { type: SET_FILTER(storeKey), payload: value, meta: { filterKey } };
}
