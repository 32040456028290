import React from "react";
import { FormGroup, FormControl, Button } from "react-bootstrap";

export default class KommentarEditor extends React.Component {
  state = {
    commentText: ""
  };

  componentDidMount() {
    this.setState({ commentText: this.props.defaultValue || "" });
  }

  commentChanged = commentText => {
    this.setState({ commentText: commentText });
  };

  render() {
    const addButtonEnabled = this.state.commentText && this.state.commentText.length > 2;
    const { onCancel = () => null, onSave = () => null, defaultValue, ...rest } = this.props;
    return (
      <React.Fragment>
        <FormGroup>
          <FormControl
            className="filter-menu-input"
            componentClass="textarea"
            value={this.state.commentText}
            {...rest}
            onChange={e => this.commentChanged(e.target.value)}
          />
        </FormGroup>
        <Button
          bsStyle="link"
          onClick={() => {
            onCancel();
            this.commentChanged("");
          }}
        >
          Avbryt
        </Button>
        <Button
          bsStyle="bym-action"
          onClick={() => {
            onSave(this.state.commentText);
            this.commentChanged("");
          }}
          disabled={!addButtonEnabled}
        >
          Lagre kommentar
        </Button>
      </React.Fragment>
    );
  }
}
