import React from "react";
import PropTypes from "prop-types";
import { Row, Col, TabContainer, TabContent, Image, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import "./EiendomTabs.css";
import kartIcon from "../../images/map-marker.svg";
import listeIcon from "../../images/liste.svg";

const EiendomTabs = ({ mode, onSelect, children }) => (
  <TabContainer id="eiendommer-tabs" activeKey={mode} onSelect={onSelect}>
    <div>
      <div className="eiendommer-tabs-panel">
        <Row>
          <Col md={4}>
            <ToggleButtonGroup
              className="eiendommer-tabs"
              justified
              type="radio"
              name="eiendom-tab"
              value={mode}
              onChange={onSelect}
            >
              <ToggleButton value="liste" bsStyle="bym-standard">
                <Image src={listeIcon} style={{ height: "12px" }} />
                Liste
              </ToggleButton>
              <ToggleButton value="kart" bsStyle="bym-standard">
                <Image src={kartIcon} />
                Kart
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </Row>
      </div>
      <TabContent>{children}</TabContent>
    </div>
  </TabContainer>
);

EiendomTabs.propTypes = {
  mode: PropTypes.oneOf(["liste", "kart"]),
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

EiendomTabs.defaultProps = {
  mode: "liste"
};

export default EiendomTabs;
