import { map, forEach } from "lodash-es";
import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../helpers/actionHelper";
import { vedleggListSchema, vedleggSchema } from "../constants/schemas";

// POST VEDLEGG
export const POST_VEDLEGG_REQUEST = "POST_VEDLEGG_REQUEST";
export const POST_VEDLEGG_SUCCESS = "POST_VEDLEGG_SUCCESS";
export const POST_VEDLEGG_FAILURE = "POST_VEDLEGG_FAILURE";

export function postVedlegg(eiendomId, filer) {
  return dispatch => {
    dispatch({
      type: POST_VEDLEGG_REQUEST,
      payload: map(filer, file => file.name),
      meta: { eiendomId }
    });
    const data = new FormData();
    forEach(filer, file => data.append("Filer", file));

    return axios
      .post(api.POST_VEDLEGG_URL(eiendomId), data)
      .then(
        handle.success(dispatch, POST_VEDLEGG_SUCCESS, vedleggListSchema, {
          eiendomId
        })
      )
      .catch(handle.error(dispatch, POST_VEDLEGG_FAILURE));
  };
}

// DELETE VEDLEGG
export const DELETE_VEDLEGG_REQUEST = "DELETE_VEDLEGG_REQUEST";
export const DELETE_VEDLEGG_SUCCESS = "DELETE_VEDLEGG_SUCCESS";
export const DELETE_VEDLEGG_FAILURE = "DELETE_VEDLEGG_FAILURE";

export function deleteVedlegg(eiendomId, id) {
  return dispatch => {
    dispatch({
      type: DELETE_VEDLEGG_REQUEST,
      payload: id,
      meta: { eiendomId }
    });
    return axios
      .delete(api.DELETE_VEDLEGG_URL(eiendomId, id))
      .then(
        handle.success(dispatch, DELETE_VEDLEGG_SUCCESS, vedleggSchema, {
          eiendomId,
          id
        })
      )
      .catch(handle.error(dispatch, DELETE_VEDLEGG_FAILURE));
  };
}
