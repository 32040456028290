function required() {
  return (value: any, id: any) => (!value ? `${id} er påkrevd` : null);
}

function number() {
  return (value: any, id: any) => (isNaN(value) ? `${id} må være et tall` : null);
}

function currency() {
  return (value: any, id: any) => (getNumberOfDecimals(value) > 2 ? `${id} kan ikke ha mer enn 2 desimaler` : null);
}

function optionalNumber() {
  return (value: any, id: any) =>
    value !== null && value !== undefined && isNaN(value) ? `${id} må være et tall` : null;
}

const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

function email() {
  return (value: any) => (value != null && !emailRegex.test(value) ? "Er ikke en epost" : null);
}

function getNumberOfDecimals(value: any) {
  if (!value || isNaN(value)) return 0;

  if (Math.floor(value.valueOf()) === value.valueOf()) return 0;
  const decimals = value.toString().split(".");
  if (decimals.length <= 1) return 0;

  return decimals[1].length;
}

function length(min: any, max: any = null, checkEmpty = true) {
  return (value: any) => {
    // allow to not check empty values so it can be used together with required rule
    if (!value && checkEmpty === false) {
      return null;
    }
    if (value != null && value.length < min) {
      return `Må være minimum ${min} karakterer lang`;
    }
    if (max != null && value.length > max) {
      return `Må være maximum ${max} karakterer lang`;
    }
    return null;
  };
}

export { required, number, currency, optionalNumber, email, getNumberOfDecimals, length };
