import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Cookies } from "react-cookie";
import PageAnonymous from "../../components/Layout/PageAnonymous";
import { withAuth } from "react-oidc-context";
import { setToastError } from "../../actions/toastAction";
import { Button } from "react-bootstrap";

const REDIRECT_ROUTE = "REDIRECT_ROUTE";

export function setRedirectRoute(route) {
  return sessionStorage.setItem(REDIRECT_ROUTE, route);
}

export function getRedirectRoute() {
  return sessionStorage.getItem(REDIRECT_ROUTE);
}

export function removeRedirectRoute() {
  return sessionStorage.removeItem(REDIRECT_ROUTE);
}

function getNextPathName(location) {
  return location?.state?.from?.pathname;
}

class LoginPage extends Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({}).isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired
  };

  handleOneLogin = () => {
    const nextPath = getRedirectRoute() ?? getNextPathName(this.props.location) ?? "/";
    setRedirectRoute(nextPath);
    this.props.signinRedirect();
  };

  render() {
    const auth = this.props;

    return (
      <PageAnonymous title="Logg inn">
        <div style={{ marginLeft: "auto", marginRight: "auto", width: "14em" }}>
          <Button disabled={auth.isLoading} onClick={this.handleOneLogin}>
            Logg inn med OneLogin
          </Button>
        </div>
      </PageAnonymous>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setToastError }, dispatch);
}

export default withAuth(withRouter(connect(null, mapDispatchToProps)(LoginPage)));
