import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Alert } from "react-bootstrap";
import { setToastError } from "actions/toastAction";
import PageSection from "../Layout/PageSection";
import FakturaEditorDialog from "./FakturaEditorDialog";
import FakturaSectionList from "./FakturaSectionList";
import GenererFakturaDialog from "./GenererFakturaDialog";
import { isAdminOrUgeBruker } from "auth/authTools";

const FakturaSection = ({
  kontraktId,
  kontrakt,
  addKreditnota,
  nesteKontraktFakturering,
  faktureringFerdigDenneManed,
  fakturaer = [],
  ...props
}) => {
  const [showOpprettFakturaDialog, setShowOpprettFakturaDialog] = useState(false);
  const [showOpprettManuellFakturaDialog, setShowOpprettManuellFakturaDialog] = useState(false);
  const [showOpprettKreditnotaDialog, setShowOpprettKreditnotaDialog] = useState(false);
  const [showEditFakturaDialog, setShowEditFakturaDialog] = useState(false);
  const [fakturaEditObject, setFakturaEditObject] = useState();
  const [showAlertNoMoreInvoicesDialog, setShowAlertNoMoreInvoicesDialog] = useState(false);
  const [kreditnotaFakturaReferanse, setKreditnotaFakturaReferanse] = useState();
  const [manualFakturaDate, setManualFakturaDate] = useState();
  const [isTilleggsFaktura, setIsTilleggsFaktura] = useState(false);

  const nesteFakturering = faktureringFerdigDenneManed ? moment().add("M", 1) : moment();

  const generateFakturaClicked = () => {
    if (nesteKontraktFakturering !== null) setShowOpprettFakturaDialog(true);
    else setShowAlertNoMoreInvoicesDialog(true);
  };

  const addManualFaktura = async faktura => {
    const { postKontraktManualFaktura, getKontraktFakturaer, setToastSuccess } = props;
    try {
      await postKontraktManualFaktura(kontraktId, faktura);
      setToastSuccess("Faktura opprettet");
      getKontraktFakturaer(kontraktId);
    } catch (ex) {
      setToastError(ex);
    } finally {
      setShowOpprettManuellFakturaDialog(false);
    }
  };

  const originalFakturaAlreadyExists = () => {
    if (!nesteKontraktFakturering) return false;

    const ar = moment(nesteKontraktFakturering).year();
    const maned = moment(nesteKontraktFakturering).month() + 1;
    const existingFakturaer = fakturaer.filter(f => f.ar === ar && f.maned === maned && !f.tilleggsFaktura);
    return existingFakturaer.length > 0;
  };

  fakturaer.sort((a, b) => a.maned < b.maned).sort((c, d) => c.ar < d.ar);

  return (
    <>
      {showOpprettFakturaDialog && (
        <GenererFakturaDialog
          show
          showErstatt={!originalFakturaAlreadyExists()}
          onNew={() => {
            setManualFakturaDate(nesteFakturering);
            setShowOpprettManuellFakturaDialog(true);
            setShowOpprettFakturaDialog(false);
            setIsTilleggsFaktura(true);
          }}
          onErstatt={() => {
            setManualFakturaDate(moment(nesteKontraktFakturering));
            setShowOpprettManuellFakturaDialog(true);
            setShowOpprettFakturaDialog(false);
            setIsTilleggsFaktura(false);
          }}
          onClose={() => setShowOpprettFakturaDialog(false)}
        >
          <>
            {originalFakturaAlreadyExists() && (
              <p>
                Neste faktura for denne kontrakten (
                <strong>{moment(nesteKontraktFakturering).format("MMMM - YYYY")}</strong>) er allerede opprettet. Denne
                fakturaen kan endres ved å trykke rediger i fakturalisten.
              </p>
            )}
            {!originalFakturaAlreadyExists() && (
              <>
                <p>
                  Vi ser at neste ordinære faktura for denne kontrakten skal sendes i{" "}
                  <strong>{moment(nesteKontraktFakturering).format("MMMM - YYYY")}</strong>.
                </p>
                <p>Vil du erstatte denne fakturaen trykk &quot;Erstatt&quot;</p>
              </>
            )}
            <p>
              Vil du sende en ekstra tilleggsfaktura ved neste fakturering i{" "}
              <strong>{nesteFakturering.format("MMMM - YYYY")}</strong> trykk &quot;Tillegg&quot;
            </p>
          </>
        </GenererFakturaDialog>
      )}
      {showAlertNoMoreInvoicesDialog && (
        <GenererFakturaDialog
          show
          title="Generer faktura"
          showErstatt={false}
          onNew={() => {
            setShowOpprettManuellFakturaDialog(true);
            setShowAlertNoMoreInvoicesDialog(false);
            setManualFakturaDate(nesteFakturering);
            setIsTilleggsFaktura(true);
          }}
          onClose={() => {
            setShowAlertNoMoreInvoicesDialog(false);
          }}
        >
          <Alert bsStyle="danger">
            <strong>Advarsel!</strong> Det ser ikke ut til at det skal sendes ut faktura de neste 12 månedene på denne
            kontrakten (kan skyldes at kontrakten er utgått osv.). Er du sikker på at du vil sende en tilleggsfaktura
            ved neste fakturering: <br /> <strong>{nesteFakturering.format("MMMM - YYYY")}</strong>
          </Alert>
        </GenererFakturaDialog>
      )}
      {showEditFakturaDialog && (
        <FakturaEditorDialog
          kontrakt={kontrakt}
          editObject={fakturaEditObject}
          show={showEditFakturaDialog}
          onHide={() => {
            setShowEditFakturaDialog(false);
            setFakturaEditObject(undefined);
          }}
          onEdit={kontraktFaktura => {
            props.updateKontraktFaktura(kontraktFaktura);
            setShowEditFakturaDialog(false);
          }}
        />
      )}
      {showOpprettManuellFakturaDialog && (
        <FakturaEditorDialog
          title={isTilleggsFaktura ? "Opprett tilleggsfaktura" : "Erstatt faktura"}
          kontrakt={kontrakt}
          editObject={{
            ar: manualFakturaDate.year(),
            maned: manualFakturaDate.month() + 1, // add 1 as moment month is 0 based
            tilleggsFaktura: isTilleggsFaktura
          }}
          show
          onHide={() => setShowOpprettManuellFakturaDialog(false)}
          onSave={addManualFaktura}
        />
      )}
      {showOpprettKreditnotaDialog && (
        <FakturaEditorDialog
          title="Opprett kreditnota"
          kontrakt={kontrakt}
          show
          kreditnotaFakturaReferanse={kreditnotaFakturaReferanse}
          onHide={() => setShowOpprettKreditnotaDialog(false)}
          onSave={kreditnota => {
            addKreditnota(kreditnota);
            setShowOpprettKreditnotaDialog(false);
          }}
        />
      )}

      <PageSection
        title={`Fakturaer (${fakturaer.length})`}
        addText={nesteKontraktFakturering !== undefined ? "Opprett faktura" : undefined}
        onAdd={generateFakturaClicked}
        showButton={isAdminOrUgeBruker()}
      >
        <FakturaSectionList
          fakturaer={fakturaer}
          {...props}
          onAddKreditnota={fakturaNummer => {
            setShowOpprettKreditnotaDialog(true);
            setKreditnotaFakturaReferanse(fakturaNummer);
          }}
          onEdit={editFaktura => {
            setShowEditFakturaDialog(true);
            setFakturaEditObject(editFaktura);
          }}
        />
      </PageSection>
    </>
  );
};

FakturaSection.propTypes = {
  fakturaer: PropTypes.arrayOf(PropTypes.object).isRequired,
  createKontraktFaktura: PropTypes.func.isRequired,
  postKontraktManualFaktura: PropTypes.func.isRequired,
  addKreditnota: PropTypes.func.isRequired,
  kontrakt: PropTypes.shape({}).isRequired,
  kontraktId: PropTypes.string.isRequired,
  getKontraktFakturaer: PropTypes.func.isRequired,
  setToastSuccess: PropTypes.func.isRequired
};

export default FakturaSection;
