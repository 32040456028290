import { map, orderBy } from "lodash-es";
import createSelector from "re-reselect";

const eierSelector = (state, eierType, eierId) => state.entities[eierType][eierId];
const kommentarSelector = state => state.entities.kommentarer;

const cacheKey = (_state, eierType, eierId) => `${eierType}Kommentarer_${eierId}`;

export default createSelector(
  eierSelector,
  kommentarSelector,
  (eier, kommentarer) =>
    eier &&
    orderBy(
      map(eier.kommentarer, id => kommentarer[id]),
      "opprettetDato",
      "desc"
    )
)(cacheKey);
