import React from "react";
import { FormGroup, FormControl, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import "./KontraktNotat.css";
import ShowOnlyForUsersWith from "auth/ShowOnlyFor";
import { isAdminOrUgeBruker } from "auth/authTools";

class KontraktNotat extends React.Component {
  constructor(props) {
    super(props);

    // Initial state "notatText" as empty
    this.state = {
      notatText: ""
    };
  }

  // Make sure that "notat" is rendered when page/component is loaded
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ notatText: nextProps.editObject.notat });
  }

  // Cancel changes in "notat" and render latest saved content
  notatCanceled = () => {
    const { editObject } = this.props;
    this.setState({ notatText: editObject.notat });
  };

  // Add og change "notat" content based on formcontrol
  notatChanged = () => {
    const { editObject, onEdit, afterEditSaved } = this.props;
    const { notatText } = this.state;
    const newkontrakt = { ...editObject, notat: notatText };
    onEdit(editObject.eiendomId, newkontrakt).then(() => {
      if (afterEditSaved) afterEditSaved();
    });
  };

  // Delete content in "notat" by setting the string empty
  notatDeleted = () => {
    const { editObject, onEdit, afterEditSaved } = this.props;
    const deleteNotat = { ...editObject, notat: "" };
    onEdit(editObject.eiendomId, deleteNotat).then(() => {
      if (afterEditSaved) afterEditSaved();
    });
  };

  render() {
    const { notatText } = this.state;
    const { className, editObject } = this.props;
    // Check if "Lagre notat" button should be active or not (changes)
    const addButtonEnabled = notatText && notatText.length > 2;
    // Activation of "slett notat" link when contain content
    const deleteButtonEnabled = editObject.notat && notatText.length >= 0;

    return (
      <div className={`kommentar-component ${className}` || ""}>
        <>
          <FormGroup>
            <FormControl
              readOnly={!isAdminOrUgeBruker()}
              className="filter-menu-input"
              componentClass="textarea"
              value={notatText || ""}
              onChange={e => this.setState({ notatText: e.target.value })}
            />
          </FormGroup>
          <ShowOnlyForUsersWith roles={["UGE-admin", "UGE-bruker"]}>
            <Button
              bsStyle="link"
              onClick={() => {
                this.notatDeleted();
              }}
              disabled={!deleteButtonEnabled}
            >
              Slett notat
            </Button>
            <Button
              bsStyle="link"
              onClick={() => {
                this.notatCanceled();
              }}
            >
              Avbryt
            </Button>
            <Button bsStyle="bym-action" onClick={this.notatChanged} disabled={!addButtonEnabled}>
              Lagre notat
            </Button>
          </ShowOnlyForUsersWith>
        </>
      </div>
    );
  }
}

KontraktNotat.propTypes = {
  editObject: PropTypes.shape({
    notat: PropTypes.string
  }).isRequired,
  className: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  afterEditSaved: PropTypes.func.isRequired
};

KontraktNotat.defaultProps = {
  className: ""
};

export default KontraktNotat;
