import BymFormControl, { BymFormControlProps } from "./BymFormControl";
import { number } from "./Rules";

const BymNumberInput = ({ rules = [], ...props }: BymFormControlProps) => (
  <BymFormControl
    rules={[...rules, number()]}
    onChange={(e: any) => {
      if (e?.target?.value) {
        e.target.value = e.target.value.replace(/[\s]/g, "");
        if (isNaN(e.target.value) && e.target.value !== "+" && e.target.value !== "-") {
          e.target.value = e.target.value.replace(/(?![+-])[^0-9.]/g, "");
        }
      }
      return e;
    }}
    {...props}
    type="text"
  />
);

export default BymNumberInput;
