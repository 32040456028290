import { normalize } from "normalizr";
import { toastError } from "../actions/toastAction";

export function success(dispatch, type, schema = null, meta = {}) {
  return response => {
    let { result } = response.data;
    const newMeta = { ...meta };

    // Try get etag from header
    if (response.headers.etag) newMeta.etag = response.headers.etag;

    if (response.status === 304) {
      dispatch({ type, not_modified: true, meta: newMeta });
      return result;
    }

    if (schema != null) {
      result = normalize(result, schema);
    }
    dispatch({ type, payload: result, meta: newMeta });
    return result;
  };
}

export function error(dispatch, type) {
  return err => {
    // show toast errormessage for all requests except get requests
    if (err.config && err.config.method && err.config.method !== "get") {
      dispatch(toastError(err));
    }
    dispatch({ type, error: true, payload: { ...err } });
    return Promise.reject(err);
  };
}
