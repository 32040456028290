// types
export const ERROR = "toast/ERROR";
export const SUCCESS = "toast/SUCCESS";
export const CLEAR = "toast/CLEAR";

export function toastError(payload) {
  return { type: ERROR, payload };
}

export function toastSuccess(payload) {
  return { type: SUCCESS, payload };
}

export function resetToast() {
  return { type: CLEAR };
}

export function setToastSuccess(message) {
  return dispatch => {
    dispatch(toastSuccess(message));
  };
}

export function setToastError(error) {
  return dispatch => {
    dispatch(toastError(error));
  };
}

export const errorSelector = state => state.toast.error;
export const successSelector = state => state.toast.success;
export const toastTypeSelector = state => state.toast.toastType;
