import { AuthProviderProps } from "react-oidc-context";
import getEnvironmentVariable from "constants/environment";

export const oidcBaseUrl = getEnvironmentVariable("REACT_APP_ONELOGIN_AUTHORITY");

export const oidcConfig: AuthProviderProps = {
  authority: getEnvironmentVariable("REACT_APP_ONELOGIN_AUTHORITY"),
  client_id: getEnvironmentVariable("REACT_APP_ONELOGIN_CLIENT_ID"),
  redirect_uri: getEnvironmentVariable("REACT_APP_ONELOGIN_REDIRECT_URI"),
  silent_redirect_uri: getEnvironmentVariable("REACT_APP_ONELOGIN_SILENT_REDIRECT_URI"),
  post_logout_redirect_uri: getEnvironmentVariable("REACT_APP_ONELOGIN_POST_LOGOUT_REDIRECT_URI"),
  automaticSilentRenew: true,
  response_type: "code",
  scope: "openid profile groups",
  resource: "https://uge.bymoslo.no",
  onSigninCallback: () => {
    /**
     * You must provide an implementation of onSigninCallback to oidcConfig to remove the payload from the URL upon successful login.
     * Otherwise if you refresh the page and the payload is still there, signinSilent - which handles renewing your token - won't work.
     */
    window.history.replaceState({}, document.title, "/");
  }
};
