import { map, filter } from "lodash-es";
import { createSelector } from "reselect";
import makeGetAdvancedList from "./advancedListSelector";
import { obsKeysToString } from "../utils/utils";

const getEiendomIds = state => state.eiendommer.items;
const getEiendomEntities = state => state.entities.eiendommer;

export const getEiendommer = state => map(state.entities.eiendommer, e => e);

/**
 * Method that returns a selector that returns eiendommer
 *
 * @param {*} freeTextSearchProperties
 * Array with properties that can be used in a freetext search filter.
 * If this is given a property is generated on the object that joins
 * all given properties in a single string that can be searched
 */
const makeGetEiendommer = freeTextSearchProperties =>
  createSelector([getEiendomIds, getEiendomEntities], (ids, entities) =>
    map(ids, id => {
      const eiendom = entities[id];
      return {
        ...eiendom,
        leieObjektCount: eiendom.leieObjekter ? eiendom.leieObjekter.length : 0,
        _freetextSearchProperty: obsKeysToString(eiendom, freeTextSearchProperties, " ")
      };
    })
  );

export default makeGetEiendommer;

/**
 * Method that returns a selector that returns eiendommer
 *
 * @param {*} freeTextSearchProperties
 * Array with properties that can be used in a freetext search filter.
 * If this is given a property is generated on the object that joins
 * all given properties in a single string that can be searched
 */
export const makeGetPublicEiendommer = () =>
  createSelector([getEiendomEntities], entities => filter(entities, entity => entity.visIPublikumPortal));

// Filtered and sorted list of eiendommer
export const makeGetEiendomList = freeTextSearchProperties =>
  makeGetAdvancedList("eiendommer", makeGetEiendommer(freeTextSearchProperties));
