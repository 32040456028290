import React from "react";
import PropTypes from "prop-types";
import { BymForm, BymCheckboxInput, BymSubmitButton } from "components/Common/Forms";
import { Modal } from "react-bootstrap";

import "./BildePicker.css";

const BildePicker = ({ bilder = [], selected = [], onSave = () => undefined, onHide = () => undefined }) => {
  const initialFormValues = bilder.reduce((prev, curr) => {
    return {
      [curr.fil.id]: selected.filter(b => b.fil.id === curr.fil.id).length > 0,
      ...prev
    };
  }, {});

  const returnSelected = selectedImages => {
    const bilderToSave = bilder.filter(bilde => {
      return selectedImages[bilde.fil.id];
    });
    onSave(bilderToSave);
  };

  return (
    <Modal
      onHide={onHide}
      show
      bsSize="large"
      dialogClassName="leieobjekt-dialog"
      backdrop="static"
      allowFullScreen={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Velg bilder for leieobjekt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BymForm onSubmit={e => returnSelected(e)} values={initialFormValues}>
          {bilder.map(bilde => {
            return <BymCheckboxInput controlId={bilde.fil.id} label={bilde.fil.navn} inline />;
          })}
          <BymSubmitButton />
        </BymForm>
        <br />
      </Modal.Body>
    </Modal>
  );
};

BildePicker.propTypes = {
  bilder: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.arrayOf(PropTypes.string),
  onHide: PropTypes.func,
  onSave: PropTypes.func
};

export default BildePicker;
