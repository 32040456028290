import React, { useState } from "react";
import PropTypes from "prop-types";
import { DOWNLOAD_BILDER_URL } from "../../constants/api";
import leggtilIcon from "../../images/leggtil.svg";
import BildeItem from "./BildeItem";
import BildeDialog from "./BildeDialog";

import "./BildeList.css";

const BildeList = ({ bilder = [], onClick = () => undefined, onDelete, onAdd, showDialog = false }) => {
  const [dialogSrc, setDialogSrc] = useState(undefined);
  const [dialogTitle, setDialogTitle] = useState("");

  const showBildeDialog = bilde => {
    const src = DOWNLOAD_BILDER_URL(bilde.eiendomId, bilde.fil.id);
    setDialogSrc(src);
    setDialogTitle(bilde.fil.navn);
  };

  return (
    <ul className="list-unstyled bilder-list">
      {dialogSrc && <BildeDialog src={dialogSrc} show onClose={() => setDialogSrc(undefined)} title={dialogTitle} />}

      {bilder.map(bilde => (
        <li key={bilde.fil.id}>
          <BildeItem
            onClick={() => (showDialog ? showBildeDialog(bilde) : onClick(bilde))}
            src={DOWNLOAD_BILDER_URL(bilde.eiendomId, bilde.fil.id)}
            label={bilde.fil.navn}
            alt=""
            deleteBilde={onDelete ? () => onDelete(bilde) : undefined}
          />
        </li>
      ))}
      {onAdd && (
        <li style={{ verticalAlign: "top" }}>
          <div role="presentation" onKeyPress={() => undefined} onClick={onAdd} className="bilder-add">
            <img src={leggtilIcon} alt="legg til bilde" />
          </div>
        </li>
      )}
    </ul>
  );
};

BildeList.propTypes = {
  bilder: PropTypes.arrayOf(PropTypes.object),
  showDialog: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func
};

export default BildeList;
