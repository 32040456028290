import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";

const GenererFakturaDialog = ({ show, onClose = () => null, onNew = () => null, onErstatt, children, showErstatt }) => {
  return (
    <Modal
      onHide={onClose}
      show={show}
      bsSize="large"
      dialogClassName="leieobjekt-dialog"
      backdrop="static"
      allowFullScreen={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Opprett faktura</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} bsStyle="link">
          Avbryt
        </Button>
        {showErstatt && (
          <Button onClick={onErstatt} bsStyle="bym-action">
            Erstatt
          </Button>
        )}
        <Button onClick={onNew} bsStyle="bym-action">
          Tillegg
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

GenererFakturaDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  onErstatt: PropTypes.func.isRequired,
  children: PropTypes.element
};

export default GenererFakturaDialog;
