import { Modal, Button, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";

interface ConfirmDialogProps {
  show?: boolean;
  onClose?: () => null | void;
  onConfirm?: () => null | void;
  children?: any;
  message?: string;
  title?: string;
  closeButtonText?: string;
  confirmButtonText?: string;
  confirmButtonStyle?: string;
}

const ConfirmDialog = ({
  show,
  onClose = () => null,
  onConfirm = () => null,
  children,
  message,
  title,
  closeButtonText = "Avbryt",
  confirmButtonText = "Bekreft",
  confirmButtonStyle = "bym-action"
}: ConfirmDialogProps) => (
  <Modal onHide={onClose} show={show} backdrop="static" style={{ display: "flex", alignItems: "center" }}>
    {title && (
      <ModalHeader closeButton>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
    )}
    <ModalBody>
      {children && <>{children}</>}
      {message && <p>{message}</p>}
    </ModalBody>
    <ModalFooter>
      <Button onClick={onClose} bsStyle="link">
        {closeButtonText}
      </Button>
      <Button onClick={onConfirm} bsStyle={confirmButtonStyle}>
        {confirmButtonText}
      </Button>
    </ModalFooter>
  </Modal>
);

export default ConfirmDialog;
