/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable object-shorthand */
import { useRef } from "react";
import L, { LatLng } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import { useLeaflet } from "react-leaflet";

export interface EditDetails {
  currentLineLength: number;
  currentArea: number;
}

interface BymMapEditControlProps {
  onEditChange?: (d: EditDetails) => void;
  [key: string]: any;
}

const BymMapEditControl = ({ onEditChange, ...props }: BymMapEditControlProps) => {
  const { map } = useLeaflet();
  const lastVertex = useRef<any>(undefined);
  const details = useRef<EditDetails>({
    currentLineLength: 0,
    currentArea: 0
  });
  L.Draw.Tooltip = L.Class.extend({
    initialize: function (bymMap: any) {
      this._map = bymMap;
      this._popupPane = bymMap._panes.popupPane;

      this._container = bymMap.options.drawControlTooltips
        ? L.DomUtil.create("div", "leaflet-draw-tooltip", this._popupPane)
        : null;
      this._singleLineLabel = false;
    },

    dispose: function () {
      if (this._container) {
        this._popupPane.removeChild(this._container);
        this._container = null;
      }
    },

    updateContent: function (labelText: any) {
      if (!this._container) {
        return this;
      }
      labelText.subtext = `Areal: ${details.current.currentArea.toFixed(
        0
      )}&#13217; <br>Lengde: ${details.current.currentLineLength.toFixed(1)}m`;

      // update the vertical position (only if changed)
      if (labelText.subtext.length === 0 && !this._singleLineLabel) {
        L.DomUtil.addClass(this._container, "leaflet-draw-tooltip-single");
        this._singleLineLabel = true;
      } else if (labelText.subtext.length > 0 && this._singleLineLabel) {
        L.DomUtil.removeClass(this._container, "leaflet-draw-tooltip-single");
        this._singleLineLabel = false;
      }

      this._container.innerHTML = `${
        labelText.subtext.length > 0
          ? `<span class="leaflet-draw-tooltip-subtext">${labelText.subtext}</span><br />`
          : ""
      }<span>${labelText.text}</span>`;

      return this;
    },

    updatePosition: function (latlng: LatLng) {
      const pos = this._map.latLngToLayerPoint(latlng);
      const tooltipContainer = this._container;

      if (this._container) {
        tooltipContainer.style.visibility = "inherit";
        L.DomUtil.setPosition(tooltipContainer, pos);
      }

      return this;
    },

    showAsError: function () {
      if (this._container) {
        L.DomUtil.addClass(this._container, "leaflet-error-draw-tooltip");
      }
      return this;
    },

    removeError: function () {
      if (this._container) {
        L.DomUtil.removeClass(this._container, "leaflet-error-draw-tooltip");
      }
      return this;
    },

    _onMouseOut: function () {
      if (this._container) {
        this._container.style.visibility = "hidden";
      }
    }
  });

  map?.on("mousemove", (e: any) => {
    if (lastVertex.current) {
      details.current.currentLineLength = lastVertex.current.distanceTo(e.latlng);
      if (onEditChange) onEditChange(details.current);
    }
  });

  return (
    <EditControl
      {...props}
      onCreated={(e: any) => {
        details.current.currentArea = L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0]);
        if (onEditChange) onEditChange(details.current);
        lastVertex.current = undefined;
        details.current.currentArea = 0;
        if (props.onCreated) {
          props.onCreated(e);
        }
      }}
      onDrawVertex={(e: any) => {
        const latLngs: LatLng[] = [];
        let lastLayer: any;
        e.layers.eachLayer((a: any) => {
          latLngs.push(a.getLatLng());
          lastLayer = a;
        });
        details.current.currentArea = L.GeometryUtil.geodesicArea(latLngs);
        if (lastLayer) {
          lastVertex.current = lastLayer.getLatLng();
        }
        if (onEditChange) onEditChange(details.current);
      }}
      draw={{
        rectangle: false,
        marker: false,
        polyline: false,
        circle: false,
        circlemarker: false,
        polygon: {
          allowIntersection: false,
          showArea: true,
          showLength: false
        }
      }}
      edit={{
        edit: false
      }}
    />
  );
};

export default BymMapEditControl;
