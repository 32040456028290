import React from "react";
import { FormContextTypes } from "components/Common/Forms";

/**
 * Image Button control that triggers form onChange so that button click can be handeled there.
 */
const BymImageButton = ({ src, alt = "", controlId, children, buttonClass, buttonStyle, ...props }, context) => {
  const onChange = () => {
    if (context && context.form) {
      context.form.handleChange({ target: { id: controlId, value: undefined } });
    }
  };
  return (
    <a
      href=""
      onClick={e => {
        e.preventDefault();
        onChange(e);
      }}
      className={buttonClass}
      style={{ cursor: "pointer", ...buttonStyle }}
    >
      <img src={src} {...props} alt={alt} />
    </a>
  );
};

BymImageButton.contextTypes = FormContextTypes;

export default BymImageButton;
