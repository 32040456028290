import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Radio } from "react-bootstrap";
import { withCookies } from "react-cookie";
import axios from "../../services/axios";
import * as api from "../../constants/api";

const setSegment = (cookies, segementId = 0) => {
  if (segementId > 0) {
    cookies.set("uge_segment", segementId, { path: "/" });
  } else {
    cookies.remove("uge_segment", { path: "/" });
  }
  window.setTimeout(() => window.location.reload(false), 1);
};

const SegmentChooser = ({ cookies, allCookies, segmenter, ...restProps }) => {
  const alleSegementer = [{ id: 0, navn: "Alle" }, ...segmenter];
  const selectedSegment = parseInt(cookies.get("uge_segment") || 0);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    axios.get(api.GET_BRUKER_URL("me")).then(result => {
      if (result && result.data && result.data.result) {
        const { segmentId } = result.data.result;

        if (segmentId) {
          // The user is bound to a specific segment.
          if (segmentId !== selectedSegment) {
            // The user's segment isn't set as the cookie yet.
            // -> Set the cookie and refresh the page.
            setSegment(cookies, segmentId);
          }
        } else {
          // The user isn't bound to a segment or the user is allowed to see all segments.
          // -> Show the chooser.
          setVisible(true);
        }
      }
    });
  }, []);

  return visible ? (
    <div {...restProps}>
      <h3>Avdeling</h3>
      {alleSegementer.map(segment => (
        <Radio
          key={segment.id}
          onClick={() => setSegment(cookies, segment.id)}
          name="segmenter"
          defaultChecked={segment.id.toString() === selectedSegment.toString()}
          value={segment.id}
        >
          {segment.navn}
        </Radio>
      ))}
    </div>
  ) : null;
};

SegmentChooser.propTypes = {
  cookies: PropTypes.shape({}).isRequired,
  allCookies: PropTypes.shape({}).isRequired,
  segmenter: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withCookies(SegmentChooser);
