import * as loginAction from "../actions/loginAction";
import { isAdmin } from "auth/authTools";

export default function loginReducer(state = null, action) {
  switch (action.type) {
    case loginAction.LOGIN_USER_SUCCESS:
      return { ...action.payload, isAdmin: isAdmin(action.payload) };
    case loginAction.LOGOUT_USER:
      return null;
    case loginAction.SET_APP_VIEW:
      return { ...state, appView: action.payload };
    default:
      return state;
  }
}
