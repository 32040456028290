import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "../../../services/axios";
import * as api from "../../../constants/api";
import { getMimeTypeByExtension } from "../../../utils/mimeUtils";
import dokumentIcon from "../../../images/dokument.svg";
import ImageWithPromiseSpinner from "../Spinner/ImageWithPromiseSpinner";

export default class ArkivFileLink extends Component {
  state = {
    contentLoaded: false,
    documentLoadPromise: undefined
  };

  /**
   * When the link is clicked the file contents is downloaded
   * via api. Then we create a dataUri from the content, set the
   * href of the link to this dataUri and trigger a new click
   */
  documentClicked = async e => {
    const { file } = this.props;
    const { contentLoaded } = this.state;
    if (contentLoaded) return; // don't load content if it is already loaded

    e.preventDefault();
    const promise = axios.get(api.GET_KONTRAKT_FILE_URL(file.id));
    this.setState({ documentLoadPromise: promise });
    const result = await promise;
    // set contentLoaded to true to prevent loop
    this.setState({ contentLoaded: true });
    const downLoadfile = result.data.result;
    const mimeType = getMimeTypeByExtension(downLoadfile.format);
    const dataUri = `data:${mimeType};base64,${downLoadfile.base64Data}`;
    this.documentLink.href = dataUri;
    this.documentLink.click();
  };

  render() {
    const { file, fileNumber } = this.props;
    const { documentLoadPromise } = this.state;
    const navn = `${file.navn}.${file.format.toLowerCase()}`;
    return (
      <div>
        <ImageWithPromiseSpinner promise={documentLoadPromise} style={{ width: "16px" }} src={dokumentIcon} alt="" />
        <a
          className="btn btn-link"
          download={navn}
          ref={element => {
            this.documentLink = element;
          }}
          href=""
          onClick={e => this.documentClicked(e, fileNumber)}
        >
          {`${file.navn}`}
        </a>
      </div>
    );
  }
}

ArkivFileLink.propTypes = {
  file: PropTypes.shape({}).isRequired,
  fileNumber: PropTypes.number.isRequired,
  dokument: PropTypes.shape({}).isRequired
};
