import PropTypes from "prop-types";
import { connect } from "react-redux";
import getLogg from "store/logg/loggSelector";
import LoggSection from "components/Logg/LoggSection";
import getKoderSelector from "selectors/koderSelector";

const mapStateToProps = (state, ownProps) => {
  return {
    logg: ownProps.logg || getLogg(state, ownProps.eiendomId),
    eiendomId: ownProps.eiendomId,

    kontraktTyper: getKoderSelector(state, "kontrakttyper"),
    faktureringTyper: getKoderSelector(state, "faktureringTyper"),
    faktureringTidspunkter: getKoderSelector(state, "faktureringTidspunkter"),
    betalingTerminTyper: getKoderSelector(state, "betalingterminer"),
    kontraktStatuser: getKoderSelector(state, "kontraktStatuser"),
    kostSteder: getKoderSelector(state, "kostSteder"),
    kontoNummere: getKoderSelector(state, "kontoNummere"),
    prisTyper: getKoderSelector(state, "prisTyper")
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch) {
  return {};
  //return bindActionCreators(kommentarAction, dispatch);
}

const LoggSectionContainer = connect(mapStateToProps, mapDispatchToProps)(LoggSection);

LoggSectionContainer.propTypes = {
  eiendomId: PropTypes.string
};

export default LoggSectionContainer;
