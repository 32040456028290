import React from "react";
import { Button, ButtonProps } from "react-bootstrap";

type BymResetButtonProps = ButtonProps & {
  text?: string;
};

const BymResetButton = ({ text = "Avbryt", bsStyle = "link", ...props }: BymResetButtonProps) => (
  <Button bsStyle={bsStyle} type="reset" {...props}>
    {text}
  </Button>
);

export default BymResetButton;
