import { merge } from "lodash-es";

export default function brukere(state = {}, action) {
  switch (action.type) {
    default: {
      if (action.payload && action.payload.entities && action.payload.entities.brukere) {
        return merge({}, state, action.payload.entities.brukere);
      }
      return state;
    }
  }
}
