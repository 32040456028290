import React from "react";
import { ReactComponent as SpinnerImage } from "images/spinner.svg";
import BymButton, { BymButtonProps } from "./BymButton";
import "./BymSpinnerButton.css";

export type BymSpinnerButtonProps = BymButtonProps & {
  showSpinner?: boolean;
};

const BymSpinnerButton = ({ text, showSpinner = false, children, className, ...props }: BymSpinnerButtonProps) => (
  <BymButton text="" className={`bym-spinner-button ${className}`} {...props}>
    {text || children}
    {showSpinner && <SpinnerImage />}
  </BymButton>
);

export default BymSpinnerButton;
