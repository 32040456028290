// export const pristype_aar = 1;

export const pristype = {
  aar: 1,
  periode: 2,
  sesong: 3,
  totalt: 4
};

export const betalingTermin = {
  maanedlig: 1,
  kvartalsvis: 2,
  halvaarlig: 3,
  aarlig: 4,
  engang: 5,
  tilpasset: 6,
  sesong: 7
};

export const faktureringTidspunkt = {
  forskuddsvis: 501,
  etterskuddsvis: 502
};

export const kontraktStatus = {
  underBehandling: 1,
  transportertKontrakt: 2,
  tidsbestemtKontrakt: 3,
  nullKontrakt: 4,
  lopendeKontrakt: 5,
  forlengetKontrakt: 6,
  oppsagtKontrakt: 7,
  overfortTilBydel: 8,
  overfortTilEby: 9,
  utgattKontrakt: 10
};

export const faktureringType = {
  intern: 31001,
  ekstern: 31002,
  ansattMedTrekkILonn: 31003
};

export const kontraktType = {
  abonnementsalg: 28001,
  annet: 28002,
  area: 27001,
  areal: 27002,
  arrangement: 28005,
  bad: 27003,
  bolig: 27004,
  demonstrasjoner: 28006,
  filming_foto: 28007,
  forpaktningsbruk: 27005,
  hytte: 27006,
  jordleie: 27008,
  konsert: 28008,
  kulturkontrakt: 27007,
  lokaleutleie: 28013,
  løp: 28009,
  markering: 28012,
  næringslokale: 27009,
  parkering: 28011,
  riggplass: 27010,
  småbåtfondet: 27011,
  sportsstue: 27012,
  stalleie_beiteleie: 27013,
  standplass_markedsføring: 28004,
  standplass_vederlagsfri: 28003,
  tegneplass: 28010,
  tilfluktsrom: 27014,
  uteservering: 28014,
  valgbod: 28015,
  veibelysning: 27015
};
