import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setPageCount, setPage } from "../../actions/advancedListAction";
import PaginationControl from "../../components/Common/PaginationControl";
import { makeGetAdvancedListCount } from "../../selectors/advancedListSelector";

export default function createPaginationControl(storeKey, getEntities) {
  const makeMapStateToProps = () => {
    const getAdvancedListCount = makeGetAdvancedListCount(storeKey, getEntities);

    const mapStateToProps = state => {
      const advList = state[storeKey];
      return {
        page: advList.page,
        count: advList.count,
        total: getAdvancedListCount(state)
      };
    };
    return mapStateToProps;
  };

  const mapDispatchToProps = dispatch => bindActionCreators({ setPageCount, setPage }, dispatch);

  const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    onChange: value => dispatchProps.setPageCount(storeKey, value),
    onNext: () => dispatchProps.setPage(storeKey, stateProps.page + 1),
    onPrevious: () => dispatchProps.setPage(storeKey, stateProps.page - 1)
  });

  return connect(makeMapStateToProps, mapDispatchToProps, mergeProps)(PaginationControl);
}
