import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import getEiendomSelector from "../../selectors/eiendomSelector";
import { getKontraktSelector } from "../../selectors/kontrakterSelector";
import { getLeieObjektSelector } from "../../selectors/leieObjektSelector";
import Breadcrumb from "../../components/Layout/Breadcrumb";

const [isEiendomPage, isKontraktPage, isLeieObjektPage] = ["eiendommer", "kontrakter", "leieobjekter"].map(
  pageType => pathElements => pathElements[1] === pageType && pathElements[2]
);

const getEiendomElement = (state, id) => {
  const eiendom = getEiendomSelector(state, id);
  if (eiendom) {
    return {
      title: eiendom.Navn,
      url: `/eiendommer/${id}`
    };
  }
  return undefined;
};

const getKontraktElements = (state, id) => {
  const kontrakt = getKontraktSelector(state, id);
  if (kontrakt) {
    const returnElements = [];
    const eiendom = getEiendomSelector(state, kontrakt.eiendomId);
    if (eiendom) {
      returnElements.push({
        title: eiendom.Navn,
        url: `/eiendommer/${eiendom.id}`
      });
    }
    returnElements.push({
      title: kontrakt.tittel,
      url: `/kontrakter/${id}`
    });
    return returnElements;
  }
  return undefined;
};

const getLeieObjektElements = (state, id) => {
  const leieobjekt = getLeieObjektSelector(state, id);
  if (leieobjekt) {
    const returnElements = [];
    const eiendom = getEiendomSelector(state, leieobjekt.eiendomId);
    if (eiendom) {
      returnElements.push({
        title: eiendom.Navn,
        url: `/eiendommer/${eiendom.id}`
      });
    }
    returnElements.push({
      title: leieobjekt.navn,
      url: `/leieobjekter/${id}`
    });
    return returnElements;
  }
  return undefined;
};

const mapStateToProps = state => {
  const pathElements = window.location.pathname.split("/");
  const breadcrumbElements = [
    {
      title: pathElements[1] === "okonomi" ? "økonomi" : pathElements[1],
      url: `/${pathElements[1]}`
    }
  ];
  const id = pathElements[2];
  if (isEiendomPage(pathElements)) {
    const element = getEiendomElement(state, id);
    if (element) breadcrumbElements.push(element);
  } else if (isKontraktPage(pathElements)) {
    const elements = getKontraktElements(state, id);
    if (elements) breadcrumbElements.push(...elements);
  } else if (isLeieObjektPage(pathElements)) {
    const elements = getLeieObjektElements(state, id);
    if (elements) breadcrumbElements.push(...elements);
  }

  return {
    pathElements: breadcrumbElements
  };
};

export default withRouter(connect(mapStateToProps, {})(Breadcrumb));
