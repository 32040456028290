import { uniq, map, filter } from "lodash-es";
import createSelector from "re-reselect";

const eiendomSelector = (state, eiendomId) => state.entities.eiendommer[eiendomId];
const bilderSelector = state => state.entities.bilder;

export default createSelector(eiendomSelector, bilderSelector, (eiendom, bilder) => {
  const eiendomBilder = eiendom ? uniq(eiendom.bilder) : []; // remove duplicates
  const allImages = (eiendom && map(eiendomBilder, id => bilder[id])) || [];
  return filter(allImages, bilde => bilde && !bilde.fil.slettet);
})((state, eiendomId) => eiendomId);
