import React from "react";
import PropTypes from "prop-types";
import { BymCheckboxInput } from "components/Common/Forms";
import moment from "moment";
import "./FakturaGenerationViewer.css";

const FORSKUDD = 501;
const ETTERSKUDD = 502;

const MAANEDLIG = 1;
const KVARTALSVIS = 2;
const HALVAARLIG = 3;
const AARLIG = 4;
const TILPASSET = 6;
const SESONG = 7;

const getWrappedMonth = month =>
  moment()
    .month(1)
    .add(Number(month - 1), "M")
    .month();

const ShouldGenerateSeasonFaktura = (month, kontrakt) => {
  const { faktureringTidspunktId, betalingTerminId, hasPris2 } = kontrakt;
  const monthNumber = Number(month);
  const seasonStartMonth = kontrakt.sesongStartDato ? moment(kontrakt.sesongStartDato).month() : 0;
  const seasonEndMonth = kontrakt.sesongSluttDato ? moment(kontrakt.sesongSluttDato).month() : 11;
  const isVinterSesong = seasonStartMonth > seasonEndMonth;

  let subtractMonths = faktureringTidspunktId == FORSKUDD ? 1 : 0;

  if (betalingTerminId == MAANEDLIG) {
    // if season is full year return true
    if (seasonStartMonth === 0 && seasonEndMonth === 11) return true;

    // If season is from january and we pay in advance we return true for december
    if (seasonStartMonth == 0 && faktureringTidspunktId == FORSKUDD && month == 11) return true;

    if (isVinterSesong) {
      if (month < seasonStartMonth - subtractMonths && month > seasonEndMonth - subtractMonths) return false;
    } else {
      if (month < seasonStartMonth - subtractMonths || month > seasonEndMonth - subtractMonths) return false;
    }

    return true;
  }

  if (betalingTerminId == KVARTALSVIS) {
    subtractMonths *= 3;
    const addMonths = faktureringTidspunktId == FORSKUDD ? 0 : 3;

    // if we pay in advance and season starts in first quarter we send faktura in desember
    if (seasonStartMonth < 3 && month == 11 && faktureringTidspunktId == FORSKUDD) return true;

    if (month < seasonStartMonth - subtractMonths || month >= seasonEndMonth + addMonths) return false;
    if (month % 3 === 2) return true;
  }

  if (betalingTerminId == SESONG) {
    if (seasonStartMonth == 0 && faktureringTidspunktId == FORSKUDD && month == 11) return true;

    if (faktureringTidspunktId == FORSKUDD && monthNumber === seasonStartMonth - subtractMonths) return true;
    if (faktureringTidspunktId == ETTERSKUDD && monthNumber === seasonEndMonth - subtractMonths) return true;

    if (hasPris2 === "1") {
      if (faktureringTidspunktId == FORSKUDD && monthNumber === seasonEndMonth) return true;
      if (faktureringTidspunktId == ETTERSKUDD && monthNumber === getWrappedMonth(seasonStartMonth - 1)) return true;
    }
  }

  return false;
};

const ShouldGenerateFaktura = (month, kontrakt) => {
  const betalingTerminId = Number(kontrakt.betalingTerminId);
  if (kontrakt.prisTypeId === 3 && (kontrakt.hasPris2 === "0" || kontrakt.betalingTerminId == SESONG))
    return ShouldGenerateSeasonFaktura(month, kontrakt);

  if (betalingTerminId === MAANEDLIG) return true;

  if (betalingTerminId === KVARTALSVIS && month % 3 === 2) return true;

  if (betalingTerminId === HALVAARLIG && month % 6 === 5) return true;

  if (betalingTerminId === AARLIG && month % 12 === 11) return true;

  if (betalingTerminId === TILPASSET && (kontrakt.tilpassetBetalingManed1 || kontrakt.tilpassetBetalingManed2)) {
    const subtractMonths = Number(kontrakt.faktureringTidspunktId) === FORSKUDD ? -2 : -1;
    const maned1 = kontrakt.tilpassetBetalingManed1
      ? moment().month(kontrakt.tilpassetBetalingManed1).add(subtractMonths, "M").month()
      : undefined;
    const maned2 = kontrakt.tilpassetBetalingManed2
      ? moment().month(kontrakt.tilpassetBetalingManed2).add(subtractMonths, "M").month()
      : undefined;
    return month === maned1 || month === maned2;
  }

  return false;
};

const getClassName = (month, kontrakt) => {
  if (kontrakt.prisTypeId == 1) return "month-box";

  let className = "month-box";

  const seasonStartMonth = kontrakt.sesongStartDato ? moment(kontrakt.sesongStartDato).month() : 0;
  const seasonEndMonth = kontrakt.sesongSluttDato ? moment(kontrakt.sesongSluttDato).month() : 11;

  if (month == seasonStartMonth) className += " seasonStart";
  if (month == seasonEndMonth) className += " seasonEnd";

  return className;
};

const FakturaGenerationViewer = ({ kontrakt }) => {
  const months = moment.localeData().monthsShort();
  return (
    <>
      <h4>Faktura utsendes</h4>
      <p>Basert på valgene over vil det sendes ut faktura rundt den 15 i følgende måneder:</p>
      <div className="fakturageneration-viewer">
        {months.map((month, index) => (
          <div className={getClassName(index, kontrakt)} key={month}>
            <BymCheckboxInput readOnly label={month} value={ShouldGenerateFaktura(index, kontrakt)} />
          </div>
        ))}
      </div>
    </>
  );
};

FakturaGenerationViewer.propTypes = {
  kontrakt: PropTypes.shape({}).isRequired
};

export default FakturaGenerationViewer;
