import React from "react";
import BymFormValueWrapper from "components/Common/Forms/BymFormValueWrapper";
import BlobLink from "components/Common/BlobLink";
import axios from "services/axios";
import * as api from "constants/api";
import { AvtaleData } from "./KontraktAvtaleUtils";

const postAvtaleDocument = (preview: boolean, formValues?: any) => {
  const url = preview ? api.POST_KONTRAKT_AVTALE_PREVIEW : api.POST_KONTRAKT_AVTALE;
  return axios.post(
    url,
    {
      ...formValues,
      kontrakt: {
        ...formValues?.kontrakt,
        leieObjekter: formValues?.kontrakt?.leieObjekter?.filter((l: any) => l.checked),
        kommentarer: undefined
      }
    },
    {
      responseType: "arraybuffer"
    }
  );
};

type DownloadAvtaleButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  preview?: boolean;
};

const DownloadAvtaleButton = ({ preview, children, ...rest }: DownloadAvtaleButtonProps) => (
  <BymFormValueWrapper<AvtaleData> mapFunction={formValues => ({ formValues })}>
    <DownloadAvtaleButtonInternal preview={preview} {...rest}>
      {children}
    </DownloadAvtaleButtonInternal>
  </BymFormValueWrapper>
);

const DownloadAvtaleButtonInternal = ({ preview = false, formValues, className, ...rest }: any) => (
  <BlobLink
    showIcon
    className={`btn btn-bym-positive ${className}`}
    {...rest}
    downloadFunction={() => postAvtaleDocument(preview, formValues)}
  />
);

export default DownloadAvtaleButton;
