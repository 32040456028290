import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useActions from "store/useActions";
import PageSection from "../Layout/PageSection";
import AddLeieObjektDialog from "./AddLeieObjektDialog";
import dokumentIcon from "../../images/dokument.svg";
import getLeieObjekterForEiendom from "../../selectors/leieObjektSelector";
import * as leieObjektAction from "../../actions/leieObjektAction";
import { getKoder } from "../../actions/koderAction";
import getKoderSelector from "../../selectors/koderSelector";
import { setToastSuccess } from "../../actions/toastAction";
import { isAdminOrUgeBruker } from "auth/authTools";

const LeieObjektSection = ({ sectionUpdated, eiendomId, eiendom }) => {
  const [showLeieObjektDialog, setShowLeieObjektDialog] = useState(false);
  const leieObjekter = useSelector(state => getLeieObjekterForEiendom(state, eiendomId)) || [];
  const vernTyper = useSelector(state => getKoderSelector(state, "verntyper"));
  const leieObjektTyper = useSelector(state => getKoderSelector(state, "leieobjekttyper"));
  const title = `Leieobjekter (${leieObjekter.length})`;

  const actions = useActions(
    {
      ...leieObjektAction,
      getKoder,
      setToastSuccess
    },
    []
  );

  const saveLeieObjekt = leieObjekt => {
    setShowLeieObjektDialog(false);
    actions.postLeieObjekt(eiendomId, leieObjekt).then(() => {
      actions.setToastSuccess("Leieobjekt lagt til");
      if (sectionUpdated) sectionUpdated();
    });
  };

  useEffect(() => {
    actions.getKoder("verntyper");
    actions.getKoder("leieobjekttyper");
  }, [actions]);

  return (
    <React.Fragment>
      {showLeieObjektDialog && (
        <AddLeieObjektDialog
          vernTyper={vernTyper}
          leieObjektTyper={leieObjektTyper}
          eiendom={eiendom}
          show={showLeieObjektDialog}
          onHide={() => setShowLeieObjektDialog(false)}
          onSave={saveLeieObjekt}
        />
      )}
      <PageSection
        title={title}
        addText="Legg til leieobjekt"
        onAdd={() => setShowLeieObjektDialog(true)}
        showButton={isAdminOrUgeBruker()}
      >
        <ul className="list-unstyled ps-kontrakt-list">
          {leieObjekter.map((leieObjekt = {}) => (
            <li key={leieObjekt.id}>
              <img src={dokumentIcon} alt="" />
              <Link to={`/leieobjekter/${leieObjekt.id}`}>{leieObjekt.navn}</Link>
            </li>
          ))}
        </ul>
      </PageSection>
    </React.Fragment>
  );
};

LeieObjektSection.propTypes = {
  eiendomId: PropTypes.string.isRequired,
  eiendom: PropTypes.shape(PropTypes.object),
  sectionUpdated: PropTypes.func.isRequired
};

export default LeieObjektSection;
