import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as fakturaAction from "actions/fakturaAction";
import KreditnotaSection from "components/Faktura/KreditnotaSection";
import { getKontraktFakturaList } from "selectors/fakturaerSelector";
import * as toastAction from "actions/toastAction";
import axios from "services/axios";
import * as api from "constants/api";

class KreditnotaSectionContainer extends Component {
  deleteKreditnota = (kreditnotaId, kommentar) => {
    const { kontraktId, deleteKontraktFaktura, getKontraktFakturaer, setToastSuccess } = this.props;
    deleteKontraktFaktura(kontraktId, kreditnotaId, kommentar).then(() => {
      setToastSuccess("Kreditnota slettet");
      getKontraktFakturaer(kontraktId);
    });
  };

  sendToAgresso = fakturaId => {
    const { kontraktId, postFakturaToAgresso, getKontraktFakturaer, setToastSuccess } = this.props;
    return postFakturaToAgresso(fakturaId).then(() => {
      setToastSuccess("Kreditnota sendt til agresso");
      getKontraktFakturaer(kontraktId);
    });
  };

  addKreditnota = kreditnota => {
    const { postKontraktKreditnota, kontraktId, getKontraktFakturaer, setToastSuccess } = this.props;
    postKontraktKreditnota(kontraktId, kreditnota).then(() => {
      setToastSuccess("Kreditnota opprettet");
      getKontraktFakturaer(kontraktId);
    });
  };

  synkroniserKontraktFaktura = async faktura => {
    await axios.post(`${api.GET_FAKTURA_SYNKRONISER_URL(faktura.id)}`);
    const { kontraktId, getKontraktFakturaer, setToastSuccess } = this.props;
    setToastSuccess("Kreditnota status oppdatert");
    getKontraktFakturaer(kontraktId);
  };

  render() {
    return (
      <KreditnotaSection
        {...this.props}
        onAdd={this.addKreditnota}
        onDelete={this.deleteKreditnota}
        onSynkroniserAgresso={this.synkroniserKontraktFaktura}
        onSendToAgresso={this.sendToAgresso}
      />
    );
  }
}

KreditnotaSectionContainer.propTypes = {
  kontraktId: PropTypes.string,
  getKontraktFakturaer: PropTypes.func,
  setToastSuccess: PropTypes.func,
  postKontraktKreditnota: PropTypes.func,
  postFakturaToAgresso: PropTypes.func,
  deleteKontraktFaktura: PropTypes.func
};

const mapStateToProps = state => {
  const fakturaer = getKontraktFakturaList(state);
  const kreditnotaer = fakturaer.filter(f => f.fakturaType === "kreditnota");
  return {
    kreditnotaer
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...fakturaAction, setToastSuccess: toastAction.setToastSuccess }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(KreditnotaSectionContainer);
