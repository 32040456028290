import React from "react";
import { FormContextTypes } from "components/Common/Forms";
import oppdaterImage from "images/oppdater.svg";
import spinnerImage from "images/spinner.svg";
import BymImageButton from "./BymImageButton";

/**
 * Update Button control that triggers form onChange so that button click can be handeled there.
 */
class BymUpdateSpinnerButton extends React.Component {
  state = {
    showSpinner: false
  };

  setupSpinner(promise) {
    if (promise && promise.then) {
      this.setState({ showSpinner: true });
      promise
        .then(() => {
          this.setState({ showSpinner: false });
        })
        .catch(() => this.setState({ showSpinner: false }));
    }
  }

  buttonClicked = e => {
    e.preventDefault();
    const { onClick } = this.props;
    if (onClick) {
      const promise = onClick();
      this.setupSpinner(promise);
    }
  };

  render() {
    const { showSpinner } = this.state;
    if (showSpinner)
      return (
        <BymImageButton
          {...this.props}
          // disable click while spinning
          onClick={e => {
            e.preventDefault();
          }}
          src={spinnerImage}
          style={{ width: "24px" }}
        />
      );

    return <BymImageButton {...this.props} onClick={this.buttonClicked} src={oppdaterImage} />;
  }
}

BymUpdateSpinnerButton.contextTypes = FormContextTypes;

export default BymUpdateSpinnerButton;
