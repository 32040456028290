import * as React from "react";
import spinnerImage from "../../../images/spinner.svg";

export default class ImageWithPromiseSpinner extends React.Component {
  state = {
    showSpinner: false
  };

  componentDidMount() {
    this.setupSpinner(this.props);
  }

  componentWillReceiveProps(props) {
    this.setupSpinner(props);
  }

  async setupSpinner(props) {
    const { promise } = props;
    if (promise && promise.then) {
      this.setState({ showSpinner: true });
      try {
        await promise.then();
      } finally {
        this.setState({ showSpinner: false });
      }
    }
  }

  render() {
    const { showSpinner } = this.state;
    return (
      <React.Fragment>
        {showSpinner && <img alt="" {...this.props} src={spinnerImage} />}
        {!showSpinner && <img alt="" {...this.props} />}
      </React.Fragment>
    );
  }
}
