import { useState } from "react";
import { get } from "lodash-es";
import { Link } from "react-router-dom";
import { DetailsView, DetailsItem, DetailsColumn, CustomDetailsItem } from "../Common/DetailsView";
import { getFormatedDate } from "utils/dateUtils";

import InfoIcon from "images/info.svg";
import Checkbox from "components/Common/Checkbox";
import InfoDialog from "components/Common/InfoDialog";
import { Eiendom, Kontrakt } from "interfaces";

function GetArchiveLink(arkivsak: any) {
  if (!arkivsak || !arkivsak.linker) return undefined;
  const arkivLink = arkivsak.linker.filter((l: any) => l.rel === "Acos")[0];

  return `${arkivLink.href}sak/${arkivsak.id}`;
}

interface KontraktDetailsProps {
  kontrakt?: Kontrakt;
  eiendom?: Eiendom;
  arkivksak?: any;
  arkivInfoClicked?: any;
}

const KontraktDetails = ({ kontrakt, eiendom, arkivksak, arkivInfoClicked = () => null }: KontraktDetailsProps) => {
  const [showSaerAvtaleGrunn, setShowSaerAvtaleGrunn] = useState(false);

  if (kontrakt == null) {
    return null;
  }

  const currentEiendom = eiendom || kontrakt.eiendom;
  const { leieObjekter = [] } = kontrakt;
  const archiveLink = GetArchiveLink(arkivksak);
  const totalPrice = leieObjekter.reduce((acc, current) => {
    const antall = current.antall || 1;
    return acc + (current.pris || 0) * antall;
  }, 0);

  const totalPrice2 = leieObjekter.reduce((acc, current) => {
    const antall = current.antall || 1;
    return acc + (current.pris2 || 0) * antall;
  }, 0);

  return (
    <DetailsView>
      <DetailsColumn md={4} sm={6}>
        <CustomDetailsItem label="Eiendom">
          {currentEiendom && <Link to={`/eiendommer/${currentEiendom.id}`}>{`${currentEiendom.Navn}`}</Link>}
        </CustomDetailsItem>
        <DetailsItem label="Kontraktstatus" value={`${kontrakt.kontraktStatus ? kontrakt.kontraktStatus.navn : ""}`} />
        <DetailsItem label="Kontrakt type" value={`${kontrakt.kontraktType ? kontrakt.kontraktType.navn : ""}`} />
        <DetailsItem label="Pristype" value={kontrakt.prisTypeId && (get(kontrakt, "prisType.navn") || "Årspris")} />
        <CustomDetailsItem label={kontrakt.prisTypeId === 3 ? "Periodepris" : "Total årspris"}>
          <div style={{ display: "inline-block", marginRight: "10px" }}>{`${totalPrice.toFixed(2) || 0} kr`}</div>
          {totalPrice2 > 0 && (
            <div style={{ display: "inline-block" }}>{`( ${totalPrice2.toFixed(2) || 0} kr utenfor sesong )`}</div>
          )}
        </CustomDetailsItem>
        <CustomDetailsItem label="Leieobjekter">
          {leieObjekter.map((klo: any) => {
            const antall = klo.antall || 1;
            return (
              <div key={klo.leieObjektId} className="kontrakt-leieobjekter">
                <span>
                  {klo.leieObjektNavn}
                  {antall && antall > 1 ? ` (${antall})` : ""}:
                </span>{" "}
                <span>{((klo.pris || 0) * antall).toFixed(2) || ""} kr</span>
              </div>
            );
          })}
        </CustomDetailsItem>
      </DetailsColumn>
      <DetailsColumn md={4} sm={6}>
        <DetailsItem label="Signert" value={getFormatedDate(kontrakt.signertDato)} />

        <DetailsItem label="Organisasjonsnummer" value={kontrakt.organisasjonsnummer} />
        <DetailsItem label="Fødselsnummer" value={kontrakt.fodselsnummer} />
        <DetailsItem label="Kundenummer" value={kontrakt.kundenummer} />

        <CustomDetailsItem label="Arkiv saksnummer">
          {archiveLink && (
            <div style={{ position: "relative" }}>
              <a href={archiveLink} target="_blank" rel="noreferrer">
                {kontrakt.arkivSakNummer}
              </a>
              <a
                href=""
                onClick={e => {
                  arkivInfoClicked(arkivksak);
                  e.preventDefault();
                }}
                style={{ position: "absolute", right: "0" }}
              >
                <img src={InfoIcon} alt="" style={{ width: "19px", marginBottom: "3px" }} />
              </a>
            </div>
          )}
          {!archiveLink && <span>{kontrakt.arkivSakNummer}</span>}
        </CustomDetailsItem>
        <DetailsItem label="Koststed" value={kontrakt.koststedId} />
        <DetailsItem label="Konto" value={kontrakt.depositumKonto} />
        <DetailsItem
          label="Fakturering type"
          value={get(kontrakt, "faktureringType.navn") || kontrakt.faktureringTypeId}
        />
        <DetailsItem label="Fakturering tidspunkt" value={get(kontrakt, "faktureringTidspunkt.navn")} />
        <DetailsItem
          label="Betaling termin"
          value={kontrakt.betalingTermin ? kontrakt.betalingTermin.navn : kontrakt.betalingTerminId}
        />
      </DetailsColumn>
      <DetailsColumn md={4} sm={6}>
        <DetailsItem label="Opprettet" value={getFormatedDate(kontrakt.opprettetDato)} />
        <DetailsItem label="Gyldig fra" value={getFormatedDate(kontrakt.gyldigFraDato)} />
        <DetailsItem label="Gyldig til" value={getFormatedDate(kontrakt.utlopDato)} />
        <CustomDetailsItem label="Skal ikke fornyes">
          <Checkbox inline disabled checked={!kontrakt.fornyes} />
        </CustomDetailsItem>
        <DetailsItem label="Sist endret" value={getFormatedDate(kontrakt.sistEndretDato)} />
        <DetailsItem label="Sist indeksregulert" value={getFormatedDate(kontrakt.sistIndeksregulertDato)} />
        <DetailsItem label="Kontaktperson" value={kontrakt.kontaktpersonNavn} />
        <CustomDetailsItem label="Kontaktperson telefon">
          {kontrakt.kontaktpersonTelefon && (
            <a href={`tel:${kontrakt.kontaktpersonTelefon}`}>{kontrakt.kontaktpersonTelefon}</a>
          )}
        </CustomDetailsItem>
        <CustomDetailsItem label="Kontaktperson epost">
          {kontrakt.kontaktpersonEpost && (
            <a href={`mailto:${kontrakt.kontaktpersonEpost}`}>{kontrakt.kontaktpersonEpost}</a>
          )}
        </CustomDetailsItem>
        {kontrakt?.kontraktMal && <DetailsItem label="Kontraktmal" value={kontrakt.kontraktMal.navn} />}
        {kontrakt?.saeravtaleGrunn && (
          <CustomDetailsItem label="Særavtale">
            <>
              <span>Ja </span>
              <img
                src={InfoIcon}
                alt="Vis særavtale grunn"
                title="Vis særavtale grunn"
                style={{ width: "19px", marginBottom: "3px", cursor: "pointer", float: "right", marginRight: "20px" }}
                onClick={() => setShowSaerAvtaleGrunn(true)}
              />
              <InfoDialog
                title="Særavtale grunn"
                message={kontrakt?.saeravtaleGrunn}
                show={showSaerAvtaleGrunn}
                onHide={() => setShowSaerAvtaleGrunn(false)}
              />
            </>
          </CustomDetailsItem>
        )}
      </DetailsColumn>
    </DetailsView>
  );
};

export default KontraktDetails;
