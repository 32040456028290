import React from "react";
import { map } from "lodash-es";
import { Image, Panel, PanelGroup } from "react-bootstrap";

import utvidIcon from "../../images/utvid.svg";
import "./ExpandableDetailsList.css";

class ExpandableDetailsList extends React.Component {
  state = {
    selectedItemIndex: null
  };

  shouldShowItemDetails = itemIndex =>
    // in accordion mode we only show det details component for the expanded item.
    // This should improve performance in long lists
    this.props.accordion !== false ? this.state.selectedItemIndex === itemIndex : true;

  onActionClicked = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onActionClicked && this.props.onActionClicked(item);
  };

  onItemSelected = e => {
    this.setState({ selectedItemIndex: e });
    this.props.itemSelected && this.props.itemSelected(e);
  };

  getHeader = (item, actionLinkText, actionLinkClassName = "btn-bym-negative-link") => {
    const title = item.title || item.navn;
    if (actionLinkText) {
      return (
        <React.Fragment>
          <span>
            <Image className="collapse-icon" src={utvidIcon} />
            {title}
          </span>
          <a className={actionLinkClassName} onClick={e => this.onActionClicked(e, item)}>
            {actionLinkText}
          </a>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <span>
          <Image className="collapse-icon" src={utvidIcon} /> {title}
        </span>
      </React.Fragment>
    );
  };

  render() {
    const { items, makeDetailsComponent, actionLinkText, actionLinkClassName, accordion = true } = this.props;
    return (
      <PanelGroup
        defaultActiveKey="0"
        bsClass="panel-group expandable-list"
        accordion={accordion}
        onSelect={this.onItemSelected}
      >
        {map(items, (item, index) => {
          const header = this.getHeader(item, actionLinkText, actionLinkClassName);
          return (
            <Panel collapsible={!accordion} header={header} key={index} eventKey={index || 0}>
              {makeDetailsComponent && this.shouldShowItemDetails(index) && makeDetailsComponent(item)}
            </Panel>
          );
        })}
      </PanelGroup>
    );
  }
}

export default ExpandableDetailsList;
