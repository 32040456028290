import { sortBy } from "lodash-es";
import * as koderAction from "../actions/koderAction";

const INITIAL_STATE = [];

export default function koderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case koderAction.GET_KODER_SUCCESS: {
      let koder = action.payload || [];
      if (koder instanceof Array) {
        koder = sortBy(koder, "navn");
      }
      return {
        ...state,
        [action.meta.kodeNavn]: koder
      };
    }
    default:
      return state;
  }
}
