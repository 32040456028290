import { split } from "lodash-es";
import { getFormatedDate } from "../../utils/dateUtils";
import { getFormatedZipCode } from "../../utils/zipcodeUtils";
import { DetailsView, DetailsItem, DetailsColumn } from "../Common/DetailsView";
import { Eiendom } from "interfaces";
import "./EiendomDetails.css";

const EiendomDetails = ({ eiendom }: { eiendom: Eiendom }) => (
  <DetailsView>
    <DetailsColumn md={4} sm={6}>
      <DetailsItem label="Kategori" value={eiendom?.Kategori} />
      <DetailsItem label="Adresse" value={eiendom?.Adresse} />
      <DetailsItem label="Poststed" value={getFormatedZipCode(eiendom?.Postnummer, eiendom?.Poststed)} />
      <DetailsItem label="Gnr." value={split(eiendom?.Gnr_Bnr, "/")[0]} />
      <DetailsItem label="Bnr." value={split(eiendom?.Gnr_Bnr, "/")[1]} />
      <DetailsItem label="" value="" />
    </DetailsColumn>
    <DetailsColumn md={4} sm={6}>
      <DetailsItem label="Distrikt" value={eiendom?.Distrikt} />
      <DetailsItem label="Område" value={eiendom?.Område} />
      <DetailsItem label="Anleggsnummer" value={eiendom?.Anleggsnummer} />
      <DetailsItem label="Prosjektnummer" value={eiendom?.Prosjektnummer} />
      <DetailsItem label="Areal" value={eiendom?.Areal_eiendom} />
      <DetailsItem label="" value="" />
    </DetailsColumn>
    <DetailsColumn md={4} sm={6}>
      <DetailsItem label="Segment" value={eiendom?.segment ? eiendom?.segment.navn : ""} />
      <DetailsItem label="Vann" value={eiendom?.Vann} />
      <DetailsItem label="Avløp" value={eiendom?.Avløp} />
      <DetailsItem label="Sist oppdatert" value={getFormatedDate(eiendom?.sistEndretDato)} />
      {/* <DetailsItem label="Vis i BYleie" value={eiendom?.visIPublikumPortal ? "Ja" : "Nei"} /> */}
      <DetailsItem label="Arkiv saknummer" value={eiendom?.arkivSakNummer} />
    </DetailsColumn>
  </DetailsView>
);

export default EiendomDetails;
