import { useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash-es";
import utvidIcon from "images/utvid.svg";
import { Image } from "react-bootstrap";
import FakturaDetails from "./FakturaDetails";

import deleteIcon from "../../images/delete.svg";
import "./FakturaList.css";

const FakturaAdminListRow = ({
  id,
  kontrakt = {},
  kontraktId,
  fakturaStatus,
  ordreNummer,
  totalPris,
  kontonummerId,
  koststedId,
  onDelete,
  faktura
}) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <tbody aria-expanded={showDetails}>
      <tr>
        <td>{kontrakt ? kontrakt.tittel : kontraktId}</td>
        <td>{fakturaStatus && fakturaStatus.navn}</td>
        <td>{ordreNummer}</td>
        <td>{kontrakt.opprettetAvNavn}</td>
        <td>{kontonummerId}</td>
        <td>{koststedId}</td>
        <td>{get(kontrakt, "faktureringTidspunktId") === 501 ? "Forskudd" : "Etterskudd"} </td>
        <td align="right">{totalPris.toFixed(2)} kr</td>
        {onDelete && (
          <td>
            {fakturaStatus && (fakturaStatus.id === 1 || fakturaStatus.id === 8) && (
              <a href onClick={() => onDelete(id)} style={{ cursor: "pointer" }}>
                <img src={deleteIcon} alt="" />
              </a>
            )}
          </td>
        )}
        <td onClick={() => setShowDetails(!showDetails)}>
          <Image title="Detaljer" className={`collapse-icon ${showDetails ? " expanded" : ""}`} src={utvidIcon} />
        </td>
      </tr>
      {showDetails && (
        <tr className="details">
          <td colSpan="10">
            <FakturaDetails faktura={faktura} />
          </td>
        </tr>
      )}
    </tbody>
  );
};

FakturaAdminListRow.propTypes = {
  kontrakt: PropTypes.shape(PropTypes.object).isRequired,
  kontraktId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  fakturaStatus: PropTypes.shape(PropTypes.object).isRequired,
  ordreNummer: PropTypes.number.isRequired,
  totalPris: PropTypes.number.isRequired,
  kontonummerId: PropTypes.number.isRequired,
  koststedId: PropTypes.number.isRequired,
  onDelete: PropTypes.func
};

FakturaAdminListRow.defaultProps = {
  onDelete: undefined
};

export default FakturaAdminListRow;
