import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Page from "../Layout/Page";
import SegmentChooser from "../../containers/Layout/SegmentChooserContainer";
import EiendomAutocomplete from "./EiendomAutocomplete";
import ExpandableKontraktList from "./ExpandableKontraktList";
import ExpandableFakturaList from "./ExpandableFakturaList";
import Spinner from "../Common/Spinner/Spinner";

import "./Forside.css";

import eiendomIcon from "../../images/eiendom.svg";
import leieobjektIcon from "../../images/leieobjekt.svg";
import kontraktIcon from "../../images/kontrakt.svg";

const IconBox = ({ link, title, ...restProps }) => (
  <Link to={link}>
    <img alt="" {...restProps} />
    <h4>{title}</h4>
  </Link>
);

IconBox.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string
};

const Forside = ({ segment = "0", eiendommer = [], kontrakter = [], fakturaer = [] }) => {
  if (eiendommer.length === 0) return <Spinner text="Laster data..." spinnerStyle="page-spinner" />;
  return (
    <Page title="Utleie av grunn og eiendom" className="forside">
      {/* Invisible segment chooser */}
      <SegmentChooser className="forside-avdelinger" segment={segment} />

      <EiendomAutocomplete eiendommer={eiendommer} />
      <div className="forside-icons">
        <IconBox link="/eiendommer" src={eiendomIcon} title="Eiendom" />
        <IconBox link="/leieobjekter" src={leieobjektIcon} title="Leieobjekter" />
        <IconBox link="/kontrakter" src={kontraktIcon} title="Kontrakter" />
      </div>
      <h3>
        Utløpende kontrakter <small>(neste 6 måneder)</small>
      </h3>
      <ExpandableKontraktList initialLength={10} kontrakter={kontrakter} />
      <h3>Faktura - utestående betalinger</h3>
      <ExpandableFakturaList initialLength={10} fakturaer={fakturaer} />
    </Page>
  );
};

Forside.propTypes = {
  segment: PropTypes.string,
  eiendommer: PropTypes.arrayOf(PropTypes.object),
  kontrakter: PropTypes.arrayOf(PropTypes.object),
  fakturaer: PropTypes.arrayOf(PropTypes.object)
};

export default Forside;
