import PropTypes, { string, number } from "prop-types";

export const sortShape = PropTypes.shape({
  cellKey: string.isRequired,
  orders: string
});

export const eiendomShape = PropTypes.shape({
  id: string,
  Adresse: string,
  Avløp: string,
  Byggeaar: number,
  GNR_BNR: string,
  Leietaker: string,
  Maalenumme: string,
  Maalerpunk: string,
  Navn: string,
  Postnummer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  Telefon: string,
  Type: string,
  Vann: string,
  Vern: string
});

export const leieObjektShape = PropTypes.shape({
  id: string,
  eiendomId: string,
  navn: string,
  leieObjektTypeId: number,
  bygningsnummer: number,
  gyldigFra: string,
  gyldigTil: string,
  adresse2: string,
  byggeAr: number,
  bebygdAreal: number,
  bruksAreal: number,
  primarRom: number,
  sekundarRom: number,
  vernTypeId: number,
  stromMalernummer: string,
  stromMalepunkt: string,
  vannMalernummer: string,
  vannMalepunkt: string,
  bruksenhetNummer: string,
  gnr: string,
  bnr: string
});

export const vedleggShape = PropTypes.shape({
  filId: string.isRequired,
  fil: PropTypes.shape({
    id: string.isRequired,
    navn: string.isRequired
  }).isRequired
});

export const kontraktShape = PropTypes.shape({
  id: string.isRequired,
  tittel: string.isRequired,
  kontraktStatus: PropTypes.shape({
    kode: string,
    navn: string
  }),
  eiendomId: string.isRequired
});

export const fakturaShape = PropTypes.shape({
  id: PropTypes.string,
  tittel: PropTypes.string,
  eiendomNavn: PropTypes.string
});

export const routerMatchShape = PropTypes.shape({
  params: PropTypes.shape({
    year: PropTypes.string,
    month: PropTypes.string
  }).isRequired
});

export const routerHistoryShape = PropTypes.shape({
  push: PropTypes.func
});
