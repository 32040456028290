import { HeaderCell } from "../Common/Tables";
import Table from "../../containers/Common/SortableTableContainer";
import KontraktListRow from "./KontraktListRow";
import { Kontrakt } from "interfaces";

const renderKontraktRow = (kontrakt: Kontrakt) => <KontraktListRow kontrakt={kontrakt} />;

const KontraktList = ({ kontrakter }: { kontrakter: Kontrakt[] }) => (
  <Table id="kontrakt-liste" storeKey="kontrakter" items={kontrakter} renderRow={renderKontraktRow}>
    <HeaderCell cellKey="tittel">Kontrakt</HeaderCell>
    <HeaderCell cellKey="kontraktType.navn">Kontrakttype</HeaderCell>
    <HeaderCell cellKey="eiendom.Navn">Eiendom</HeaderCell>
    <HeaderCell cellKey="faktureringType.navn">Faktureringtype</HeaderCell>
    <HeaderCell cellKey="kontraktStatus.navn">Kontraktstatus</HeaderCell>
    <HeaderCell cellKey="betalingTermin.navn">Fakturafrekvens</HeaderCell>
    <HeaderCell cellKey="utlopDato">Utløper</HeaderCell>
    <HeaderCell cellKey="opprettetDato">Opprettet</HeaderCell>
  </Table>
);

export default KontraktList;
