import React, { useState, useEffect } from "react";
import { Modal, Row, Col, TabPane, TabContent, TabContainer } from "react-bootstrap";
import PropTypes from "prop-types";
import { BymForm, BymRadioButtonsInput, BymSubmitButton, length, number } from "components/Common/Forms";
import BymButton from "../Common/Forms/BymButton";
import UgeFormControl from "../Common/Forms/UgeFormControl";
import axios from "../../services/axios";
import * as api from "../../constants/api";
import "./EditKundenummerDialog.css";

const kundeTypes = {
  ORGANISASJONSNUMMER: "1",
  PERSONNUMMER: "2",
  KUNDENUMMER: "3"
};

const defaultUIState = {
  isHentet: false,
  inValid: new Set(),
  showErrors: false
};

const defaultKundeState = {
  kundeType: kundeTypes.ORGANISASJONSNUMMER,
  navn: "",
  kundenummer: "",
  personnummer: "",
  organisasjonsnummer: ""
};

const resultValues = result => {
  if (!result.data.result) {
    return defaultKundeState;
  }

  return {
    navn: result.data.result.name || "",
    kundenummer: result.data.result.customerNumber || "",
    personnummer: result.data.result.socialSecurityNumber || "",
    organisasjonsnummer: result.data.result.organizationNumber || ""
  };
};

/**
 * FRONT API methods (via ugeservice)
 */
const hentKundeVedKundenummer = async values => {
  const result = await axios.get(api.GET_KUNDE_URL(values.kundenummer));
  return { ...resultValues(result), kundenummer: values.kundenummer };
};

const hentKundeVedOrganisasjonsnummer = async values => {
  const result = await axios.get(api.GET_KUNDE_ORGANISASJON_URL(values.organisasjonsnummer));
  return { ...resultValues(result), organisasjonsnummer: values.organisasjonsnummer };
};

const hentKundeVedPersonnummer = async values => {
  const result = await axios.get(api.GET_KUNDE_PRIVAT_URL(values.personnummer));
  return { ...resultValues(result), personnummer: values.personnummer };
};

const opprettKunde = async values => {
  const result = await axios.post(api.POST_KUNDE_URL, {
    publicRegistryIdentifier: values.organisasjonsnummer || values.personnummer
  });

  return resultValues(result);
};

const EditKundenummerDialog = ({ show, onHide, onSave, kundenummer }) => {
  // State and Effect hooks

  const [kundeState, setKundeState] = useState({
    ...defaultKundeState,
    kundenummer,
    kundeType: kundenummer ? kundeTypes.KUNDENUMMER : kundeTypes.ORGANISASJONSNUMMER
  });

  const [uiState, setUIState] = useState(defaultUIState);

  useEffect(() => {
    if (kundenummer) {
      hentKundeVedKundenummer({ ...kundeState, kundenummer }).then(v => {
        setUIState({ ...defaultUIState, isHentet: true });
        setKundeState({ ...v, kundeType: kundeTypes.KUNDENUMMER });
      });
    }
  }, []);

  // Event handlers

  const processKundeState = values => {
    // Update kundeState and return the values so the bymform values can be updated with them
    if (values) {
      setKundeState(values); // update our own state
      return values; // return the values
    }
    return undefined;
  };

  const handleHentInfoButton = (values, hentKundeFn) => {
    if (uiState.inValid.size === 0) {
      return hentKundeFn(values).then(v => {
        setUIState({ ...defaultUIState, isHentet: true });
        return processKundeState({ ...v, kundeType: values.kundeType });
      });
    }
    setUIState({ ...uiState, showErrors: true });
    return undefined;
  };

  const handleValidate = (controlId, validationResult) => {
    // Update state without re-rendering
    if (validationResult.hasError) {
      uiState.inValid.add(controlId);
    } else {
      uiState.inValid.delete(controlId);
    }

    return validationResult;
  };

  const handleChange = (propertyName, values) => {
    switch (propertyName) {
      // Switching type resets the values
      case "kundeType":
        setUIState(defaultUIState);
        return processKundeState({ ...defaultKundeState, kundeType: values.kundeType });

      // Reset values when editing one of the fields, otherwise you can change one of the fields after having
      // retrieved info. This also disabled the Lagre button.
      // Not doing so could result in faulty values (e.g. change org.nummer after retrieving the kundenummer).
      case "organisasjonsnummer":
        setUIState({ ...uiState, isHentet: false });
        return processKundeState({
          ...defaultKundeState,
          kundeType: values.kundeType,
          organisasjonsnummer: values.organisasjonsnummer
        });
      case "personnummer":
        setUIState({ ...uiState, isHentet: false });
        return processKundeState({
          ...defaultKundeState,
          kundeType: values.kundeType,
          personnummer: values.personnummer
        });
      case "kundenummer":
        setUIState({ ...uiState, isHentet: false });
        return processKundeState({
          ...defaultKundeState,
          kundeType: values.kundeType,
          kundenummer: values.kundenummer
        });

      // Handle "Hent info" buttons
      case "hentKundeVedOrganisasjonsnummer":
        return handleHentInfoButton(values, hentKundeVedOrganisasjonsnummer);
      case "hentKundeVedPersonnummer":
        return handleHentInfoButton(values, hentKundeVedPersonnummer);
      case "hentKundeVedKundenummer":
        return handleHentInfoButton(values, hentKundeVedKundenummer);

      // Other form elements (except submit) should not do anything.
      default:
        break;
    }

    return undefined;
  };

  const handleSubmit = values => {
    // Create a kundenummer first if needed,
    // then save the values on the calling page.
    if (!values.kundenummer) {
      opprettKunde(values).then(onSave);
    } else {
      onSave(values);
    }
  };

  // Shared UI elements

  const orgInfo = (
    <Col md={12}>
      <table className="kundeInfo">
        <tr>
          <td>Organisasjonsnummer: </td>
          <td>{kundeState.organisasjonsnummer}</td>
        </tr>
        <tr>
          <td>Navn: </td>
          <td>{kundeState.navn}</td>
        </tr>
      </table>
    </Col>
  );

  const persInfo = (
    <Col md={12}>
      <table className="kundeInfo">
        <tr>
          <td>Personnummer: </td>
          <td>{kundeState.personnummer}</td>
        </tr>
        <tr>
          <td>Navn: </td>
          <td>{kundeState.navn}</td>
        </tr>
      </table>
    </Col>
  );

  const spacer = (
    <>
      <Row>
        <Col md={3} />
      </Row>
      <br />
    </>
  );

  const kundeTypeItems = [
    { id: kundeTypes.ORGANISASJONSNUMMER, name: "Organisasjon" },
    { id: kundeTypes.PERSONNUMMER, name: "Privatperson" },
    { id: kundeTypes.KUNDENUMMER, name: "Kundenummer" }
  ];

  // Return Main UI element to be rendered

  return (
    <Modal
      onHide={onHide}
      show={show}
      bsSize="large"
      dialogClassName="leieobjekt-dialog"
      allowFullScreen
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Rediger kundenummer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BymForm id="editKundenummer" values={kundeState} onChange={handleChange} onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <BymRadioButtonsInput inline hidelabel controlId="kundeType" items={kundeTypeItems} />
            </Col>
          </Row>
          {spacer}
          <TabContainer id="kundeTypeContainer" activeKey={kundeState.kundeType}>
            <TabContent>
              <TabPane eventKey={kundeTypes.ORGANISASJONSNUMMER}>
                <Row>
                  <Col md={3}>
                    <UgeFormControl
                      controlId="organisasjonsnummer"
                      label="Organisasjonsnummer"
                      rules={[length(9, 9, false), number()]}
                      alwaysShowError={uiState.showErrors}
                      onValidate={handleValidate}
                    />
                  </Col>
                  <Col md={1}>
                    <BymButton
                      controlId="hentKundeVedOrganisasjonsnummer"
                      style={{ marginTop: "24px", marginLeft: "-24px" }}
                      text="Hent info"
                    />
                  </Col>
                </Row>
                {spacer}
                <Row>{uiState.isHentet && orgInfo}</Row>
              </TabPane>
              <TabPane eventKey={kundeTypes.PERSONNUMMER}>
                <Row>
                  <Col md={3}>
                    <UgeFormControl
                      controlId="personnummer"
                      label="Personnummer"
                      rules={[length(11, 11, false), number()]}
                      alwaysShowError={uiState.showErrors}
                      onValidate={handleValidate}
                    />
                  </Col>
                  <Col md={1}>
                    <BymButton
                      controlId="hentKundeVedPersonnummer"
                      style={{ marginTop: "24px", marginLeft: "-24px" }}
                      text="Hent info"
                    />
                  </Col>
                </Row>
                {spacer}
                <Row>{uiState.isHentet && persInfo}</Row>
              </TabPane>
              <TabPane eventKey={kundeTypes.KUNDENUMMER}>
                <Row>
                  <Col md={3}>
                    <UgeFormControl
                      controlId="kundenummer"
                      label="Kundenummer"
                      rules={[number()]}
                      alwaysShowError={uiState.showErrors}
                      onValidate={handleValidate}
                    />
                  </Col>
                  <Col md={1}>
                    <BymButton
                      controlId="hentKundeVedKundenummer"
                      style={{ marginTop: "24px", marginLeft: "-24px" }}
                      text="Hent info"
                    />
                  </Col>
                </Row>
                {spacer}
                <Row>{uiState.isHentet && (kundeState.personnummer ? persInfo : orgInfo)}</Row>
              </TabPane>
            </TabContent>
          </TabContainer>
          {spacer}
          {spacer}
          <BymButton bsStyle="link" onClick={onHide} text="Avbryt" />
          <BymSubmitButton
            controlId="lagre"
            text="Lagre"
            disabled={!(kundeState.navn && (kundeState.organisasjonsnummer || kundeState.personnummer))}
          />
        </BymForm>
      </Modal.Body>
    </Modal>
  );
};

EditKundenummerDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default EditKundenummerDialog;
