import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./FilterCount.css";

class FilterCount extends PureComponent {
  static propTypes = {
    value: PropTypes.number
  };

  render() {
    const { value } = this.props;

    if (value == null || value <= 0) {
      return null;
    }

    return <span className="f-filter-count">{value}</span>;
  }
}

export default FilterCount;
