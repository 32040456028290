import * as fakturaAction from "../actions/fakturaAction";

const INITIAL_STATE = [];

export default function fakturaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case fakturaAction.GET_FAKTURAER_SUCCESS:
      return action.payload.result;
    case fakturaAction.GET_KONTRAKT_FAKTURAER_SUCCESS:
      return action.payload.result;
    case fakturaAction.GET_UTESTAAENDE_FAKTURAER_SUCCESS:
      return action.payload.result;
    default:
      return state;
  }
}
