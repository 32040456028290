import React from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { uniqBy, some } from "lodash-es";

import "./FakturaTotal.css";

const hasDoubleOrdreNummer = fakturaer => uniqBy(fakturaer, "ordreNummer").length < fakturaer.length;

const hasToLowPrice = fakturaer => some(fakturaer, f => f.totalPris && f.totalPris <= 0);

const hasToHighPrice = fakturaer => some(fakturaer, f => f.totalPris && f.totalPris >= 1000000);

const multipleFakturaer = fakturaer => uniqBy(fakturaer, "kontraktId").length < fakturaer.length;

const FakturaWarnings = ({ fakturaer = [] }) => {
  if (fakturaer.length < 1) return <></>;

  return (
    <>
      {hasDoubleOrdreNummer(fakturaer) && (
        <Alert bsStyle="danger" style={{ marginTop: "15px" }}>
          <h4>
            <strong>Doble ordrenummer</strong>
          </h4>
          <p>
            Det finnes en eller flere fakturaer med samme ordrenummer. Sjekk med systemadministrator for å få fikset
            feilen før fakturaer sendes til Agresso
          </p>
        </Alert>
      )}
      {hasToLowPrice(fakturaer) && (
        <Alert bsStyle="warning" style={{ marginTop: "15px" }}>
          <p>
            <strong>Advarsel lav totalpris!</strong> Det finnes en eller flere fakturaer med 0 eller negativ totalpris.
            Hvis du mistenker at dette er feil, verifiser at dette er riktig med forvalter av kontrakten før fakturaer
            sendes til Agresso.
          </p>
        </Alert>
      )}
      {hasToHighPrice(fakturaer) && (
        <Alert bsStyle="warning" style={{ marginTop: "15px" }}>
          <p>
            <strong>Advarsel høy totalpris!</strong> Det finnes en eller flere fakturaer med totalpris over 1.000.000kr.
            Hvis du mistenker at dette er feil, verifiser at dette er riktig med forvalter av kontrakten før fakturaer
            sendes til Agresso.
          </p>
        </Alert>
      )}
      {multipleFakturaer(fakturaer) && (
        <Alert bsStyle="warning" style={{ marginTop: "15px" }}>
          <p>
            <strong>Advarsel flere fakturaer pr kontrakt!</strong> Det finnes en eller flere kontrakter med mer enn 1
            faktura. Dette trenger ikke være feil, men hvis usikker så verifiser at dette er riktig med forvalter av
            kontrakten før fakturaer sendes til Agresso.
          </p>
        </Alert>
      )}
    </>
  );
};

FakturaWarnings.propTypes = {
  fakturaer: PropTypes.arrayOf(PropTypes.object)
};

export default FakturaWarnings;
