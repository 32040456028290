/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import { bindActionCreators } from "redux";
import moment from "moment";
import { getKontrakterWithEiendom } from "selectors/kontrakterSelector";
import * as eiendomAction from "actions/eiendomAction";
import * as kontraktAction from "actions/kontraktAction";
import * as fakturaAction from "actions/fakturaAction";
import { getEiendommer as getEiendommerSelector } from "selectors/eiendommerSelector";
import Forside from "components/Forside/Forside";
import axios from "services/axios";
import * as api from "constants/api";
import { getExpiresKontrakter } from "./ForsideUtils";
import { setFilter as setFilterAction } from "actions/advancedListAction";

const getUtestaaendeFakturaer = segment => {
  const url = segment
    ? `${api.GET_UTESTAAENDE_FAKTURAER_URL()}?segment=${segment}`
    : api.GET_UTESTAAENDE_FAKTURAER_URL();
  return axios.get(url);
};

const ForsideContainer = ({
  cookies,
  getEiendommer,
  getEiendommerBySegment,
  getKontrakterBySegment,
  getKontrakter,
  ...restProps
}) => {
  const [fakturaer, setFakturaer] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if (dataLoaded) return;

    const filter = {
      fornyes: true,
      maxUtlopDato: moment().add(6, "M").format()
    };

    const segment = cookies.get("uge_segment");
    if (segment) {
      getEiendommerBySegment(segment);
      getKontrakterBySegment(segment, filter);
    } else {
      getEiendommer();
      getKontrakter(filter);
    }
    getUtestaaendeFakturaer(segment).then(result => {
      setFakturaer(result.data.result);
    });
    setDataLoaded(true);
  }, [cookies, dataLoaded, getEiendommer, getEiendommerBySegment, getKontrakter, getKontrakterBySegment, restProps]);
  return <Forside fakturaer={fakturaer} {...restProps} />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...kontraktAction,
      ...eiendomAction,
      ...fakturaAction,
      setFilter: setFilterAction
    },
    dispatch
  );
}

const makeMapStateToProps = () => state => {
  const allKontrakter = getKontrakterWithEiendom(state);
  const kontrakter = getExpiresKontrakter(allKontrakter);
  return {
    kontrakter,
    eiendommer: getEiendommerSelector(state)
  };
};

export default withCookies(connect(makeMapStateToProps, mapDispatchToProps)(ForsideContainer));
