import { map, orderBy, values } from "lodash-es";
import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import makeGetAdvancedList from "./advancedListSelector";
import { obsKeysToString } from "../utils/utils";

const getKontraktIds = (state: any) => state.kontrakter.items;
const getKontraktEntities = (state: any) => state.entities.kontrakter;
const getEiendomEntities = (state: any) => state.entities.eiendommer;
const getSort = (state: any) => state.kontrakter.sort;

const getKontrakter = createSelector(
  [getKontraktIds, getKontraktEntities, getEiendomEntities, getSort],
  (ids, entities, eiendommer, sort) => {
    const cellKey = (sort && sort.cellKey) || "tittel";
    const orders = (sort && sort.orders) || "asc";
    return orderBy(
      map(ids, id => {
        const kontrakt = entities[id];
        if (eiendommer) {
          const eiendom = eiendommer[kontrakt.eiendomId];
          if (eiendom != null) {
            kontrakt.eiendom = eiendom;
          }
        }
        // eslint-disable-next-line no-underscore-dangle
        const _freetextSearchProperty = obsKeysToString(
          kontrakt,
          [
            "tittel",
            "eiendom.Navn",
            "kontraktStatus.navn",
            "kontraktType.navn",
            "faktureringType.navn",
            "betalingTermin.navn",
            "utlopDato"
          ],
          " "
        );
        return { ...kontrakt, _freetextSearchProperty };
      }),
      cellKey,
      orders
    );
  }
);

export default getKontrakter;

export const getKontraktSelector = (state: any, id: any) => state.entities.kontrakter[id];

export const getKontrakterWithEiendom = createSelector(
  [getKontraktEntities, getEiendomEntities],
  (kontrakter = {}, eiendommer = []) => {
    const allKontrakts = values(kontrakter);
    return map(allKontrakts, kontrakt => {
      return { ...kontrakt, eiendom: eiendommer[kontrakt.eiendomId] };
    });
  }
);

// Filtered and sorted list of eiendommer
export const getKontraktList = makeGetAdvancedList("kontrakter", getKontrakter);

// GET KONTRAKTER FOR EIENDOM (state, eiendomId)
const getEiendom = (state: any, eiendomId: string) => state.entities.eiendommer[eiendomId];
const getKontrakterE = (state: any) => state.entities.kontrakter;
export const getKontrakterForEiendom: any = createCachedSelector(
  getEiendom,
  getKontrakterE,
  (eiendom, kontrakter) => eiendom && map(eiendom.kontrakter, id => kontrakter[id])
)((state, eiendomId) => eiendomId);
