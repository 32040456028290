import { merge } from "lodash-es";

export default function kommentarer(state = {}, action) {
  switch (action.type) {
    default:
      if (action.payload && action.payload.entities && action.payload.entities.kommentarer) {
        return merge({}, state, action.payload.entities.kommentarer);
      }
      return state;
  }
}
