import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import Button from "react-bootstrap/lib/Button";
import { useAuth } from "react-oidc-context";
import Release5 from "./releases/Release5";
import { useSelector } from "react-redux";

const release_version_seen = "releaseVersionSeen";

function getLastReleaseSeen() {
  return window.localStorage.getItem(release_version_seen);
}

function setLastReleaseSeen(releaseVersion: number) {
  return window.localStorage.setItem(release_version_seen, String(releaseVersion));
}

export default function NewReleaseModal() {
  const [showReleaseNote, setShowReleaseNote] = useState(false);
  const auth = useAuth();
  const login = useSelector<any, any>(s => s.login);

  // øk dette tallet ved ny release
  const latestRelease = 5;

  useEffect(() => {
    if (!auth.isAuthenticated) return;

    const latestSeen = getLastReleaseSeen();
    if (latestSeen === null || Number(latestSeen) < latestRelease) {
      setShowReleaseNote(true);
      setLastReleaseSeen(latestRelease);
    }
  }, [auth.isAuthenticated]);

  return (
    <Modal bsSize="large" show={showReleaseNote} onHide={() => false}>
      <ModalHeader>
        <h4>Ny versjon av UGE</h4>
      </ModalHeader>
      <ModalBody>
        {/* Bytt ut releaseX med releaseY ved neste leveranse av betydning */}
        <Release5 />

        <Button onClick={() => setShowReleaseNote(false)}>Ok</Button>
      </ModalBody>
    </Modal>
  );
}
