import { JsonObject } from "type-fest";

export interface LeieObjekt {
  id: string;
  eiendomId: string;
  navn: string;
  beskrivelse: null;
  betingelser: null;
  leieObjektTypeId: LeieObjektTypeId;
  leieObjektType?: LeieObjektType;
  bygningsnummer: null;
  gyldigFra: null;
  gyldigTil: null;
  adresse2: string;
  byggeAr: number;
  bebygdAreal: null;
  bruksAreal: null;
  primarRom: null;
  sekundarRom: null;
  vernTypeId: null;
  vernType: null;
  stromMalernummer: null;
  stromMalepunkt: null;
  vannMalernummer: null;
  vannMalepunkt: null;
  bruksenhetNummer: string;
  gnr: null;
  bnr: null;
  linker: null;
  visIPublikumPortal: boolean;
  koordinater: null;
  opprettetDato: string;
  sistEndretDato: string;
  opprettetAv: string;
  opprettetAvNavn: string;
  sistEndretAv: string;
  sistEndretAvNavn: string;
  geometri: JsonObject;
}

export const LeieObjektTypeId = {
  Leilighet: 5001,
  Hytte: 5002,
  Driftsbygning: 5003,
  Garasje: 5004,
  Grunneiendom: 5005,
  Låve: 5006,
  Skogsstue: 5007,
  Sportsstue: 5008,
  StallLåve: 5009,
  Toalett: 5010,
  Uthus: 5011,
  Tilfluktsrom: 5012,
  Bolig: 5013,
  Hovedhus: 5014,
  Areal: 5101,
  Vann: 5102,
  Strøm: 5103,
  Avløp: 5104,
  Svømmebane: 5201,
  Basseng: 5202,
  Næringslokale: 5203,
  Stupetårn: 5204,
  Løpebane: 5205,
  Fotballbane: 5206,
  Håndballbane: 5207
} as const;
export type LeieObjektTypeId = typeof LeieObjektTypeId[keyof typeof LeieObjektTypeId];

export interface LeieObjektType {
  // segmenter: any[];
  id: number;
  navn: string;
  slettet: boolean;
  opprettetDato: string;
  sistEndretDato: string;
  opprettetAv: string;
  opprettetAvNavn: null;
  sistEndretAv: string;
  sistEndretAvNavn: null;
}
