import { filter, map, includes, toLower } from "lodash-es";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown, FormGroup, InputGroup, FormControl, Button, Image } from "react-bootstrap";
import { RootCloseWrapper } from "react-overlays";
import Checkbox from "../Checkbox";

import "./FilterMenu.css";
import sokIcon from "../../../images/søk.svg";
import tomIcon from "../../../images/tøm.svg";

class FilterMenu extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        checked: PropTypes.bool.isRequired,
        value: PropTypes.string
      })
    ).isRequired,
    onChange: PropTypes.func.isRequired
  };

  state = {
    text: ""
  };

  handleRootClose = event => {
    this.props.onClose(event, { source: "rootClose" });
  };

  handleChange = e => {
    this.setState({ text: e.target.value });
  };

  handleClear = () => {
    this.setState({ text: "" });
  };

  handleclose = e => {
    // Prevent react-bootstrap from shifting focus to toggle-button
    e.target.blur();

    this.props.onClose();
  };

  handleChangeCheckbox = e => {
    this.props.onChange(e.target.name, e.target.checked);
  };

  handleClear = e => {
    e.preventDefault();
    this.props.onClear && this.props.onClear();
  };

  render() {
    const { open, rootCloseEvent, items } = this.props;
    const { text } = this.state;
    const filteredItems =
      text === ""
        ? filter(items, item => !(item.value === undefined || item.value === null))
        : filter(items, item => (item.value === undefined ? false : includes(toLower(item.value), toLower(text))));

    const checkedItems = filter(filteredItems, item => item.checked) || [];
    const clearFilterClassName = checkedItems.length > 0 ? "visible" : "invisible";
    return (
      <RootCloseWrapper disabled={!open} onRootClose={this.handleRootClose} event={rootCloseEvent}>
        <Dropdown.Menu className="filter-menu">
          <FormGroup>
            <InputGroup>
              <FormControl className="filter-menu-input" type="text" value={text} onChange={this.handleChange} />
              <InputGroup.Addon className="fm-addon-search">
                <Image src={sokIcon} />
              </InputGroup.Addon>
              <InputGroup.Button className="fm-addon-clear">
                <Button onClick={this.handleClear}>
                  <Image src={tomIcon} />
                </Button>
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
          <div className="clear-filter">
            <a onClick={this.handleClear} className={clearFilterClassName}>
              fjern filter
            </a>
          </div>
          <ul className="filter-menu-list">
            {map(filteredItems, item => (
              <li key={item.id}>
                <Checkbox name={item.value} checked={item.checked} onChange={this.handleChangeCheckbox}>
                  {item.value}
                </Checkbox>
              </li>
            ))}
          </ul>
          <Button block className="fm-close" bsStyle="bym-standard" onClick={this.handleclose}>
            Lukk
          </Button>
        </Dropdown.Menu>
      </RootCloseWrapper>
    );
  }
}

export default FilterMenu;
