import * as React from "react";
import PropTypes from "prop-types";
import { Button, Glyphicon, Modal } from "react-bootstrap";
import { get } from "lodash-es";
import ExpandableDetailsList from "../Common/ExpandableDetailsList";
import KodeAdminDetails from "./KodeAdminDetails";
import KodeEditor from "./KodeEditor";
import ConfirmDialog from "../Common/ConfirmDialog";

const AddKodeDialog = ({ show, onHide, onAdd, title, ...rest }) => (
  <Modal onHide={onHide} show={show} backdrop="static">
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <KodeEditor submitButtonTitle={title} onSubmit={onAdd} onCancel={onHide} buttonPlacement="right" {...rest} />
    </Modal.Body>
  </Modal>
);

export default class KodeAdmin extends React.Component {
  state = {
    showAddDialog: false,
    kodeToDelete: null,
    kodeToUpdate: null,
    showEditDetails: false
  };

  onAddKode = kodeObject => {
    const { postKode, kodeNavn } = this.props;
    postKode(kodeNavn, kodeObject);
    this.setState({ showAddDialog: false });
  };

  onUpdateClicked = kodeObject => {
    this.setState({ kodeToUpdate: kodeObject });
  };

  updateKode = () => {
    const { updateKode, kodeNavn } = this.props;
    const { kodeToUpdate } = this.state;
    if (kodeToUpdate !== null) {
      updateKode(kodeNavn, kodeToUpdate);
      this.setState({ kodeToUpdate: null, showEditDetails: false });
    }
  };

  cancelUpdate = () => {
    this.setState({ kodeToUpdate: null });
  };

  makeKodeDetails = kodeObject => {
    const { extraFields, allowEdit } = this.props;
    const { showEditDetails } = this.state;
    if (showEditDetails) {
      return (
        <KodeEditor
          submitButtonTitle="Oppdater"
          onSubmit={item => this.onUpdateClicked(item)}
          onCancel={() => this.setState({ showEditDetails: false })}
          extraFields={extraFields}
          kodeObject={kodeObject}
        />
      );
    }
    return (
      <KodeAdminDetails
        kodeObject={kodeObject}
        extraFields={extraFields}
        allowEdit={allowEdit}
        onEditClicked={() => this.setState({ showEditDetails: true })}
      />
    );
  };

  onDeleteClicked = kodeObject => {
    this.setState({ kodeToDelete: kodeObject });
  };

  cancelDelete = () => {
    this.setState({ kodeToDelete: null });
  };

  deleteKode = () => {
    const { kodeToDelete } = this.state;
    const { deleteKode, kodeNavn } = this.props;
    if (kodeToDelete !== null) {
      deleteKode(kodeNavn, kodeToDelete.id);
      this.setState({ kodeToDelete: null });
    }
  };

  addKontonummer = () => {
    this.setState({ showAddDialog: true });
  };

  kodeSelected = () => {
    const { showEditDetails } = this.state;
    if (showEditDetails) this.setState({ showEditDetails: false });
  };

  render() {
    const { displayName, kodeNavn, extraFields, allowAdd, allowDelete } = this.props;
    const { kodeToUpdate, kodeToDelete, showAddDialog } = this.state;
    const kodeDisplayName = displayName ? displayName.toLowerCase() : kodeNavn;
    let kodeItems = get(this.props, kodeNavn) || [];
    kodeItems.sort((k1, k2) => k1.id - k2.id);
    kodeItems = kodeItems.map(kode => ({ ...kode, title: `${kode.id} - ${kode.navn}` }));
    return (
      <React.Fragment>
        {kodeToDelete !== null && (
          <ConfirmDialog
            title="Slett kode?"
            message={
              <div>
                {`Er du sikker på at du vil slette ${kodeDisplayName}:`}
                <br />
                <br />
                <em>{kodeToDelete.navn}</em>
              </div>
            }
            show
            onConfirm={this.deleteKode}
            onClose={this.cancelDelete}
          />
        )}
        {kodeToUpdate !== null && (
          <ConfirmDialog
            title="Oppdater kode?"
            message={
              <div>
                {`Er du sikker på at du vil oppdatere ${kodeDisplayName}:`}
                <br />
                <br />
                <em>{kodeToUpdate.navn}</em>
              </div>
            }
            show
            onConfirm={this.updateKode}
            onClose={this.cancelUpdate}
          />
        )}
        <AddKodeDialog
          onAdd={this.onAddKode}
          show={showAddDialog}
          title={`Legg til ${kodeDisplayName}`}
          kodeNavn={kodeNavn}
          extraFields={extraFields}
          onHide={() => this.setState({ showAddDialog: false })}
        />
        {allowAdd && (
          <div style={{ textAlign: "right", marginBottom: "15px" }}>
            <Button className="" bsStyle="bym-standard" onClick={() => this.setState({ showAddDialog: true })}>
              <Glyphicon glyph="plus" />
              {`Legg til ${kodeDisplayName}`}
            </Button>
          </div>
        )}
        <ExpandableDetailsList
          makeDetailsComponent={this.makeKodeDetails}
          items={kodeItems}
          itemSelected={this.kodeSelected}
          actionLinkText={allowDelete ? `Fjern ${kodeDisplayName}` : null}
          onActionClicked={item => this.onDeleteClicked(item)}
        />
      </React.Fragment>
    );
  }
}

KodeAdmin.propTypes = {
  displayName: PropTypes.string.isRequired,
  kodeNavn: PropTypes.string.isRequired,
  allowAdd: PropTypes.bool.isRequired,
  allowDelete: PropTypes.bool.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  postKode: PropTypes.func.isRequired,
  updateKode: PropTypes.func.isRequired,
  deleteKode: PropTypes.func.isRequired,
  extraFields: PropTypes.arrayOf(PropTypes.object).isRequired
};
