import { combineReducers } from "redux";

import createAdvancedListReducer from "./createAdvancedListReducer";
import entityReducers from "./entityReducer";

import loginReducer from "./loginReducer";
import eiendomReducer from "./eiendomReducer";
import leieObjektReducer from "./leieObjektReducer";
import kontraktReducer from "./kontraktReducer";
import tabReducer from "./tabReducer";
import koderReducer from "./koderReducer";
import fakturaReducer from "./fakturaReducer";
import toastReducer from "./toastReducer";

const rootReducer = combineReducers({
  entities: entityReducers,
  login: loginReducer,
  toast: toastReducer,
  eiendommer: createAdvancedListReducer("EIENDOMMER", eiendomReducer, { cellKey: "Navn", orders: "asc" }),
  leieobjekter: createAdvancedListReducer("LEIEOBJEKTER", leieObjektReducer, { cellKey: "navn", orders: "asc" }),
  kontrakter: createAdvancedListReducer("KONTRAKTER", kontraktReducer, { cellKey: "tittel", orders: "asc" }),
  fakturaer: createAdvancedListReducer("FAKTURAER", fakturaReducer, { cellKey: "tittel", orders: "asc" }),
  tab: tabReducer,
  koder: koderReducer
});

export default rootReducer;
