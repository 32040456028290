import { forEach, map } from "lodash-es";
import axios from "../../services/axios";
import * as api from "../../constants/api";
import * as handle from "../../helpers/actionHelper";
import { bilderListSchema, bilderSchema } from "../../constants/schemas";

// POST VEDLEGG
export const POST_BILDER_REQUEST = "POST_BILDER_REQUEST";
export const POST_BILDER_SUCCESS = "POST_BILDER_SUCCESS";
export const POST_BILDER_FAILURE = "POST_BILDER_FAILURE";

export function postBilder(eiendomId, filer) {
  return dispatch => {
    dispatch({
      type: POST_BILDER_REQUEST,
      payload: map(filer, file => file.name),
      meta: { eiendomId }
    });
    const data = new FormData();
    forEach(filer, file => data.append("Filer", file));

    return axios
      .post(api.POST_BILDER_URL(eiendomId), data)
      .then(
        handle.success(dispatch, POST_BILDER_SUCCESS, bilderListSchema, {
          eiendomId
        })
      )
      .catch(handle.error(dispatch, POST_BILDER_FAILURE));
  };
}

// DELETE VEDLEGG
export const DELETE_BILDER_REQUEST = "DELETE_BILDER_REQUEST";
export const DELETE_BILDER_SUCCESS = "DELETE_BILDER_SUCCESS";
export const DELETE_BILDER_FAILURE = "DELETE_BILDER_FAILURE";

export function deleteBilde(eiendomId, id) {
  return dispatch => {
    dispatch({
      type: DELETE_BILDER_REQUEST,
      payload: id,
      meta: { eiendomId }
    });
    return axios
      .delete(api.DELETE_BILDER_URL(eiendomId, id))
      .then(
        handle.success(dispatch, DELETE_BILDER_SUCCESS, bilderSchema, {
          eiendomId,
          id
        })
      )
      .catch(handle.error(dispatch, DELETE_BILDER_FAILURE));
  };
}
