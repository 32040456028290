import { update, cloneDeep, isArray, pull } from "lodash-es";

export function setWithArrayPush(state, path, item) {
  return update(cloneDeep(state), path, (array = []) => {
    if (!isArray(array)) throw new Error("Not an array");
    array.push(item);
    return array;
  });
}

export function setWithArrayPull(state, path, item) {
  return update(cloneDeep(state), path, (array = []) => {
    if (!isArray(array)) throw new Error("Not an array");
    pull(array, item);
    return array;
  });
}
