import { Link } from "react-router-dom";
import { getFormatedDate } from "../../utils/dateUtils";
import { Kontrakt } from "interfaces";
import { blirFakturert, expiresSoon } from "utils/kontraktUtils";

const KontraktListRow = ({ kontrakt }: { kontrakt: Kontrakt }) => (
  <tr>
    <td>
      <Link
        to={`/kontrakter/${kontrakt.id}`}
        style={!blirFakturert(kontrakt) || expiresSoon(kontrakt) ? { color: "#a71225" } : {}}
      >
        {kontrakt.tittel}
      </Link>
    </td>
    <td>{kontrakt.kontraktType?.navn}</td>
    <td>
      <Link to={`/eiendommer/${kontrakt?.eiendom?.id}`}>{kontrakt?.eiendom?.Navn}</Link>
    </td>
    <td>{kontrakt?.faktureringType?.navn}</td>
    <td style={!blirFakturert(kontrakt) ? { color: "#a71225" } : {}}>{kontrakt?.kontraktStatus?.navn}</td>
    <td>{kontrakt?.betalingTermin?.navn}</td>
    <td style={expiresSoon(kontrakt) ? { color: "#a71225" } : {}}>{getFormatedDate(kontrakt.utlopDato)}</td>
    <td>{getFormatedDate(kontrakt?.opprettetDato)}</td>
  </tr>
);

export default KontraktListRow;
