import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as vedleggAction from "../../actions/vedleggAction";
import getVedlegg from "../../selectors/vedleggSelector";
import VedleggSection from "../../components/Vedlegg/VedleggSection";

const mapStateToProps = (state, ownProps) => {
  return {
    vedlegg: getVedlegg(state, ownProps.eiendomId)
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(vedleggAction, dispatch);
}

const VedleggSectionContainer = connect(mapStateToProps, mapDispatchToProps)(VedleggSection);

VedleggSectionContainer.propTypes = {
  eiendomId: PropTypes.string
};

export default VedleggSectionContainer;
