import React from "react";
import { FormControl as BSFormControl } from "react-bootstrap";
import { get, map } from "lodash-es";
import BymFormControl from "./BymFormControl";
import { FormContextTypes } from "./BymForm";

const getBsSelectControl = (value, items, defaultText, controlId, readOnly, disabled) => (
  <BSFormControl
    readOnly={readOnly}
    disabled={disabled}
    id={controlId}
    componentClass="select"
    value={value}
    onChange={() => null}
  >
    <option value="">{defaultText}</option>
    {items &&
      map(items, item => {
        return (
          <option key={item.id} value={item.value || item.id}>
            {item.name || item.navn}
          </option>
        );
      })}
  </BSFormControl>
);

const BymSelectInput = ({ items = [], defaultText = "Velg", controlId, ...restProps }, context) => {
  const value = get(context.form.values, controlId);
  let textValue = get(items, value);
  textValue = textValue ? textValue.navn || textValue.value : value;
  const readOnly = context.form.readOnly || restProps.readOnly ? true : false;
  const disabled = context.form.disabled || restProps.disabled ? true : false;
  if (readOnly) {
    return <BymFormControl value={textValue} readOnly={readOnly} controlId={controlId} {...restProps} />;
  }
  return (
    <BymFormControl
      controlId={controlId}
      readOnly={readOnly}
      {...restProps}
      component={() => getBsSelectControl(value, items, defaultText, controlId, readOnly, disabled)}
    />
  );
};

BymSelectInput.contextTypes = FormContextTypes;

export default BymSelectInput;
