import { Modal, Button, ModalHeader, ModalBody, ModalTitle, ModalFooter, ModalProps } from "react-bootstrap";

interface InfoDialogProps extends ModalProps {
  message: string;
  title: string;
}

const InfoDialog = ({ message, title, ...rest }: InfoDialogProps) => (
  <Modal backdrop="static" style={{ display: "flex", alignItems: "center" }} {...rest}>
    {title && (
      <ModalHeader closeButton>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
    )}
    <ModalBody>
      <p>{message}</p>
    </ModalBody>
    <ModalFooter>
      <Button onClick={() => rest?.onHide()} bsStyle="link">
        Lukk
      </Button>
    </ModalFooter>
  </Modal>
);

export default InfoDialog;
