import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { get } from "lodash-es";
import { Image } from "react-bootstrap";
import moment from "moment";
import utvidIcon from "images/utvid.svg";

import deleteIcon from "images/delete.svg";
import FakturaDetails from "./FakturaDetails";
import "./FakturaList.css";

const FakturaListRow = ({
  id,
  kontrakt = {},
  kontraktId,
  fakturaStatus,
  ordreNummer,
  fakturaNummer,
  totalPris,
  kontonummerId,
  koststedId,
  hideKontraktLink,
  onDelete,
  fakturaDato,
  utvidetStatus,
  kreditnotaer,
  kreditertFaktura,
  fakturaType,
  faktura
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showKreditnotaer, setShowKreditnotaer] = useState(false);
  const [showOpprinneligFaktura, setShowOpprinneligFaktura] = useState(false);
  const title = kontrakt ? kontrakt.tittel : kontraktId;
  const kontraktTitle = hideKontraktLink ? title : <Link to={`/kontrakter/${kontraktId}`}>{title}</Link>;

  const getFakturaStatusClassName = () => {
    if (!fakturaDato || !fakturaStatus) return "";
    const forfallDato = moment(fakturaDato).add("days", 15);
    if (fakturaStatus.id === 4) return "fakturastatus-betalt";
    if (fakturaStatus.id === 3 && moment().isAfter(forfallDato)) return "fakturastatus-forfalt";
    return "";
  };

  return (
    <tbody aria-expanded={showDetails || showKreditnotaer}>
      <tr>
        <td>{kontraktTitle}</td>
        <td>
          <div className={getFakturaStatusClassName()}>{utvidetStatus}</div>
        </td>
        <td>{ordreNummer}</td>
        <td>{fakturaNummer}</td>
        <td>{kontonummerId}</td>
        <td>{koststedId}</td>
        <td>{get(kontrakt, "faktureringTidspunktId") === 501 ? "Forskudd" : "Etterskudd"} </td>
        <td align="right">{totalPris.toFixed(2)} kr</td>
        {onDelete && (
          <td>
            {fakturaStatus && fakturaStatus.id === 1 && (
              <a href onClick={() => onDelete(id)} style={{ cursor: "pointer" }}>
                <img src={deleteIcon} alt="" />
              </a>
            )}
          </td>
        )}
        <td align="center">
          <Image
            onClick={() => setShowDetails(!showDetails)}
            title={fakturaType === "faktura" ? "Faktura" : "Kreditnota"}
            className={`collapse-icon ${fakturaType}-icon` + (showDetails ? " expanded" : "")}
            src={utvidIcon}
          />
        </td>
        <td align="center">
          {kreditnotaer && kreditnotaer.length !== 0 && (
            <Image
              onClick={() => setShowKreditnotaer(!showKreditnotaer)}
              title="Kreditnotaer"
              className={"collapse-icon kreditnota-icon" + (showKreditnotaer ? " expanded" : "")}
              src={utvidIcon}
            />
          )}
          {kreditertFaktura && (
            <Image
              onClick={() => setShowOpprinneligFaktura(!showOpprinneligFaktura)}
              title="Opprinnelig faktura"
              className={"collapse-icon" + (showOpprinneligFaktura ? " expanded" : "")}
              src={utvidIcon}
            />
          )}
        </td>
      </tr>
      {showDetails && (
        <tr className="details">
          <td colSpan="10">
            <FakturaDetails faktura={faktura} />
          </td>
        </tr>
      )}
      {showOpprinneligFaktura && (
        <tr className="details">
          <td colSpan="10">
            <FakturaDetails faktura={kreditertFaktura} />
          </td>
        </tr>
      )}
      {showKreditnotaer && (
        <tr className="details">
          <td colSpan="10">
            {kreditnotaer.map(kreditnota => (
              <FakturaDetails faktura={kreditnota} />
            ))}
          </td>
        </tr>
      )}
    </tbody>
  );
};

FakturaListRow.propTypes = {
  kontrakt: PropTypes.object,
  kontraktId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  fakturaStatus: PropTypes.object,
  ordreNummer: PropTypes.number.isRequired,
  fakturaNummer: PropTypes.number,
  totalPris: PropTypes.number.isRequired,
  kontonummerId: PropTypes.number.isRequired,
  koststedId: PropTypes.number.isRequired,
  hideKontraktLink: PropTypes.bool,
  onDelete: PropTypes.func,
  fakturaDato: PropTypes.string
};

FakturaListRow.defaultProps = {
  onDelete: undefined,
  fakturaDato: undefined,
  fakturaNummer: undefined,
  hideKontraktLink: false
};

export default FakturaListRow;
