import classnames from "classnames";
import React from "react";
import { Checkbox, CheckboxProps, Image } from "react-bootstrap";
import "./BymCheckboxInternal.css";
import checkIcon from "images/avkrysning_ja.svg";
import uncheckIcon from "images/avkrysning_nei.svg";

const BymCheckboxInternal = ({ className, checked, onChange, children, ...rest }: CheckboxProps) => {
  return (
    <Checkbox className={classnames(className, "common-checkbox")} checked={checked} onChange={onChange} {...rest}>
      <Image src={checked === true ? checkIcon : uncheckIcon} />
      {children}
    </Checkbox>
  );
};

export default BymCheckboxInternal;
