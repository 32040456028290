import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { BymForm, BymTextInput, BymSelectInput, length, required } from "components/Common/Forms";
import { splitPath } from "../../../utils/stringUtils";

export default class AddArkivDocumentDialog extends React.Component {
  static propTypes = {
    file: PropTypes.shape({}).isRequired,
    onAdd: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired
  };

  state = {
    disableSubmitButton: false
  };

  onSubmit = (dokument, file) => {
    const { onAdd } = this.props;
    const returnDokument = {
      ...dokument,
      file
    };
    this.setState({ disableSubmitButton: true });
    onAdd(returnDokument);
  };

  render() {
    const { file, onHide } = this.props;
    const { disableSubmitButton } = this.state;
    const { tittel } = splitPath(file.name);

    const values = { tittel: tittel, filnavn: file.name };
    const docStatuses = [{ name: "Reservert", id: "R" }];
    const docCategories = [
      { name: "Internt notat uten oppfølging", id: "X" },
      { name: "Saksfremlegg/innstilling", id: "S" }
    ];

    return (
      <Modal onHide={onHide} show backdrop="static">
        <BymForm id="addArchiveDocument" values={values} onSubmit={dokument => this.onSubmit(dokument, file)}>
          <Modal.Header closeButton>
            <Modal.Title>Legg til arkiv dokument</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={10}>
                <BymTextInput controlId="tittel" label="*Dokument navn" rules={[required(), length(6, 56)]} />
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <BymTextInput controlId="filnavn" label="Filnavn" readOnly />
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <BymSelectInput controlId="status" label="*Status" items={docStatuses} rules={[required()]} />
              </Col>
              <Col md={5}>
                <BymSelectInput controlId="kategori" label="*Kategori" items={docCategories} rules={[required()]} />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide} bsStyle="link">
              Avbryt
            </Button>
            <Button type="submit" bsStyle="bym-action" disabled={disableSubmitButton}>
              Legg til
            </Button>
          </Modal.Footer>
        </BymForm>
      </Modal>
    );
  }
}
