import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tabs, Tab, TabPane, TabContent, TabContainer, Button, Alert, Row } from "react-bootstrap";
import LoggSection from "components/Logg/LoggSection";
import getBilder from "store/bilder/bilderSelector";
import { getLeieObjektSelector } from "selectors/leieObjektSelector";
import getEiendomSelector from "selectors/eiendomSelector";
import getKoderSelector from "selectors/koderSelector";
import loggResourceSelector from "selectors/loggResourceSelector";
import useActions from "store/useActions";
import * as leieObjektAction from "actions/leieObjektAction";
import { getKoder } from "actions/koderAction";
import { setToastSuccess } from "actions/toastAction";
import { getEiendom } from "actions/eiendomAction";

import { useParams } from "hooks/routerHooks";
import { get } from "lodash-es";
import Page from "../Layout/Page";
import PageSection from "../Layout/PageSection";
import LeieObjektDetails from "./LeieObjektDetails";
import LeieObjektEditor from "./LeieObjektEditor";
import BildePicker from "../Bilder/BildePicker";
import PublikumSettings from "./PublikumSettings";
import BymMap from "components/Common/Map/BymMap";
import "./LeieObjektPage.css";
import ShowOnlyForUsersWith from "auth/ShowOnlyFor";

const LeieObjektPage = () => {
  const { id } = useParams();
  const [isInitialized, setInitialized] = useState(false);

  const [isEditMode, setEditMode] = useState(false);
  const [showAddBildeDialog, setShowAddBildeDialog] = useState(false);

  const leieObjekt = useSelector(state => getLeieObjektSelector(state, id));
  const eiendom = useSelector(state => getEiendomSelector(state, get(leieObjekt, "eiendomId")));
  const eiendomBilder = useSelector(state => getBilder(state, get(leieObjekt, "eiendomId"))) || [];

  const vernTyper = useSelector(state => getKoderSelector(state, "verntyper"));
  const leieObjektTyper = useSelector(state => getKoderSelector(state, "leieobjekttyper"));
  const leieobjektLogg = useSelector(state => loggResourceSelector(state, id));

  const actions = useActions(
    {
      ...leieObjektAction,
      getKoder,
      setToastSuccess,
      getEiendom
    },
    []
  );

  useEffect(() => {
    if (!isInitialized) {
      actions.getKoder("verntyper");
      actions.getKoder("leieobjekttyper");
      actions.getLeieObjekt(id);
      setInitialized(true);
    }
    if (!eiendom && leieObjekt) {
      actions.getEiendom(leieObjekt.eiendomId);
    }
  }, [actions, eiendom, id, isInitialized, leieObjekt]);

  const subtitle = leieObjekt != null ? leieObjekt.navn : "laster...";
  const leieObjektBilder = eiendomBilder.filter(b => {
    const bilder = leieObjekt.bilder || [];
    return bilder.find(lb => b.fil.id === lb.filId);
  });

  const saveLeieObjekt = lo => {
    actions.editLeieObjekt(lo.eiendomId, lo.id, lo).then(() => {
      actions.setToastSuccess("Leieobjekt oppdatert");
      setEditMode(false);
    });
  };

  const saveLeieObjektBilder = bilder => {
    const updatedBilder = bilder.map(b => {
      return {
        leieObjektId: leieObjekt.id,
        rekkefolge: 0,
        filId: b.fil.id,
        eiendomId: b.eiendomId
      };
    });
    const updatedLeieObjekt = { ...leieObjekt, bilder: updatedBilder };
    saveLeieObjekt(updatedLeieObjekt);
  };

  return (
    <Page title={subtitle}>
      {showAddBildeDialog && (
        <BildePicker
          bilder={eiendomBilder}
          selected={leieObjektBilder}
          onHide={() => setShowAddBildeDialog(false)}
          onSave={b => {
            saveLeieObjektBilder(b);
            setShowAddBildeDialog(false);
          }}
        />
      )}
      {isEditMode && (
        <>
          <Button
            id="cancelButton"
            className="pull-right"
            bsStyle="bym-standard"
            type="button"
            onClick={() => setEditMode(false)}
            style={{ marginTop: "-44px" }}
          >
            Avbryt
          </Button>
          <LeieObjektEditor
            vernTyper={vernTyper}
            leieObjektTyper={leieObjektTyper}
            editObject={leieObjekt}
            eiendom={eiendom}
            onEdit={saveLeieObjekt}
          />
        </>
      )}
      {!isEditMode && (
        <>
          <ShowOnlyForUsersWith roles={["UGE-admin", "UGE-bruker"]}>
            <Button
              id="editButton"
              className="pull-right"
              bsStyle="bym-standard"
              type="button"
              onClick={() => setEditMode(true)}
              style={{ marginTop: "-44px" }}
            >
              Rediger
            </Button>
          </ShowOnlyForUsersWith>
          <LeieObjektDetails leieObjekt={leieObjekt} />
          {eiendom && eiendom.visIPublikumPortal && leieObjekt && (
            <>
              <PageSection title="BYleie.no innstillinger">
                <PublikumSettings leieObjekt={leieObjekt} saveLeieObjekt={saveLeieObjekt} />
              </PageSection>
              <PageSection
                id="LeieObjektBildeSection"
                title={`Bilder `}
                addText={eiendomBilder.length > 0 ? "Velg bilder" : undefined}
                showButton={false}
              >
                <Row>
                  <Alert>
                    Bildebehandling har blitt flyttet til byleie saksbehandling og settes nå på eiendomsnivå
                  </Alert>
                </Row>
              </PageSection>
            </>
          )}
        </>
      )}
      {eiendom && eiendom.visIPublikumPortal && !isEditMode && (
        <PageSection id="LeieObjektKartSection" title={`Kart`} mountOnEnter>
          <BymMap
            markers={
              eiendom?.koordinater
                ? [
                    {
                      kind: "point",
                      position: [eiendom?.koordinater.y, eiendom?.koordinater.x],
                      tooltipTitle: eiendom?.Navn,
                      showTooltip: true
                    },
                    {
                      kind: "geoJSON",
                      tooltipTitle: leieObjekt?.navn,
                      data: leieObjekt.geometri,
                      showTooltip: true
                    }
                  ]
                : []
            }
            autoBounds
          />
        </PageSection>
      )}
      <TabContainer id="leieobjekt-tabs-container">
        <Tabs id="leieobjekt-tabs" onSelect={() => undefined}>
          <Tab eventKey={1} title="Historikk" />
          <TabContent>
            <TabPane eventKey={1}>
              <LoggSection logg={leieobjektLogg} onUndo={() => undefined} />
            </TabPane>
          </TabContent>
        </Tabs>
      </TabContainer>
    </Page>
  );
};

export default LeieObjektPage;
