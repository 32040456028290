import React from "react";
import { get, map, includes } from "lodash-es";
import BymCheckboxInternal from "./BymCheckboxInternal";
import BymFormControl from "./BymFormControl";
import { FormContextTypes } from "./BymForm";

const getCheckBoxListControl = (checkedValues, items, onChange, controlId, readOnly, inline, valuePropertyName) => {
  return (
    <div className="bym-checkboxgroup">
      {items &&
        map(items, item => {
          const itemValue = item[valuePropertyName] || item.value || item.id;
          const checked = includes(checkedValues, itemValue);
          return (
            <BymCheckboxInternal
              data-bymtype="checkboxgroup"
              inline={inline}
              key={item.id}
              name={controlId}
              checked={checked}
              onChange={onChange}
              value={itemValue}
            >
              {item.name || item.navn}
            </BymCheckboxInternal>
          );
        })}
    </div>
  );
};

const BymCheckboxGroupInput = (
  { items = [], controlId, onChange, value, inline = false, valuePropertyName, ...restProps },
  context
) => {
  const checkedValues = value || get(context.form.values, controlId);
  const readOnly = context.form.readOnly || restProps.readOnly ? true : false;
  return (
    <BymFormControl
      controlId={controlId}
      readOnly={readOnly}
      {...restProps}
      component={() =>
        getCheckBoxListControl(checkedValues, items, onChange, controlId, readOnly, inline, valuePropertyName)
      }
    />
  );
};

BymCheckboxGroupInput.contextTypes = FormContextTypes;

export default BymCheckboxGroupInput;
