import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-bootstrap";

import "./PageAnonymous.css";

class PageAnonymous extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node
  };

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col>
            <div className="pageanonymous-header">
              <h1>{this.props.title}</h1>
            </div>
            <div className="pageanonymous-body">{this.props.children}</div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default PageAnonymous;
