import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../helpers/actionHelper";
import etag from "../utils/etagUtils";
import { kontraktListSchema, kontraktSchema, kundeSchema } from "../constants/schemas";

// GET KONTRAKTER
export const GET_KONTRAKTER_REQUEST = "GET_KONTRAKTER_REQUEST";
export const GET_KONTRAKTER_SUCCESS = "GET_KONTRAKTER_SUCCESS";
export const GET_KONTRAKTER_FAILURE = "GET_KONTRAKTER_FAILURE";

export function getKontrakter(filter) {
  return (dispatch, getState) => {
    dispatch({ type: GET_KONTRAKTER_REQUEST });
    const url =
      api.GET_KONTRAKTER_URL() +
      (filter
        ? "&" +
          Object.entries(filter)
            .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
            .join("&")
        : "");

    return axios
      .get(url, etag(getState, "kontrakter"))
      .then(handle.success(dispatch, GET_KONTRAKTER_SUCCESS, kontraktListSchema))
      .catch(handle.error(dispatch, GET_KONTRAKTER_FAILURE));
  };
}

export function getKontrakterBySegment(segmentId, filter) {
  return getKontrakter({ ...filter, segment: segmentId });
}

// GET KONTRAKT
export const GET_KONTRAKT_REQUEST = "GET_KONTRAKT_REQUEST";
export const GET_KONTRAKT_SUCCESS = "GET_KONTRAKT_SUCCESS";
export const GET_KONTRAKT_FAILURE = "GET_KONTRAKT_FAILURE";

export function getKontrakt(id) {
  return dispatch => {
    dispatch({ type: GET_KONTRAKT_REQUEST, payload: id });
    return axios
      .get(api.GET_KONTRAKT_URL(id))
      .then(handle.success(dispatch, GET_KONTRAKT_SUCCESS, kontraktSchema))
      .catch(handle.error(dispatch, GET_KONTRAKT_FAILURE));
  };
}

// GET KONTRAKT VEDLEGG
export const GET_KONTRAKT_SAK_REQUEST = "GET_KONTRAKT_SAK_REQUEST";
export const GET_KONTRAKT_SAK_SUCCESS = "GET_KONTRAKT_SAK_SUCCESS";
export const GET_KONTRAKT_SAK_FAILURE = "GET_KONTRAKT_SAK_FAILURE";

export function getKontraktSak(sakNummer) {
  return async dispatch => {
    dispatch({ type: GET_KONTRAKT_SAK_REQUEST, payload: sakNummer });
    try {
      const result = await axios.get(api.GET_KONTRAKT_SAK_URL(sakNummer));
      return handle.success(dispatch, GET_KONTRAKT_SAK_SUCCESS, null)(result);
    } catch (error) {
      return handle.error(dispatch, GET_KONTRAKT_SAK_FAILURE)(error);
    }
  };
}

// GET KONTRAKT VEDLEGG
export const GET_KONTRAKT_DOKUMENTER_REQUEST = "GET_KONTRAKT_DOKUMENTER_REQUEST";
export const GET_KONTRAKT_DOKUMENTER_SUCCESS = "GET_KONTRAKT_DOKUMENTER_SUCCESS";
export const GET_KONTRAKT_DOKUMENTER_FAILURE = "GET_KONTRAKT_DOKUMENTER_FAILURE";

export function getKontraktDokumenter(id) {
  return dispatch => {
    dispatch({ type: GET_KONTRAKT_DOKUMENTER_REQUEST, payload: id });
    return axios
      .get(api.GET_KONTRAKT_DOKUMENTER_URL(id))
      .then(handle.success(dispatch, GET_KONTRAKT_DOKUMENTER_SUCCESS, null))
      .catch(handle.error(dispatch, GET_KONTRAKT_DOKUMENTER_FAILURE));
  };
}

export const POST_KONTRAKT_VEDLEGG_REQUEST = "POST_KONTRAKT_VEDLEGG_REQUEST";
export const POST_KONTRAKT_VEDLEGG_SUCCESS = "POST_KONTRAKT_VEDLEGG_SUCCESS";
export const POST_KONTRAKT_VEDLEGG_FAILURE = "POST_KONTRAKT_VEDLEGG_FAILURE";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const dataUrl = reader.result;
      const index = dataUrl.indexOf("base64,");
      resolve(dataUrl.substring(index + 7));
    };
    reader.onerror = error => reject(error);
  });
}

export function postKontraktVedlegg(saknummer, tittel, file, status, kategori) {
  return async dispatch => {
    const fileAsString = await getBase64(file);
    const data = {
      vedleggListe: [
        {
          fileName: file.name,
          fileBytes: fileAsString
        }
      ]
    };

    try {
      const result = await axios.post(api.POST_KONTRAKT_VEDLEGG_URL(saknummer, tittel, kategori, status), data);
      return handle.success(dispatch, POST_KONTRAKT_VEDLEGG_SUCCESS, null, { saknummer })(result);
    } catch (error) {
      return handle.error(dispatch, POST_KONTRAKT_VEDLEGG_FAILURE)(error);
    }
  };
}

// POST KONTRAKT
export const POST_KONTRAKT_REQUEST = "POST_KONTRAKT_REQUEST";
export const POST_KONTRAKT_SUCCESS = "POST_KONTRAKT_SUCCESS";
export const POST_KONTRAKT_FAILURE = "POST_KONTRAKT_FAILURE";

export function postKontrakt(eiendomId, kontraktData) {
  return dispatch =>
    axios
      .post(api.POST_KONTRAKT_URL, kontraktData)
      .then(
        handle.success(dispatch, POST_KONTRAKT_SUCCESS, kontraktSchema, {
          eiendomId
        })
      )
      .catch(handle.error(dispatch, POST_KONTRAKT_FAILURE));
}

// EDIT KONTRAKT
export const EDIT_KONTRAKT_REQUEST = "EDIT_KONTRAKT_REQUEST";
export const EDIT_KONTRAKT_SUCCESS = "EDIT_KONTRAKT_SUCCESS";
export const EDIT_KONTRAKT_FAILURE = "EDIT_KONTRAKT_FAILURE";

export function editKontrakt(eiendomId, kontraktData) {
  return dispatch =>
    axios
      .put(api.EDIT_KONTRAKT_URL(kontraktData.id), kontraktData)
      .then(
        handle.success(dispatch, EDIT_KONTRAKT_SUCCESS, kontraktSchema, {
          eiendomId
        })
      )
      .catch(handle.error(dispatch, EDIT_KONTRAKT_FAILURE));
}

// - KUNDER -- DO NOT USE

// GET KUNDE
export const GET_KUNDE_REQUEST = "GET_KUNDE_REQUEST";
export const GET_KUNDE_SUCCESS = "GET_KUNDE_SUCCESS";
export const GET_KUNDE_FAILURE = "GET_KUNDE_FAILURE";

export function getKunde(kundeNummer) {
  return dispatch => {
    dispatch({ type: GET_KUNDE_REQUEST, payload: kundeNummer });
    return axios
      .get(api.GET_KUNDE_URL(kundeNummer))
      .then(handle.success(dispatch, GET_KUNDE_SUCCESS, kundeSchema))
      .catch(handle.error(dispatch, GET_KUNDE_FAILURE));
  };
}

// GET KUNDE VED PERSONNUMMER
export const GET_KUNDE_PRIVAT_REQUEST = "GET_KUNDE_PRIVAT_REQUEST";
export const GET_KUNDE_PRIVAT_SUCCESS = "GET_KUNDE_PRIVAT_SUCCESS";
export const GET_KUNDE_PRIVAT_FAILURE = "GET_KUNDE_PRIVAT_FAILURE";

export function getKundeByPersonNummer(personNummer) {
  return dispatch => {
    dispatch({ type: GET_KUNDE_PRIVAT_REQUEST, payload: personNummer });
    return axios
      .get(api.GET_KUNDE_PRIVAT_URL(personNummer))
      .then(handle.success(dispatch, GET_KUNDE_PRIVAT_SUCCESS, kundeSchema))
      .catch(handle.error(dispatch, GET_KUNDE_PRIVAT_FAILURE));
  };
}

// GET KUNDE VED ORGANISASJONSNUMMER
export const GET_KUNDE_ORGANISASJON_REQUEST = "GET_KUNDE_ORGANISASJON_REQUEST";
export const GET_KUNDE_ORGANISASJON_SUCCESS = "GET_KUNDE_ORGANISASJON_SUCCESS";
export const GET_KUNDE_ORGANISASJON_FAILURE = "GET_KUNDE_ORGANISASJON_FAILURE";

export function getKundeByOrganisasjonsNummer(organisasjonsNummer) {
  return dispatch => {
    dispatch({ type: GET_KUNDE_ORGANISASJON_REQUEST, payload: organisasjonsNummer });
    return axios
      .get(api.GET_KUNDE_ORGANISASJON_URL(organisasjonsNummer))
      .then(handle.success(dispatch, GET_KUNDE_ORGANISASJON_SUCCESS, kundeSchema))
      .catch(handle.error(dispatch, GET_KUNDE_ORGANISASJON_FAILURE));
  };
}
