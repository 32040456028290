import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const AdminRedirect = ({ isAdmin, children, appView, whenAppView, ...rest }) => {
  if (isAdmin && appView === whenAppView) {
    return <Redirect {...rest} />;
  }
  return <>{children}</>;
};

function mapStateToProps(state) {
  return {
    isAdmin: state.login && state.login.isAdmin,
    appView: state.login && state.login.appView
  };
}

export default connect(mapStateToProps)(AdminRedirect);
