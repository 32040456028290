import React from "react";
import PropTypes from "prop-types";
import ArkivFileLink from "./ArkivFileLink";
import Tag from "../Tag";

import "./ArkivDocumentLink.css";

const ArkivDocumentLink = ({ dokument }) => (
  <div className="arkiv-dokument">
    <div className="arkiv-dokument-title">
      <h4>{dokument.offisiellTittel}</h4>
      <div className="tags">
        <Tag tagStyle="default">{dokument.kategori.beskrivelse}</Tag>
        <Tag tagStyle="blue">{dokument.statusBeskrivelse}</Tag>
      </div>
    </div>
    <div className="arkiv-dokument-files">
      {dokument.filer.map((fil, index) => (
        <ArkivFileLink file={fil} fileNumber={index} dokument={dokument} key={index} />
      ))}
    </div>
  </div>
);

ArkivDocumentLink.propTypes = {
  dokument: PropTypes.shape({}).isRequired
};

export default ArkivDocumentLink;
