import * as React from "react";
import IndeksReguleringAdmin from "../../components/Admin/IndeksReguleringAdmin";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class IndeksReguleringAdminContainer extends React.Component {
  render = () => <IndeksReguleringAdmin {...this.props} />;
}

const mapStateToProps = (state, { ...ownPropsRest }) => {
  return {
    ...ownPropsRest
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IndeksReguleringAdminContainer);
