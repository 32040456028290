// import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import * as bs from "react-bootstrap";

// Import external styles
import "leaflet/dist/leaflet.css";
import "bootstrap/dist/css/bootstrap.css";

// Import internal styles
import "./styles/bym.css";

import configureStore from "./store/configureStore";
import App from "./App";
import ScrollToTop from "./components/Common/ScrollToTop";

import { AuthProvider } from "react-oidc-context";
import { oidcConfig } from "auth/oidcConfig";

bs.utils.bootstrapUtils.addStyle(bs.Navbar, "bym");
bs.utils.bootstrapUtils.addStyle(bs.Button, "bym-standard");
bs.utils.bootstrapUtils.addStyle(bs.Button, "bym-action");
bs.utils.bootstrapUtils.addStyle(bs.Button, "bym-positive");
bs.utils.bootstrapUtils.addStyle(bs.Button, "bym-negative");
bs.utils.bootstrapUtils.addStyle(bs.Button, "bym-negative-link");

const store = configureStore();

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <AuthProvider {...oidcConfig}>
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </BrowserRouter>
      </CookiesProvider>
    </Provider>
  </AuthProvider>,
  document.getElementById("root")
);
