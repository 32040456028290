import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Row, Col, Alert, Button } from "react-bootstrap";
import { BymForm, BymSelectInput } from "components/Common/Forms";
import moment from "moment";
import { some } from "lodash-es";
import { ReactComponent as SpinnerImage } from "images/spinner.svg";
import DeleteFakturaDialog from "components/Faktura/DeleteFakturaDialog";
import FakturaList from "components/Faktura/FakturaList";
import Page from "components/Layout/Page";
import TotalFakturaSum from "components/Faktura/FakturaTotal";
import getAllFakturaerInList, { getFakturaList, getFilteredFakturaer } from "selectors/fakturaerSelector";
import { getFakturaer, deleteKontraktFaktura } from "actions/fakturaAction";
import axios from "services/axios";
import { getToken } from "auth/authTools";
import { POST_FAKTURAER_URL, GET_FAKTURAER_AGRESSO_URL } from "constants/api";
import { routerMatchShape, routerHistoryShape } from "constants/proptypes";
import createPaginationControl from "containers/Common/createPaginationControl";
import { getMonths } from "utils/dateUtils";
import { isProduction } from "constants/environment";

const PaginationControl = createPaginationControl(
  "fakturaer",
  getFilteredFakturaer(f => f.fakturaType !== "kreditnota")
);

class AdminFakturaListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failedKontrakts: [],
      showDeleteDialog: false,
      deleteFakturaId: undefined,
      isGeneratingFaktura: false
    };
  }

  componentDidMount() {
    const currentDate = moment();
    const {
      match: {
        params: { year, month }
      },
      history
    } = this.props;
    if (!year || !month) {
      history.push(`/fakturaadministrasjon/${currentDate.year()}/${currentDate.month() + 1}`);
    }
    this.getFakturaer(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match } = this.props;
    if (!nextProps.match.params.year || !nextProps.match.params.month) {
      const currentDate = moment();
      nextProps.history.push(`/fakturaadministrasjon/${currentDate.year()}/${currentDate.month() + 1}`);
      return;
    }
    // if we have changed year or month reload fakturaer for the new year/month
    if (
      !match ||
      nextProps.match.params.year !== match.params.year ||
      nextProps.match.params.month !== match.params.month
    ) {
      this.getFakturaer(nextProps);
    }
  }

  /**
   * Only allow faktura generation one month ahead when in production
   */
  disableGenerateFaktura = () => {
    if (!isProduction()) return false;

    const { year, month } = this.getYearMonth();
    const yearMonth = moment({ year, month: month - 1 });
    const maxDate = moment().add(1, "months");
    return yearMonth.isSameOrAfter(maxDate);
  };

  onFormChange = (propertyName, values) => {
    const { history } = this.props;
    if ((propertyName === "ar" || propertyName === "maned") && values.ar && values.maned) {
      history.push(`/fakturaadministrasjon/${values.ar}/${values.maned}`);
    }
  };

  onGenerateFaktura = () => {
    const { year, month } = this.getYearMonth();
    this.setState({ isGeneratingFaktura: true });
    axios.post(POST_FAKTURAER_URL, { ar: year, maned: month }).then(response => {
      const failedKontrakts = response.data.result.failedKontrakts || [];
      const errorMessages = response.data.result.failedKontraktsErrorMessages || [];
      const failedKontraktsWithErrors = failedKontrakts.map((failedKontrakt, index) => ({
        ...failedKontrakt,
        errorMessage: errorMessages[index]
      }));
      this.setState({ failedKontrakts: failedKontraktsWithErrors, isGeneratingFaktura: false });
      this.getFakturaer(this.props);
    });
  };

  onDeleteFakturaClicked = fakturaId => {
    this.setState({ showDeleteDialog: true, deleteFakturaId: fakturaId });
  };

  getSelectableYears = () => {
    const yearsToAdd = isProduction() ? 0 : 3; // allow to generate faktura in the future in dev/test environments
    const firstYear = isProduction() ? 2018 : 2017;
    let currentYear = moment().add(1, "M").add(yearsToAdd, "y").year();
    const years = [];
    while (currentYear >= firstYear) {
      years.push({ name: currentYear.toString(), value: currentYear.toString() });
      currentYear -= 1;
    }
    return years;
  };

  getYearMonth = props => {
    const currentProps = props || this.props;
    const {
      match: {
        params: { year, month }
      }
    } = currentProps;
    return { year, month };
  };

  getFakturaer = props => {
    const currentDate = moment();

    const year = props.match.params.year || currentDate.year();
    const month = props.match.params.month || currentDate.month() + 1;
    props.getFakturaer(year, month);
  };

  deleteFaktura = deleteFakturaKommentar => {
    const { fakturaer = [], deleteKontraktFaktura: deleteFaktura } = this.props;
    const { deleteFakturaId } = this.state;
    const faktura = fakturaer.filter(f => f.id === deleteFakturaId)[0];
    if (faktura) {
      deleteFaktura(faktura.kontraktId, faktura.id, deleteFakturaKommentar).then(() => {
        this.getFakturaer(this.props);
        this.setState({ showDeleteDialog: false, deleteFakturaId: undefined });
      });
    }
    this.setState({ showDeleteDialog: true, deleteFakturaId: undefined });
  };

  isPreviousMonth = () => {};

  isAlreadyAvstemt = () => {
    const { fakturaer } = this.props;
    return some(fakturaer, faktura => faktura.fakturaStatusId === 7);
  };

  confirmSendToAgresso = e => {
    if (!window.confirm("Sikker på at du vil sende til Agresso?")) e.preventDefault();
  };

  render() {
    const { fakturaer, allFakturaerInList } = this.props;
    const { failedKontrakts, showDeleteDialog, isGeneratingFaktura } = this.state;
    const { year, month } = this.getYearMonth();
    const isAlreadyAvstemt = this.isAlreadyAvstemt();
    return (
      <Page title="Fakturaer" key={year + month}>
        <br />
        <Row>
          <Col md={8}>
            <BymForm
              id="fakturaAdmin"
              onChange={this.onFormChange}
              values={{
                maned: month,
                faktureringTypeId: "31001",
                ar: year
              }}
            >
              <Row>
                <Col md={4}>
                  <BymSelectInput hidelabel controlId="ar" items={this.getSelectableYears()} />
                </Col>
                <Col md={4}>
                  <BymSelectInput hidelabel controlId="maned" items={getMonths()} />
                </Col>
                <Col md={2}>
                  <Button
                    id="genererFakturaer"
                    disabled={isAlreadyAvstemt || this.disableGenerateFaktura() || isGeneratingFaktura}
                    type="button"
                    onClick={this.onGenerateFaktura}
                    bsStyle="bym-action"
                    style={{ paddingRight: "30px" }}
                  >
                    Generer fakturaer{" "}
                    {isGeneratingFaktura && (
                      <SpinnerImage
                        style={{ position: "absolute", top: "10px", right: "-7px" }}
                        height="18"
                        width="18"
                      />
                    )}
                  </Button>
                </Col>
              </Row>
            </BymForm>
          </Col>
          <Col md={2} align="right">
            <form method="post" action={GET_FAKTURAER_AGRESSO_URL} target="_blank" onSubmit={this.confirmSendToAgresso}>
              <input type="hidden" name="sps_access_token" value={getToken()} />
              <input type="hidden" name="ar" value={year} />
              <input type="hidden" name="maned" value={month} />
              <input type="hidden" name="tidspunkt" value={501} />
              <Button type="submit" bsStyle="bym-action" disabled={isAlreadyAvstemt}>
                Send Agresso: Forskudd
              </Button>
            </form>
          </Col>
          <Col md={2} align="right">
            <form method="post" action={GET_FAKTURAER_AGRESSO_URL} target="_blank" onSubmit={this.confirmSendToAgresso}>
              <input type="hidden" name="sps_access_token" value={getToken()} />
              <input type="hidden" name="ar" value={year} />
              <input type="hidden" name="maned" value={month} />
              <input type="hidden" name="tidspunkt" value={502} />
              <Button type="submit" bsStyle="bym-action" disabled={isAlreadyAvstemt}>
                Send Agresso: Etterskudd
              </Button>
            </form>
          </Col>
          <Col md={1} />
        </Row>
        <Row>
          <Col md={12}>
            {failedKontrakts && failedKontrakts.length > 0 && (
              <Alert bsStyle="danger">
                <h4> Fakturagenerering for følgende {failedKontrakts.length} kontrakter feilet</h4>
                <ul>
                  {failedKontrakts.map(failedKontrakt => (
                    <li>
                      <strong>{failedKontrakt.tittel}</strong> -{" "}
                      {failedKontrakt.eiendom && failedKontrakt.eiendom.Adresse}
                      <ul>
                        <li>
                          <em>{failedKontrakt.errorMessage}</em>
                        </li>
                      </ul>
                    </li>
                  ))}
                </ul>
              </Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DeleteFakturaDialog
              show={showDeleteDialog}
              onClose={() =>
                this.setState({
                  showDeleteDialog: false,
                  deleteFakturaId: undefined
                })
              }
              onConfirm={kommentar => this.deleteFaktura(kommentar)}
            />
            <PaginationControl storeKey="fakturaer" />
            <FakturaList
              fakturaer={fakturaer}
              allFakturaerInList={allFakturaerInList}
              isAdmin
              onDelete={this.onDeleteFakturaClicked}
              showWarnings
            />
            <PaginationControl storeKey="fakturaer" />
          </Col>
        </Row>
        <Row>
          <Col md={8} />
          <Col md={4}>
            <TotalFakturaSum fakturaer={fakturaer} />
          </Col>
        </Row>
      </Page>
    );
  }
}

AdminFakturaListPage.propTypes = {
  fakturaer: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tittel: PropTypes.string,
      eiendomNavn: PropTypes.string
    })
  ).isRequired,
  match: routerMatchShape.isRequired,
  history: routerHistoryShape.isRequired,
  deleteKontraktFaktura: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  fakturaer: getFakturaList(state).filter(f => f.fakturaType === "faktura"),
  allFakturaerInList: getAllFakturaerInList(state).filter(f => f.fakturaType === "faktura")
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getFakturaer, deleteKontraktFaktura }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminFakturaListPage);
