import PropTypes from "prop-types";
import { getGeoJSONFromPoint, getGeoJSONFromRings } from "components/Common/Map/BymMarker";
import { useHistory } from "react-router";
import BymMap from "components/Common/Map/BymMap";

export const getMarkerDataFromEiendommer = (eiendommer, history) => {
  let eiendomArray = Array.isArray(eiendommer) ? eiendommer : [eiendommer];
  // filter away eiendommer without koordinater
  eiendomArray = eiendomArray.filter(
    e =>
      (e.koordinater && e.koordinater.x !== 0 && e.koordinater.y !== 0) ||
      (e.koordinater && e.koordinater.rings && e.koordinater.rings.length > 0)
  );
  return eiendomArray.map(eiendom => {
    const properties = {
      Adresse: eiendom.Adresse,
      Postnummer: eiendom.Postnummer,
      Poststed: eiendom.Poststed,
      Kategori: eiendom.Kategori,
      Anleggsnummer: eiendom.Anleggsnummer,
      Eier: eiendom.Eier
    };

    const polygon = eiendom.koordinater.rings ? eiendom.koordinater.rings[0] : null;

    return {
      objectId: eiendom.id,
      kind: "geoJSON",
      data: polygon
        ? getGeoJSONFromRings([polygon], properties)
        : getGeoJSONFromPoint(eiendom.koordinater.x, eiendom.koordinater.y, properties),
      showTooltip: true,
      tooltipTitle: eiendom.Navn,
      onClick: () => history.push(`/eiendommer/${eiendom.id}`)
    };
  });
};

const EiendomMap = ({ eiendommer, ...mapProps }) => {
  const history = useHistory();
  if (!eiendommer) return null;
  const eiendomArray = Array.isArray(eiendommer) ? eiendommer : [eiendommer];
  const markers = getMarkerDataFromEiendommer(eiendomArray, history);
  return <BymMap {...mapProps} markers={markers} />;
};

EiendomMap.propTypes = {
  eiendommer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.shape({})]).isRequired
};

export default EiendomMap;
