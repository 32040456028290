import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../helpers/actionHelper";

// GET KODER
export const GET_KODER_REQUEST = "GET_KODER_REQUEST";
export const GET_KODER_SUCCESS = "GET_KODER_SUCCESS";
export const GET_KODER_FAILURE = "GET_KODER_FAILURE";

/**
 * returns action creator for fetching koder. Koder changes rarely
 * so we only dispatch action if the given koder is not in the store already or if forceReload is set to true
 */
export function getKoder(kodeNavn, forceReload = false) {
  return (dispatch, getState) => {
    const currentState = getState();
    if (!forceReload && currentState.koder && currentState.koder[kodeNavn]) {
      return;
    }
    return axios
      .get(`${api.GET_KODER_URL}${kodeNavn}`)
      .then(handle.success(dispatch, GET_KODER_SUCCESS, null, { kodeNavn: kodeNavn }))
      .catch(handle.error(dispatch, GET_KODER_FAILURE));
  };
}

// POST KODE
export const POST_KODER_REQUEST = "POST_KODER_REQUEST";
export const POST_KODER_SUCCESS = "POST_KODER_SUCCESS";
export const POST_KODER_FAILURE = "POST_KODER_FAILURE";

export function postKode(kodeNavn, kodeObject) {
  return async (dispatch, getState) => {
    try {
      const result = await axios.post(`${api.GET_KODER_URL}${kodeNavn}`, kodeObject);
      // reload koder after update
      getKoder(kodeNavn, true)(dispatch, getState);
      return handle.success(dispatch, POST_KODER_SUCCESS, null, { kodeNavn })(result);
    } catch (error) {
      return handle.error(dispatch, POST_KODER_FAILURE)();
    }
  };
}

// UPDATE KODE
export const UPDATE_KODER_REQUEST = "UPDATE_KODER_REQUEST";
export const UPDATE_KODER_SUCCESS = "UPDATE_KODER_SUCCESS";
export const UPDATE_KODER_FAILURE = "UPDATE_KODER_FAILURE";

export function updateKode(kodeNavn, kodeObject) {
  return async (dispatch, getState) => {
    try {
      const result = await axios.put(`${api.GET_KODER_URL}${kodeNavn}/${kodeObject.id}`, kodeObject);
      // reload koder after delete
      getKoder(kodeNavn, true)(dispatch, getState);
      return handle.success(dispatch, UPDATE_KODER_SUCCESS, null, { kodeNavn })(result);
    } catch (error) {
      return handle.error(dispatch, UPDATE_KODER_FAILURE)();
    }
  };
}

// DELETE KODE
export const DELETE_KODER_REQUEST = "DELETE_KODER_REQUEST";
export const DELETE_KODER_SUCCESS = "DELETE_KODER_SUCCESS";
export const DELETE_KODER_FAILURE = "DELETE_KODER_FAILURE";

export function deleteKode(kodeNavn, kodeId) {
  return async (dispatch, getState) => {
    try {
      const result = await axios.delete(`${api.GET_KODER_URL}${kodeNavn}/${kodeId}`);
      // reload koder after delete
      getKoder(kodeNavn, true)(dispatch, getState);
      return handle.success(dispatch, DELETE_KODER_SUCCESS, null, { kodeNavn })(result);
    } catch (error) {
      return handle.error(dispatch, DELETE_KODER_FAILURE)();
    }
  };
}
