import moment from "moment";
import "moment/locale/nb";

moment.locale("nb");

function getFormatedDate(dateString, format = "DD.MM.YYYY") {
  if (!dateString) {
    return "";
  }
  return moment(dateString).format(format);
}

function getFormatedDateTime(dateString, format = "DD.MM.YYYY, HH:mm", utc = true) {
  if (!dateString) {
    return "";
  }
  return moment(dateString).utc(utc).local().format(format);
}

function getFormatedTime(dateString, format = "HH:mm") {
  return getFormatedDateTime(dateString, format);
}

const getMonths = () => [
  { name: "Januar", value: "1" },
  { name: "Februar", value: "2" },
  { name: "Mars", value: "3" },
  { name: "April", value: "4" },
  { name: "Mai", value: "5" },
  { name: "Juni", value: "6" },
  { name: "Juli", value: "7" },
  { name: "August", value: "8" },
  { name: "September", value: "9" },
  { name: "Oktober", value: "10" },
  { name: "November", value: "11" },
  { name: "Desember", value: "12" }
];

function getWeekPeriodText(currentDate) {
  const startOfWeek = moment(currentDate).startOf("isoWeek");
  const endOfWeek = moment(currentDate).endOf("isoWeek");
  if (startOfWeek.month() === endOfWeek.month()) {
    return `${startOfWeek.format("DD")} - ${endOfWeek.format("DD")}. ${endOfWeek.format("MMMM")}`;
  }
  return `${startOfWeek.format("DD")}. ${startOfWeek.format("MMMM")} - ${endOfWeek.format("DD")}. ${endOfWeek.format(
    "MMMM"
  )}`;
}

export { getFormatedDate, getFormatedDateTime, getFormatedTime, getMonths, getWeekPeriodText };
