import { slice, filter, every, includes, orderBy, get, toLower, omit } from "lodash-es";
import { createSelector } from "reselect";

const filterEntities = (entities, filters) => {
  let freeTextFilter = filters.freetextSearchProperty;
  freeTextFilter = freeTextFilter ? freeTextFilter.toLowerCase() : undefined;
  const standardFilters = omit(filters, ["freetextSearchProperty"]);
  const filteredEntities = filter(entities, entity => {
    const passesStandardFilters = every(standardFilters, (values, key) =>
      values && values.length > 0 ? includes(values, get(entity, key)) : true
    );
    let passesFreetextFilter = true;
    // eslint-disable-next-line no-underscore-dangle
    if (freeTextFilter && entity._freetextSearchProperty) {
      // eslint-disable-next-line no-underscore-dangle
      passesFreetextFilter = entity._freetextSearchProperty.indexOf(freeTextFilter) > -1;
    }
    return passesStandardFilters && passesFreetextFilter;
  });
  return filteredEntities;
};

export const makeGetAdvancedListCount = (storeKey, getEntities) => {
  const getOptions = state => state[storeKey].filters;
  return createSelector([getEntities, getOptions], (entities, filters) => {
    const filteredEntities = filterEntities(entities, filters);
    return filteredEntities.length;
  });
};

const makeGetAdvancedList = (storeKey, getEntities) => {
  const getOptions = state => ({
    sort: state[storeKey].sort,
    count: state[storeKey].count,
    page: state[storeKey].page,
    filters: state[storeKey].filters
  });

  return createSelector([getEntities, getOptions], (entities, { sort, count, page, filters }) => {
    const filteredEntities = filterEntities(entities, filters);
    const cellKey = (sort && sort.cellKey) || "Navn";
    const orders = (sort && sort.orders) || "asc";
    const start = (page - 1) * count;
    return slice(orderBy(filteredEntities, [item => toLower(get(item, cellKey))], orders), start, start + count);
  });
};

export default makeGetAdvancedList;
