import React from "react";
import PropTypes from "prop-types";
import { Media, Image } from "react-bootstrap";
import profileImage from "../../images/profilbilde_small.svg";
import deleteImage from "../../images/delete.svg";
import editImage from "../../images/rediger.svg";
import KommentarEditor from "./KommentarEditor";
import ConfirmDialog from "../Common/ConfirmDialog";
import { getFormatedTime } from "../../utils/dateUtils";

export default class CommentItem extends React.Component {
  state = {
    isEditMode: false,
    showDeleteDialog: false,
    showEditButtons: false
  };

  mouseEnter = () => {
    const { login, opprettetAv, allowEdit } = this.props;
    const { isEditMode } = this.state;
    const isMyComment = login["bym-id"] === opprettetAv;
    const showEditButtons2 = allowEdit && !isEditMode && isMyComment;
    this.setState({ showEditButtons: showEditButtons2 });
  };

  mouseLeave = () => {
    this.setState({ showEditButtons: false });
  };

  render() {
    const {
      id,
      opprettetAvNavn,
      tekst,
      className,
      opprettetDato,
      editKommentar = () => null,
      deleteKommentar = () => null
    } = this.props;

    const date = new Date(opprettetDato);
    const { showDeleteDialog, isEditMode, showEditButtons } = this.state;
    return (
      <div
        className={`kommentar-item ${className}` || ""}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        <ConfirmDialog
          show={showDeleteDialog}
          message="Er du sikker på at du vil slette kommentaren?"
          title="Slett kommentar"
          confirmButtonText="Slett kommentar"
          confirmButtonStyle="bym-negative"
          onConfirm={() => {
            deleteKommentar(id);
            this.setState({ showDeleteDialog: false });
          }}
          onClose={() => this.setState({ showDeleteDialog: false })}
        />
        <Media>
          <Media.Left align="top">
            <Image src={profileImage} />
          </Media.Left>
          <Media.Body>
            <Media.Heading>{opprettetAvNavn}</Media.Heading>
            {isEditMode && (
              <KommentarEditor
                defaultValue={tekst}
                onCancel={() => this.setState({ isEditMode: false })}
                onSave={newText => {
                  editKommentar(newText);
                  this.setState({ isEditMode: false });
                }}
              />
            )}
            {!isEditMode && (
              <React.Fragment>
                <div>
                  <p>{tekst}</p>
                  <div className="kommentar-edit-buttons">
                    {showEditButtons && (
                      <React.Fragment>
                        <Image
                          title="Rediger kommentar"
                          src={editImage}
                          onClick={() => this.setState({ isEditMode: true })}
                        />
                        <Image
                          title="Slett kommentar"
                          src={deleteImage}
                          onClick={() => this.setState({ showDeleteDialog: true })}
                        />
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div>
                  <span>{`${date.toLocaleDateString("nb-NO")}, kl ${getFormatedTime(opprettetDato)}`}</span>
                </div>
              </React.Fragment>
            )}
          </Media.Body>
        </Media>
      </div>
    );
  }
}

CommentItem.propTypes = {
  id: PropTypes.string.isRequired,
  opprettetAvNavn: PropTypes.string.isRequired,
  tekst: PropTypes.string.isRequired,
  className: PropTypes.string,
  opprettetDato: PropTypes.string.isRequired,
  editKommentar: PropTypes.func.isRequired,
  deleteKommentar: PropTypes.func.isRequired,
  login: PropTypes.shape({}).isRequired,
  opprettetAv: PropTypes.string.isRequired,
  allowEdit: PropTypes.bool.isRequired
};

CommentItem.defaultProps = {
  className: undefined
};
