import { __RouterContext } from "react-router";
import { useContext } from "react";

export function useLocation() {
  const context = useContext(__RouterContext);
  return context.location;
}

export function useMatch() {
  const context = useContext(__RouterContext);
  return context.match;
}

export function useParams() {
  const context = useContext(__RouterContext);
  return context.match.params;
}
