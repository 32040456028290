import React from "react";
import { Radio } from "react-bootstrap";
import { get, map } from "lodash-es";
import BymFormControl, { BymFormControlProps } from "./BymFormControl";
import { FormContextTypes } from "./BymForm";

import "./BymRadioButtonsInput.css";

const getRadioButtonListControl: any = (
  selectedValue: any,
  items: any,
  onChange: any,
  controlId: any,
  readOnly: any,
  inline: any
) => (
  <div className="radio-buttons">
    {items &&
      map(items, item => {
        const itemValue = item.value || item.id;
        return (
          <Radio
            inline={inline}
            key={item.id}
            name={controlId}
            checked={selectedValue === itemValue}
            onChange={onChange}
            disabled={item.disabled === true}
            value={itemValue}
          >
            {item.name || item.navn}
          </Radio>
        );
      })}
  </div>
);

type BymRadioButtonsInputProps = BymFormControlProps & {
  items?: any[];
  // controlId?: string;
  onChange?: any;
  value?: any;
  inline?: boolean;
};

const BymRadioButtonsInput = (
  { items = [], controlId = "", onChange, value, inline = false, readOnly, ...restProps }: BymRadioButtonsInputProps,
  { form }: any
) => {
  const selectedValue = value || get(form.values, controlId);
  const readOnly2 = !!(form.readOnly || readOnly);
  return (
    <BymFormControl
      controlId={controlId}
      readOnly={readOnly2}
      {...restProps}
      component={() => getRadioButtonListControl(selectedValue, items, onChange, controlId, readOnly, inline)}
    />
  );
};

BymRadioButtonsInput.contextTypes = FormContextTypes;

export default BymRadioButtonsInput;
