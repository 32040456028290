import { Kode } from "interfaces";

function getKoderSelector<T extends Kode>(state: any, kodeNavn: string) {
  if (state.koder && state.koder[kodeNavn]) {
    const koder: T[] = state.koder[kodeNavn] || [];
    return koder.filter(kode => !kode.slettet);
  }
  return [];
}

export default getKoderSelector;
