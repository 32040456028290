import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { filter, isEmpty } from "lodash-es";

import { Tabs, Tab, TabPane, TabContent, TabContainer, Button, Glyphicon } from "react-bootstrap";
import Page from "components/Layout/Page";

import * as kontraktAction from "actions/kontraktAction";
import * as toastAction from "actions/toastAction";
import { getEiendom as getEiendomAction } from "actions/eiendomAction";
import * as kodeAction from "actions/koderAction";
// import { getKontraktSak as getKontraktSakAction } from "actions/kontraktAction";
import { getKontraktSelector } from "selectors/kontrakterSelector";
import { getKontraktFakturaList } from "selectors/fakturaerSelector";
import getKoderSelector from "selectors/koderSelector";
import getLeieObjekterForEiendom from "selectors/leieObjektSelector";
import eiendomSelector from "selectors/eiendomSelector";
import loggResourceSelector from "selectors/loggResourceSelector";

import KontraktDetails from "components/Kontrakt/KontraktDetails";
import KontraktEditor from "components/Kontrakt/KontraktEditor";
import KontraktNotat from "components/Kontrakt/KontraktNotat";
import ArkivDocumentLink from "components/Common/Arkiv/ArkivDocumentLink";
import AddArkivDocumentDialog from "components/Common/Arkiv/AddArkivDocumentDialog";
import ArkivInfoDialog from "components/Common/Arkiv/ArkivInfoDialog";
import { isProduction } from "constants/environment";
import * as fakturaAction from "actions/fakturaAction";
import KreditnotaSection from "containers/Faktura/KreditnotaSectionContainer";
import FakturaSection from "containers/Faktura/FakturaSectionContainer";
import LoggSection from "containers/Logg/LoggSectionContainer";
import AddKontraktAvtaleDialog from "components/Kontrakt/AddKontraktAvtaleDialog";

import PageSection from "components/Layout/PageSection";
import KommentarSectionContainer from "containers/Kommentar/KommentarSectionContainer";
import axios from "services/axios";
import * as api from "constants/api";
import { Eiendom, Kontrakt, LeieObjekt, NesteFakturering } from "interfaces";
import { blirFakturert } from "utils/kontraktUtils";
import Spinner from "components/Common/Spinner/Spinner";
import { ArkivSak, ArkivVedlegg } from "interfaces/Arkiv";
import ShowOnlyForUsersWith from "auth/ShowOnlyFor";
import { isAdminOrUgeBruker } from "auth/authTools";

interface KontraktPageProps {
  kontraktId: string;
  kontrakt: Kontrakt;
  kontraktslogg: any;
  kontraktTyper: any[];
  faktureringTyper: any[];
  faktureringTidspunkter: any[];
  betalingTerminTyper: any[];
  kontraktStatuser: any[];
  kostSteder: any[];
  kontoNummere: any[];
  prisTyper: any[];
  leieObjekter: LeieObjekt[];
  eiendom: Eiendom;
  fakturaer: any[];
  getKontrakt: (kontraktId: string) => Kontrakt;
  getEiendom: (eiendomId: string) => Eiendom;
  getKontraktFakturaer: (kontraktId: string) => any[];
  getKontraktSak: (saksnummer: string) => ArkivSak;
  getKoder: any;
  editKontrakt: any;
  setToastSuccess: any;
  setToastError: any;
  postKontraktVedlegg: any;
}

interface KontraktPageState {
  isEditMode: boolean;
  leieObjekterLoaded: boolean;
  dokumenterLoaded: boolean;
  dokumenterIsLoading: boolean;
  showArkivDialog: boolean;
  arkivUploadFile: any;
  vedlegg: ArkivVedlegg[];
  arkivSakLoaded: boolean;
  arkivsak?: ArkivSak;
  showArkivInfoDialog: boolean;
  showKontraktAvtaleDialog: boolean;
  nesteFakturering?: NesteFakturering;
}

class KontraktPage extends Component<KontraktPageProps, KontraktPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isEditMode: false,
      leieObjekterLoaded: false,
      dokumenterLoaded: false,
      dokumenterIsLoading: false,
      showArkivDialog: false,
      arkivUploadFile: undefined,
      vedlegg: [],
      arkivSakLoaded: false,
      arkivsak: undefined,
      showArkivInfoDialog: false,
      showKontraktAvtaleDialog: false
    };
  }

  componentDidMount() {
    const { getKontrakt, getKoder, kontraktId, kontraktslogg, kontrakt, getEiendom, getKontraktFakturaer } = this.props;
    getKontrakt(kontraktId);

    // Pre-fetch koder
    getKoder("kontrakttyper");
    getKoder("betalingterminer");
    getKoder("faktureringTyper");
    getKoder("faktureringTidspunkter");
    getKoder("kontraktStatuser");
    getKoder("kostSteder");
    getKoder("kontoNummere");
    getKoder("prisTyper");

    // Load logg via eiendom endpoint (NOT a good solution). Should be at least one log entry.
    if (isEmpty(kontraktslogg) && kontrakt.eiendomId) {
      getEiendom(kontrakt.eiendomId);
      this.setState({ leieObjekterLoaded: true });
    }
    this.getNesteFakturering(kontraktId);
    getKontraktFakturaer(kontraktId);
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(props: any) {
    const { leieObjekterLoaded, arkivSakLoaded } = this.state;
    // if we have no leieobjekter we don't know if that is because there are no leieobjekter for this eiendom
    // or if they are not loaded yet. So we load leieobjekter (via eiendom) once to be sure
    if (props.kontrakt.eiendomId && props.leieObjekter.length < 1 && !leieObjekterLoaded) {
      props.getEiendom(props.kontrakt.eiendomId);
      this.setState({ leieObjekterLoaded: true });
    }

    if (props.kontrakt.arkivSakNummer && !arkivSakLoaded) {
      this.setState({ arkivSakLoaded: true });
      const sak = await props.getKontraktSak(props.kontrakt.arkivSakNummer);
      this.setState({ arkivsak: sak });
    }
  }

  onKommentarSectionUpdated = () => {
    const { kontrakt, getEiendom } = this.props as any;

    // Reload history after the kommentar section is updated
    getEiendom(kontrakt.eiendomId);
  };

  getNesteFakturering = (kontraktId: string) => {
    axios
      .get(api.GET_KONTRAKT_NESTE_FAKTURERING_URL(kontraktId))
      .then(result => this.setState(prev => ({ ...prev, nesteFakturering: result.data.result })));
  };

  getKontraktDokumenter = async (arkivSaknummer: string) => {
    this.setState({ dokumenterIsLoading: true });
    const { getKontraktDokumenter } = this.props as any;
    const result = await getKontraktDokumenter(arkivSaknummer);
    this.setState({ vedlegg: result, dokumenterIsLoading: false, dokumenterLoaded: true });
  };

  handleFileSelected = async (e: any) => {
    this.setState({ showArkivDialog: true, arkivUploadFile: e.target.files[0] });
    e.target.value = null;
  };

  onAddArkivdokument = async (dokument: any) => {
    const { postKontraktVedlegg, kontrakt, setToastSuccess, setToastError } = this.props;
    try {
      const result = await postKontraktVedlegg(
        encodeURIComponent(kontrakt.arkivSakNummer),
        dokument.tittel,
        dokument.file,
        dokument.status,
        dokument.kategori
      );

      if (result) setToastSuccess("Dokument lastet opp til arkivet");
      else {
        setToastError({ message: "Feilet i å laste opp dokument til arkivet" });
      }
    } catch (ex) {
      setToastError(ex);
    } finally {
      this.getKontraktDokumenter(kontrakt.arkivSakNummer);
      this.hideArkivDialog();
    }
  };

  hideArkivDialog = () => {
    this.setState({ showArkivDialog: false, arkivUploadFile: undefined });
  };

  showFileSelector = () => {
    (this as any).fileInput.click();
  };

  editKontrakt = (kontrakt: any) => {
    this.setState({ isEditMode: false });
    const { editKontrakt, setToastSuccess, getEiendom } = this.props;
    editKontrakt(kontrakt.eiendomId, kontrakt).then(() => {
      setToastSuccess("Kontrakt oppdatert");
      this.getKontraktDokumenter(kontrakt.arkivSakNummer);
      if (kontrakt.eiendomId) getEiendom(kontrakt.eiendomId);
      this.getNesteFakturering(kontrakt.id);
    });
  };

  hasUnsentFakturas = () => {
    const { fakturaer = [] } = this.props;
    const unsentFakturaer = fakturaer.filter((f: any) => f.fakturaStatusId === 1);
    return unsentFakturaer.length > 0;
  };

  render() {
    const {
      kontrakt,
      kontraktId,
      editKontrakt,
      eiendom,
      leieObjekter,
      kostSteder,
      kontoNummere,
      kontraktslogg,
      getEiendom
    } = this.props;
    const {
      isEditMode,
      vedlegg,
      showArkivDialog,
      arkivUploadFile,
      arkivsak,
      showArkivInfoDialog,
      dokumenterIsLoading,
      dokumenterLoaded,
      nesteFakturering,
      showKontraktAvtaleDialog
    } = this.state;
    const arkivDokumenter = filter(vedlegg, v => v.filer.length > 0);
    let kontraktTittel = kontrakt.tittel;
    if (!blirFakturert(kontrakt)) {
      kontraktTittel += " (faktureres ikke)";
    }
    const kostStedSelectList = kostSteder.map((k: any) => ({ ...k, navn: `${k.id}: ${k.navn}` }));
    const kontoNummereSelectList = kontoNummere.map((k: any) => ({ ...k, navn: `${k.id}: ${k.navn}` }));

    return (
      <Page title={kontraktTittel} subtitle={undefined} className={undefined}>
        {showArkivDialog && (
          <AddArkivDocumentDialog
            file={arkivUploadFile}
            onAdd={this.onAddArkivdokument}
            onHide={this.hideArkivDialog}
          />
        )}
        <ShowOnlyForUsersWith roles={["UGE-admin", "UGE-bruker"]}>
          <div className="pull-right" style={{ marginTop: "-44px" }}>
            {kontrakt.kontraktStatusId === 1 &&
              kontrakt.kontraktMalId && ( // status "under arbeid"
                <Button
                  bsStyle="bym-standard"
                  type="button"
                  onClick={() => this.setState({ showKontraktAvtaleDialog: true })}
                >
                  Nytt kontraktdokument
                </Button>
              )}
            <Button
              bsStyle="bym-positive"
              type="button"
              onClick={() => this.setState({ isEditMode: true })}
              style={{ marginLeft: "10px" }}
            >
              Rediger
            </Button>
          </div>
        </ShowOnlyForUsersWith>
        <ShowOnlyForUsersWith roles={["UGE-admin", "UGE-bruker"]}>
          {showKontraktAvtaleDialog && (
            <AddKontraktAvtaleDialog
              kontraktData={kontrakt}
              eiendom={eiendom}
              leieObjekter={leieObjekter}
              show={showKontraktAvtaleDialog}
              onHide={() => this.setState({ showKontraktAvtaleDialog: false })}
              onSave={() => undefined as any}
            />
          )}
        </ShowOnlyForUsersWith>

        {isEditMode && (
          <KontraktEditor
            style={{ paddingTop: "10px" }}
            {...this.props}
            kostSteder={kostStedSelectList}
            kontoNummere={kontoNummereSelectList}
            editObject={kontrakt}
            showUnsentFakturaWarning={this.hasUnsentFakturas()}
            onEdit={this.editKontrakt}
            onCancel={() => this.setState({ isEditMode: false })}
          />
        )}
        {arkivsak && showArkivInfoDialog && (
          <ArkivInfoDialog arkivSak={arkivsak} onHide={() => this.setState({ showArkivInfoDialog: false })} />
        )}
        {!isEditMode && (
          <KontraktDetails
            arkivInfoClicked={() => this.setState({ showArkivInfoDialog: true })}
            kontrakt={kontrakt}
            eiendom={eiendom}
            arkivksak={arkivsak}
          />
        )}
        <PageSection
          title={
            <>
              {dokumenterLoaded && <span>Arkivdokumenter ({arkivDokumenter.length})</span>}
              {!dokumenterLoaded && !dokumenterIsLoading && <span>Arkivdokumenter (ikke hentet)</span>}
              {dokumenterIsLoading && (
                <span>
                  Arkivdokumenter <Spinner spinnerStyle="inline-spinner" />
                </span>
              )}
            </>
          }
          addText={isProduction() ? undefined : "Opprett dokument"}
          onAdd={this.showFileSelector}
          showButton={isAdminOrUgeBruker()}
          buttonsComponent={
            !dokumenterLoaded && !dokumenterIsLoading ? (
              <Button
                bsStyle="bym-standard"
                style={{ marginRight: isProduction() ? "0px" : "20px" }}
                type="button"
                onClick={() => this.getKontraktDokumenter(kontrakt.arkivSakNummer)}
              >
                <Glyphicon glyph="arrow-down" />
                Hent dokumenter
              </Button>
            ) : undefined
          }
        >
          {!dokumenterLoaded && <span>Hent dokumenter med &quot;Hent dokumenter&quot; knappen</span>}
          <ul className="list-unstyled vedlegg-list">
            {arkivDokumenter.map(dokument => (
              <li key={dokument.id}>
                <ArkivDocumentLink dokument={dokument} />
              </li>
            ))}
          </ul>
          <input
            ref={r => {
              (this as any).fileInput = r;
            }}
            type="file"
            accept=".jpg, .jpeg, .png, .txt, .pdf, .docx, .doc, .pptx, .ppt, .xlsx, .xls"
            autoFocus
            style={{ display: "none" }}
            onChange={this.handleFileSelected}
          />
        </PageSection>
        <FakturaSection
          kontraktId={kontraktId}
          kontrakt={kontrakt}
          nesteFakturering={nesteFakturering?.nesteFakturering}
          faktureringFerdigDenneManed={nesteFakturering?.faktureringFerdigDenneManed}
        />
        <KreditnotaSection kontraktId={kontraktId} kontrakt={kontrakt} />
        <TabContainer id="kontrakt-tabs-container">
          <Tabs id="kontrakt-tabs" onSelect={(this as any).onTabSelected}>
            <Tab eventKey={1} title="Kommentarer" />
            <Tab eventKey={2} title="Notat" />
            <Tab eventKey={3} title="Historikk" />
            <TabContent>
              <TabPane eventKey={1}>
                <KommentarSectionContainer
                  eierType="kontrakter"
                  eierId={kontraktId}
                  sectionUpdated={this.onKommentarSectionUpdated}
                  allowEdit={false}
                />
              </TabPane>
              <TabPane eventKey={2}>
                <KontraktNotat
                  editObject={kontrakt}
                  onEdit={editKontrakt}
                  afterEditSaved={() => {
                    getEiendom(kontrakt.eiendomId);
                  }}
                />
              </TabPane>
              <TabPane eventKey={3}>
                <LoggSection logg={kontraktslogg} onUndo={() => {}} />
              </TabPane>
            </TabContent>
          </Tabs>
        </TabContainer>
      </Page>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const kontraktId = ownProps.match.params.id;
  const kontrakt = getKontraktSelector(state, kontraktId) || {};
  let leieObjekter = [];
  let eiendom = null;
  if (kontrakt && kontrakt.eiendomId) {
    leieObjekter = getLeieObjekterForEiendom(state, kontrakt.eiendomId);
    eiendom = eiendomSelector(state, kontrakt.eiendomId);
  }
  const allFakturaer = getKontraktFakturaList(state);
  const fakturaer = allFakturaer.filter(f => f.fakturaType === "faktura");

  return {
    kontraktId,
    kontrakt,
    kontraktslogg: loggResourceSelector(state, kontraktId),
    kontraktTyper: getKoderSelector(state, "kontrakttyper"),
    faktureringTyper: getKoderSelector(state, "faktureringTyper"),
    faktureringTidspunkter: getKoderSelector(state, "faktureringTidspunkter"),
    betalingTerminTyper: getKoderSelector(state, "betalingterminer"),
    kontraktStatuser: getKoderSelector(state, "kontraktStatuser"),
    kostSteder: getKoderSelector(state, "kostSteder"),
    kontoNummere: getKoderSelector(state, "kontoNummere"),
    prisTyper: getKoderSelector(state, "prisTyper"),
    leieObjekter,
    eiendom,
    fakturaer
  };
};

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      ...kontraktAction,
      getKoder: kodeAction.getKoder,
      getEiendom: getEiendomAction,
      ...toastAction,
      ...fakturaAction
    } as any,
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(KontraktPage);
